import React from 'react';
import { useTranslation } from 'react-i18next';
import hello from 'hellojs';
import { parse } from 'query-string';
import { Button } from 'reactstrap';
import { SECRETS } from '../_config';
import { useUser } from '../hooks';
import { Icon } from '.';

export const SocialLogin = ({ className, ...props }) => {
    const { location = {} } = props;
    const { search = '' } = location;
    const { t } = useTranslation();
    const { createUserBySocialLogin } = useUser();
    const { referral = '' } = parse(search);

    const handleSocialLogin = (event) => {
        const provider = event.currentTarget.dataset.provider;
        const announcementsYes = document.getElementById('CommunicationYes');
        const announcementsNo = document.getElementById('CommunicationNo');
        const terms = document.getElementById('Terms');
        let announcements;
        if (announcementsYes && announcementsNo && terms) {
            announcements = announcementsYes.checked;
            if (
                (!announcementsYes.reportValidity() && !announcementsNo.reportValidity()) ||
                !terms.reportValidity()
            ) {
                return;
            }
        }

        hello.init(SECRETS.SOCIAL_LOGIN_KEYS, {
            redirect_uri: '/',
            oauth_proxy: `${URL.API}/social/${provider}`,
        });

        hello(provider)
            .login({ scope: 'email' })
            .then(
                () => hello(provider).api('me'),
                (response) => console.error(`Signin error: ${response.error.message}`)
            )
            .then(
                (json) => {
                    createUserBySocialLogin({
                        announcements,
                        referral,
                        provider,
                        ...json,
                    });
                },
                (response) => {
                    console.error('KO >>>', response.error);
                }
            );
    };

    return (
        <div className="social-login" data-or={t('common.or')}>
            <Button
                className="social-button"
                color="facebook"
                outline
                data-provider="facebook"
                onClick={handleSocialLogin}
            >
                <Icon type="facebook" />
            </Button>
            <Button
                className="social-button"
                color="twitter"
                outline
                data-provider="twitter"
                onClick={handleSocialLogin}
            >
                <Icon type="twitter" />
            </Button>
            <Button
                className="social-button"
                color="google"
                outline
                data-provider="google"
                onClick={handleSocialLogin}
            >
                <Icon type="google" />
            </Button>
        </div>
    );
};
