export * from './constants';

export const APPS = {
    DOYO: 'https://doyo.tech',
    DIXIBOX: 'https://dixibox.com',
    GRANSFER: 'https://gransfer.com',
};

export const MODALS = {
    ADVERTISEMENT: 'advertisement',
    APP_INFO: 'appinfo',
    APPLICATIONS: 'applications',
    BARS: 'bars',
    MY_ACCOUNT: 'myaccount',
    PUBLISH: 'publish',
    RESOURCES: 'resources',
    TOOLS: 'tools',
    UPGRADE: 'upgrade',
    WELCOME: 'welcome',
};

export const PAGES = {
    ACCOUNT: '/account',
    APPLICATIONS: '/applications',
    BILLING_DATA: '/billing/data',
    BILLING_HISTORY: '/billing/history',
    BILLING_PAYMENT: '/billing/payment',
    BUY_DOYO_COINS: '/buy/doyocoins',
    FORGOT: '/forgot',
    HOME: '/',
    MAINTENANCE: '/maintenance',
    LEGAL_POLICIES: '/legal/policies',
    LEGAL_TERMS: '/legal/terms',
    PASSWORD_RESET: '/password/reset',
    PREMIUM_PLANS: '/premium/plans',
    PUBLISH: '/publish',
    RESOURCES: '/resources',
    SIGN_IN: '/signin',
    SIGN_UP: '/signup',
    SUBSCRIPTION: '/subscription',
};
