import Analytics from 'analytics';
import googleAnalytics from '@analytics/google-analytics';
import { SECRETS } from '../_config';

export const createAnalyticsContext = () =>
    Analytics({
        app: 'Doyo',
        plugins: [
            googleAnalytics({
                trackingId: SECRETS.ANALYTICS,
            }),
        ],
        debug: process.env.NODE_ENV === 'development',
    });
