import React, { useCallback } from 'react';
import { Sortable } from '../../../components';
import { DRAG_ITEM_TYPE, ELEMENT_TYPE } from '../../../context';
import { useElements } from '../../../hooks';
import { Element, ElementWrapper } from '.';

export const BODY_TYPE = {
    VERTICAL: 'vertical',
    HORIZONTAL: 'horizontal',
};

export const BODY_ICON = {
    [BODY_TYPE.VERTICAL]: 'table-column',
    [BODY_TYPE.HORIZONTAL]: 'table-row',
    VERTICAL: 'table-column',
    HORIZONTAL: 'table-row',
};

export const Body = React.memo(({ element }) => {
    const { elements = [] } = useElements();
    const { concrete = {}, id } = element;
    const { bodyType } = concrete;
    const childElements = elements
        .filter(({ IDParentElement }) => IDParentElement === id)
        .sort((e1, e2) => (e1.Sequence < e2.Sequence ? -1 : 1));
    const data = { 'data-body-type': bodyType, 'data-loaded': true };
    const sortableData = {
        'data-accept': `${
            bodyType === BODY_TYPE.VERTICAL ? DRAG_ITEM_TYPE.ELEMENT : BODY_TYPE.VERTICAL
        }`,
        'data-not-accept': ELEMENT_TYPE.BAR,
    };

    if (bodyType === BODY_TYPE.VERTICAL) {
        sortableData[
            'data-not-accept'
        ] = `${sortableData['data-not-accept']},${BODY_TYPE.VERTICAL}`;
    }

    const isSomeDescendantCurrent = useCallback(
        (parent = element) => {
            const children = elements.filter(
                ({ IDParentElement }) => IDParentElement === parent.id
            );
            return children.some((element) => {
                const { current, IDParentElement } = element;
                if (IDParentElement === parent.id && current) {
                    return true;
                }
                return isSomeDescendantCurrent(element);
            });
        },
        [element, elements]
    );

    return (
        <ElementWrapper
            element={element}
            className={isSomeDescendantCurrent() ? 'descendant--current' : ''}
            IDElementType={ELEMENT_TYPE.BODY}
            data={data}
        >
            <Sortable
                key={`ElementContent-${element.id}-${childElements
                    .map(({ lastUpdated }) => lastUpdated)
                    .join('-')}`}
                className="element-content"
                group={{ name: 'Elements', pull: true, put: ['DragItems', 'Elements'] }}
                data={sortableData}
            >
                {childElements.map((element) => (
                    <Element key={`Element${element.id}`} element={element} />
                ))}
            </Sortable>
        </ElementWrapper>
    );
});
