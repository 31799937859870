import { useCallback, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import ls from 'local-storage';
import mimeTypes from '../_helpers/mimeTypes.json';
import { useResources, useScreens } from '../hooks';
import noIframe from '../assets/img/no-iframe.png';

const mimetypes = Object.keys(mimeTypes)
    .reverse()
    .join('|');
const regex = new RegExp(`['"]*[./]*([\\w\\-/]+\\.(${mimetypes}))[?]*[#\\w]*(['"]*)`, 'ig');
const tagSource = {
    src: 'script',
    href: 'style',
};

export const useMutationObserver = (externalRef) => {
    const { t } = useTranslation();
    const { getContent, getContentReplaced, resources = [] } = useResources();
    const { screen = {} } = useScreens();
    const observers = useRef([]);
    let { concrete = {} } = screen;
    concrete = concrete || {};
    const { idZip } = concrete;
    const zipRes = resources.find(({ id }) => id === idZip) || {};
    const { entries = [], jsonProperties, updated = (zipRes.entries || []).length } = zipRes;

    const updateIframeContent = useCallback(
        (iframe) => {
            const contentWindow = iframe.contentWindow;
            if (jsonProperties && contentWindow) {
                contentWindow.postMessage(jsonProperties, window.location.origin);
            }
        },
        [jsonProperties, updated]
    );

    useEffect(() => {
        externalRef.current && updateIframeContent(externalRef.current);
    }, [updated]);

    const replaceContent = useCallback(
        async (iframe) => {
            const { attributes = {} } = iframe;
            const { src = {} } = attributes;
            const entry = entries.find(({ url }) => url === src.value);
            const html = await getContent(entry, zipRes);
            const parser = new DOMParser();
            const doc = parser.parseFromString(html, 'text/html');
            let elements =
                doc.querySelectorAll('[style],a[href],link[href],script[src],img[src],style') || [];
            elements = Object.values(elements);
            Promise.all(
                elements.map(
                    (element) =>
                        new Promise((resolve, reject) => {
                            replaceElement(element, { entries, resolve, reject });
                        })
                )
            ).then(() => {
                const iframeDocument = iframe && iframe.contentDocument;
                if (
                    !iframeDocument ||
                    doc.documentElement.outerHTML === '<html><head></head><body>null</body></html>'
                ) {
                    return;
                }
                iframeDocument.open();
                iframeDocument.write(doc.documentElement.outerHTML);
                iframeDocument.close();

                initObserver(iframe);
            });
        },
        [entries]
    );

    const replaceElement = useCallback(
        async (element, { entries = zipRes.entries || [], resolve, reject }) => {
            const { attributes, tagName } = element;
            let { src, href } = attributes;
            src = src && src.nodeValue;
            href = href && href.nodeValue;
            if (tagName === 'STYLE') {
                element.innerHTML = getHtmlReplaced(element.innerHTML);
                return resolve();
            }
            if (element.attributes.style && element.attributes.style.value) {
                element.attributes.style.value = getHtmlReplaced(element.attributes.style.value);
                return resolve();
            }
            if (
                (!src && !href) ||
                (src && !src.includes('blob:') && src.includes('://')) ||
                (href && !href.includes('blob:') && href.includes('://'))
            ) {
                return resolve();
            }

            let entry = entries.find(
                ({ name }) => (src && name === src) || (href && name === href)
            );
            if (tagName === 'A') {
                if (entry) {
                    element.setAttribute('href', entry.url);
                }
                return resolve();
            }
            if (tagName === 'IMG' && entry) {
                element.setAttribute('src', entry.url);
                return resolve();
            }

            const source = (src && 'src') || (href && 'href');
            entry = entries.find(({ name }) => name === attributes[source].nodeValue);

            getContentReplaced(entry, zipRes).then((content) => {
                window.$(element).before(`<${tagSource[source]}>${content}</${tagSource[source]}>`);
                element.remove();
                return resolve();
            });
        },
        [entries]
    );

    const getHtmlReplaced = useCallback(
        (content) => {
            if (!content || !content.replace) {
                return;
            }
            content = content.replace(regex, (s, key, type, quote) => {
                const resource = resources.find(
                    ({ Filename, filename }) =>
                        Filename === key.capitalize() || filename === key.capitalize()
                );
                const entry = entries.find(({ name }) => name === key);
                if (entry) {
                    return `${quote}${entry.url}${quote}`;
                } else if (resource) {
                    const { auth = {} } = ls('user') || {};
                    const { token } = auth;
                    return `${quote}${resource.blobUrl ||
                        `${resource.url}?token=${token}`}${quote}`;
                }
                return s;
            });
            return content;
        },
        [entries, externalRef]
    );

    const updateChildListMutation = useCallback(
        async (mutationRecord) => {
            Object.keys(mutationRecord.addedNodes).forEach(async (iNode) => {
                const el = mutationRecord.addedNodes[iNode];
                if (el.tagName === 'A') {
                    el.onclick = (event) => {
                        const { currentTarget } = event;
                        const { href, target } = currentTarget;
                        const iframe =
                            externalRef.current.contentDocument.querySelector(
                                `iframe[name="${target}"]`
                            ) || externalRef.current;
                        iframe.src = href;
                        iframe && setTimeout(() => replaceContent(iframe), 10);
                    };
                }
                if (el.attributes && el.attributes.length) {
                    Object.keys(el.attributes).forEach(async (iAttr) => {
                        const key = el.attributes[iAttr].name;
                        if (el.getAttribute(key) && !el.getAttribute(key).includes('://')) {
                            el.setAttribute(key, getHtmlReplaced(el.getAttribute(key)));
                        }
                    });
                }
                if (el.childNodes.length) {
                    updateChildListMutation({
                        addedNodes: el.childNodes,
                    });
                } else if (el.textContent) {
                    el.textContent = getHtmlReplaced(el.textContent);
                }
                if (el.style && el.style.cssText) {
                    el.style.cssText = getHtmlReplaced(el.style.cssText);
                }
            });
        },
        [entries, externalRef]
    );

    const updateAttributeMutation = useCallback(
        (mutationRecord) => {
            const attrKey = mutationRecord.attributeName;
            const oldValue =
                mutationRecord.target.getAttribute && mutationRecord.target.getAttribute(attrKey);
            if (mutationRecord.target.tagName === 'IFRAME') {
                replaceContent(mutationRecord.target);
            }
            if (!oldValue) {
                return;
            }
            let attrValue = getHtmlReplaced(oldValue);
            if (oldValue !== attrValue) {
                mutationRecord.target.setAttribute(attrKey, attrValue);
            }
        },
        [entries, externalRef]
    );

    const onChange = (mutations, observer) => {
        mutations.forEach((mutation) => {
            if (mutation.addedNodes.length) {
                updateChildListMutation(mutation);
            }
            updateAttributeMutation(mutation);
        });
    };

    const initObserver = (iframe) => {
        const iframeDocument = iframe.contentDocument;
        const observer = new MutationObserver(onChange);
        observers.current.push(observer);
        if (iframeDocument) {
            observer.observe(iframeDocument, {
                childList: true,
                attributes: true,
                subtree: true,
                attributeOldValue: true,
            });
            updateIframeContent(iframe);
        }
    };

    useEffect(() => {
        const { url: mainSrc } = entries.find(({ name }) => name === 'index.html') || {};
        if (mainSrc && externalRef.current) {
            externalRef.current.src = mainSrc;
            replaceContent(externalRef.current);
        } else if (!mainSrc && entries.length) {
            externalRef.current.contentDocument.body.innerHTML = `
                <style>
                    body {
                        padding: 0;
                        margin: 0
                    }
                    div{
                        align-items: center;
                        background: #D2D2D2;
                        display: flex;
                        flex-direction: column;
                        font-family: system-ui, sans-serif;
                        height: 100%;
                        justify-content: center;
                        position: absolute;
                        width: 100%;
                    }
                    img {
                        width: 100%;
                    }
                    span {
                        box-sizing: border-box;
                        color: #ffffff;
                        line-height: 1.5rem;
                        padding: 0 4rem;
                        text-align: center;
                    }
                </style>
                <div>
                    <img src="${noIframe}" alt="no iframe" />
                    <span>${t('screens.embededWeb_index_error')}</span>
                </div>
            `;
        } else {
            externalRef.current.contentDocument.body.innerHTML = '';
        }

        return () => {
            observers.current.forEach((observer) => observer && observer.disconnect());
        };
    }, [entries.length, externalRef]);

    return;
};
export default useMutationObserver;
