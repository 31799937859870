// @flow
import React, { useEffect } from 'react';
import { Route, Switch, useLocation } from 'react-router-dom';
import { useAnalytics } from 'use-analytics';
import { message } from 'antd';
import { MODALS, PAGES } from './_config';
import { DropWrapper, PrivateRoute } from './components';
import { ContextProviders } from './context';
import {
    ForgotPage,
    ForgotPageV1,
    LoginPage,
    LoginPageV1,
    MainPage,
    MaintenancePage,
    NotFoundPage,
    PlansPage,
    PoliciesPage,
    RegisterPage,
    RegisterPageV1,
    ResetPasswordPage,
    TermsPage,
} from './pages';
import './assets/scss/main.scss';

message.config({ duration: 5 });

function App() {
    const location = useLocation();
    const { page } = useAnalytics();

    useEffect(() => {
        page();
    }, [location]);

    return (
        <ContextProviders>
            <Switch>
                <Route exact path={PAGES.MAINTENANCE} component={MaintenancePage} />
                <Route exact path={PAGES.PREMIUM_PLANS} component={PlansPage} />
                <Route exact path={PAGES.FORGOT} component={ForgotPage} />
                <Route exact path={`${PAGES.FORGOT}/v1`} component={ForgotPageV1} />
                <Route exact path={PAGES.SIGN_IN} component={LoginPage} />
                <Route exact path={`/login/v1`} component={LoginPageV1} />
                <Route exact path={`${PAGES.SIGN_IN}/v1`} component={LoginPageV1} />
                <Route exact path={PAGES.SIGN_UP} component={RegisterPage} />
                <Route exact path={`${PAGES.SIGN_UP}/v1`} component={RegisterPageV1} />
                <Route exact path={`/register/v1`} component={RegisterPageV1} />
                <Route exact path={PAGES.LEGAL_POLICIES} component={PoliciesPage} />
                <Route exact path={PAGES.LEGAL_TERMS} component={TermsPage} />
                <Route
                    exact
                    path={`${PAGES.PASSWORD_RESET}/:token`}
                    component={ResetPasswordPage}
                />
                <PrivateRoute exact path="/" component={MainPage} />
                <PrivateRoute exact path="/:idUser" component={MainPage} />
                <PrivateRoute
                    exact
                    path={`/:idUser${PAGES.APPLICATIONS}`}
                    component={MainPage}
                    modal={MODALS.APPLICATIONS}
                />
                <PrivateRoute
                    exact
                    path={`/:idUser/:idApplication${PAGES.PUBLISH}`}
                    component={MainPage}
                    modal={MODALS.PUBLISH}
                />
                <PrivateRoute
                    exact
                    path={`/:idUser/:idApplication${PAGES.RESOURCES}`}
                    component={MainPage}
                    modal={MODALS.RESOURCES}
                />
                <PrivateRoute exact path="/:idUser/:idApplication" component={MainPage} />
                <PrivateRoute
                    exact
                    path="/:idUser/:idApplication/:idSection"
                    component={MainPage}
                />
                <PrivateRoute
                    exact
                    path="/:idUser/:idApplication/:idSection/e/:idElement"
                    component={MainPage}
                />
                <Route exact path="*" component={NotFoundPage} />
            </Switch>
            <DropWrapper />
        </ContextProviders>
    );
}

export default App;
