import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ls from 'local-storage';
import { Button, Col, Container, FormGroup, Label, Row } from 'reactstrap';
import ReCAPTCHA from 'react-google-recaptcha';
import { SECRETS } from '../../_config';
import { scrollToInvalid } from '../../_helpers';
import { useUser } from '../../hooks';
import { CirclePreloader, PasswordInput } from '../../components';
import { logo } from '../../assets/svg/logos';
import banner from '../../assets/svg/not-found.svg';

export const ResetPasswordPage = ({ match: { params: { token } = {} } }) => {
    const { t } = useTranslation();
    const { applyRecovery, loading } = useUser();
    const email = ls('recovery_email');
    const [state, setState] = useState({
        captcha: '',
        email,
        password: '',
        password_confirmation: '',
        token,
    });
    const recaptchaRef = useRef();
    const recoveryForm = useRef();
    const submitted = useRef();

    useEffect(() => {
        if (submitted.current) {
            applyRecovery({ variables: { ...state } });
        }
    }, [state]);

    const handleSubmit = (event) => {
        event && event.preventDefault();
        if (submitted.current) {
            return;
        }

        submitted.current = true;
        const form = recoveryForm.current;

        if (form.reportValidity() === false) {
            scrollToInvalid(form);
            return;
        }

        if (!state.captcha) {
            recaptchaRef.current.execute();
        }
    };

    return (
        <main
            className="reset-password min-height-100vh--lg"
            style={{ backgroundImage: `url(${banner})` }}
        >
            <header className="position-absolute-top-0 z-index-2">
                <nav className="d-flex justify-content-between align-items-center">
                    <Col className="px-0">
                        <a
                            className="d-inline-block px-3 py-5 p-sm-5"
                            href="/"
                            aria-label="Mobincube"
                        >
                            <img className="d-inline-block" src={logo} height="42px" alt="Logo" />
                        </a>
                    </Col>
                </nav>
            </header>
            <Container>
                <Row noGutters>
                    <Col
                        md={{ size: 8, offset: 2 }}
                        lg={{ size: 7, offset: 2 }}
                        xl={{ size: 6, offset: 3 }}
                        className="space-3"
                    >
                        <div className="my-5">
                            <h2 className="h3 text-primary font-weight-normal mb-0">
                                {t('reset_password.title')}
                            </h2>
                            <p>{t('reset_password.subtitle')}</p>
                        </div>
                        <form
                            className="js-validate mt-5"
                            onSubmit={handleSubmit}
                            ref={recoveryForm}
                        >
                            <FormGroup>
                                <Label
                                    for="InputPassword"
                                    className="h6 small d-block text-uppercase"
                                >
                                    {t('my_account.new_password')}
                                </Label>
                                <PasswordInput
                                    id="InputPassword"
                                    onChange={(event) => {
                                        const { target } = event;
                                        const { value: password } = target;
                                        setState((state) => ({
                                            ...state,
                                            password,
                                        }));
                                    }}
                                />
                            </FormGroup>
                            <FormGroup>
                                <Label
                                    for="InputPassword2"
                                    className="h6 small d-block text-uppercase"
                                >
                                    {t('my_account.repeat_password')}
                                </Label>
                                <PasswordInput
                                    id="InputPassword2"
                                    onChange={(event) => {
                                        const { target } = event;
                                        const { value: password_confirmation } = target;
                                        setState((state) => ({
                                            ...state,
                                            password_confirmation,
                                        }));
                                    }}
                                />
                            </FormGroup>
                            <FormGroup className="mb-5">
                                <ReCAPTCHA
                                    ref={recaptchaRef}
                                    sitekey={SECRETS.GRE_CAPTCHA_SITE_KEY}
                                    size="invisible"
                                    onChange={(captcha) =>
                                        setState((state) => ({ ...state, captcha }))
                                    }
                                />
                            </FormGroup>
                            <div className="text-right mb-5">
                                <Button
                                    color="primary"
                                    className="position-relative"
                                    disabled={submitted.current}
                                >
                                    {loading && <CirclePreloader />}
                                    {t('forgot.reset_password')}
                                </Button>
                            </div>
                        </form>
                    </Col>
                </Row>
            </Container>
        </main>
    );
};

export default ResetPasswordPage;
