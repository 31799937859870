import React from 'react';
import { Link } from 'react-router-dom';
import { Jumbotron } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import { logo } from '../../assets/svg/logos';
import banner from '../../assets/svg/not-found.svg';

function MaintenancePage() {
    const { t } = useTranslation();
    return (
        <main className="maintenance">
            <header className="position-absolute-top-0 z-index-2">
                <nav className="d-flex justify-content-between align-items-center">
                    <div className="col-lg-5 col-xl-4 text-lg-center px-0">
                        <Link className="d-inline-block p-3 p-sm-5" to="/" aria-label="Mobincube">
                            <img src={logo} height="42px" alt="Logo" />
                        </Link>
                    </div>
                </nav>
            </header>
            <Jumbotron
                fluid
                className="d-flex flex-column justify-content-center align-items-center mb-0 p-0"
                style={{ backgroundImage: `url(${banner})` }}
            >
                <div className="mt--5 d-flex flex-column justify-content-center align-items-center position-relative text-center z-index-2">
                    <h1 className="text-secondary rounded" style={{ background: '#f0f1f3' }}>
                        {t('maintenance.title')}
                    </h1>
                    <p className="rounded" style={{ background: '#f0f1f3' }}>
                        {t('maintenance.subtitle')}
                    </p>
                </div>
            </Jumbotron>
        </main>
    );
}

export default MaintenancePage;
export { MaintenancePage };
