import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, ButtonGroup } from 'reactstrap';
import { getHex, getRgba } from '../_helpers';
import { ColorPicker, Icon } from '.';
import { useApplications } from '../hooks';

const TransparentShading = () => {
    return <ColorPicker disabled={true} value="#00ffffff" />;
};

const SolidShading = ({ mainColor = 'ffffff', onTypeChange: handleShadingTypeChange }) => {
    const handleChangingColor = () => {};

    const handleChangeColor = (color) => {
        let mainColor = getHex(color);
        mainColor = mainColor.replace('#', '');
        handleShadingTypeChange({ shadingType: 'solid', mainColor });
    };

    return (
        <ColorPicker value={mainColor} onChange={handleChangingColor} onBlur={handleChangeColor} />
    );
};

const GradientShading = ({
    finalColor = '000000',
    mainColor = 'ffffff',
    onTypeChange: handleShadingTypeChange,
    shadingType = 'gradient-up-down',
}) => {
    const handleChangingMainColor = () => {};

    const handleChangeMainColor = (color) => {
        let mainColor = getHex(color);
        mainColor = mainColor.replace('#', '');
        handleShadingTypeChange({ mainColor });
    };

    const handleChangingFinalColor = () => {};

    const handleChangeFinalColor = (color) => {
        let finalColor = getHex(color);
        finalColor = finalColor.replace('#', '');
        handleShadingTypeChange({ finalColor });
    };

    return (
        <>
            <ButtonGroup className="gradient-type-picker">
                <Button
                    className={`gradient-up-down${
                        shadingType === 'gradient_up_down' ? ' active' : ''
                    }`}
                    onClick={() => handleShadingTypeChange({ shadingType: 'gradient_up_down' })}
                >
                    <div
                        style={{
                            background: `linear-gradient(to bottom, ${getRgba(
                                mainColor
                            )}, ${getRgba(finalColor)})`,
                        }}
                    />
                </Button>
                <Button
                    className={`gradient-down-up${
                        shadingType === 'gradient_down_up' ? ' active' : ''
                    }`}
                    name="gradient_down_up"
                    onClick={() => handleShadingTypeChange({ shadingType: 'gradient_down_up' })}
                >
                    <div
                        style={{
                            background: `linear-gradient(to top, ${getRgba(mainColor)}, ${getRgba(
                                finalColor
                            )})`,
                        }}
                    />
                </Button>
                <Button
                    className={`gradient-left-right${
                        shadingType === 'gradient_left_right' ? ' active' : ''
                    }`}
                    onClick={() => handleShadingTypeChange({ shadingType: 'gradient_left_right' })}
                >
                    <div
                        style={{
                            background: `linear-gradient(to right, ${getRgba(mainColor)}, ${getRgba(
                                finalColor
                            )})`,
                        }}
                    />
                </Button>
                <Button
                    className={`gradient-right-left${
                        shadingType === 'gradient_right_left' ? ' active' : ''
                    }`}
                    onClick={() => handleShadingTypeChange({ shadingType: 'gradient_right_left' })}
                >
                    <div
                        style={{
                            background: `linear-gradient(to left, ${getRgba(mainColor)}, ${getRgba(
                                finalColor
                            )})`,
                        }}
                    />
                </Button>
            </ButtonGroup>
            <ButtonGroup className="gradient-color-picker">
                <Button outline color="gray">
                    <ColorPicker
                        value={mainColor}
                        onChange={handleChangingMainColor}
                        onBlur={handleChangeMainColor}
                    />
                </Button>
                <Button outline color="gray">
                    <ColorPicker
                        value={finalColor}
                        onChange={handleChangingFinalColor}
                        onBlur={handleChangeFinalColor}
                    />
                </Button>
            </ButtonGroup>
        </>
    );
};

const ShadingPicker = ({ finalColor, mainColor, onTypeChange, shadingType }) => {
    switch (shadingType) {
        case 'transparent':
            return <TransparentShading />;
        case 'solid':
            return <SolidShading mainColor={mainColor} onTypeChange={onTypeChange} />;
        case 'gradient_down_up':
        case 'gradient_left_right':
        case 'gradient_right_left':
        case 'gradient_up_down':
            return (
                <GradientShading
                    finalColor={finalColor}
                    mainColor={mainColor}
                    onTypeChange={onTypeChange}
                    shadingType={shadingType}
                />
            );
        default:
            return null;
    }
};

const Shading = ({ handleChange = () => {}, id }) => {
    const { t } = useTranslation();
    const { getShading } = useApplications();
    const [shading, setShading] = useState({ id });
    let { finalColor, mainColor, shadingType = 'transparent' } = shading;
    finalColor = finalColor || '000000';
    mainColor = mainColor || 'ffffff';

    const handleShadingTypeClick = (event) => {
        const { currentTarget } = event;
        const { name: shadingType } = currentTarget;

        handleShadingTypeChange({ shadingType });
    };

    const handleShadingTypeChange = async ({
        finalColor: _finalColor,
        mainColor: _mainColor,
        shadingType: _shadingType,
    } = {}) => {
        finalColor = _finalColor || finalColor;
        mainColor = _mainColor || mainColor;
        shadingType = _shadingType || shadingType;
        const shading = await getShading({ finalColor, mainColor, shadingType });
        setShading(shading || {});
    };

    useEffect(() => {
        (async () => {
            const shading = await getShading({ id });
            setShading(shading || { finalColor, mainColor });
        })();
    }, [id]);

    useEffect(() => {
        handleChange(shading);
    }, [shading]);

    return (
        <>
            <ButtonGroup className="z-index-2">
                <Button
                    outline
                    color="primary"
                    className={shadingType === 'transparent' ? 'active' : ''}
                    name="transparent"
                    onClick={handleShadingTypeClick}
                >
                    <Icon type="checkbox-blank-off-outline" />
                    <span>{t('properties.transparent')}</span>
                </Button>
                <Button
                    outline
                    color="primary"
                    className={shadingType === 'solid' ? 'active' : ''}
                    name="solid"
                    onClick={handleShadingTypeClick}
                >
                    <Icon type="checkbox-blank" />
                    <span>{t('properties.solid')}</span>
                </Button>
                <Button
                    outline
                    color="primary"
                    className={shadingType.includes('gradient') ? 'active' : ''}
                    name="gradient_up_down"
                    onClick={handleShadingTypeClick}
                >
                    <Icon type="gradient" />
                    <span>{t('properties.gradient')}</span>
                </Button>
            </ButtonGroup>
            <div className="shading-picker-wrapper">
                <ShadingPicker
                    finalColor={`#${finalColor}`}
                    mainColor={`#${mainColor}`}
                    shadingType={shadingType}
                    onTypeChange={handleShadingTypeChange}
                />
            </div>
        </>
    );
};

export default Shading;
export { Shading };
