import React from 'react';
import { Tooltip } from 'antd';
import { Button, Card, CardHeader } from 'reactstrap';
import { Icon, Sortable } from '.';
import { ELEMENT_TYPE } from '../context';
import { useApplications } from '../hooks';

export const DragItemGroup = ({ children, items, query = '', title = '' }) => {
    const filteredItems = items.filter(({ title }) =>
        title.toLowerCase().includes(query.toLowerCase())
    );
    return (
        <Card className="drag-item-group" data-items={filteredItems.length}>
            <CardHeader>
                <strong>{title}</strong>
            </CardHeader>
            <Sortable
                className="card-body"
                sort={false}
                group={{ name: 'DragItems', pull: 'clone', put: false }}
                handle=".drag-item"
                filter=".disabled"
            >
                {filteredItems.map((item) => (
                    <DragItem key={`DragItem${item.id}`} {...item} />
                ))}
            </Sortable>
        </Card>
    );
};

export const DragItem = ({ attributes = {}, icon, id, title, type }) => {
    const { application = {} } = useApplications();
    const { bars = [] } = application;
    const { concrete: { bodyType } = {}, IDElementType } = attributes;
    const data = {
        'data-id': id,
        'data-type': type,
    };

    if (IDElementType) {
        data['data-element-type'] = IDElementType;
        if (bodyType) {
            data['data-body-type'] = bodyType;
        }
    }
    let disabled = false;
    if (IDElementType === ELEMENT_TYPE.BAR) {
        disabled = !bars.length;
    }

    return (
        <Tooltip mouseEnterDelay={1} title={title}>
            <Button className="drag-item" color="light" disabled={disabled} {...data}>
                <Icon type={icon} />
                <span>{title}</span>
            </Button>
        </Tooltip>
    );
};
