import React, { useRef, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import ls from 'local-storage';
import { Button, Form, FormGroup, Input, Label } from 'reactstrap';
import { PAGES } from '../../_config';
import { scrollToInvalid } from '../../_helpers';
import { PasswordInput } from '../../components';
import { useUser } from '../../hooks';

export const LoginForm = ({ callback = () => {} }) => {
    const { t } = useTranslation();
    const history = useHistory();
    const { createAuthentication } = useUser();
    const submitted = useRef(false);
    const [credentials, setCredentials] = useState({});
    const { identifier, password } = credentials;

    const handleSubmit = (event) => {
        event.preventDefault();
        const loginForm = event.currentTarget;
        if (loginForm.reportValidity && loginForm.reportValidity() === false) {
            scrollToInvalid(loginForm);
            return;
        }
        createAuthentication({ variables: { ...credentials } }).then(() => {
            const { auth = {} } = ls('user') || {};
            const { token } = auth;

            if (token) {
                history.push('/');
            }
        });
    };

    const handleChange = (event) => {
        const { name, value } = event.currentTarget;
        setCredentials({ ...credentials, [name]: value });
    };

    return (
        <Form className="js-validate" onSubmit={handleSubmit}>
            <FormGroup>
                <Label for="InputIdentifier" className="h6 small d-block text-uppercase">
                    {t('common.identifier')}
                </Label>
                <Input
                    id="InputIdentifier"
                    name="identifier"
                    data-cy="InputIdentifier"
                    aria-describedby="identifierHelp"
                    placeholder={t('common.email_placeholder')}
                    defaultValue={identifier}
                    onChange={handleChange}
                    tabIndex="1"
                    required
                    autoFocus
                />
            </FormGroup>
            <FormGroup>
                <div className="d-flex justify-content-between align-items-center">
                    <Label for="InputPassword" className="h6 small d-block text-uppercase">
                        {t('common.password')}
                    </Label>
                    <div className="mb-2 small">
                        <Link to={PAGES.FORGOT} className="link-muted" data-cy="forgot">
                            {t('login.forgot_password')}
                        </Link>
                    </div>
                </div>
                <PasswordInput
                    id="InputPassword"
                    data-cy="InputPassword"
                    defaultValue={password}
                    minLength={0}
                    tabIndex="2"
                    onChange={handleChange}
                />
            </FormGroup>
            <div className="d-lg-flex justify-content-between">
                <div>
                    {[PAGES.SIGN_IN, PAGES.ADDONS_SIGN_IN].includes(window.location.pathname) && (
                        <span className="text-sm-right text-muted">
                            {t('login.dont_have_account')}{' '}
                            <Link
                                to={PAGES.SIGN_UP}
                                target={
                                    window.location.pathname === PAGES.ADDONS_SIGN_IN
                                        ? '_blank'
                                        : ''
                                }
                                className="clearfix"
                                data-cy="signup"
                            >
                                {t('common.sign_up_long')}
                            </Link>
                        </span>
                    )}
                </div>
                <div className="text-right pl-lg-5">
                    <Button
                        color="primary"
                        className="w-100 position-relative text-uppercase font-weight-bold mt-4 mt-lg-0"
                        disabled={submitted.current}
                        data-cy="submit"
                    >
                        {t('common.sign_in')}
                    </Button>
                </div>
            </div>
        </Form>
    );
};

export default LoginForm;
