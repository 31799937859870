import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import parse from 'html-react-parser';
import QRCode from 'qrcode.react';
import { Collapse, Menu, message, Typography, Popconfirm, Space, Switch, Tag } from 'antd';
import { Badge, Button, Input, ModalBody, Table } from 'reactstrap';
import { MODALS, URL } from '../_config';
import { getLanguage } from '../_helpers';
import { CirclePreloader, Icon, Modal } from '../components';
import { DEMO_PLATFORM, DEMO_STATE, PUBLISH_STATE } from '../context';
import { useApplications, useErrors, useModals, useViewer } from '../hooks';
import { Certificates } from '../modals/AppInfo';
import { android, appStore, playStore } from '../assets/img/publish';

const colorByDemoState = {
    [DEMO_STATE.DOWNLOADED]: 'primary',
    [DEMO_STATE.FAILED]: 'danger',
    [DEMO_STATE.PROCESSED]: 'success',
    [DEMO_STATE.PROCESSING]: 'warning',
};

const colorByPublishState = {
    '0': 'warning',
    '1': 'success',
};

const AndroidDemo = () => {
    const { t } = useTranslation();
    const { application = {}, loading, requestDemo } = useApplications();
    const { validate } = useErrors();
    const { closeModal } = useModals();
    const { setIsVisibleErrorsPanel } = useViewer();
    const { demos = [] } = application;
    const filteredDemos = demos
        .filter(
            ({ idPlatform, Descargado: isDownloaded }) =>
                idPlatform === DEMO_PLATFORM.ANDROID && !isDownloaded
        )
        .sort((d1, d2) => (d1.FechaDemo > d2.FechaDemo ? -1 : 1));
    const lastDemo = filteredDemos[0];
    const { downloadCode, state } = lastDemo || {};
    const url = `${URL.STORE}/${downloadCode}`;

    const handleButtonClick = useCallback(() => {
        validate &&
            validate({ application, remote: true }).then(({ errors, isValid }) => {
                if (!isValid) {
                    message.warn(t('errors.errors_found_in_some_element'));
                    setIsVisibleErrorsPanel(true);
                    const errorPanel = document.querySelector('.errors-panel');
                    if (errorPanel) {
                        errorPanel.classList.add('heartbeat');
                        setTimeout(() => errorPanel.classList.remove('heartbeat'), 1000);
                    }
                    closeModal();
                    return;
                }
                requestDemo({ application, platform: 'android' });
            });
    }, [application]);

    return (
        <>
            <p>{t('publish.android_text')}</p>

            <div>
                {state && <Badge color={colorByDemoState[state]}>{t(`publish.${state}`)}</Badge>}
                <div className="align-items-center bg-gray-light d-flex justify-content-center position-relative my-3 rounded">
                    {(state === DEMO_STATE.DOWNLOADED && (
                        <Icon className="font-size-7x" type="cellphone-arrow-down" />
                    )) ||
                        (state === DEMO_STATE.FAILED && (
                            <Icon className="font-size-7x" type="cellphone-off" />
                        )) ||
                        (state === DEMO_STATE.PROCESSED && (
                            <QRCode className="m-3" value={url} includeMargin={false} />
                        )) ||
                        (state === DEMO_STATE.PROCESSING && (
                            <>
                                <CirclePreloader />
                                <Icon className="font-size-7x" type="cellphone-android" />
                            </>
                        ))}
                </div>
            </div>
            <Button
                disabled={loading || state === DEMO_STATE.PROCESSING}
                onClick={handleButtonClick}
            >
                {t('publish.request_demo')}
            </Button>
        </>
    );
};

const IosDemo = () => {
    const { t } = useTranslation();
    const { application = {}, requestDemo } = useApplications();
    const { validate } = useErrors();
    const { closeModal } = useModals();
    const { setIsVisibleErrorsPanel } = useViewer();
    const { demos = [] } = application;
    const filteredDemos = demos
        .filter(
            ({ idPlatform, Descargado: isDownloaded }) =>
                idPlatform === DEMO_PLATFORM.APPETIZE && !isDownloaded
        )
        .sort((d1, d2) => (d1.FechaDemo > d2.FechaDemo ? -1 : 1));
    const lastDemo = filteredDemos[0];
    const { downloadCode, state } = lastDemo || {};
    const url = `${URL.STORE}/${downloadCode}`;

    const handleButtonClick = useCallback(() => {
        validate &&
            validate({ application, remote: true }).then(({ isValid }) => {
                if (!isValid) {
                    message.warn(t('errors.errors_found_in_some_element'));
                    setIsVisibleErrorsPanel(true);
                    const errorPanel = document.querySelector('.errors-panel');
                    if (errorPanel) {
                        errorPanel.classList.add('heartbeat');
                        setTimeout(() => errorPanel.classList.remove('heartbeat'), 1000);
                    }
                    closeModal();
                    return;
                }
                requestDemo({ application, platform: 'appetize' });
            });
    }, [application]);

    return (
        <>
            <p>{t('publish.ios_text')}</p>

            <div>
                {state && <Badge color={colorByDemoState[state]}>{t(`publish.${state}`)}</Badge>}
                <div className="align-items-center bg-gray-light d-flex justify-content-center position-relative my-3 rounded">
                    {(state === DEMO_STATE.DOWNLOADED && (
                        <Icon className="font-size-7x" type="cellphone-arrow-down" />
                    )) ||
                        (state === DEMO_STATE.FAILED && (
                            <Icon className="font-size-7x" type="cellphone-off" />
                        )) ||
                        (state === DEMO_STATE.PROCESSED && (
                            <QRCode className="m-3" value={url} includeMargin={false} />
                        )) ||
                        (state === DEMO_STATE.PROCESSING && (
                            <>
                                <CirclePreloader />
                                <Icon className="font-size-7x" type="cellphone-iphone" />
                            </>
                        ))}
                </div>
            </div>
            <Button disabled={state === DEMO_STATE.PROCESSING} onClick={handleButtonClick}>
                {t('publish.request_demo')}
            </Button>
        </>
    );
};

const PublishVersion = () => {
    const { t } = useTranslation();
    const { application = {}, loading, publishVersion, skipVersionTo } = useApplications();
    const { validate } = useErrors();
    const { closeModal } = useModals();
    const inputSkipVersionTo = useRef();
    const [isPopconfirmVisible, setPopconfirmVisible] = useState();
    const { setIsVisibleErrorsPanel } = useViewer();
    const {
        idCurrentLang,
        inProcess,
        languages = [],
        lastVersionInfo = {},
        processState_googleplay: googlePlayState,
        processState_ios: iosState,
        versions = [],
    } = application;
    const { downloadCode } = languages.find(({ IDLang }) => IDLang === idCurrentLang) || {};
    const url = `${URL.STORE}/${downloadCode}`;

    const handleButtonClick = useCallback(() => {
        validate &&
            validate({ application, remote: true }).then(({ isValid }) => {
                if (!isValid) {
                    message.warn(t('errors.errors_found_in_some_element'));
                    setIsVisibleErrorsPanel(true);
                    const errorPanel = document.querySelector('.errors-panel');
                    if (errorPanel) {
                        errorPanel.classList.add('heartbeat');
                        setTimeout(() => errorPanel.classList.remove('heartbeat'), 1000);
                    }
                    closeModal();
                    return;
                }
                publishVersion({ application });
            });
    }, [application]);

    const handleSkipVersionTo = useCallback(() => {
        const input = inputSkipVersionTo.current;
        if (input.reportValidity() === false) {
            setPopconfirmVisible(true);
            return;
        }
        let { value: version } = input;
        version = parseInt(version, 10);
        skipVersionTo({ application, version });
        setPopconfirmVisible(false);
    }, [application]);

    return (
        <>
            <p>
                {t('publish.publish_title')}
                <a className="ml-2" href={url} target="_blank" rel="noopener noreferrer">
                    {t('publish.view_on_store')}
                </a>
            </p>

            <div className="align-items-center bg-gray-light d-flex justify-content-center position-relative my-3 rounded">
                <QRCode className="m-3" value={url} includeMargin={false} />
            </div>

            <Table>
                <thead>
                    <tr>
                        <th>{t('publish.version')}</th>
                        <th>{t('publish.creation_date')}</th>
                        <th />
                    </tr>
                </thead>
                <tbody>
                    {versions
                        .sort((v1, v2) => (v1.added_on > v2.added_on ? -1 : 1))
                        .map((version) => {
                            const { added_on: addedOn, closed, version: versionNumber } = version;
                            return (
                                <tr key={`Version-${versionNumber}`}>
                                    <th scope="row">{versionNumber}</th>
                                    <td>{new Date(addedOn).toLocaleString()}</td>
                                    <td>
                                        {(versionNumber === lastVersionInfo.version &&
                                            closed === '1' &&
                                            inProcess && (
                                                <Badge color="primary">
                                                    {t('publish.processing')}
                                                </Badge>
                                            )) || (
                                            <>
                                                <Badge color={colorByPublishState[closed]}>
                                                    {closed === '1'
                                                        ? t('publish.published_version', {
                                                              version: versionNumber,
                                                          })
                                                        : t('publish.publish_first')}
                                                </Badge>
                                            </>
                                        )}
                                    </td>
                                </tr>
                            );
                        })}
                </tbody>
            </Table>
            <div>
                <Popconfirm
                    title={
                        <>
                            <p>{t('publish.skip_version_to')}</p>
                            <Input
                                type="number"
                                min={lastVersionInfo.version + 1}
                                defaultValue={lastVersionInfo.version + 1}
                                innerRef={inputSkipVersionTo}
                            />
                        </>
                    }
                    onConfirm={handleSkipVersionTo}
                    onCancel={() => setPopconfirmVisible(false)}
                    okText={t('publish.skip')}
                    cancelText={t('common.cancel')}
                    visible={isPopconfirmVisible}
                >
                    <Button
                        className="mr-3"
                        outline
                        disabled={
                            loading ||
                            inProcess ||
                            googlePlayState === PUBLISH_STATE.PROCESSING ||
                            iosState === PUBLISH_STATE.PROCESSING
                        }
                        onClick={() => setPopconfirmVisible(true)}
                    >
                        {t('publish.skip_version_to')}
                    </Button>
                </Popconfirm>
                <Button
                    color="primary"
                    disabled={
                        loading ||
                        inProcess ||
                        googlePlayState === PUBLISH_STATE.PROCESSING ||
                        iosState === PUBLISH_STATE.PROCESSING
                    }
                    onClick={handleButtonClick}
                >
                    {t('publish.publish_version', {
                        count: lastVersionInfo.version + (lastVersionInfo.closed === '1' ? 1 : 0),
                    })}
                </Button>
            </div>
        </>
    );
};

const GooglePlay = () => {
    const { t } = useTranslation();
    const {
        application = {},
        downloadGooglePlayBundle,
        downloadPepk,
        generateGooglePlayBundle,
    } = useApplications();
    const { inProcess, processState_googleplay: googlePlayState } = application;

    return (
        <>
            <img src={playStore} alt="Google Play logo" />
            {parse(t('publish.google_play_description'))}
            <hr className="mt-5 mb-4" />
            <Button outline className="mr-2" onClick={() => downloadPepk()}>
                {t('publish.download_pepk')}
            </Button>
            {googlePlayState === PUBLISH_STATE.PROCESSING ? (
                <Button disabled={true}>{t('publish.processing')}</Button>
            ) : googlePlayState === PUBLISH_STATE.PROCESSED ? (
                <Button
                    color="primary"
                    disabled={inProcess}
                    onClick={() => downloadGooglePlayBundle()}
                >
                    {t('publish.download_bundle')}
                </Button>
            ) : (
                <Button disabled={inProcess} onClick={() => generateGooglePlayBundle()}>
                    {t('publish.generate_bundle')}
                </Button>
            )}
        </>
    );
};

const AndroidMarkets = () => {
    const { t } = useTranslation();
    const { downloadApk } = useApplications();
    return (
        <>
            <img src={android} alt="Android logo" />
            {parse(t('publish.other_android_markets_description'))}
            <hr className="mt-5 mb-4" />
            <Button color="primary" onClick={() => downloadApk()}>
                {t('publish.download_apk')}
            </Button>
        </>
    );
};

const AppStore = () => {
    const lang = getLanguage();
    const { t } = useTranslation();
    const {
        application = {},
        downloadIpa,
        generateIosIpa,
        updateApplicationSettings,
    } = useApplications();
    const { inProcess, processState_ios: iosState, settings = {} } = application;
    const { appstore = {} } = settings;
    let { messageATT, p12, showATT, signature } = appstore;
    messageATT = messageATT || {};
    const messageATTToShow = messageATT[lang] || messageATT.en;

    const handleChange = useCallback(
        (showATT) => {
            console.log(showATT);
            updateApplicationSettings({ setting: 'appstore', value: { showATT } }, application);
        },
        [application]
    );

    return (
        <>
            <img src={appStore} alt="Apple Store logo" />
            <Certificates />
            <hr className="mt-5 mb-4" />

            <p>{t('publish.att_title')}</p>
            <Collapse activeKey={showATT ? 'showATT' : ''} ghost>
                <Collapse.Panel
                    header={
                        <Space>
                            <Switch checked={showATT} onChange={handleChange} />
                            <div>{t('publish.att_message')}</div>
                        </Space>
                    }
                    key="showATT"
                    showArrow={false}
                >
                    <Typography.Paragraph
                        className="bg-light p-3 position-relative rounded"
                        style={{ maxWidth: '520px' }}
                        copyable={{
                            icon: [
                                <Button
                                    className="position-absolute right-0 bottom-0 m-2"
                                    color="primary"
                                    size="sm"
                                >
                                    <Icon type="content-copy" className="mr-2" />
                                    {t('common.copy')}
                                </Button>,
                                <Button
                                    className="position-absolute right-0 bottom-0 m-2"
                                    color="success"
                                    disabled
                                    size="sm"
                                >
                                    <Icon type="check" className="mr-2" />
                                    {t('common.copied')}
                                </Button>,
                            ],
                            text: messageATTToShow,
                            tooltips: false,
                        }}
                    >
                        <div className="user-select-all">{messageATTToShow}</div>
                    </Typography.Paragraph>
                </Collapse.Panel>
            </Collapse>
            <hr className="mt-5 mb-4" />
            {iosState === PUBLISH_STATE.PROCESSING ? (
                <Button disabled={true}>{t('publish.processing')}...</Button>
            ) : iosState === PUBLISH_STATE.PROCESSED ? (
                <Button color="primary" disabled={inProcess} onClick={() => downloadIpa()}>
                    {t('common.download_ipa')}
                </Button>
            ) : (
                <Button
                    dis
                    abled={inProcess || !p12 || !signature}
                    onClick={() => generateIosIpa()}
                >
                    {t('publish.generate_ipa')}
                </Button>
            )}
        </>
    );
};

const PublishContent = ({ content }) => {
    switch (content) {
        case 'androidDemo':
            return <AndroidDemo />;
        case 'iosDemo':
            return <IosDemo />;
        case 'version':
            return <PublishVersion />;
        case 'googlePlay':
            return <GooglePlay />;
        case 'androidMarkets':
            return <AndroidMarkets />;
        case 'appStore':
            return <AppStore />;
        default:
            return null;
    }
};

export const Publish = ({ isOpen: isOpenProp }) => {
    const { t } = useTranslation();
    const [isOpen, setIsOpen] = useState(isOpenProp);
    const { application = {} } = useApplications();
    const {
        inProcess,
        processState_googleplay: googlePlayState,
        processState_ios: iosState,
        versions = [],
    } = application;
    const { modal, setModal, key = 'androidDemo' } = useModals();
    const [content, setContent] = useState(key);

    useEffect(() => {
        setIsOpen(modal === MODALS.PUBLISH);
    }, [modal]);

    const handleMenuClick = ({ key }) => {
        setContent(key);
    };

    return (
        <Modal
            className="publish modal-menu max-80"
            isOpen={isOpen}
            title={t('publish.publish')}
            onClosed={() => setModal(null)}
        >
            <ModalBody>
                <Menu
                    className="w-auto"
                    mode="inline"
                    onClick={handleMenuClick}
                    defaultSelectedKeys={[content]}
                    openKeys={['demoSubmenu', 'versionSubmenu']}
                >
                    <Menu.SubMenu key="demoSubmenu" expandIcon={<></>} title={t('publish.demo')}>
                        <Menu.Item key="androidDemo">{t('publish.android_demo')}</Menu.Item>
                        <Menu.Item key="iosDemo">{t('publish.ios_demo')}</Menu.Item>
                    </Menu.SubMenu>
                    <Menu.SubMenu
                        key="versionSubmenu"
                        expandIcon={<></>}
                        title={t('publish.version')}
                    >
                        <Menu.Item key="version">
                            Mobincube
                            {inProcess && <CirclePreloader className="justify-content-end mr-2" />}
                        </Menu.Item>
                        <Menu.Item
                            key="googlePlay"
                            disabled={inProcess || !versions.some(({ closed }) => closed === '1')}
                        >
                            {t('publish.google_play')}
                            {googlePlayState === PUBLISH_STATE.PROCESSING && (
                                <CirclePreloader className="justify-content-end mr-2" />
                            )}
                            {!versions.some(({ closed }) => closed === '1') && (
                                <Tag color="warning" className="ml-2 mr-0">
                                    {t('publish.publish_version_first')}
                                </Tag>
                            )}
                        </Menu.Item>
                        <Menu.Item
                            key="androidMarkets"
                            disabled={inProcess || !versions.some(({ closed }) => closed === '1')}
                        >
                            {t('publish.other_android_markets')}
                            {!versions.some(({ closed }) => closed === '1') && (
                                <Tag color="warning" className="ml-2 mr-0">
                                    {t('publish.publish_version_first')}
                                </Tag>
                            )}
                        </Menu.Item>
                        <Menu.Item
                            key="appStore"
                            disabled={inProcess || !versions.some(({ closed }) => closed === '1')}
                        >
                            {t('publish.app_store')}
                            {iosState === PUBLISH_STATE.PROCESSING && (
                                <CirclePreloader className="justify-content-end mr-2" />
                            )}
                            {!versions.some(({ closed }) => closed === '1') && (
                                <Tag color="warning" className="ml-2 mr-0">
                                    {t('publish.publish_version_first')}
                                </Tag>
                            )}
                        </Menu.Item>
                    </Menu.SubMenu>
                </Menu>
                <ModalBody className="px-5" data-key={content}>
                    <PublishContent content={content} />
                </ModalBody>
            </ModalBody>
        </Modal>
    );
};
