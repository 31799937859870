import React, { useEffect, useMemo, useRef, useState } from 'react';
import Gravatar from 'react-gravatar';
import parse from 'html-react-parser';
import { useTranslation } from 'react-i18next';
import { loadStripe } from '@stripe/stripe-js';
import { CardElement, Elements, useStripe, useElements } from '@stripe/react-stripe-js';
import { Menu, Popconfirm, Progress, Statistic, Tooltip } from 'antd';
import {
    Badge,
    Button,
    ButtonGroup,
    Card,
    CardBody,
    CardFooter,
    CardHeader,
    CardTitle,
    Col,
    Form,
    FormFeedback,
    FormGroup,
    Input,
    InputGroup,
    InputGroupAddon,
    InputGroupText,
    Label,
    ModalBody,
    ModalFooter,
    Row,
    UncontrolledCollapse,
} from 'reactstrap';
import { MODALS, SECRETS, URL } from '../_config';
import { isValidVat } from '../_helpers';
import { CustomModal, Icon, Modal, PasswordInput } from '../components';
import { PLAN_NAME, PLAN_TYPE, SERVICE_TYPE, SUBSCRIPTION_STATE } from '../context';
import { useModals, usePlans, useViewer } from '../hooks';
import { logoMin } from '../assets/svg/logos';
import americanexpress from '../assets/img/cards/americanexpress.png';
import mastercard from '../assets/img/cards/mastercard.png';
import visa from '../assets/img/cards/visa.png';

const Summary = () => {
    const { t } = useTranslation();
    const [isOpenPasswordModal, setIsOpenPasswordModal] = useState(false);
    const { disableUser, updateUser, viewer = {} } = useViewer();
    const {
        address = '',
        contactName = '',
        contactSurname = '',
        countryCode = '',
        description = '',
        Email: email = '',
        phoneNumber = '',
        postalCode = '',
        username = '',
    } = viewer;

    const handleChange = ({ nativeEvent = {} } = {}) => {
        const { target = {} } = nativeEvent;
        const { name, value } = target;
        updateUser({ [name]: value });
    };

    const handleChangePassword = () => {
        const passwords = document.querySelectorAll('input[name="password"]');
        const { value: password } = passwords[0];
        const { value: password1 } = passwords[1];

        if (password && password === username) {
            passwords[0].setCustomValidity(t('my_account.username_password_must_be_different'));
        } else {
            passwords[0].setCustomValidity('');
        }

        if (password && password1 && password !== password1) {
            passwords[1].setCustomValidity(t('my_account.passwords_dont_match'));
        } else {
            passwords[1].setCustomValidity('');
        }

        if (!passwords[0].reportValidity() || !passwords[1].reportValidity()) {
            return;
        }

        updateUser({ password });
        setIsOpenPasswordModal(false);
    };

    const handleDisableAccount = async () => {
        await disableUser();
        window.location = URL.DISABLE_ACCOUNT_FORM;
    };

    return (
        <>
            <h6 className="mb-4">{t('my_account.profile_information')}</h6>
            <div className="grid">
                <FormGroup>
                    <Label className="h6 small d-block text-uppercase">
                        {t('my_account.username')}
                    </Label>
                    <Input value={username} name="username" disabled={true} onBlur={handleChange} />
                </FormGroup>
                <FormGroup>
                    <Label className="h6 small d-block text-uppercase">
                        {t('my_account.email')}
                    </Label>
                    <Input type="email" defaultValue={email} name="Email" onBlur={handleChange} />
                </FormGroup>
                <FormGroup>
                    <Label className="h6 small d-block text-uppercase">{t('common.avatar')}</Label>
                    <CustomModal
                        button={
                            <Gravatar
                                email={email}
                                size={128}
                                default="monsterid"
                                className="avatar"
                            />
                        }
                        buttonParameters={{
                            className: 'avatar-wrapper',
                            outline: true,
                        }}
                        title={`${t('common.change')} ${t('common.avatar')}`}
                    >
                        <p>{t('my_account.gravatar_description')}</p>
                        <Button
                            color="primary"
                            href="https://es.gravatar.com/emails/"
                            target="_blank"
                        >
                            {`${t('common.go_to')} Gravatar`}
                        </Button>
                    </CustomModal>
                </FormGroup>
                <FormGroup>
                    <Label className="h6 small d-block text-uppercase">
                        {t('my_account.password')}
                    </Label>
                    <Button block outline onClick={() => setIsOpenPasswordModal(true)}>
                        {t('common.change')}
                    </Button>
                    <Modal isOpen={isOpenPasswordModal} style={{ minWidth: '280px' }}>
                        <ModalBody>
                            <FormGroup>
                                <Label className="h6 small d-block text-uppercase">
                                    {t('my_account.new_password')}
                                </Label>
                                <PasswordInput />
                            </FormGroup>
                            <FormGroup>
                                <Label className="h6 small d-block text-uppercase">
                                    {t('my_account.repeat_password')}
                                </Label>
                                <PasswordInput />
                            </FormGroup>
                        </ModalBody>
                        <ModalFooter>
                            <Button color="link" onClick={handleChangePassword}>
                                {t('common.change')}
                            </Button>
                        </ModalFooter>
                    </Modal>
                </FormGroup>
                <FormGroup style={{ gridColumn: 1 }}>
                    <Label className="h6 small d-block text-uppercase">
                        {t('my_account.name')}
                    </Label>
                    <Input defaultValue={contactName} name="contactName" onBlur={handleChange} />
                </FormGroup>
                <FormGroup>
                    <Label className="h6 small d-block text-uppercase">
                        {t('my_account.surname')}
                    </Label>
                    <Input
                        defaultValue={contactSurname}
                        name="contactSurname"
                        onBlur={handleChange}
                    />
                </FormGroup>
                <FormGroup>
                    <Label className="h6 small d-block text-uppercase">
                        {t('my_account.postal_address')}
                    </Label>
                    <Input defaultValue={address} name="address" onBlur={handleChange} />
                </FormGroup>
                <FormGroup>
                    <Label className="h6 small d-block text-uppercase">
                        {t('my_account.post_code')}
                    </Label>
                    <Input defaultValue={postalCode} name="postalCode" onBlur={handleChange} />
                </FormGroup>
                <FormGroup>
                    <Label className="h6 small d-block text-uppercase">
                        {t('my_account.country')}
                    </Label>
                    <Input defaultValue={countryCode} name="countryCode" onBlur={handleChange} />
                </FormGroup>
                <FormGroup>
                    <Label className="h6 small d-block text-uppercase">
                        {t('my_account.phone_number')}
                    </Label>
                    <Input defaultValue={phoneNumber} name="phoneNumber" onBlur={handleChange} />
                </FormGroup>
            </div>
            <div>
                <FormGroup>
                    <Label className="h6 small d-block text-uppercase">
                        {t('my_account.information')}
                    </Label>
                    <Input
                        type="textarea"
                        defaultValue={description}
                        name="description"
                        onBlur={handleChange}
                    />
                </FormGroup>
            </div>
            <div className="mt-5">
                <CustomModal
                    button={t('my_account.disable_account')}
                    buttonParameters={{
                        color: 'danger',
                        outline: true,
                        size: 'sm',
                    }}
                    footer={
                        <Button color="link" onClick={handleDisableAccount}>
                            {t('my_account.disable_account')}
                        </Button>
                    }
                    title={t('my_account.are_you_leaving')}
                >
                    <div className="small">
                        <strong className="d-block mb-3">{t('my_account.before_disabling')}</strong>
                        <ul className="text-muted">
                            {parse(t('my_account.disable_account_dialog_text'))}
                        </ul>
                        <small className="text-muted">{t('my_account.form_redirection')}</small>
                    </div>
                </CustomModal>
            </div>
        </>
    );
};

const Plans = ({ plan }) => {
    const { t } = useTranslation();
    const { getPlans, plans = [] } = usePlans();
    const { setPlan, viewer = {} } = useViewer();
    const { subscriptions = [] } = viewer;
    const { idSubscription: idActivePlan } =
        subscriptions.find(({ idState }) => idState === SUBSCRIPTION_STATE.ACTIVE) || {};
    let { idSubscription: idPendingPlan, activationDate } =
        subscriptions.find(({ idState }) => idState === SUBSCRIPTION_STATE.PENDING) || {};
    const activePlan = plans.find(({ id }) => id === idActivePlan) || {};
    activationDate = new Date(activationDate);

    useMemo(() => {
        if (!plans.length) {
            getPlans();
        }
    }, [plans]);

    const downgradeToFree = () => {
        const { intervals } = plans.find(({ id }) => id === PLAN_TYPE.FREE);
        const interval = intervals[0];
        setPlan({ interval }).then((done) => {
            done && document.querySelector('.modal .close').click();
        });
    };

    return (
        <Row xs="1" md="2" lg="3" className="plans">
            {plans
                .sort((plan1, plan2) => (plan1.allowedDiskUsage < plan2.allowedDiskUsage ? -1 : 1))
                .map((plan) => {
                    const { allowedDiskUsage, id, mauLimit, maxNotifications, services } = plan;
                    return (
                        <Col key={`Plan-${id}`} className="mb-4">
                            <Card className="plan h-100">
                                <CardHeader>
                                    <h2 className="text-uppercase">{PLAN_NAME[id]}</h2>
                                    {id === idPendingPlan && (
                                        <Badge color="primary" pill>
                                            {`${t(
                                                'my_account.activation_date'
                                            )}: ${activationDate.toLocaleDateString()}`}
                                        </Badge>
                                    )}
                                </CardHeader>
                                <CardBody>
                                    <Statistic
                                        title={t('my_account.disk_space')}
                                        value={`${allowedDiskUsage / 1024 / 1024} MB`}
                                    />
                                    <Statistic
                                        title={t('my_account.push_notifications')}
                                        value={maxNotifications}
                                    />
                                    <Statistic
                                        title={t('my_account.mau_number')}
                                        value={mauLimit}
                                    />
                                    <hr />
                                    <Services
                                        services={services.filter(
                                            (s) => s.id !== SERVICE_TYPE.PUSH_NOTIFICATION
                                        )}
                                    />
                                </CardBody>
                                <CardFooter className="bg-transparent border-top-0">
                                    {plan.id === PLAN_TYPE.FREE ? (
                                        <Popconfirm
                                            disabled={plan.id === activePlan.id}
                                            title={t('my_account.downgrading_to_free')}
                                            onConfirm={downgradeToFree}
                                            okText={t('my_account.unsubscribe')}
                                            okButtonProps={{ size: 'middle' }}
                                            cancelText={t('common.cancel')}
                                            cancelButtonProps={{ size: 'middle' }}
                                            overlayStyle={{ maxWidth: '400px' }}
                                        >
                                            <Button
                                                block
                                                outline
                                                disabled={plan.id === idActivePlan}
                                            >
                                                {plan.id === idActivePlan
                                                    ? t('my_account.current')
                                                    : t('my_account.unsubscribe')}
                                            </Button>
                                        </Popconfirm>
                                    ) : (
                                        <Checkout plan={plan} />
                                    )}
                                </CardFooter>
                            </Card>
                        </Col>
                    );
                })}
        </Row>
    );
};

const Checkout = ({ plan }) => {
    const { t } = useTranslation();
    const { plans = [] } = usePlans();
    const { getSubscriptionPreview, loading, setPlan, viewer = {} } = useViewer();
    let { countryCode, favoriteCurrencyCode, preview = {}, subscriptions = [] } = viewer;
    favoriteCurrencyCode = favoriteCurrencyCode || 'USD';
    const { coupon = {}, intervals = [] } = preview;
    const { idSubscription: idActivePlan, idSubscriptionPeriod } =
        subscriptions.find(({ idState }) => idState === SUBSCRIPTION_STATE.ACTIVE) || {};
    const activePlan = plans.find(({ id }) => id === idActivePlan) || {};
    const [selectedInterval, setSelectedInterval] = useState({ type: 'year' });
    let { detail = {} } = selectedInterval;
    detail = intervals.length ? detail : {};
    const { description: name, mauLimit } = plan;
    const couponInput = document.getElementById('CouponInput');
    const couponInputProps = {};

    if (!loading && couponInput && couponInput.value && coupon.error) {
        couponInputProps.invalid = true;
    } else if (coupon.amount_off || coupon.percent_off) {
        couponInputProps.valid = true;
    }

    const handleCheckoutOpened = async (plan) => {
        const { intervals: planIntervals = [] } = plan;
        let interval =
            planIntervals.find(
                ({ id, period }) => id !== idSubscriptionPeriod && period === selectedInterval.type
            ) || planIntervals.reverse().find(({ id, period }) => id !== idSubscriptionPeriod);
        const { intervals = [] } = await getSubscriptionPreview({ interval, plan });
        setSelectedInterval(intervals.find(({ type }) => type === interval.period));
    };

    const handleIntervalClick = (interval) => {
        setSelectedInterval(interval);
    };

    const handleCouponInputBlur = async (event, plan) => {
        const { currentTarget } = event;
        const { value } = currentTarget;
        const { intervals: planIntervals = [] } = plan;
        const interval = planIntervals.find(({ period }) => period === selectedInterval.type);
        const { coupon = {}, intervals = [] } = await getSubscriptionPreview({
            coupon: value,
            interval,
            plan,
        });
        const { error } = coupon;

        if (currentTarget) {
            if (value && error) {
                currentTarget.setCustomValidity(t(`my_account.${error}`));
            } else {
                currentTarget.setCustomValidity('');
            }
            currentTarget.reportValidity();
        }

        setSelectedInterval(
            intervals.find(({ type }) => type === (selectedInterval.type || 'year'))
        );
    };

    const handleCouponInputChange = (event) => {
        const { currentTarget } = event;
        currentTarget.setCustomValidity('');
    };

    const handleBuyClick = ({ intervals }) => {
        const interval = intervals.find(({ period }) => period === selectedInterval.type);
        const coupon = couponInput.value;
        setPlan({ coupon, interval }).then((done) => {
            done && document.querySelector('.modal .close').click();
        });
    };

    return (
        <CustomModal
            button={
                plan.id === idActivePlan
                    ? t('my_account.current')
                    : activePlan.mauLimit < mauLimit
                    ? t('my_account.upgrade', { name })
                    : t('my_account.downgrade', { name })
            }
            buttonParameters={{
                block: true,
                color: activePlan.mauLimit < mauLimit ? 'primary' : 'secondary',
                disabled: plan.id === idActivePlan,
                outline: true,
            }}
            className="checkout max-unset"
            footer={
                <Button
                    color="primary"
                    disabled={!selectedInterval.amount}
                    onClick={() => handleBuyClick(plan)}
                >
                    {t('my_account.buy')}
                </Button>
            }
            onOpened={() => handleCheckoutOpened(plan)}
        >
            <Row>
                <Col>
                    <PaymentMethods changeDefaultOnClick={true} />
                    <h6 className="mt-5">{t('my_account.payment_period')}</h6>
                    <ButtonGroup className="intervals">
                        {intervals.map((interval, i) => {
                            const { amount, type } = interval;
                            const { intervals: planIntervals } = plan;
                            const { id: idPlanInterval } =
                                planIntervals.find(({ period }) => period === type) || {};

                            return (
                                <Button
                                    key={`Interval-${i}`}
                                    className={type === selectedInterval.type ? 'active' : ''}
                                    disabled={idPlanInterval === idSubscriptionPeriod}
                                    onClick={() => handleIntervalClick(interval)}
                                    outline
                                >
                                    <Tooltip
                                        color="#28a745"
                                        title={
                                            idPlanInterval === idSubscriptionPeriod &&
                                            t('my_account.current')
                                        }
                                        visible={idPlanInterval === idSubscriptionPeriod}
                                    >
                                        <small>
                                            {t('my_account.payment_interval', {
                                                context: type,
                                            })}
                                        </small>
                                    </Tooltip>
                                    <strong className="d-block font-size-26">
                                        {amount.toLocaleString(countryCode, {
                                            style: 'currency',
                                            currency: favoriteCurrencyCode,
                                            minimumFractionDigits: 2,
                                            maximumFractionDigits: 2,
                                        })}
                                    </strong>
                                    <small>/{t(`plans.${type}`)}</small>
                                </Button>
                            );
                        })}
                    </ButtonGroup>
                    <h6 className="mt-5">{t('my_account.discount_code')}</h6>
                    <Input
                        {...couponInputProps}
                        id="CouponInput"
                        defaultValue={coupon.value}
                        onBlur={(event) => handleCouponInputBlur(event, plan)}
                        onChange={handleCouponInputChange}
                    />
                    {(coupon.amount_off && (
                        <FormFeedback className="text-right" valid>
                            -
                            {coupon.amount_off.toLocaleString(countryCode, {
                                style: 'currency',
                                currency: favoriteCurrencyCode,
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                            })}
                        </FormFeedback>
                    )) ||
                        ''}
                    {(coupon.percent_off && (
                        <FormFeedback className="text-right" valid>
                            -{coupon.percent_off * 100}%
                        </FormFeedback>
                    )) ||
                        ''}
                </Col>
                <Col xs="auto">
                    <Card className="payment-summary">
                        <CardBody>
                            <h6 className="mb-4">{t('my_account.payment_summary')}</h6>
                            <div>
                                {(detail.amount && (
                                    <>
                                        <small className="d-flex justify-content-between">
                                            <label>{t('my_account.amount')}</label>
                                            <span>
                                                {detail.amount.toLocaleString(countryCode, {
                                                    style: 'currency',
                                                    currency: favoriteCurrencyCode,
                                                    minimumFractionDigits: 2,
                                                    maximumFractionDigits: 2,
                                                })}
                                            </span>
                                        </small>
                                    </>
                                )) ||
                                    ''}

                                {(detail.remaining && (
                                    <>
                                        <hr />
                                        <small className="d-flex justify-content-between">
                                            <label>{t('my_account.remaining')}</label>
                                            <span>
                                                {detail.remaining.toLocaleString(countryCode, {
                                                    style: 'currency',
                                                    currency: favoriteCurrencyCode,
                                                    minimumFractionDigits: 2,
                                                    maximumFractionDigits: 2,
                                                })}
                                            </span>
                                        </small>
                                    </>
                                )) ||
                                    ''}

                                {(detail.amount_prorated && (
                                    <>
                                        <hr />
                                        <small className="d-flex justify-content-between">
                                            <label>{t('my_account.amount_prorated')}</label>
                                            <span>
                                                {detail.amount_prorated.toLocaleString(
                                                    countryCode,
                                                    {
                                                        style: 'currency',
                                                        currency: favoriteCurrencyCode,
                                                        minimumFractionDigits: 2,
                                                        maximumFractionDigits: 2,
                                                    }
                                                )}
                                            </span>
                                        </small>
                                    </>
                                )) ||
                                    ''}

                                {(detail.tax && (
                                    <small className="d-flex justify-content-between">
                                        <label>{t('my_account.taxes')}</label>
                                        <span>
                                            {detail.tax.toLocaleString(countryCode, {
                                                style: 'currency',
                                                currency: favoriteCurrencyCode,
                                                minimumFractionDigits: 2,
                                                maximumFractionDigits: 2,
                                            })}
                                        </span>
                                    </small>
                                )) ||
                                    ''}

                                {(detail.discount && (
                                    <>
                                        <hr />
                                        <small className="d-flex justify-content-between">
                                            <label>{t('my_account.discount')}</label>
                                            <span>
                                                {detail.discount.toLocaleString(countryCode, {
                                                    style: 'currency',
                                                    currency: favoriteCurrencyCode,
                                                    minimumFractionDigits: 2,
                                                    maximumFractionDigits: 2,
                                                })}
                                            </span>
                                        </small>
                                    </>
                                )) ||
                                    ''}

                                {(detail.total && (
                                    <>
                                        <hr className="total" />
                                        <div className="d-flex justify-content-between">
                                            <strong>{t('my_account.total')}</strong>
                                            <strong>
                                                {detail.total.toLocaleString(countryCode, {
                                                    style: 'currency',
                                                    currency: favoriteCurrencyCode,
                                                    minimumFractionDigits: 2,
                                                    maximumFractionDigits: 2,
                                                })}
                                            </strong>
                                        </div>
                                    </>
                                )) ||
                                    ''}
                            </div>
                        </CardBody>
                        <CardFooter>
                            <small>
                                {detail.next_payment_date && (
                                    <p>
                                        {`${t('my_account.next_payment_date')}: ${new Date(
                                            detail.next_payment_date
                                        ).toLocaleDateString()}`}
                                    </p>
                                )}
                                <p>
                                    {detail.total &&
                                        detail.total.toLocaleString(countryCode, {
                                            style: 'currency',
                                            currency: favoriteCurrencyCode,
                                            minimumFractionDigits: 2,
                                            maximumFractionDigits: 2,
                                        })}
                                    /{t(`plans.${selectedInterval.type}`)}{' '}
                                    {t('my_account.summary_payment_footer')}
                                </p>
                            </small>
                        </CardFooter>
                    </Card>
                </Col>
            </Row>
        </CustomModal>
    );
};

const Services = ({ className = '', idPlan, services = [] }) => {
    const { t } = useTranslation();
    const { plans = [] } = usePlans();
    if (!services.length && idPlan) {
        const { services: planServices = [] } = plans.find(({ id }) => id === idPlan) || {};
        services = planServices;
    }
    return (
        <div className={`${className}`}>
            <div>
                {Object.values(SERVICE_TYPE)
                    .filter((id) => !services.length || services.some((s) => s.id === id))
                    .map((id) => (
                        <div key={`Service-${id}`} className="small text-black-50 py-2">
                            {t(`services.service_${id}`)}
                        </div>
                    ))}
            </div>
        </div>
    );
};

const Limits = ({ className }) => {
    const { t } = useTranslation();
    const { viewer = {} } = useViewer();
    const { subscriptions = [] } = viewer;
    const activeSubscription = subscriptions.find(
        ({ idState }) => idState === SUBSCRIPTION_STATE.ACTIVE
    );
    const { pushSent = 0, mau = 0, mauLimit = 100, maxNotifications = 100 } =
        activeSubscription || {};
    let { allowedDiskUsage = 1073741824, diskUsage = 0 } = activeSubscription || {};
    allowedDiskUsage = allowedDiskUsage / 1024 / 1024;
    diskUsage = diskUsage / 1024 / 1024;

    return (
        <Row className="limits" xs="1" lg="3">
            <Col>
                <CardBody>
                    <Progress
                        type="circle"
                        strokeColor={{
                            '0%': '#ff7113',
                            '100%': '#aa2585',
                        }}
                        format={() => `${diskUsage} MB`}
                        percent={(diskUsage * 100) / allowedDiskUsage}
                    />
                    <CardTitle className="m-0 mt-3">{t('my_account.disk_space')}</CardTitle>
                    <small className="text-muted">
                        {t('my_account.max', {
                            unit: 'MB',
                            max: allowedDiskUsage,
                        })}
                    </small>
                </CardBody>
            </Col>
            <Col>
                <CardBody>
                    <Progress
                        type="circle"
                        strokeColor={{
                            '0%': '#ff7113',
                            '100%': '#aa2585',
                        }}
                        format={() => pushSent}
                        percent={(pushSent * 100) / maxNotifications}
                    />
                    <CardTitle className="m-0 mt-3">{t('my_account.push_notifications')}</CardTitle>
                    <small className="text-muted">
                        {t('my_account.max', { max: maxNotifications })}
                    </small>
                </CardBody>
            </Col>
            <Col>
                <CardBody>
                    <Progress
                        type="circle"
                        strokeColor={{
                            '0%': '#ff7113',
                            '100%': '#aa2585',
                        }}
                        format={() => mau}
                        percent={(mau * 100) / mauLimit}
                    />
                    <CardTitle className="m-0 mt-3">{t('my_account.mau_usage')}</CardTitle>
                    <small className="text-muted">{t('my_account.max', { max: mauLimit })}</small>
                </CardBody>
            </Col>
        </Row>
    );
};

const Subscription = () => {
    const { t } = useTranslation();
    const { getSubscriptions, viewer = {} } = useViewer();
    const { subscriptions = [] } = viewer;
    const activeSubscription = subscriptions.find(
        ({ idState }) => idState === SUBSCRIPTION_STATE.ACTIVE
    );
    const { idSubscription } = activeSubscription || {};
    let { nextPaymentDate } = activeSubscription || {};
    nextPaymentDate = nextPaymentDate && new Date(nextPaymentDate);
    let pendingSubscription = subscriptions.find(
        ({ idState }) => idState === SUBSCRIPTION_STATE.PENDING
    );
    let { activationDate, idSubscription: idPendingPlan } = pendingSubscription || {};
    activationDate = new Date(activationDate);

    useMemo(() => {
        if (!activeSubscription) {
            getSubscriptions();
        }
    }, [subscriptions]);

    return (
        <div className="text-center d-flex flex-column h-100">
            <div className="flex-grow-1 d-flex flex-column align-items-center justify-content-center">
                <img src={logoMin} alt="Logo" height="128px" />
                <h1 className="my-3 text-uppercase">{PLAN_NAME[idSubscription]}</h1>
                {nextPaymentDate && (
                    <p>
                        <small>{`${t(
                            'my_account.next_payment_date'
                        )}: ${nextPaymentDate.toLocaleDateString()}`}</small>
                    </p>
                )}
                {idPendingPlan && (
                    <>
                        <h6 className="m-0">{`${t('my_account.pending')}: ${
                            PLAN_NAME[idPendingPlan]
                        }`}</h6>
                        <p>
                            <small>
                                {`${t(
                                    'my_account.activation_date'
                                )}: ${activationDate.toLocaleDateString()}`}
                            </small>
                        </p>
                    </>
                )}
                <UncontrolledCollapse toggler="#ServicesToggler">
                    <Services idPlan={idSubscription} />
                </UncontrolledCollapse>
                <div className="d-flex my-3">
                    <Button outline id="ServicesToggler">
                        {t('services.title')}
                    </Button>
                    <Button className="ml-3" id="PlansToggler">
                        {t('my_account.upgrade_account')}
                    </Button>
                </div>
                <UncontrolledCollapse toggler="#PlansToggler">
                    <div className="py-4">
                        <Plans />
                    </div>
                </UncontrolledCollapse>
            </div>
            <div className="bg-light d-flex justify-content-center mx--5 mb--3">
                <Limits />
            </div>
        </div>
    );
};

const Billing = () => {
    const { t } = useTranslation();
    const { updateUser, viewer = {} } = useViewer();
    const {
        companyName = '',
        companyVATNumber = '',
        companyContactName = '',
        companyContactSurname = '',
        companyCountryCode = '',
        companyAddress = '',
        companyPostalCode = '',
        companyTelNumber = '',
        companyEmail = '',
        paypalEmail = '',
    } = viewer;
    const [isValidVatNumber, setIsValidVatNumber] = useState(false);

    useMemo(() => {
        setIsValidVatNumber(isValidVat(companyVATNumber, companyCountryCode));
    }, [companyVATNumber, companyCountryCode]);

    const handleChange = ({ nativeEvent = {} } = {}) => {
        const { target = {} } = nativeEvent;
        const { name, value } = target;
        updateUser({ [name]: value });
    };

    return (
        <>
            <h6 className="mb-4">{t('my_account.income_payment_method')}</h6>
            <div className="grid mb-4">
                <FormGroup>
                    <Label className="h6 small d-block text-uppercase">
                        {t('my_account.paypal_email')}
                    </Label>
                    <Input
                        type="email"
                        defaultValue={paypalEmail}
                        name="paypalEmail"
                        onBlur={handleChange}
                    />
                </FormGroup>
            </div>
            <h6>{t('my_account.billing_information')}</h6>
            <div className="grid">
                <FormGroup>
                    <Label className="h6 small d-block text-uppercase">
                        {t('my_account.company_name')}
                    </Label>
                    <Input defaultValue={companyName} name="companyName" onBlur={handleChange} />
                </FormGroup>
                <FormGroup>
                    <Label className="h6 small d-block text-uppercase">
                        {t('my_account.VAT_NIF')}
                    </Label>
                    <InputGroup>
                        <Input
                            defaultValue={companyVATNumber}
                            name="companyVATNumber"
                            onBlur={handleChange}
                        />

                        <InputGroupAddon addonType="append">
                            <Tooltip
                                placement="bottom"
                                title={isValidVatNumber ? '' : t('my_account.invalid_vat')}
                            >
                                <InputGroupText
                                    className={`border-0 ${
                                        isValidVatNumber ? 'bg-success' : 'bg-danger'
                                    }`}
                                >
                                    <Icon
                                        className="text-white"
                                        type={isValidVatNumber ? 'check' : 'cancel'}
                                    />
                                </InputGroupText>
                            </Tooltip>
                        </InputGroupAddon>
                    </InputGroup>
                </FormGroup>
                <FormGroup>
                    <Label className="h6 small d-block text-uppercase">
                        {t('my_account.name')}
                    </Label>
                    <Input
                        defaultValue={companyContactName}
                        name="companyContactName"
                        onBlur={handleChange}
                    />
                </FormGroup>
                <FormGroup>
                    <Label className="h6 small d-block text-uppercase">
                        {t('my_account.surname')}
                    </Label>
                    <Input
                        defaultValue={companyContactSurname}
                        name="companyContactSurname"
                        onBlur={handleChange}
                    />
                </FormGroup>
                <FormGroup>
                    <Label className="h6 small d-block text-uppercase">
                        {t('my_account.postal_address')}
                    </Label>
                    <Input
                        defaultValue={companyAddress}
                        name="companyAddress"
                        onBlur={handleChange}
                    />
                </FormGroup>
                <FormGroup>
                    <Label className="h6 small d-block text-uppercase">
                        {t('my_account.post_code')}
                    </Label>
                    <Input
                        defaultValue={companyPostalCode}
                        name="companyPostalCode"
                        onBlur={handleChange}
                    />
                </FormGroup>
                <FormGroup>
                    <Label className="h6 small d-block text-uppercase">
                        {t('my_account.country')}
                    </Label>
                    <Input
                        defaultValue={companyCountryCode}
                        name="companyCountryCode"
                        onBlur={handleChange}
                    />
                </FormGroup>
                <FormGroup>
                    <Label className="h6 small d-block text-uppercase">
                        {t('my_account.phone_number')}
                    </Label>
                    <Input
                        defaultValue={companyTelNumber}
                        name="companyTelNumber"
                        onBlur={handleChange}
                    />
                </FormGroup>
                <FormGroup>
                    <Label className="h6 small d-block text-uppercase">
                        {t('my_account.email')}
                    </Label>
                    <Input defaultValue={companyEmail} name="companyEmail" onBlur={handleChange} />
                </FormGroup>
            </div>
        </>
    );
};

const stripePromise = loadStripe(SECRETS.STRIPE);

const CardForm = ({ setIsOpen }) => {
    const { t } = useTranslation();
    const stripe = useStripe();
    const elements = useElements();
    const { createSource } = useViewer();

    const handleSubmit = async (event) => {
        event.preventDefault();
        const { nativeEvent } = event;
        const { target } = nativeEvent;
        const { name } = target;
        const { token } = await stripe.createToken(elements.getElement(CardElement), {
            name: name.value,
        });
        createSource(token).then((done) => setIsOpen(!done));
    };

    return (
        <Form onSubmit={handleSubmit}>
            <Input
                className="mb-3"
                name="name"
                placeholder={t('my_account.card_holder_name')}
                required
            />
            <CardElement />
            <Button className="mt-3" disabled={!stripe}>
                {t('my_account.create_card')}
            </Button>
        </Form>
    );
};

const PaymentMethods = ({ changeDefaultOnClick }) => {
    const { t } = useTranslation();
    const [isCardInputModalOpened, setCardInputModalOpened] = useState(false);
    const { deleteSource, getSources, setSourceAsDefault, viewer = {} } = useViewer();
    const { sources = [] } = viewer;
    const sourcesRequested = useRef(false);

    useEffect(() => {
        if (!sources.length && !sourcesRequested.current) {
            getSources();
            sourcesRequested.current = true;
        }
    }, [sources]);

    const sourcesLogos = {
        americanexpress,
        mastercard,
        visa,
    };

    return (
        <>
            <h6>{t('my_account.cards')}</h6>
            <div className="sources d-flex flex-wrap mt-4 mx--2">
                {sources.map(({ brand, default: _default, exp_month, exp_year, id, last4 }) => {
                    const showSetDefaultButton = !_default;
                    const showDeleteButton = !_default || sources.length < 2;
                    return (
                        <Card
                            key={`Source-${id}`}
                            className="shadow-sm m-2"
                            data-default={_default}
                            onClick={() => changeDefaultOnClick && setSourceAsDefault({ id })}
                        >
                            {_default && (
                                <Badge color="success" pill>
                                    {t('my_account.default')}
                                </Badge>
                            )}
                            <CardBody>
                                <div className="d-flex">
                                    <div className="flex-grow-1">
                                        <img
                                            alt={brand}
                                            className="rounded overflow-hidden"
                                            src={sourcesLogos[brand.toLowerCase()]}
                                        />
                                    </div>
                                    <div className="pl-5 text-right">
                                        <h6>...{last4}</h6>
                                        <small className="text-muted">
                                            exp. {exp_month}/{exp_year}
                                        </small>
                                    </div>
                                </div>
                            </CardBody>
                            {(showSetDefaultButton || showDeleteButton) && (
                                <CardFooter className="text-right">
                                    {showSetDefaultButton && (
                                        <Button
                                            className="border-0"
                                            outline
                                            onClick={() => setSourceAsDefault({ id })}
                                        >
                                            {t('my_account.set_as_default')}
                                        </Button>
                                    )}
                                    {showDeleteButton && (
                                        <Button
                                            className="border-0"
                                            color="danger"
                                            outline
                                            onClick={() => deleteSource({ id })}
                                        >
                                            {t('my_account.delete')}
                                        </Button>
                                    )}
                                </CardFooter>
                            )}
                        </Card>
                    );
                })}
            </div>
            <hr className="border-0" />
            <CustomModal
                button={t('my_account.create_card')}
                buttonParameters={{ outline: true }}
                isOpen={isCardInputModalOpened}
                title={`${t('my_account.create_card')}`}
                style={{ width: '400px' }}
            >
                <Elements stripe={stripePromise}>
                    <CardForm setIsOpen={setCardInputModalOpened} />
                </Elements>
            </CustomModal>
        </>
    );
};

const PaymentReceipts = () => {
    const { t } = useTranslation();
    return (
        <>
            <h6>{t('my_account.payment_receipts')}</h6>
        </>
    );
};

const MyAccountContent = ({ content }) => {
    switch (content) {
        case 'summary':
            return <Summary />;
        case 'subscription':
            return <Subscription />;
        case 'billing':
            return <Billing />;
        case 'payment_methods':
            return <PaymentMethods />;
        case 'payment_receipts':
            return <PaymentReceipts />;
        default:
            return null;
    }
};

export const MyAccount = ({ isOpen: isOpenProp }) => {
    const { t } = useTranslation();
    const [isOpen, setIsOpen] = useState(isOpenProp);
    const { modal, setModal, key = 'summary' } = useModals();
    const [content, setContent] = useState(key);

    useEffect(() => {
        setIsOpen(modal === MODALS.MY_ACCOUNT);
    }, [modal]);

    const handleMenuClick = ({ key }) => {
        setContent(key);
    };

    return (
        <Modal
            className="myaccount modal-menu max-80"
            isOpen={isOpen}
            title={t('my_account.title')}
            onClosed={() => setModal(null)}
        >
            <ModalBody>
                <Menu
                    className="w-auto"
                    mode="inline"
                    onClick={handleMenuClick}
                    defaultSelectedKeys={[content]}
                >
                    <Menu.Item key="summary">{t('my_account.summary')}</Menu.Item>
                    <Menu.Item key="subscription">{t('my_account.subscription')}</Menu.Item>
                    <Menu.Item key="billing">{t('my_account.billing')}</Menu.Item>
                    <Menu.Item key="payment_methods">{t('my_account.payment_methods')}</Menu.Item>
                    <Menu.Item key="payment_receipts">{t('my_account.payment_receipts')}</Menu.Item>
                </Menu>
                <ModalBody className="px-5" data-key={content}>
                    <MyAccountContent content={content} />
                </ModalBody>
            </ModalBody>
        </Modal>
    );
};
