import { i18n } from '../i18n';

const commonElementProperties = {
    IDAction: { type: 'number' },
    IDElement: { type: 'number' },
    IDElementType: { type: 'number', enum: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11] },
    IDGuia: { type: 'number' },
    IDInfo: { type: 'number' },
    IDSection: { oneOf: [{ type: 'null' }, { type: 'number' }] },
    Sequence: { type: 'number' },
    hAlignChild: {},
    hAlignParent: {
        oneOf: [{ type: 'null' }, { type: 'string', enum: ['left', 'center', 'right'] }],
    },
    id: { type: 'number' },
    idShading: {},
    lastUpdated: { type: 'number' },
    parameter: { type: 'string' },
    vAlignChild: {
        oneOf: [{ type: 'null' }, { type: 'string', enum: ['top', 'middle', 'bottom'] }],
    },
    vAlignParent: {},
    width: { type: 'number' },
};

export const element = {
    title: 'Element',
    type: 'object',
    properties: {
        ...commonElementProperties,
    },
    select: { $data: '0/IDElementType' },
    selectCases: {
        1: {
            properties: {
                ...commonElementProperties,
                IDParentElement: { type: 'number' },
                concrete: {
                    type: 'object',
                    properties: {
                        IDElement: { type: 'number' },
                        IDImage: {
                            type: 'number',
                            minimum: 1,
                            errorMessage: {
                                minimum: i18n.t('errors.image_not_selected'),
                            },
                        },
                        IDImageElem: { type: 'number' },
                        hAlign: { type: 'string', enum: ['left', 'center', 'right'] },
                        id: { type: 'number' },
                        imageFieldName: {},
                        srcRef: {},
                        srcType: {},
                    },
                },
            },
        },
        2: {
            properties: {
                ...commonElementProperties,
                IDParentElement: { type: 'number' },
                concrete: {
                    type: 'object',
                    properties: {
                        CoordX: { type: 'number' },
                        FontBoldColor: { type: 'string' },
                        FontColor: { type: 'string' },
                        IDElement: { type: 'number' },
                        IDFont: { type: 'number' },
                        IDFontBold: { type: 'number' },
                        IDText: {
                            type: 'number',
                            minimum: 1,
                            errorMessage: {
                                minimum: i18n.t('errors.text_not_selected'),
                            },
                        },
                        IDTextElem: { type: 'number' },
                        externalFont: { type: 'null' },
                        fontSize: {
                            oneOf: [{ type: 'null' }, { type: 'string' }],
                        },
                        hAlign: { type: 'string', enum: ['left', 'center', 'right'] },
                        id: { type: 'number' },
                        srcRef: {
                            oneOf: [{ type: 'null' }, { type: 'string' }],
                        },
                        srcType: {
                            oneOf: [{ type: 'null' }, { type: 'string' }],
                        },
                        textFieldName: {
                            oneOf: [{ type: 'null' }, { type: 'string' }],
                        },
                        textType: { type: 'string', enum: ['plain'] },
                        width: { type: 'number' },
                    },
                },
            },
        },
        3: {
            properties: {
                ...commonElementProperties,
                IDParentElement: { type: 'number' },
                concrete: {
                    type: 'object',
                    properties: {
                        IDElement: { type: 'number' },
                        IDFont: { type: 'number' },
                        IDNewLineElem: { type: 'number' },
                        hAlign: { type: 'string', enum: ['left', 'center', 'right'] },
                        id: { type: 'number' },
                        lineStyle: {
                            type: 'string',
                            enum: ['none', 'solid', 'dashed', 'dotted', 'shaded'],
                        },
                        width: { type: 'number' },
                    },
                },
            },
        },
        4: {
            properties: {
                ...commonElementProperties,
                IDParentElement: { type: 'number' },
                concrete: {
                    type: 'object',
                    properties: {
                        IDElement: { type: 'number' },
                        IDFont: { type: 'number' },
                        IDLabelCancel: { type: 'number' },
                        IDLabelOK: { type: 'number' },
                        IDTextFieldElem: { type: 'number' },
                        backgroundColor: { type: 'string' },
                        borderColor: { type: 'string', pattern: '^([A-Fa-f0-9]{3,4}){1,2}$' },
                        fontColor: { type: 'string', pattern: '^([A-Fa-f0-9]{3,4}){1,2}$' },
                        format: { type: 'string', enum: ['text', 'number'] },
                        hAlign: { type: 'string', enum: ['left', 'center', 'right'] },
                        id: { type: 'number' },
                        reference: { type: 'string' },
                        srcRef: {
                            oneOf: [{ type: 'null' }, { type: 'string' }],
                        },
                        srcType: {
                            oneOf: [{ type: 'null' }, { type: 'string' }],
                        },
                        title: { type: 'string' },
                        width: { type: 'number' },
                    },
                },
            },
        },
        5: {
            properties: {
                ...commonElementProperties,
                IDParentElement: { oneOf: [{ type: 'null' }, { type: 'number' }] },
                concrete: {
                    type: 'object',
                    properties: {
                        IDBodyElem: { type: 'number' },
                        IDElement: { type: 'number' },
                        bodyType: { type: 'string', enum: ['horizontal', 'vertical'] },
                        id: { type: 'number' },
                    },
                },
            },
        },
        6: {
            properties: {
                ...commonElementProperties,
                IDParentElement: { type: 'null' },
                IDSection: { type: 'null' },
                concrete: {
                    type: 'object',
                    properties: {
                        IDBarElem: { type: 'number' },
                        IDElement: { type: 'number' },
                        id: { type: 'number' },
                        name: { type: 'string' },
                        title: { type: 'string' },
                    },
                },
            },
        },
        11: {
            properties: {
                ...commonElementProperties,
                IDParentElement: { type: 'number' },
                concrete: {
                    type: 'object',
                    properties: {
                        IDElement: { type: 'number' },
                        IDVideoElem: { type: 'number' },
                        hAlign: { type: 'string', enum: ['left', 'center', 'right'] },
                        id: { type: 'number' },
                        idVideo: {
                            type: 'number',
                            errorMessage: {
                                type: i18n.t('errors.video_not_selected'),
                            },
                        },
                        loops: { type: 'integer' },
                        pause: { type: 'number' },
                        play: { type: 'string', enum: ['onload', 'ontouch'] },
                        srcRef: {
                            oneOf: [{ type: 'null' }, { type: 'string' }],
                        },
                        srcType: {
                            oneOf: [{ type: 'null' }, { type: 'string' }],
                        },
                        thumbSrcRef: {
                            oneOf: [{ type: 'null' }, { type: 'string' }],
                        },
                        thumbSrcType: {
                            oneOf: [{ type: 'null' }, { type: 'string' }],
                        },
                        videoFieldName: {
                            oneOf: [{ type: 'null' }, { type: 'string' }],
                        },
                    },
                },
            },
        },
    },
};

export default element;
