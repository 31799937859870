import { i18n } from '../i18n';

export const screen = {
    title: 'Screen',
    type: 'object',
    properties: {
        Background: { type: 'string' },
        IDAction: { type: 'integer' },
        IDGuia: { type: 'integer' },
        IDHeader: { type: 'number' },
        IDSection: { type: 'integer' },
        IDTipoSeccion: {
            type: 'integer',
            minimum: 1,
            errorMessage: {
                minimum: i18n.t('errors.empty_screen'),
            },
        },
        Nombre: { type: 'string' },
        Parameter: { type: 'string' },
        Timeout: { type: 'number' },
        anchor: { type: 'boolean' },
        id: { type: 'integer' },
        lastUpdated: { type: 'string' },
        orientation: {
            oneOf: [{ type: 'null' }, { type: 'string', enum: ['landscape', 'portrait'] }],
        },
        section_bars: { type: 'array' },
        section_image: { oneOf: [{ type: 'null' }, { type: 'object' }] },
        typeName: { type: 'string' },
    },
};

export default screen;
