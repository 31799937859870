import React from 'react';
import { Input } from 'reactstrap';

export const DateProperty = ({ onChange: handleChange, value }) => {
    const handleDateChange = (event) => {
        const { currentTarget } = event;
        const { value } = currentTarget;
        handleChange(value);
    };

    return <Input type="date" value={value} onChange={handleDateChange} />;
};
