import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import parse from 'html-react-parser';
import { Popover } from 'antd';
import {
    Button,
    ButtonGroup,
    CardDeck,
    Carousel,
    CarouselItem,
    CarouselControl,
    CarouselIndicators,
    CarouselCaption,
    Modal,
    ModalBody,
} from 'reactstrap';
import { MODALS } from '../_config';
import { Application } from '../components';
import { useApplications, useModals, useViewer } from '../hooks';
import { logoMin as welcome1 } from '../assets/svg/logos';
import welcome2 from '../assets/img/welcome/init-slide-2.png';
import welcome3 from '../assets/img/welcome/init-slide-3.png';
import welcome4 from '../assets/img/welcome/init-slide-4.png';
import createAppImage from '../assets/img/welcome/init-actions-create.png';
import upgradeImage from '../assets/img/welcome/init-actions-upgrade.png';

const WelcomeCarousel = () => {
    const { t } = useTranslation();
    const { recentApplications = [] } = useViewer();
    const [activeIndex, setActiveIndex] = useState(0);
    const [animating, setAnimating] = useState(false);

    let items = [
        {
            src: welcome1,
            altText: 'Slide 1',
            header: t('welcome_carousel.slide1.title'),
            text: t('welcome_carousel.slide1.text'),
        },
        {
            src: welcome2,
            altText: 'Slide 2',
            header: t('welcome_carousel.slide2.title'),
            text: t('welcome_carousel.slide2.text'),
        },
        {
            src: welcome3,
            altText: 'Slide 3',
            header: t('welcome_carousel.slide3.title'),
            text: t('welcome_carousel.slide3.text'),
        },
        {
            src: welcome4,
            altText: 'Slide 4',
            header: t('welcome_carousel.slide4.title'),
            text: t('welcome_carousel.slide4.text'),
        },
    ];

    const next = () => {
        if (animating) return;
        const nextIndex = activeIndex === items.length - 1 ? 0 : activeIndex + 1;
        setActiveIndex(nextIndex);
    };

    const previous = () => {
        if (animating) return;
        const nextIndex = activeIndex === 0 ? items.length - 1 : activeIndex - 1;
        setActiveIndex(nextIndex);
    };

    const goToIndex = (newIndex) => {
        if (animating) return;
        setActiveIndex(newIndex);
    };

    let slides = items.map((item) => {
        return (
            <CarouselItem
                onExiting={() => setAnimating(true)}
                onExited={() => setAnimating(false)}
                key={item.src}
            >
                <div>
                    <img src={item.src} alt={item.altText} />
                    <CarouselCaption captionHeader={parse(item.header)} captionText={item.text} />
                </div>
            </CarouselItem>
        );
    });
    if (recentApplications[0]) {
        items = [
            {
                src: '',
                altText: recentApplications[0].Nombre,
                header: t('welcome.continue_working'),
            },
            ...items,
        ];
        slides = [
            <CarouselItem
                onExiting={() => setAnimating(true)}
                onExited={() => setAnimating(false)}
                key={`RecentApplication-${recentApplications[0].id}`}
            >
                <div>
                    <Application {...recentApplications[0]} />
                    <CarouselCaption
                        captionHeader={recentApplications[0].Nombre}
                        captionText={t('welcome.continue_working')}
                    />
                </div>
            </CarouselItem>,
            ...slides,
        ];
    }

    return (
        <Carousel activeIndex={activeIndex} next={next} previous={previous}>
            <CarouselIndicators
                items={items}
                activeIndex={activeIndex}
                onClickHandler={goToIndex}
            />
            {slides}
            <CarouselControl
                className="mdi mdi-chevron-left"
                direction="prev"
                directionText="Previous"
                onClickHandler={previous}
            />
            <CarouselControl
                className="mdi mdi-chevron-right"
                direction="next"
                directionText="Next"
                onClickHandler={next}
            />
        </Carousel>
    );
};

export const Welcome = ({ isOpen: isOpenProp }) => {
    const { t } = useTranslation();
    const [isOpen, setIsOpen] = useState(isOpenProp);
    const { createApplication } = useApplications();
    const { modal, openModal } = useModals();
    const { recentApplications = [] } = useViewer();

    useEffect(() => {
        setIsOpen(modal === MODALS.WELCOME);
    }, [modal]);

    return (
        <Modal className="welcome" isOpen={isOpen}>
            <ModalBody className="d-flex">
                <WelcomeCarousel />
                <ButtonGroup className="bg-light" vertical>
                    <Button
                        block
                        className="bg-image"
                        onClick={() => openModal(MODALS.MY_ACCOUNT, { key: 'subscription' })}
                        style={{ backgroundImage: `url(${upgradeImage})` }}
                    >
                        <span>{t('welcome.upgrade_subscription')}</span>
                        <div className="bg" />
                    </Button>
                    <Button
                        block
                        color="gray-light"
                        className="bg-image"
                        onClick={() => createApplication()}
                        style={{ backgroundImage: `url(${createAppImage})` }}
                    >
                        <span>{t('welcome.create_application')}</span>
                        <div className="bg" />
                    </Button>
                    <div className="pt-3 bg-light w-100" />
                    <Button block color="light" onClick={() => openModal(MODALS.APPLICATIONS)}>
                        <small>{t('welcome.open_application')}</small>
                    </Button>
                    {!!recentApplications.length && (
                        <>
                            <Popover
                                content={
                                    <CardDeck className="bg-light flex-wrap overflow-auto">
                                        {recentApplications.map((application) => (
                                            <Application
                                                key={`WelcomeRecentApplication${application.id}`}
                                                {...application}
                                            />
                                        ))}
                                    </CardDeck>
                                }
                                overlayClassName="recent-applications"
                                placement="top"
                                trigger="click"
                            >
                                <Button block color="light">
                                    <small>{t('header.open_recent')}...</small>
                                </Button>
                            </Popover>
                        </>
                    )}
                    <Button block className="p-0" color="light">
                        <a
                            href="https://mobincube.zendesk.com/hc/es/community/posts"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            <small>{t('welcome.join_community')}</small>
                        </a>
                    </Button>
                    <div className="h-100" />
                    <Button
                        block
                        className="mt-3"
                        color="gray-light"
                        onClick={() => setIsOpen(false)}
                    >
                        <small className="font-weight-bold">{t('dialogs.close')}</small>
                    </Button>
                </ButtonGroup>
            </ModalBody>
        </Modal>
    );
};

export default Welcome;
