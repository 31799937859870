import React, { createContext, useCallback, useEffect, useState } from 'react';
import { useImmerReducer } from 'use-immer';
import Unsplash from 'unsplash-js';
import { UNSPLASH, UnsplashReducer } from './reducers';
import { SECRETS } from '../_config';

const unsplash = new Unsplash({
    accessKey: SECRETS.UNSPLASH_ACCESS_KEY,
    secret: SECRETS.UNSPLASH_SECRET,
    callbackUrl: SECRETS.UNSPLASH_CALLBACK_URL,
});

export const UnsplashContext = createContext(UNSPLASH.INITIAL_STATE);

export const UnsplashProvider = ({ children }) => {
    const [loading, setLoading] = useState(false);
    const [state, dispatch] = useImmerReducer(UnsplashReducer, UNSPLASH.INITIAL_STATE);
    const [_page, setPage] = useState(1);
    const [_value, setValue] = useState('');

    const checkPhoto = (photos, { checked = true } = {}) => {
        photos = Array.isArray(photos) ? photos : [photos];
        dispatch({
            type: UNSPLASH.CHECK,
            payload: { photos, checked },
        });
    };

    const search = useCallback(
        ({ next, page = _page, perPage = 10, value = _value } = {}) => {
            return new Promise((resolve) => {
                setLoading(true);
                setValue(value);
                unsplash.search
                    .photos(value, page, perPage)
                    .then((response) => response.json())
                    .then((response) => {
                        const { results: photos } = response;
                        setLoading(false);
                        dispatch({ type: UNSPLASH.PHOTOS, payload: { photos, reset: page === 1 } });
                        resolve();
                    })
                    .catch((e) => console.error('Unsplash', e));
            });
        },
        [_page, _value]
    );

    useEffect(() => {
        search();
    }, [_page]);

    if (process.env.NODE_ENV === 'development') {
        console.log('UNSPLASH >>>', state);
    }

    return (
        <UnsplashContext.Provider
            value={{
                ...state,
                checkPhoto,
                loading,
                search,
                setPage,
            }}
        >
            {children}
        </UnsplashContext.Provider>
    );
};

export const UnsplashConsumer = UnsplashContext.Consumer;
export default UnsplashContext;
