import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Card, CardHeader, ListGroup, ListGroupItem } from 'reactstrap';
import { getUnique } from '../_helpers';
import { Icon, Panel } from '../components';
import { ELEMENT_ICON, SCREEN_ICON } from '../context';
import { useApplications, useElements, useErrors, useScreens, useViewer } from '../hooks';

export const ErrorsPanel = () => {
    const { t } = useTranslation();
    const { application = {} } = useApplications();
    const { screen = {}, screens = [], selectScreen } = useScreens();
    const { element = {}, elements = [], getRootElement, selectElement } = useElements();
    const {
        applications: applicationErrors = [],
        elements: elementErrors = [],
        screens: screenErrors = [],
        validate = () => {},
    } = useErrors();
    const { getPanelWidth, isVisibleErrorsPanel } = useViewer();

    const handleMouseEnter = (event) => {
        const { currentTarget } = event;
        const { dataset } = currentTarget;
        const { elementId } = dataset;
        document
            .querySelectorAll(`[data-element-id]`)
            .forEach((el) => el.classList.remove('hover'));
        const elementWrapper = document.querySelector(
            `.element-wrapper[data-element-id="${elementId}"]`
        );
        if (elementWrapper) {
            elementWrapper.classList.add('hover');
            elementWrapper.scrollIntoView({
                behavior: 'smooth',
                block: 'center',
                inline: 'center',
            });
        }
    };

    const handleMouseLeave = (event) => {
        const { currentTarget } = event;
        const { dataset } = currentTarget;
        const { elementId } = dataset;
        document.querySelector(`[data-element-id="${elementId}"]`).classList.remove('hover');
    };

    const handleSelectElement = (event) => {
        event.stopPropagation();
        const { currentTarget } = event;
        const { dataset } = currentTarget;
        let { elementId, screenId } = dataset;
        elementId = parseInt(elementId, 10);
        screenId = parseInt(screenId, 10);
        if (elementId && elementId === element.id) {
            return;
        }
        selectScreen({ id: screenId }).then(() => {
            selectElement({ id: elementId });
        });
    };

    const handleSelectScreen = (event) => {
        event.stopPropagation();
        const { currentTarget } = event;
        const { dataset } = currentTarget;
        const { screenId } = dataset;
        const id = parseInt(screenId, 10);
        if (id && id === element.id) {
            return;
        }
        selectScreen({ id });
    };

    useEffect(() => {
        validate({ application });
    }, [screens.length]);

    useEffect(() => {
        validate({ screen });
    }, [elements.length, screen.IDTipoSeccion]);

    useEffect(() => {
        validate({ element });
    }, [element.concrete]);

    return (
        <Panel
            className={`errors-panel${!isVisibleErrorsPanel ? ' d-none' : ''}`}
            disabled={!application.id}
            panel="errors"
            title={t('errors.title')}
            width={getPanelWidth('errors')}
        >
            {!!applicationErrors.length && (
                <>
                    <Card className="drag-item-group">
                        <ListGroup>
                            {applicationErrors.map(({ errors, id }) =>
                                errors.map(({ message }, i) => (
                                    <ListGroupItem
                                        key={`Error-Screen${id}-${i}`}
                                        onClick={handleSelectScreen}
                                        data-active={id && id === screen.id}
                                        data-application-id={id}
                                    >
                                        <Icon type="alert" className="mr-2" />
                                        <span>{message}</span>
                                    </ListGroupItem>
                                ))
                            )}
                        </ListGroup>
                    </Card>
                </>
            )}
            {!!screenErrors.length && (
                <>
                    <Card className="drag-item-group">
                        {screenErrors
                            .sort((s1, s2) => (s1.Nombre < s2.Nombre ? -1 : 1))
                            .map(({ errors, id, IDTipoSeccion, Nombre: name }) => {
                                return (
                                    <React.Fragment key={`Error-Screen${id}`}>
                                        <CardHeader>
                                            <strong>{name}</strong>
                                        </CardHeader>
                                        <ListGroup>
                                            {errors.map(({ message }, i) => {
                                                return (
                                                    <ListGroupItem
                                                        key={`Error-Screen${id}-${i}`}
                                                        onClick={handleSelectScreen}
                                                        data-active={id && id === screen.id}
                                                        data-screen-id={id}
                                                    >
                                                        <Icon
                                                            type={SCREEN_ICON[IDTipoSeccion]}
                                                            className="mr-2"
                                                        />
                                                        <span>{message}</span>
                                                    </ListGroupItem>
                                                );
                                            })}
                                            {elementErrors
                                                .filter(({ IDSection }) => IDSection === id)
                                                .sort((e1, e2) =>
                                                    `${e1.IDParentElement}-${e1.Sequence}` <
                                                    `${e2.IDParentElement}-${e2.Sequence}`
                                                        ? -1
                                                        : 1
                                                )
                                                .map(
                                                    ({
                                                        errors,
                                                        id: elementId,
                                                        IDElementType,
                                                        IDSection: screenId,
                                                        IDElementType: type,
                                                    }) => {
                                                        return errors.map(({ message }, i) => (
                                                            <ListGroupItem
                                                                key={`Error-Element${elementId}-${i}`}
                                                                onMouseEnter={handleMouseEnter}
                                                                onMouseLeave={handleMouseLeave}
                                                                onClick={handleSelectElement}
                                                                data-active={
                                                                    elementId &&
                                                                    elementId === element.id
                                                                }
                                                                data-element-id={elementId}
                                                                data-screen-id={screenId}
                                                            >
                                                                <Icon
                                                                    type={
                                                                        ELEMENT_ICON[IDElementType]
                                                                    }
                                                                    className="mr-2"
                                                                />
                                                                <span>{message}</span>
                                                            </ListGroupItem>
                                                        ));
                                                    }
                                                )}
                                        </ListGroup>
                                    </React.Fragment>
                                );
                            })}
                    </Card>
                </>
            )}
            {!!elementErrors.length && (
                <>
                    <Card className="drag-item-group">
                        {getUnique(
                            elementErrors
                                .filter(({ IDSection }) => IDSection === null)
                                .map((element) => getRootElement(element))
                        )
                            .map(({ id }) => elements.find(({ id: idElement }) => idElement === id))
                            .sort((s1, s2) => (s1.concrete.title < s2.concrete.title ? -1 : 1))
                            .map(({ id: idBar, concrete }) => {
                                return (
                                    <React.Fragment key={`Error-Bar${idBar}`}>
                                        <CardHeader>
                                            <strong>{concrete.title}</strong>
                                        </CardHeader>
                                        <ListGroup>
                                            {elementErrors
                                                .filter(
                                                    (element) =>
                                                        idBar === (getRootElement(element) || {}).id
                                                )
                                                .sort((e1, e2) =>
                                                    `${e1.IDParentElement}-${e1.Sequence}` <
                                                    `${e2.IDParentElement}-${e2.Sequence}`
                                                        ? -1
                                                        : 1
                                                )
                                                .map(({ errors, id: elementId, IDElementType }) => {
                                                    const { id: screenId } =
                                                        screens.find(
                                                            ({ section_bars: screenBars = [] }) =>
                                                                screenBars.some(
                                                                    ({ IDElement }) =>
                                                                        IDElement === idBar
                                                                )
                                                        ) || {};
                                                    return errors.map(({ message }, i) => (
                                                        <ListGroupItem
                                                            key={`Error-Element${elementId}-${i}`}
                                                            onMouseEnter={handleMouseEnter}
                                                            onMouseLeave={handleMouseLeave}
                                                            onClick={handleSelectElement}
                                                            data-active={
                                                                elementId &&
                                                                elementId === element.id
                                                            }
                                                            data-element-id={elementId}
                                                            data-screen-id={screenId}
                                                        >
                                                            <Icon
                                                                type={ELEMENT_ICON[IDElementType]}
                                                                className="mr-2"
                                                            />
                                                            <span>{message}</span>
                                                        </ListGroupItem>
                                                    ));
                                                })}
                                        </ListGroup>
                                    </React.Fragment>
                                );
                            })}
                    </Card>
                </>
            )}
        </Panel>
    );
};
