export const debug = window.location.hostname.startsWith('localhost');
export const URL = {
    AD_SERVER: 'https://orxata.mobincube.com',
    API: 'https://bajoqueta.mobincube.com/v1',
    CLIENT_INFO: 'https://freegeoip.live/json/',
    CLOUD: 'https://cloud.mobincube.com',
    DISABLE_ACCOUNT_FORM: 'https://rafa21.typeform.com/to/pN7YUY',
    DOYO_STORIES: 'https://stories.doyo.tech',
    GRAVATAR: 'https://secure.gravatar.com/avatar/',
    HOST: 'https://app.mobincube.com',
    ITUNES_MOBINCUBE: 'https://itunes.apple.com/us/app/mobincube/id632056416',
    KUSTODIO: 'https://kustod.io',
    KUSTODIO_SHARING: 'https://kustod.io/s',
    MAU_SERVER: 'https://mau.mobincube.com',
    MOBINCUBE_EDITOR_REDIRECT: 'https://v1.mobincube.com/redirect.php',
    MOBINCUBE_EDITOR: 'https://v1.mobincube.com/front',
    MODULE_HOST: 'https://storage.googleapis.com/mobincube_webmodules',
    PASSPORT: 'https://passport.doyo.tech/',
    PUBLIC_WEB: 'https://mobincube.com',
    STORE: 'https://mobincube.mobi',
    UNSPLASH: 'https://api.unsplash.com',
};

export const SECRETS = {
    ANALYTICS: 'UA-169626173-1',
    GRE_CAPTCHA_SITE_KEY: '6LeTkq4ZAAAAADipBJnXKWLlY77GXjJRM5xZ0XmW',
    SENTRY_DSN: 'https://a4ff37ee1afb48bdbea1110e435bfc85@o257077.ingest.sentry.io/5285143',
    SOCIAL_LOGIN_KEYS: {
        facebook: 730576716998067,
        google: '602299964439-fdj23rshlciir58b5r6i4amj5fl392ps.apps.googleusercontent.com',
        twitter: 'x3evVN9L2ZgKI3ra0Jpf5QYs4',
    },
    STRIPE: 'pk_live_OHz2oiZ2XprxAg4MmAijrn2u',
    UNSPLASH_ACCESS_KEY: '27569fa3b4802d05726c32ce46cc56dae5e1861703256555124460a04039d6d9',
    UNSPLASH_APP_ID: '26297',
    UNSPLASH_SECRET: '43006be45766018412d2f3526657c2a2256677f1a78aae1ed2487fa0386feaec',
};
