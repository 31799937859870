import { getUnique } from '../../_helpers';

export const UNSPLASH = {
    INITIAL_STATE: { photo: undefined, photos: [] },

    CHECK: 'UNSPLASH_CHECK',
    PHOTOS: 'UNSPLASH_PHOTOS',
};

export const UnsplashReducer = (draft, action) => {
    const { payload, type } = action;

    switch (type) {
        case UNSPLASH.CHECK:
            draft.photos = draft.photos.map((photo) =>
                payload.photos.some(({ id }) => id === photo.id)
                    ? {
                          ...photo,
                          checked: payload.checked,
                      }
                    : photo
            );
            break;

        case UNSPLASH.PHOTOS:
            draft.photos = payload.reset
                ? payload.photos
                : getUnique([...draft.photos, ...payload.photos]);
            break;
        default:
            return draft;
    }
};
