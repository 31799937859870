import React, { useEffect, useState } from 'react';
import { getRgba } from '../_helpers';
import { useApplications } from '../hooks';

const Background = ({ id }) => {
    const { getShading } = useApplications();
    const [shading, setShading] = useState({ id });
    let { finalColor = '000000', mainColor = 'ffffff', shadingType = 'transparent' } = shading;
    finalColor = getRgba(`#${finalColor}`);
    mainColor = getRgba(`#${mainColor}`);

    useEffect(() => {
        (async () => {
            const shading = await getShading({ id }, { readonly: true });
            setShading(shading || {});
        })();
    }, [id]);

    switch (shadingType) {
        case 'solid':
            return <div className="bg" style={{ background: mainColor }} />;
        case 'gradient_down_up':
            return (
                <div
                    className="bg"
                    style={{
                        background: `linear-gradient(to top, ${getRgba(mainColor)}, ${getRgba(
                            finalColor
                        )})`,
                    }}
                />
            );
        case 'gradient_left_right':
            return (
                <div
                    className="bg"
                    style={{
                        background: `linear-gradient(to right, ${getRgba(mainColor)}, ${getRgba(
                            finalColor
                        )})`,
                    }}
                />
            );
        case 'gradient_right_left':
            return (
                <div
                    className="bg"
                    style={{
                        background: `linear-gradient(to left, ${getRgba(mainColor)}, ${getRgba(
                            finalColor
                        )})`,
                    }}
                />
            );
        case 'gradient_up_down':
            return (
                <div
                    className="bg"
                    style={{
                        background: `linear-gradient(${getRgba(mainColor)}, ${getRgba(
                            finalColor
                        )})`,
                    }}
                />
            );
        default:
            return null;
    }
};

export default Background;
export { Background };
