import React, { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Tooltip } from 'antd';
import { Badge, Card, CardBody, Input } from 'reactstrap';
import { RESOURCE_ICON, RESOURCE_TYPE } from '../context';
import { useElements, useModals, useResources } from '../hooks';
import { FilePreview, Icon } from '.';

const ResourcePreview = (resource) => {
    const { blobUrl, online, type, url } = resource;
    const src = online ? url : blobUrl;

    switch (type) {
        case RESOURCE_TYPE.AUDIO:
            return <audio controls src={src} />;
        case RESOURCE_TYPE.IMAGE:
            return <div className="img" style={{ backgroundImage: `url(${src})` }} />;
        case RESOURCE_TYPE.VIDEO:
            return <video src={src} />;
        default:
            return (
                <FilePreview
                    className="position-absolute"
                    {...resource}
                    name={resource.Filename || resource.name}
                    style={{ height: '140px', width: '200px' }}
                />
            );
    }
};

export const Resource = ({ className, update, ...resource }) => {
    const { t } = useTranslation();
    const { updateElement = () => {} } = useElements();
    const { onClick: handleClick, target } = useModals();
    const { checkResources, getBlobUrl, selectResource, updateResource } = useResources();
    const { blobUrl, id, name, online, type, url } = resource;

    const handleResourceClick = useCallback(() => {
        if (handleClick) {
            handleClick(resource);
            return;
        }
        if (!target) {
            checkResources(resource, { checked: !resource.checked });
            return;
        }

        selectResource(resource);

        const attributes = {
            [RESOURCE_TYPE.IMAGE]: { concrete: { IDImage: id } },
            [RESOURCE_TYPE.TEXT]: { concrete: { IDText: id } },
            [RESOURCE_TYPE.VIDEO]: { concrete: { idVideo: id } },
        };
        updateElement(attributes[type]);

        document.querySelector('.modal .close').click();
    }, [resource, target, handleClick]);

    const handleBlur = (event) => {
        const { currentTarget } = event;
        const { value: name } = currentTarget;
        updateResource({ name }, resource);
    };

    const handleKeyDown = (event) => {
        const { currentTarget, key } = event;
        if (key === 'Enter') {
            currentTarget.blur();
            return false;
        }
        return true;
    };

    useEffect(() => {
        if (type !== RESOURCE_TYPE.FOLDER && !online && !blobUrl) {
            getBlobUrl(resource);
        }
    }, [blobUrl]);

    return (
        <Card
            className={`resource shadow-sm${className ? ` ${className}` : ''}`}
            data-type={resource.type}
            onClick={handleResourceClick}
        >
            <div className="card-img-top icon">
                {(((online && url) || blobUrl) && <ResourcePreview {...resource} />) || (
                    <Icon type={RESOURCE_ICON[type]} />
                )}
            </div>
            <CardBody>
                <Tooltip mouseEnterDelay={1} placement="bottom" title={name}>
                    <Input
                        defaultValue={name}
                        onBlur={handleBlur}
                        onKeyDown={handleKeyDown}
                        onClick={(e) => e.stopPropagation()}
                    />
                </Tooltip>
                <Badge color={online ? 'primary' : 'secondary'}>
                    {t(`resources.${online ? 'online' : 'offline'}`)}
                </Badge>
            </CardBody>
        </Card>
    );
};
