import React from 'react';
import { ElementWrapper } from '.';

export const Separator = ({ element }) => {
    const { concrete = {} } = element;
    const { lineStyle = 'solid' } = concrete;
    return (
        <ElementWrapper element={element}>
            <div className="element-content">
                <hr data-line-style={lineStyle} />
            </div>
        </ElementWrapper>
    );
};
