import React, { createContext, useCallback, useEffect, useState } from 'react';
import { useImmerReducer } from 'use-immer';
import { AdvertisementReducer, ADVERTISEMENT } from './reducers';
import { URL } from '../_config';
import { useApplications, useViewer } from '../hooks';
import {
    banner,
    interstitial,
    native,
    onexit,
    onreturn,
    splash,
    video,
    videopreroll,
} from '../assets/img/adFormats';

export const PROVIDER_ID = {
    ADCOLONY: 1,
    ADMOBNATIVE: 18,
    AMAZON: 25,
    FACEBOOK: 30,
    STARTAPP: 21,
    TAPPX: 26,
    UNITYADS: 24,
};

export const PROVIDER_TYPE = {
    ADCOLONY: 'adcolony',
    [PROVIDER_ID.ADCOLONY]: 'adcolony',
    ADMOBNATIVE: 'admobnative',
    [PROVIDER_ID.ADMOBNATIVE]: 'admobnative',
    AMAZON: 'amazon',
    [PROVIDER_ID.AMAZON]: 'amazon',
    FACEBOOK: 'facebook',
    [PROVIDER_ID.FACEBOOK]: 'facebook',
    STARTAPP: 'startapp',
    [PROVIDER_ID.STARTAPP]: 'startapp',
    TAPPX: 'tappxnative',
    [PROVIDER_ID.TAPPX]: 'tappxnative',
    UNITYADS: 'unityads',
    [PROVIDER_ID.UNITYADS]: 'unityads',
};

let FORMAT_ID = {
    APPID: 10,
    BANNER: 0,
    INTERSTITIAL: 1,
    ONEXIT: 5,
    ONRETURN: 8,
    NATIVE: 6,
    SPLASH: 7,
    VIDEO: 2,
    VIDEOPREROLL: 9,
};

export const FORMAT_TYPE = {
    APPID: 'appid',
    [FORMAT_ID.APPID]: 'appid',
    BANNER: 'banner',
    [FORMAT_ID.BANNER]: 'banner',
    INTERSTITIAL: 'interstitial',
    [FORMAT_ID.INTERSTITIAL]: 'interstitial',
    ONEXIT: 'onexit',
    [FORMAT_ID.ONEXIT]: 'onexit',
    ONRETURN: 'onreturn',
    [FORMAT_ID.ONRETURN]: 'onreturn',
    NATIVE: 'native',
    [FORMAT_ID.NATIVE]: 'native',
    SPLASH: 'splash',
    [FORMAT_ID.SPLASH]: 'splash',
    VIDEO: 'video',
    [FORMAT_ID.VIDEO]: 'video',
    VIDEOPREROLL: 'videopreroll',
    [FORMAT_ID.VIDEOPREROLL]: 'videopreroll',
};

FORMAT_ID = {
    ...FORMAT_ID,
    [FORMAT_TYPE.APPID]: 10,
    [FORMAT_TYPE.BANNER]: 0,
    [FORMAT_TYPE.INTERSTITIAL]: 1,
    [FORMAT_TYPE.ONEXIT]: 5,
    [FORMAT_TYPE.ONRETURN]: 8,
    [FORMAT_TYPE.NATIVE]: 6,
    [FORMAT_TYPE.SPLASH]: 7,
    [FORMAT_TYPE.VIDEO]: 2,
    [FORMAT_TYPE.VIDEOPREROLL]: 9,
};
export { FORMAT_ID };

export const FORMAT_IMAGE = {
    BANNER: banner,
    [FORMAT_ID.BANNER]: banner,
    INTERSTITIAL: interstitial,
    [FORMAT_ID.INTERSTITIAL]: interstitial,
    ONEXIT: onexit,
    [FORMAT_ID.ONEXIT]: onexit,
    ONRETURN: onreturn,
    [FORMAT_ID.ONRETURN]: onreturn,
    NATIVE: native,
    [FORMAT_ID.NATIVE]: native,
    SPLASH: splash,
    [FORMAT_ID.SPLASH]: splash,
    VIDEO: video,
    [FORMAT_ID.VIDEO]: video,
    VIDEOPREROLL: videopreroll,
    [FORMAT_ID.VIDEOPREROLL]: videopreroll,
};

export const PLATFORM_ID = {
    ANDROID: 0,
    IOS: 1,
};

export const PLATFORM_TYPE = {
    ANDROID: 'android',
    [PLATFORM_ID.ANDROID]: 'android',
    IOS: 'ios',
    [PLATFORM_ID.IOS]: 'ios',
};

export const PROVIDER_DATA = {
    [PROVIDER_ID.ADCOLONY]: [
        {
            type: FORMAT_ID.INTERSTITIAL,
            platforms: [PLATFORM_ID.ANDROID, PLATFORM_ID.IOS],
        },
        {
            type: FORMAT_ID.VIDEO,
            platforms: [PLATFORM_ID.ANDROID, PLATFORM_ID.IOS],
        },
        {
            type: FORMAT_ID.VIDEOPREROLL,
            platforms: [PLATFORM_ID.ANDROID, PLATFORM_ID.IOS],
        },
    ],
    [PROVIDER_ID.ADMOBNATIVE]: [
        {
            type: FORMAT_ID.APPID,
            platforms: [PLATFORM_ID.ANDROID, PLATFORM_ID.IOS],
        },
        {
            type: FORMAT_ID.BANNER,
            platforms: [PLATFORM_ID.ANDROID, PLATFORM_ID.IOS],
        },
        {
            type: FORMAT_ID.INTERSTITIAL,
            platforms: [PLATFORM_ID.ANDROID, PLATFORM_ID.IOS],
        },
    ],
    [PROVIDER_ID.AMAZON]: [
        {
            type: FORMAT_ID.BANNER,
            platforms: [PLATFORM_ID.ANDROID],
        },
        {
            type: FORMAT_ID.INTERSTITIAL,
            platforms: [PLATFORM_ID.ANDROID],
        },
    ],
    [PROVIDER_ID.FACEBOOK]: [
        {
            type: FORMAT_ID.BANNER,
            platforms: [PLATFORM_ID.ANDROID, PLATFORM_ID.IOS],
        },
        {
            type: FORMAT_ID.INTERSTITIAL,
            platforms: [PLATFORM_ID.ANDROID, PLATFORM_ID.IOS],
        },
    ],
    [PROVIDER_ID.STARTAPP]: [
        {
            type: FORMAT_ID.BANNER,
            platforms: [PLATFORM_ID.ANDROID, PLATFORM_ID.IOS],
        },
        {
            type: FORMAT_ID.INTERSTITIAL,
            platforms: [PLATFORM_ID.ANDROID, PLATFORM_ID.IOS],
        },
        {
            type: FORMAT_ID.ONEXIT,
            platforms: [PLATFORM_ID.ANDROID, PLATFORM_ID.IOS],
        },
        {
            type: FORMAT_ID.ONRETURN,
            platforms: [PLATFORM_ID.ANDROID, PLATFORM_ID.IOS],
        },
        {
            type: FORMAT_ID.NATIVE,
            platforms: [PLATFORM_ID.ANDROID, PLATFORM_ID.IOS],
        },
        {
            type: FORMAT_ID.SPLASH,
            platforms: [PLATFORM_ID.ANDROID, PLATFORM_ID.IOS],
        },
        {
            type: FORMAT_ID.VIDEO,
            platforms: [PLATFORM_ID.ANDROID, PLATFORM_ID.IOS],
        },
        {
            type: FORMAT_ID.VIDEOPREROLL,
            platforms: [PLATFORM_ID.ANDROID, PLATFORM_ID.IOS],
        },
    ],
    [PROVIDER_ID.TAPPX]: [
        {
            type: FORMAT_ID.BANNER,
            platforms: [PLATFORM_ID.ANDROID, PLATFORM_ID.IOS],
        },
        {
            type: FORMAT_ID.INTERSTITIAL,
            platforms: [PLATFORM_ID.ANDROID, PLATFORM_ID.IOS],
        },
    ],
    [PROVIDER_ID.UNITYADS]: [
        {
            type: FORMAT_ID.INTERSTITIAL,
            platforms: [PLATFORM_ID.ANDROID],
        },
        {
            type: FORMAT_ID.VIDEO,
            platforms: [PLATFORM_ID.ANDROID],
        },
        {
            type: FORMAT_ID.VIDEOPREROLL,
            platforms: [PLATFORM_ID.ANDROID],
        },
    ],
};

const UPDATE_FUNCTIONS = {
    interval: 'setInterstitialFrequency',
    itemsBetweenNativeAds: 'setItemsBetweenNativeAds',
    videoInterval: 'setVideoInterval',
};

export const AdvertisementContext = createContext({});

export const AdvertisementProvider = ({ children }) => {
    const { application = {} } = useApplications();
    const { adServerApp } = application;
    const { viewer = {} } = useViewer();
    const { adServerUser } = viewer;
    const [loading, setLoading] = useState(false);
    const [state, dispatch] = useImmerReducer(AdvertisementReducer, ADVERTISEMENT.INITIAL_STATE);
    const { codes = [] } = state;

    const getCodes = useCallback(
        async ({ formats, provider }) => {
            setLoading(true);

            const codes = formats
                .reduce(
                    (result, { type: format, platforms }) => [
                        ...result,
                        ...platforms.map((platform) => ({ format, platform })),
                    ],
                    []
                )
                .map(({ format, platform }) => ({ format, platform }));
            return Promise.all(
                codes.map(async ({ format, platform }) =>
                    fetch(
                        `${URL.AD_SERVER}/codes/${adServerUser}/${adServerApp}/${PROVIDER_TYPE[provider]}/${FORMAT_TYPE[format]}/${PLATFORM_TYPE[platform]}`
                    )
                )
            )
                .then((responses) => Promise.all(responses.map((response) => response.json())))
                .then((responses) => {
                    setLoading(false);
                    const codes = responses.map(({ data }) => data);
                    dispatch({
                        type: ADVERTISEMENT.CODES,
                        payload: { codes },
                    });
                    return codes;
                })
                .catch((error) => {
                    setLoading(false);
                    console.error(error);
                });
        },
        [adServerApp, adServerUser]
    );

    const getConfiguration = () => {
        setLoading(true);
        return Promise.all([
            fetch(`${URL.AD_SERVER}/apps/getInterstitialFrequency/${adServerApp}`),
            fetch(`${URL.AD_SERVER}/apps/getItemsBetweenNativeAds/${adServerApp}`),
            fetch(`${URL.AD_SERVER}/apps/getVideoInterval/${adServerApp}`),
        ])
            .then((responses) => Promise.all(responses.map((response) => response.json())))
            .then((configuration) => {
                setLoading(false);
                configuration = configuration.reduce(
                    (configuration, config) => ({ ...configuration, ...config }),
                    {}
                );
                dispatch({
                    type: ADVERTISEMENT.CONFIGURATION,
                    payload: { configuration },
                });
                return configuration;
            })
            .catch((error) => {
                setLoading(false);
                console.error(error);
            });
    };

    const getFormats = async ({ provider }) => {
        const formats = PROVIDER_DATA[provider];
        setLoading(true);

        return Promise.all(
            formats.map(({ type: format }) =>
                fetch(
                    `${URL.AD_SERVER}/format/${adServerApp}/${FORMAT_TYPE[format]}/${PROVIDER_TYPE[provider]}`
                )
            )
        )
            .then((responses) => Promise.all(responses.map((response) => response.json())))
            .then((responses) => {
                const formats = responses.map(({ data }) => data);
                setLoading(false);
                dispatch({
                    type: ADVERTISEMENT.FORMATS,
                    payload: { formats },
                });
                return formats;
            })
            .then((_formats) => {
                getCodes({ formats, provider });
                return _formats;
            })
            .catch((error) => {
                setLoading(false);
                console.error(error);
            });
    };

    const getProvider = (provider) => {
        setLoading(true);
        return fetch(`${URL.AD_SERVER}/monetize/providers/${provider}`)
            .then(async (response) => {
                const data = (await response.json()) || {};
                return response.ok ? data : Promise.reject(data.type);
            })
            .then((provider) => {
                setLoading(false);
                dispatch({
                    type: ADVERTISEMENT.PROVIDER,
                    payload: { provider },
                });
                return application;
            })
            .catch((error) => {
                setLoading(false);
                console.error(error);
            });
    };

    const setCodesDisabled = useCallback(
        ({ disabled, format, provider }) => {
            return Promise.all(
                codes
                    .filter(({ format: f, provider: p }) => f === format && p === provider)
                    .map(({ adCode, format, platform, provider }) =>
                        fetch(
                            `${URL.AD_SERVER}/codes/${adServerUser}/${adServerApp}/${PROVIDER_TYPE[provider]}/${FORMAT_TYPE[format]}/${PLATFORM_TYPE[platform]}`,
                            {
                                method: 'PUT',
                                'content-type': 'application/x-www-form-urlencoded',
                                body: encodeURI(`adCode=${adCode}&disabled=${disabled ? 1 : 0}`),
                            }
                        )
                    )
            );
        },
        [codes]
    );

    const setFormatDisabled = ({ disabled, format, provider }) => {
        setLoading(true);
        return fetch(
            `${URL.AD_SERVER}/format/${adServerApp}/${FORMAT_TYPE[format]}/${PROVIDER_TYPE[provider]}`,
            {
                method: 'PUT',
                'content-type': 'application/x-www-form-urlencoded',
                body: encodeURI(`disabled=${disabled ? 1 : 0}`),
            }
        )
            .then((response) => response.json())
            .then(({ data }) => {
                setLoading(false);
                dispatch({
                    type: ADVERTISEMENT.FORMATS,
                    payload: { formats: [data] },
                });
                return data;
            })
            .then((data) => {
                setCodesDisabled({ disabled, format, provider });
                return data;
            })
            .catch((error) => {
                setLoading(false);
                console.error(error);
            });
    };

    const updateCodes = useCallback(
        ({ provider, codes }) => {
            return Promise.all(
                codes.map(({ code: adCode, disabled, format, platform }) => {
                    const isNew = !state.codes.some(
                        ({ format: f, platform: pl, provider: pr }) =>
                            f === format && pl === platform && pr === provider
                    );
                    return fetch(
                        `${URL.AD_SERVER}/codes/${adServerUser}/${adServerApp}/${PROVIDER_TYPE[provider]}/${FORMAT_TYPE[format]}/${PLATFORM_TYPE[platform]}`,
                        {
                            method: isNew ? 'POST' : 'PUT',
                            'content-type': 'application/x-www-form-urlencoded',
                            body: encodeURI(`adCode=${adCode}&disabled=${disabled ? 1 : 0}`),
                        }
                    );
                })
            )
                .then((responses) => Promise.all(responses.map((response) => response.json())))
                .then((responses) => {
                    setLoading(false);
                    const codes = responses.map(({ data }) => data);
                    dispatch({
                        type: ADVERTISEMENT.CODES,
                        payload: { codes },
                    });
                    return codes;
                })
                .catch((error) => {
                    setLoading(false);
                    console.error(error);
                });
        },
        [codes]
    );

    const updateConfiguration = (configuration) => {
        const key = Object.keys(configuration)[0];
        const value = Object.values(configuration)[0];
        const updateFunction = UPDATE_FUNCTIONS[key];
        setLoading(true);
        return fetch(`${URL.AD_SERVER}/apps/${updateFunction}/${adServerApp}`, {
            method: 'POST',
            'content-type': 'application/x-www-form-urlencoded',
            body: encodeURI(`${key}=${value}`),
        })
            .then(() => {
                setLoading(false);
                dispatch({
                    type: ADVERTISEMENT.CONFIGURATION,
                    payload: { configuration },
                });
                return configuration;
            })
            .catch((error) => {
                setLoading(false);
                console.error(error);
            });
    };

    useEffect(() => {
        dispatch({
            type: ADVERTISEMENT.RESET,
        });
    }, [application.id]);

    if (process.env.NODE_ENV === 'development') {
        console.log('ADVERTISEMENT >>>', state);
    }

    return (
        <AdvertisementContext.Provider
            value={{
                ...state,
                loading,
                getCodes,
                getConfiguration,
                getFormats,
                getProvider,
                setFormatDisabled,
                updateCodes,
                updateConfiguration,
            }}
        >
            {children}
        </AdvertisementContext.Provider>
    );
};

export const AdvertisementConsumer = AdvertisementContext.Consumer;
export default AdvertisementContext;
