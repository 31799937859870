import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, ModalBody } from 'reactstrap';
import { MODALS } from '../_config';
import { Modal } from '../components';
import { useModals } from '../hooks';

export const Upgrade = ({ isOpen: isOpenProp }) => {
    const { t } = useTranslation();
    const [isOpen, setIsOpen] = useState(isOpenProp);
    const { back, modal, openModal, setModal } = useModals();

    useEffect(() => {
        setIsOpen(modal === MODALS.UPGRADE);
    }, [modal]);

    return (
        <Modal
            className="upgrade"
            isOpen={isOpen}
            title={t('dialogs.upgrade_account')}
            onClosed={() => {
                if (back) {
                    openModal(back);
                    return;
                }
                setModal(null);
            }}
        >
            <ModalBody>
                <p>{t('dialogs.you_need_to_upgrade')}</p>
                <div className="text-right">
                    <Button onClick={() => openModal(MODALS.MY_ACCOUNT, { key: 'subscription' })}>
                        {t('dialogs.upgrade_account')}
                    </Button>
                </div>
            </ModalBody>
        </Modal>
    );
};
