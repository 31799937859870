import React, { createContext, useCallback, useEffect, useState } from 'react';
import { useImmerReducer } from 'use-immer';
import { DRAG_ITEMS, DragItemsReducer } from './reducers';

export const DRAG_ITEM_TYPE = {
    ELEMENT: 'DRAG_ITEM_ELEMENT',
    SCREEN: 'DRAG_ITEM_SCREEN',
};

export const DragItemsContext = createContext(DRAG_ITEMS.INITIAL_STATE);

export const DragItemsProvider = ({ children }) => {
    const [loading, setLoading] = useState(false);
    const [state, dispatch] = useImmerReducer(DragItemsReducer, DRAG_ITEMS.INITIAL_STATE);
    const { dragItems = [] } = state;

    const getDragItem = useCallback(
        (_finder = () => {}) => {
            const finder = typeof _finder === 'number' ? ({ id }) => id === _finder : _finder;
            return dragItems.reduce((items, group) => [...items, ...group.items], []).find(finder);
        },
        [dragItems]
    );

    const getDragItemAttributes = useCallback(
        (idItem) => {
            const { attributes = {} } = getDragItem(idItem) || {};
            return attributes;
        },
        [dragItems]
    );

    const getDragItems = useCallback(() => {
        if (!dragItems.length) {
            setLoading(true);
            Promise.resolve(import('../panels/dragItemsData.js')).then(({ dragItemsData }) => {
                setLoading(false);
                dispatch({ type: DRAG_ITEMS.LIST, payload: { dragItemsData } });
            });
        }
    }, [dragItems]);

    useEffect(() => {
        getDragItems();
    }, []);

    if (process.env.NODE_ENV === 'development') {
        console.log('DRAG ITEMS >>>', state);
    }

    return (
        <DragItemsContext.Provider
            value={{
                ...state,
                getDragItem,
                getDragItemAttributes,
                loading,
            }}
        >
            {children}
        </DragItemsContext.Provider>
    );
};

export const DragItemsConsumer = DragItemsContext.Consumer;
export default DragItemsContext;
