import React, { useEffect, useRef, useState } from 'react';
import { Tooltip } from 'antd';
import { Button, DropdownMenu, DropdownToggle, UncontrolledDropdown } from 'reactstrap';
import { Icon } from '.';

export const MenuActions = ({ children: _children = {} }) => {
    const { props = {} } = _children;
    let { children = [] } = props;
    const [visibleActions, setVisibleActions] = useState(0);
    const actions = useRef();

    useEffect(() => {
        if (!children.length) {
            return;
        }
        let mounted = true;
        const { width } = actions.current.getBoundingClientRect();
        mounted && setVisibleActions(parseInt((width - 32) / 32, 10));
        return () => {
            mounted = false;
        };
    }, [actions]);

    children = Array.isArray(children) ? children : [children];
    if (!children.length) {
        return null;
    }

    let visibleChildren = children;
    let menuChildren = [];
    if (children.length > 1) {
        visibleChildren = children.filter((child, i) => i < visibleActions);
        menuChildren = children.filter((child, i) => i >= visibleActions);
    }

    return (
        <div className="menu-actions" ref={actions}>
            {(visibleChildren.length &&
                visibleChildren.map((child) => {
                    const { props, type } = child;

                    if (type.name !== 'DropdownItem') {
                        return child;
                    }

                    const { children, toggle, ...rest } = props;
                    const { props: props1 } = children[1];
                    const { children: text } = props1;
                    const id = `MenuAction${text.replace(/ /g, '')}`;

                    return (
                        <Tooltip key={id} placement="bottom" title={text}>
                            <Button id={id} {...rest}>
                                {children}
                            </Button>
                        </Tooltip>
                    );
                })) ||
                null}
            {(menuChildren.length && (
                <UncontrolledDropdown>
                    <DropdownToggle>
                        <Icon type="dots-vertical" />
                    </DropdownToggle>
                    <DropdownMenu right className="shadow">
                        {menuChildren.map((child) => child)}
                    </DropdownMenu>
                </UncontrolledDropdown>
            )) ||
                null}
        </div>
    );
};

export default MenuActions;
