import React, { createContext, useState } from 'react';

export const ModalsContext = createContext({});

export const ModalsProvider = (props) => {
    const [modal, setModal] = useState(null);
    const [params, setParams] = useState({});

    const openModal = (modal = null, params = {}) => {
        setModal(modal);
        setParams(params);
    };

    const closeModal = () => {
        setModal(null);
    };

    if (process.env.NODE_ENV === 'development') {
        console.log('MODALS >>>', modal, params);
    }

    return (
        <ModalsContext.Provider value={{ ...params, closeModal, modal, openModal, setModal }}>
            {props.children}
        </ModalsContext.Provider>
    );
};

export const ModalsConsumer = ModalsContext.Consumer;
export default ModalsContext;
