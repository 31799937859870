import { getUnique } from '../../_helpers';

export const ERROR = {
    INITIAL_STATE: {
        applications: [],
        elements: [],
        screens: [],
    },

    APPLICATION: 'ERROR_APPLICATION',
    ELEMENT: 'ERROR_ELEMENT',
    RESET: 'ERROR_RESET',
    SCREEN: 'ERROR_SCREEN',
};

export const ErrorsReducer = (draft, action) => {
    const { payload, type } = action;
    switch (type) {
        case ERROR.APPLICATION:
            draft.applications = getUnique(
                [{ ...payload.application, errors: payload.errors }, ...draft.applications].filter(
                    ({ errors = [] }) => errors.length
                )
            );
            break;
        case ERROR.ELEMENT:
            draft.elements = getUnique(
                [{ ...payload.element, errors: payload.errors }, ...draft.elements].filter(
                    ({ errors = [] }) => errors.length
                )
            );
            break;
        case ERROR.RESET:
            if (payload.all) {
                return ERROR.INITIAL_STATE;
            }
            if (payload.application) {
                draft.applications = draft.applications.filter(
                    (application) => application.id !== payload.application.id
                );
                draft.screens = ERROR.INITIAL_STATE.screens;
                draft.elements = ERROR.INITIAL_STATE.elements;
            }
            if (payload.screen) {
                draft.screens = draft.screens.filter(({ id }) => id !== payload.screen.id);
                draft.elements = draft.elements.filter(
                    ({ IDSection: screenId }) => screenId !== payload.screen.id
                );
            }
            if (payload.element) {
                draft.elements = draft.elements.filter(({ id }) => id !== payload.element.id);
            }
            break;
        case ERROR.SCREEN:
            draft.screens = getUnique(
                [{ ...payload.screen, errors: payload.errors }, ...draft.screens].filter(
                    ({ errors, id }) =>
                        errors.length || draft.elements.some(({ IDSection }) => IDSection === id)
                )
            );
            break;
        default:
            return draft;
    }
};
