import React, { useCallback, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import debounce from 'just-debounce-it';
import { Popconfirm, Tooltip } from 'antd';
import { Button, DropdownItem, ListGroup, ListGroupItem } from 'reactstrap';
import { SCREEN_ICON, SCREEN_TYPE } from '../context';
import { Checkbox, Icon, Panel } from '../components';
import {
    useApplications,
    useDragItems,
    useElements,
    useNearScreen,
    useResources,
    useScreens,
    useViewer,
} from '../hooks';

export const ScreensPanel = () => {
    const { t } = useTranslation();
    const deletePopconfirm = useRef();
    const externalRef = useRef();
    const {
        checkScreen,
        createScreen,
        deleteScreens,
        duplicateScreens,
        getScreens,
        loading,
        page,
        setPage,
        screen = {},
        screens = [],
        selectScreen,
    } = useScreens();
    const { application = {}, updateApplication } = useApplications();
    const { getDragItem } = useDragItems();
    const { unselectElement = () => {} } = useElements();
    const { resources = [] } = useResources();
    const { getPanelWidth, isVisibleScreensPanel } = useViewer();
    const { isNearScreen } = useNearScreen({
        externalRef: loading ? null : externalRef,
        once: false,
    });
    const checkedScreens = screens.filter(({ checked }) => checked);

    useEffect(() => {
        getScreens && getScreens();
    }, [page]);

    const debounceHandleNextPage = useCallback(
        debounce(() => setPage && setPage((prevPage) => prevPage + 1), 200),
        [setPage]
    );

    useEffect(() => {
        if (isNearScreen) debounceHandleNextPage();
    }, [debounceHandleNextPage, isNearScreen]);

    return (
        <Panel
            className={`screens-panel${!isVisibleScreensPanel ? ' d-none' : ''}`}
            disabled={!application.id}
            panel="screens"
            title={t('screens.title')}
            actions={
                <>
                    <DropdownItem disabled={!application.id} onClick={() => createScreen()}>
                        <Icon type="plus" />
                        <span>{t('screens.add_screen')}</span>
                    </DropdownItem>
                    <DropdownItem
                        disabled={
                            checkedScreens.length !== 1 ||
                            checkedScreens[0].id === application.IDMainSection
                        }
                        onClick={() => updateApplication({ IDMainSection: checkedScreens[0].id })}
                    >
                        <Icon type="numeric-1-box" />
                        <span>{t('screens.set_main_screen')}</span>
                    </DropdownItem>
                    <DropdownItem
                        disabled={!checkedScreens.length}
                        onClick={() => duplicateScreens(checkedScreens)}
                    >
                        <Icon type="content-copy" />
                        <span>
                            {t('screens.duplicate_screen', { count: checkedScreens.length })}
                        </span>
                    </DropdownItem>
                    <DropdownItem
                        disabled={!checkedScreens.length}
                        onClick={() => deletePopconfirm.current.click()}
                    >
                        <Icon type="delete" />
                        <span>{t('screens.delete_screen', { count: checkedScreens.length })}</span>
                    </DropdownItem>
                </>
            }
            width={getPanelWidth('screens')}
        >
            <Popconfirm
                title={t('screens.delete_confirm', {
                    count: checkedScreens.length,
                    name: checkedScreens[0] && checkedScreens[0].Nombre,
                })}
                onConfirm={() => {
                    deleteScreens(checkedScreens);
                }}
                onCancel={() => {}}
                okText={t('common.yes')}
                cancelText={t('common.no')}
            >
                <Button
                    className="position-absolute position-absolute-top-right-0 mt-3 mr-2"
                    innerRef={deletePopconfirm}
                />
            </Popconfirm>
            <ListGroup>
                {screens
                    .sort((screen1, screen2) =>
                        `${screen1.Nombre}${screen1.id}` < `${screen2.Nombre}${screen2.id}` ? -1 : 1
                    )
                    .map((_screen) => {
                        const { IDTipoSeccion: type } = _screen;
                        let icon = SCREEN_ICON[type];

                        if (type === SCREEN_TYPE.WEB) {
                            let { concrete = {} } = _screen;
                            concrete = concrete || {};
                            const { idZip } = concrete;
                            const zipRes = resources.find(({ id }) => id === idZip) || {};
                            const { idModule } = zipRes;
                            const { icon: dragItemIcon } =
                                getDragItem(
                                    ({ attributes: { concrete: { idModule: m } = {} } = {} }) =>
                                        idModule && m === idModule
                                ) || {};
                            icon = dragItemIcon || icon;
                        }
                        return (
                            <ListGroupItem
                                key={`ScreenPanel-Screen${_screen.id}`}
                                action
                                active={_screen.id === screen.id}
                                onClick={() => {
                                    selectScreen(_screen);
                                    unselectElement();
                                }}
                            >
                                <Checkbox
                                    checked={!!_screen.checked}
                                    onChange={() =>
                                        checkScreen(_screen, { checked: !_screen.checked })
                                    }
                                />
                                <Icon className="icon" type={icon} />
                                <Tooltip mouseEnterDelay={1} title={_screen.Nombre}>
                                    <span>{_screen.Nombre}</span>
                                </Tooltip>
                                {_screen.id === application.IDMainSection && (
                                    <Icon type="numeric-1-box" className="text-primary" />
                                )}
                            </ListGroupItem>
                        );
                    })}
                <div className="w-100" ref={externalRef} />
            </ListGroup>
        </Panel>
    );
};
