import React from 'react';
import { MODALS } from '../_config';
import { useModals } from '../hooks';
import { Advertisement } from './Advertisement';
import { Applications } from './Applications';
import { AppInfo } from './AppInfo';
import { Bars } from './Bars';
import { MyAccount } from './MyAccount';
import { Publish } from './Publish';
import { Resources } from './Resources';
import { Tools } from './Tools';
import { Upgrade } from './Upgrade';
import { Welcome } from './Welcome';

export const Modals = () => {
    const { modal } = useModals();
    switch (modal) {
        case MODALS.ADVERTISEMENT:
            return <Advertisement />;
        case MODALS.APPLICATIONS:
            return <Applications />;
        case MODALS.APP_INFO:
            return <AppInfo />;
        case MODALS.BARS:
            return <Bars />;
        case MODALS.MY_ACCOUNT:
            return <MyAccount />;
        case MODALS.PUBLISH:
            return <Publish />;
        case MODALS.RESOURCES:
            return <Resources />;
        case MODALS.TOOLS:
            return <Tools />;
        case MODALS.UPGRADE:
            return <Upgrade />;
        case MODALS.WELCOME:
            return <Welcome />;
        default:
            return null;
    }
};

export * from './Advertisement';
export * from './Applications';
export * from './AppInfo';
export * from './Bars';
export * from './MyAccount';
export * from './Publish';
export * from './Resources';
export * from './Tools';
export * from './Upgrade';
export * from './Welcome';
