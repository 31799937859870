import React, { createContext, useCallback, useEffect } from 'react';
import { useImmerReducer } from 'use-immer';
import { ErrorsReducer, ERROR } from './reducers';
import { SCREEN_TYPE } from '../context';
import { useApplications, useElements, useScreens } from '../hooks';
import schema from '../schemas';
import Ajv from 'ajv';
import AjvErrors from 'ajv-errors';
import AjvKeywords from 'ajv-keywords';
const ajv = new Ajv({
    $data: true,
    allErrors: true,
    jsonPointers: true,
    uniqueItems: true,
});
AjvErrors(ajv);
AjvKeywords(ajv, 'select');

export const ErrorsContext = createContext({});

export const ErrorsProvider = ({ children }) => {
    const { application = {} } = useApplications();
    const { elements = [] } = useElements();
    const { screens = [] } = useScreens();
    const [state, dispatch] = useImmerReducer(ErrorsReducer, ERROR.INITIAL_STATE);

    const validateElement = useCallback(
        (element) =>
            new Promise((resolve) => {
                dispatch({
                    type: ERROR.RESET,
                    payload: { element },
                });
                const validation = ajv.compile(schema.element);
                const isValid = validation(element);
                const errors = validation.errors || [];
                dispatch({
                    type: ERROR.ELEMENT,
                    payload: { element, errors },
                });
                resolve({ errors, isValid });
            }),
        [elements]
    );

    const validateScreen = useCallback(
        (screen) =>
            new Promise((resolve) => {
                dispatch({
                    type: ERROR.RESET,
                    payload: { screen },
                });
                const { section_bars: screenBars = [] } = screen;
                const getElementsOnScreen = (parents = []) => {
                    return [
                        ...parents,
                        ...(parents.length
                            ? getElementsOnScreen(
                                  elements.filter(({ IDParentElement }) =>
                                      parents.some(({ id }) => IDParentElement === id)
                                  )
                              )
                            : []),
                    ];
                };
                let elementsOnScreen = getElementsOnScreen(
                    elements.filter(({ id }) =>
                        screenBars.some(({ IDElement }) => IDElement === id)
                    )
                );

                if (screen.IDTipoSeccion === SCREEN_TYPE.INFO) {
                    elementsOnScreen = [
                        ...elementsOnScreen,
                        ...elements.filter(({ IDSection }) => IDSection === screen.id),
                    ];
                }
                Promise.all(elementsOnScreen.map((element) => validateElement(element))).then(
                    (elementErrors) => {
                        elementErrors = elementErrors.filter(({ errors = [] }) => errors.length);
                        const validation = ajv.compile(schema.screen);
                        const isValid = validation(screen) && !elementErrors.length;
                        let errors = validation.errors || [];

                        dispatch({
                            type: ERROR.SCREEN,
                            payload: { errors, screen },
                        });
                        resolve({ errors, isValid });
                    }
                );
            }),
        [elements]
    );

    const validateApplication = useCallback(
        (application) =>
            new Promise((resolve) => {
                dispatch({
                    type: ERROR.RESET,
                    payload: { application },
                });
                Promise.all(
                    screens
                        .filter(({ IDGuia }) => IDGuia === application.id)
                        .map((screen) => validateScreen(screen))
                ).then((screenErrors) => {
                    screenErrors = screenErrors.filter(({ errors = [] }) => errors.length);
                    const validation = ajv.compile(schema.application);
                    const isValid = validation(application) && !screenErrors.length;
                    let errors = validation.errors || [];

                    dispatch({
                        type: ERROR.APPLICATION,
                        payload: { errors, application },
                    });
                    resolve({ errors, isValid });
                });
            }),
        [screens]
    );

    const validate = (what) => {
        const { application, element, screen } = what;
        const key = Object.keys(what).find((key) => what[key]);
        switch (key) {
            case 'application':
                return validateApplication(application);
            case 'element':
                return validateElement(element);
            case 'screen':
                return validateScreen(screen);
            default:
                return null;
        }
    };

    useEffect(() => {
        dispatch({ type: ERROR.RESET, payload: { all: true } });
    }, [application.id]);

    if (process.env.NODE_ENV === 'development') {
        console.log('ERRORS >>>', state);
    }

    return (
        <ErrorsContext.Provider value={{ ...state, validate }}>{children}</ErrorsContext.Provider>
    );
};

export const ErrorsConsumer = ErrorsContext.Consumer;
export default ErrorsContext;
