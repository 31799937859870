import React, { useCallback, useEffect, useState } from 'react';
import { Sortable } from '../../../components';
import { DRAG_ITEM_TYPE, ELEMENT_TYPE } from '../../../context';
import { useElements } from '../../../hooks';
import { BODY_TYPE, Element, ElementWrapper } from '.';

export const Bar = ({ element = {}, screenId }) => {
    const [loaded, setLoaded] = useState(false);
    const { elements = [], getElements } = useElements();
    const { concrete, id } = element;
    const { name, title } = concrete;
    const childElements = elements
        .filter(({ IDParentElement }) => IDParentElement === id)
        .sort((el1, el2) => (el1.Sequence < el2.Sequence ? -1 : 1));
    const data = {
        'data-loaded': loaded,
        'data-screen-bar-id': `${screenId},${id}`,
        'data-title': name !== title ? title : '',
    };
    const sortableData = {
        'data-accept': DRAG_ITEM_TYPE.ELEMENT,
        'data-not-accept': `${ELEMENT_TYPE.BAR},${BODY_TYPE.VERTICAL}`,
    };

    const isSomeDescendantCurrent = useCallback(
        (parent = element) => {
            const children = elements.filter(
                ({ IDParentElement }) => IDParentElement === parent.id
            );
            return children.some((element) => {
                const { current, IDParentElement } = element;
                if (IDParentElement === parent.id && current) {
                    return true;
                }
                return isSomeDescendantCurrent(element);
            });
        },
        [element, elements]
    );

    useEffect(() => {
        let mounted = true;
        getElements({ idMainElement: id }).then(() => {
            mounted && setLoaded(true);
        });
        return () => {
            mounted = false;
        };
    }, []);

    return (
        <ElementWrapper
            element={element}
            className={isSomeDescendantCurrent() ? 'descendant--current' : ''}
            IDElementType={ELEMENT_TYPE.BAR}
            data={data}
        >
            <Sortable
                key={`ElementContent-${element.id}-${childElements
                    .map(({ lastUpdated }) => lastUpdated)
                    .join('-')}`}
                className="element-content"
                group={{ name: 'Elements', pull: true, put: ['DragItems', 'Elements'] }}
                data={sortableData}
            >
                {childElements
                    .sort((e1, e2) => (e1.Sequence < e2.Sequence ? -1 : 1))
                    .map((element) => (
                        <Element key={`Element${element.id}`} element={element} />
                    ))}
            </Sortable>
        </ElementWrapper>
    );
};
