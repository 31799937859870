import React, { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Icon } from '../../../components';
import { SCREEN_ICON } from '../../../context';
import { useMutationObserver, useResources } from '../../../hooks';
import { ScreenWrapper } from '.';

const OfflineWeb = ({ children, ...props }) => {
    const iframe = useRef();
    const { resources = [], selectResource, unzip } = useResources();
    useMutationObserver(iframe);
    let { concrete = {}, Nombre: title } = props;
    concrete = concrete || {};
    const { idZip } = concrete;
    const zipRes = resources.find(({ id }) => id === idZip) || {};

    useEffect(() => {
        if (zipRes.id) {
            selectResource(zipRes);
            unzip(zipRes);
        }
    }, [iframe, zipRes.id]);

    return <iframe title={title} ref={iframe} />;
};

const OnlineWeb = ({ children, ...props }) => {
    const { concrete = {}, Nombre: title } = props;
    const { url } = concrete;

    return <iframe src={url} title={title} />;
};

export const Web = (props) => {
    const { t } = useTranslation();
    let { concrete = {} } = props;
    concrete = concrete || {};
    const { id, idZip, url } = concrete;

    if (!id) {
        return null;
    }

    return (
        <ScreenWrapper {...props}>
            <div className="iframe-blocker" />
            {(idZip && <OfflineWeb {...props} />) || (url && <OnlineWeb {...props} />) || (
                <div className="skeleton">
                    <Icon type={SCREEN_ICON.WEB} />
                    <span>{t('elements.web')}</span>
                </div>
            )}
        </ScreenWrapper>
    );
};
