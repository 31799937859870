import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { parse } from 'query-string';
import { Button, ButtonGroup, Form, FormGroup, Input, Label } from 'reactstrap';
import ReCAPTCHA from 'react-google-recaptcha';
import { PAGES, SECRETS } from '../../_config';
import { scrollToInvalid } from '../../_helpers';
import { PasswordInput } from '../../components';
import { useUser } from '../../hooks';

export const RegisterForm = (props) => {
    const { location = {} } = props;
    const { search = '' } = location;
    const { t } = useTranslation();
    const { createUser } = useUser();
    const recaptchaRef = useRef();
    const submitted = useRef(false);
    const { referral = '' } = parse(search);

    const [user, setUser] = useState({
        referral,
        email: '',
        password: '',
        captcha: '',
        announcements: null,
    });

    const register = () => {
        createUser({ variables: user });
        submitted.current = false;
    };

    useEffect(() => {
        if (submitted.current) {
            register();
        }
    }, [user]);

    const handleChange = (event) => {
        submitted.current = false;
        const target = event.currentTarget;
        let { name, value } = target.attributes;
        name = name ? name.nodeValue : target.name;
        value = value ? value.nodeValue === 'true' : target.value;
        target.dataset.validate === 'false' && delete target.dataset.validate;
        target.setCustomValidity && target.setCustomValidity('');
        setUser((user) => ({ ...user, [name]: value }));
    };

    const handleChangeCaptcha = (captcha) => {
        setUser((user) => ({ ...user, captcha }));
    };

    const handleSubmit = (event) => {
        event && event.preventDefault();
        if (submitted.current) {
            return;
        }

        submitted.current = true;
        const form = event.currentTarget;

        if (form.reportValidity && form.reportValidity() === false) {
            scrollToInvalid(form);
            return;
        }

        if (!user.captcha) {
            recaptchaRef.current.execute();
            return;
        }

        register();
    };

    return (
        <Form className="js-validate" onSubmit={handleSubmit}>
            <FormGroup>
                <Label for="InputIdentifier" className="h6 small d-block text-uppercase">
                    {t('common.email')}
                </Label>
                <Input
                    id="InputIdentifier"
                    type="email"
                    name="email"
                    data-cy="InputIdentifier"
                    className="p-2"
                    aria-describedby="emailHelp"
                    placeholder={t('common.email_placeholder')}
                    onChange={handleChange}
                    maxLength="250"
                    required
                />
            </FormGroup>

            <FormGroup>
                <Label for="InputPassword" className="h6 small d-block text-uppercase">
                    {t('common.password')}
                </Label>
                <PasswordInput
                    id="InputPassword"
                    name="password"
                    data-cy="InputPassword"
                    className="p-2"
                    placeholder={t('common.promise_not_to_tell')}
                    aria-describedby="passwordHelp"
                    onChange={handleChange}
                    minLength="8"
                    maxLength="72"
                    required
                />
            </FormGroup>

            <FormGroup>
                <Label className="h6 small d-block text-uppercase">
                    {t('common.recieve_communications')}
                </Label>
                <ButtonGroup data-toggle="buttons">
                    <label
                        className={`btn btn-outline-primary${
                            user.announcements === true ? ' active' : ''
                        }`}
                        htmlFor="CommunicationYes"
                        name="announcements"
                        value={true}
                        onClick={handleChange}
                    >
                        <Input
                            id="CommunicationYes"
                            type="radio"
                            name="announcements"
                            value={true}
                            onChange={handleChange}
                            required
                        />
                        <span>{t('common.yes')}</span>
                    </label>
                    <label
                        className={`btn btn-outline-primary${
                            user.announcements === false ? ' active' : ''
                        }`}
                        htmlFor="CommunicationNo"
                        name="announcements"
                        value={false}
                        onClick={handleChange}
                    >
                        <Input
                            id="CommunicationNo"
                            type="radio"
                            name="announcements"
                            value={false}
                            onChange={handleChange}
                            required
                        />
                        <span>{t('common.no')}</span>
                    </label>
                </ButtonGroup>
            </FormGroup>

            <FormGroup>
                <div className="custom-control custom-checkbox">
                    <Input
                        id="Terms"
                        type="checkbox"
                        className="custom-control-input"
                        aria-describedby="agreeTermsHelp"
                        required
                    />{' '}
                    <label
                        htmlFor="Terms"
                        className="small custom-control-label mb-3 w-100 text-muted"
                    >
                        {t('register.i_agree')}{' '}
                        <Link
                            to={PAGES.LEGAL_TERMS}
                            target="_blank"
                            className="link-muted"
                            data-cy="terms"
                        >
                            {t('register.mobincube_terms')}
                        </Link>
                    </label>
                </div>
            </FormGroup>

            <div className="d-lg-flex justify-content-between">
                <div>
                    <span className="text-sm-right text-muted">
                        {t('register.have_account')}{' '}
                        <Link to={PAGES.SIGN_IN} data-cy="signin">
                            {t('common.sign_in')}
                        </Link>
                    </span>
                </div>
                <div className="text-right pl-lg-5">
                    <Button
                        id="RegisterSubmitButton"
                        color="primary"
                        className="w-100 position-relative text-uppercase font-weight-bold mt-4 mt-lg-0"
                        disabled={submitted.current}
                        data-cy="submit"
                    >
                        {t('common.sign_up_long')}
                    </Button>
                </div>
            </div>

            <FormGroup className="mt-5 mt-lg-0 mb-0">
                <ReCAPTCHA
                    ref={recaptchaRef}
                    sitekey={SECRETS.GRE_CAPTCHA_SITE_KEY}
                    size="invisible"
                    onChange={handleChangeCaptcha}
                    data-cy="recaptcha"
                />
            </FormGroup>
        </Form>
    );
};

export default RegisterForm;
