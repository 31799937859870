import React from 'react';
import ls from 'local-storage';
import { PAGES } from '../_config';
import { ActionsProvider } from './Actions';
import { ApplicationsProvider } from './Applications';
import { ClipboardProvider } from './Clipboard';
import { DragItemsProvider } from './DragItems';
import { ModalsProvider } from './Modals';
import { PlansProvider } from './Plans';
import { TimelineProvider } from './Timeline';
import { UserProvider } from './User';
import { logout } from './Viewer';

const headers = {
    'content-type': 'application/json',
};

export const getHeaders = ({ auth } = {}) => {
    if (auth) {
        const { auth = {} } = ls('user') || {};
        const { token } = auth;

        return {
            ...headers,
            authorization: `Bearer ${token}`,
        };
    }

    return headers;
};

export const handleResponse = async (response) => {
    const { ok, status } = response;
    if (status === 503 && window.location.pathname !== PAGES.MAINTENANCE) {
        logout();
        window.location.href = PAGES.MAINTENANCE;
    }
    if (status !== 503 && window.location.pathname === PAGES.MAINTENANCE) {
        window.location.href = '/';
    }
    let data;
    try {
        data = (await response.json()) || {};
        return ok ? data : Promise.reject(data);
    } catch (e) {
        Promise.reject(response);
    }
};

export const ContextProviders = ({ children }) => {
    return (
        <PlansProvider>
            <UserProvider>
                <DragItemsProvider>
                    <ClipboardProvider>
                        <TimelineProvider>
                            <ApplicationsProvider>
                                <ActionsProvider>
                                    <ModalsProvider>
                                        <>{children}</>
                                    </ModalsProvider>
                                </ActionsProvider>
                            </ApplicationsProvider>
                        </TimelineProvider>
                    </ClipboardProvider>
                </DragItemsProvider>
            </UserProvider>
        </PlansProvider>
    );
};

export * from './Actions';
export * from './Advertisement';
export * from './Applications';
export * from './Clipboard';
export * from './DragItems';
export * from './Elements';
export * from './Errors';
export * from './Modals';
export * from './Plans';
export * from './Resources';
export * from './Screens';
export * from './User';
export * from './Timeline';
export * from './Unsplash';
export * from './Viewer';
