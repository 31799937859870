import React, { useCallback, useEffect, useRef, useState } from 'react';
import debounce from 'just-debounce-it';
import { useTranslation } from 'react-i18next';
import { Empty, message } from 'antd';
import { Card, CardBody, CardDeck, CardTitle, ModalBody } from 'reactstrap';
import { Sortable as SortableJS } from 'sortablejs';
import { Element, Modal } from '.';
import { DRAG_ITEM_TYPE, ELEMENT_TYPE } from '../context';
import { useApplications, useDragItems, useElements, useScreens } from '../hooks';

export const Sortable = ({ children, className, data = {}, tag, ...props }) => {
    const { t } = useTranslation();
    const el = useRef();
    const [indexToAppendBar, setIndexToAppendBar] = useState(0);
    const { application = {} } = useApplications();
    const { bars = [] } = application;
    const { getDragItem, getDragItemAttributes } = useDragItems();
    const {
        createElement,
        element = {},
        elements = [],
        selectElement,
        unselectElement,
        updateElement,
    } = useElements();
    const { addBarToScreen, screen = {}, updateScreenBar } = useScreens();
    const { section_bars: screenBars = [] } = screen;
    const { 'data-bars': dataBars, 'data-position': position } = data;

    const selectBarToAdd = (bar, sequence) => {
        addBarToScreen({ IDElement: bar.id, screen, position, sequence });
        setIndexToAppendBar(0);
    };

    const handleStart = useCallback(
        (event) => {
            const { item } = event;
            const { dataset } = item;
            let { id: idItem, bodyType, elementId, elementType } = dataset;
            let type = DRAG_ITEM_TYPE.ELEMENT;

            if (idItem) {
                idItem = parseInt(idItem, 10);
                const dragItem = getDragItem(idItem);
                const { attributes = {} } = dragItem;
                const { concrete = {}, IDElementType } = attributes;
                elementType = IDElementType;
                bodyType = concrete.bodyType;
                type = dragItem.type;
            }

            document.body.classList.add('dragging-element');
            document.body.dataset.elementType = elementType;
            document.body.dataset.type = type;
            if (bodyType) {
                document.body.dataset.bodyType = bodyType;
            }

            const id = parseInt(elementId, 10);
            if (id && id !== element.id) {
                selectElement({ id });
            }
        },
        [element, elements]
    );

    const handleEnd = () => {
        document.body.classList.remove('dragging-element');
        delete document.body.dataset.type;
        delete document.body.dataset.elementType;
        delete document.body.dataset.bodyType;
    };

    const handleAdd = (event) => {
        const { item = {}, newIndex, to = {} } = event;
        const { dataset: itemDataset = {} } = item;
        const { parentElement = {} } = to;
        const { dataset: parentDataset = {} } = parentElement;
        const idItem = parseInt(itemDataset.id, 10);
        const attributes = {
            IDParentElement: parseInt(parentDataset.elementId, 10),
            Sequence: newIndex + 1,
        };

        if (idItem) {
            createElement({
                ...getDragItemAttributes(idItem),
                ...attributes,
                id: `c${Date.now()}`,
            }).then((element) => selectElement(element));
            item.remove();
        } else {
            const id = parseInt(itemDataset.elementId, 10);
            updateElement(attributes, { id });
        }
    };

    const handleAddBar = (event) => {
        const { item, newIndex } = event;
        const { dataset = {} } = item;
        const { id, elementId, elementType } = dataset;
        const idItem = parseInt(id, 10);
        const IDElement = parseInt(elementId, 10);
        const IDElementType = parseInt(elementType, 10);
        let attributes = { id: IDElement, IDElement, IDElementType };

        if (IDElementType === ELEMENT_TYPE.BAR) {
            if (!IDElement) {
                item.remove();
                setIndexToAppendBar(newIndex + 1);
                return;
            }
            if (!screenBars.some(({ IDElement: id }) => id === IDElement)) {
                addBarToScreen({ IDElement, screen, position, sequence: newIndex + 1 });
            }
            return;
        }

        const name = `bar${Date.now()}`;
        createElement({
            concrete: {
                name,
                title: name,
            },
            IDElementType: ELEMENT_TYPE.BAR,
            id: `c${Date.now()}`,
        })
            .then(({ id } = {}) => {
                if (!id) {
                    return;
                }
                if (idItem) {
                    attributes = getDragItemAttributes(idItem);
                    item.remove();
                    return createElement({
                        ...attributes,
                        IDParentElement: id,
                        Sequence: 1,
                        id: `c${Date.now()}`,
                    });
                }
                return updateElement(
                    {
                        IDParentElement: id,
                        IDSection: null,
                        Sequence: 1,
                    },
                    attributes
                );
            })
            .then(({ IDParentElement } = {}) => {
                if (!IDParentElement) {
                    return;
                }
                addBarToScreen({
                    IDElement: IDParentElement,
                    screen,
                    position,
                    sequence: newIndex + 1,
                });
            });
    };

    const handleMove = (event) => {
        const { dragged, to } = event;
        const { dataset: draggedDataset } = dragged;
        const { dataset: toDataset, classList } = to;
        const { bodyType = 'NONE', elementType = 'NONE', type = 'NONE' } = draggedDataset;
        const { accept = '', bars = false, notAccept = '' } = toDataset;
        const fallback = document.querySelector('.sortable-fallback');

        const isValidMove =
            (!accept ||
                accept.includes(type) ||
                accept.includes(elementType) ||
                accept.includes(bodyType)) &&
            (!notAccept ||
                !(
                    notAccept.includes(type) ||
                    notAccept.includes(elementType) ||
                    notAccept.includes(bodyType)
                ));

        if (!fallback.classList.contains('drag-item')) {
            const icon =
                parseInt(elementType, 10) === ELEMENT_TYPE.BAR
                    ? 'mdi-minus-box-outline'
                    : 'mdi-close-box-outline';
            const deleteElement = classList.contains('main-content');
            fallback.classList.toggle('delete', deleteElement);
            fallback.classList.toggle('mdi', deleteElement);
            fallback.classList.toggle(icon, deleteElement);
        }
        if (isValidMove && parseInt(elementType, 10) !== ELEMENT_TYPE.BAR) {
            if (bars) {
                message.open({ content: t('elements.create_bar'), key: 'NewBar' });
            } else {
                message.destroy('NewBar');
            }
        }

        return isValidMove;
    };

    const handleSort = (event) => {
        const { from, item, newIndex, to } = event;
        const { parentElement: fromElement } = from;
        const { dataset: fromElementDataset = {} } = fromElement || {};
        let { elementId: fromElementId = 0 } = fromElementDataset;
        const { parentElement: toElement } = to;
        const { dataset: toElementDataset = {} } = toElement || {};
        let { elementId: toElementId = 0 } = toElementDataset;
        const idItem = parseInt(item.dataset.id, 10);
        fromElementId = parseInt(fromElementId, 10);
        toElementId = parseInt(toElementId, 10);
        if (idItem || fromElementId !== toElementId) {
            return;
        }
        const attributes = {
            Sequence: newIndex + 1,
        };
        const id = parseInt(item.dataset.elementId, 10);
        updateElement(attributes, { id });
    };

    const handleSortBar = (event) => {
        const { from, item, newIndex, to } = event;
        const { dataset = {} } = item || {};
        const { screenBarId, id: idItem } = dataset;
        const fromPosition = from.dataset.position;
        const toPosition = to.dataset.position;
        if (idItem || !toPosition) {
            return;
        }

        const attributes = {
            sequence: newIndex + 1,
        };
        if (fromPosition !== toPosition) {
            attributes.position = toPosition;
        }

        debounce(updateScreenBar(attributes, { id: screenBarId }), 500);
    };

    useEffect(() => {
        let mounted = true;
        if (mounted && el.current) {
            SortableJS.create(el.current, {
                animation: 150,
                chosenClass: 'active',
                fallbackClass: 'sortable-fallback',
                fallbackOnBody: true,
                fallbackTolerance: 5,
                forceFallback: true,
                handle: '.element-handler',
                onStart: handleStart,
                onEnd: handleEnd,
                onAdd: dataBars ? handleAddBar : handleAdd,
                onMove: handleMove,
                onSort: dataBars ? handleSortBar : handleSort,
                ...props,
            });
        }
        return () => {
            mounted = false;
        };
    }, [el, props]);

    let container = (
        <div className={className} {...data} ref={el}>
            {children}
        </div>
    );

    if (tag === 'ul') {
        container = (
            <ul className={className} {...data} ref={el}>
                {children}
            </ul>
        );
    }

    return (
        <>
            {container}
            {dataBars && (
                <Modal
                    className="bars"
                    isOpen={!!indexToAppendBar}
                    onOpened={() => unselectElement()}
                    onClosed={() => setIndexToAppendBar(0)}
                    title={t('elements.bars')}
                >
                    <ModalBody>
                        {!bars.length && <Empty />}
                        <CardDeck className="flex-wrap">
                            {bars
                                .filter(
                                    (bar) => !screenBars.some(({ IDElement: id }) => id === bar.id)
                                )
                                .sort((b1, b2) =>
                                    (b1.concrete || {}).title < (b2.concrete || {}).title ? -1 : 1
                                )
                                .map((bar) => (
                                    <Card
                                        key={`BarCard-${bar.id}`}
                                        onClick={() => selectBarToAdd(bar, indexToAppendBar)}
                                    >
                                        <CardBody>
                                            <Element key={`Bar-${bar.id}`} element={bar} />
                                        </CardBody>
                                        <CardBody>
                                            <CardTitle>{(bar.concrete || {}).title}</CardTitle>
                                        </CardBody>
                                    </Card>
                                ))}
                        </CardDeck>
                    </ModalBody>
                </Modal>
            )}
        </>
    );
};
