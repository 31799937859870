import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, ButtonGroup, CardBody, CardTitle } from 'reactstrap';
import { MODALS } from '../../../_config';
import { getHex } from '../../../_helpers';
import { ColorPicker, Icon, Slider } from '../../../components';
import { ELEMENT_HALIGN, ELEMENT_VALIGN, RESOURCE_TYPE, SCREEN_TYPE } from '../../../context';
import { useModals, useResources, useScreens } from '../../../hooks';
import {
    ActionImage,
    Calendar,
    Dataview,
    Feed,
    Info,
    Map,
    OptionList,
    Splash,
    Undefined,
    Web,
} from '.';

export const ScreenWrapper = ({ children, ...screen }) => {
    const { t } = useTranslation();
    const { openModal, setModal } = useModals();
    const { selectResource, unselectResource } = useResources();
    const {
        createScreenBackgroundImage,
        deleteScreenBackgroundImage,
        updateScreen,
        updateScreenBackgroundImage,
    } = useScreens();
    const { id, IDTipoSeccion: type } = screen;
    let { Background: backgroundColor, section_image: backgroundImage = {} } = screen;
    backgroundColor = `#${backgroundColor}`;
    backgroundImage = backgroundImage ? backgroundImage : {};
    const { hAlign, vAlign, width } = backgroundImage;

    const handleChangeBackgroundColor = (color) => {
        if (color === backgroundColor) {
            return;
        }
        updateScreen({ Background: getHex(color).substr(1) });
    };

    const handleChangingBackgroundColor = (picker, color) => {
        const screenEl = document.querySelector(`[data-screen-id="${id}"]`);
        screenEl.style.backgroundColor = color;
    };

    const handleSelectBackgroundImage = () => {
        if (backgroundImage.id) {
            selectResource(backgroundImage);
        } else {
            unselectResource();
        }
        const filter = ({ type }) => type === RESOURCE_TYPE.IMAGE;
        const onClick = (resource) => {
            if (backgroundImage.id) {
                updateScreenBackgroundImage({ IDImage: resource.id }, screen);
            } else {
                createScreenBackgroundImage({ IDImage: resource.id }, screen);
            }
            setModal(null);
        };
        openModal(MODALS.RESOURCES, { filter, onClick, target: screen });
    };

    const handleDeleteBackgroundImage = () => {
        deleteScreenBackgroundImage({ id });
    };

    const handleChangeWidth = useCallback(
        (width, options = {}) => {
            const domElement = document.querySelector(`[data-screen-id="${id}"] .screen-image`);
            if (domElement) {
                domElement.style.backgroundSize = `${width}%`;
            }
            if (options.preview) {
                return;
            }
            updateScreenBackgroundImage({ width }, { id });
        },
        [id]
    );

    const handleChangeHAlign = useCallback(
        (hAlign) => {
            updateScreenBackgroundImage({ hAlign }, { id });
        },
        [id]
    );

    const handleChangeVAlign = useCallback(
        (vAlign) => {
            updateScreenBackgroundImage({ vAlign }, { id });
        },
        [id]
    );

    return (
        <div className="screen-wrapper" data-type={type}>
            {children}
            {![SCREEN_TYPE.UNDEFINED, SCREEN_TYPE.WEB].includes(type) && (
                <>
                    <CardBody>
                        <CardTitle>
                            <strong>{t('properties.background_color')}</strong>
                        </CardTitle>
                        <ColorPicker
                            key={backgroundColor}
                            value={backgroundColor}
                            onChange={handleChangingBackgroundColor}
                            onBlur={handleChangeBackgroundColor}
                            input={true}
                        />
                    </CardBody>
                    <CardBody>
                        <CardTitle>
                            <strong>{t('properties.background_image')}</strong>
                        </CardTitle>
                        <div className="d-flex">
                            <Button color="primary" onClick={handleSelectBackgroundImage}>
                                {t('properties.select_image')}
                            </Button>
                            {backgroundImage.id && (
                                <Button color="link" onClick={handleDeleteBackgroundImage}>
                                    <Icon type="delete" />
                                </Button>
                            )}
                        </div>
                    </CardBody>
                    {backgroundImage.id && (
                        <>
                            <CardBody>
                                <CardTitle>
                                    <strong>{t('properties.width')}</strong>
                                </CardTitle>
                                <Slider
                                    min={5}
                                    max={100}
                                    onChange={handleChangeWidth}
                                    format={(value) => `${value}%`}
                                    value={width}
                                    input={true}
                                />
                            </CardBody>
                            <CardBody>
                                <CardTitle>
                                    <strong>{t('properties.hAlign')}</strong>
                                </CardTitle>
                                <ButtonGroup>
                                    <Button
                                        outline
                                        color="primary"
                                        className={hAlign === ELEMENT_HALIGN.LEFT ? 'active' : ''}
                                        onClick={() => handleChangeHAlign(ELEMENT_HALIGN.LEFT)}
                                    >
                                        <Icon type="format-horizontal-align-left" />
                                        <span>{t(`properties.${ELEMENT_HALIGN.LEFT}`)}</span>
                                    </Button>
                                    <Button
                                        outline
                                        color="primary"
                                        className={hAlign === ELEMENT_HALIGN.CENTER ? 'active' : ''}
                                        onClick={() => handleChangeHAlign(ELEMENT_HALIGN.CENTER)}
                                    >
                                        <Icon type="format-horizontal-align-center" />
                                        <span>{t(`properties.${ELEMENT_HALIGN.CENTER}`)}</span>
                                    </Button>
                                    <Button
                                        outline
                                        color="primary"
                                        className={hAlign === ELEMENT_HALIGN.RIGHT ? 'active' : ''}
                                        onClick={() => handleChangeHAlign(ELEMENT_HALIGN.RIGHT)}
                                    >
                                        <Icon type="format-horizontal-align-right" />
                                        <span>{t(`properties.${ELEMENT_HALIGN.RIGHT}`)}</span>
                                    </Button>
                                </ButtonGroup>
                            </CardBody>
                            <CardBody>
                                <ButtonGroup>
                                    <Button
                                        outline
                                        color="primary"
                                        className={vAlign === ELEMENT_VALIGN.TOP ? 'active' : ''}
                                        onClick={() => handleChangeVAlign(ELEMENT_VALIGN.TOP)}
                                    >
                                        <Icon type="format-vertical-align-top" />
                                        <span>{t(`properties.${ELEMENT_VALIGN.TOP}`)}</span>
                                    </Button>
                                    <Button
                                        outline
                                        color="primary"
                                        className={vAlign === ELEMENT_VALIGN.MIDDLE ? 'active' : ''}
                                        onClick={() => handleChangeVAlign(ELEMENT_VALIGN.MIDDLE)}
                                    >
                                        <Icon type="format-vertical-align-center" />
                                        <span>{t(`properties.${ELEMENT_VALIGN.MIDDLE}`)}</span>
                                    </Button>
                                    <Button
                                        outline
                                        color="primary"
                                        className={vAlign === ELEMENT_VALIGN.BOTTOM ? 'active' : ''}
                                        onClick={() => handleChangeVAlign(ELEMENT_VALIGN.BOTTOM)}
                                    >
                                        <Icon type="format-vertical-align-bottom" />
                                        <span>{t(`properties.${ELEMENT_VALIGN.BOTTOM}`)}</span>
                                    </Button>
                                </ButtonGroup>
                            </CardBody>
                        </>
                    )}
                </>
            )}
        </div>
    );
};

export const Screen = (props) => {
    const { IDTipoSeccion: type } = props;

    switch (type) {
        case SCREEN_TYPE.UNDEFINED:
            return <Undefined {...props} />;
        case SCREEN_TYPE.OPTIONLIST:
            return <OptionList {...props} />;
        case SCREEN_TYPE.ACTIONIMAGE:
            return <ActionImage {...props} />;
        case SCREEN_TYPE.INFO:
            return <Info {...props} />;
        case SCREEN_TYPE.CALENDAR:
            return <Calendar {...props} />;
        case SCREEN_TYPE.FEED:
            return <Feed {...props} />;
        case SCREEN_TYPE.MAP:
            return <Map {...props} />;
        case SCREEN_TYPE.DATAVIEW:
            return <Dataview {...props} />;
        case SCREEN_TYPE.WEB:
        case SCREEN_TYPE.COUPON:
        case SCREEN_TYPE.RICHWEB:
            return <Web {...props} />;
        case SCREEN_TYPE.SPLASH:
            return <Splash {...props} />;
        default:
            return <Undefined {...props} />;
    }
};
