import React, { createContext, useCallback, useEffect, useState } from 'react';
import { useImmerReducer } from 'use-immer';
import { ELEMENT, ElementsReducer } from './reducers';
import { URL } from '../_config';
import { useApplications, useScreens, useViewer } from '../hooks';
import { i18n } from '../i18n';
import { getHeaders, handleResponse, SCREEN_TYPE } from '.';

export const ELEMENT_HALIGN = {
    LEFT: 'left',
    CENTER: 'center',
    RIGHT: 'right',
};

export const ELEMENT_VALIGN = {
    TOP: 'top',
    MIDDLE: 'middle',
    BOTTOM: 'bottom',
};

export const ELEMENT_TYPE = {
    UNDEFINED: 0,
    IMAGE: 1,
    TEXT: 2,
    SEPARATOR: 3,
    TEXTFIELD: 4,
    BODY: 5,
    BAR: 6,
    SWITCH: 7,
    CALCULATION: 8,
    SEARCH: 9,
    CHECKOUT: 10,
    VIDEO: 11,
};

export const ELEMENT_ICON = {
    UNDEFINED: 'file',
    IMAGE: 'image',
    TEXT: 'text',
    SEPARATOR: 'format-vertical-align-center',
    TEXTFIELD: 'textbox',
    BODY: 'table-row',
    BAR: 'view-day',
    SWITCH: 'view-list',
    CALCULATION: '',
    SEARCH: 'search',
    CHECKOUT: 'av-timer',
    VIDEO: 'play-circle-outline',
    [ELEMENT_TYPE.UNDEFINED]: 'file',
    [ELEMENT_TYPE.IMAGE]: 'image',
    [ELEMENT_TYPE.TEXT]: 'text',
    [ELEMENT_TYPE.SEPARATOR]: 'format-vertical-align-center',
    [ELEMENT_TYPE.TEXTFIELD]: 'textbox',
    [ELEMENT_TYPE.BODY]: 'table-row',
    [ELEMENT_TYPE.BAR]: 'view-day',
    [ELEMENT_TYPE.SWITCH]: 'view-list',
    [ELEMENT_TYPE.CALCULATION]: '',
    [ELEMENT_TYPE.SEARCH]: 'search',
    [ELEMENT_TYPE.CHECKOUT]: 'av-timer',
    [ELEMENT_TYPE.VIDEO]: 'play-circle-outline',
};

export const ELEMENT_TITLE = {
    [ELEMENT_TYPE.UNDEFINED]: i18n.t('elements.undefined'),
    [ELEMENT_TYPE.IMAGE]: i18n.t('elements.image'),
    [ELEMENT_TYPE.TEXT]: i18n.t('elements.text'),
    [ELEMENT_TYPE.SEPARATOR]: i18n.t('elements.separator'),
    [ELEMENT_TYPE.TEXTFIELD]: i18n.t('elements.textfield'),
    [ELEMENT_TYPE.BODY]: i18n.t('elements.body'),
    [ELEMENT_TYPE.BAR]: i18n.t('elements.bar'),
    [ELEMENT_TYPE.VIDEO]: i18n.t('elements.video'),
};

const defaultAttributes = {
    IDAction: 0,
    IDGuia: undefined,
    IDParentElement: null,
    IDSection: null,
    Sequence: 1,
    concrete: {},
    hAlignChild: undefined,
    hAlignParent: 'left',
    idShading: null,
    lastUpdated: undefined,
    parameter: '',
    vAlignChild: 'top',
    vAlignParent: undefined,
    width: 100,
};

export const ElementsContext = createContext(ELEMENT.INITIAL_STATE);

export const ElementsProvider = ({ children }) => {
    const [loading, setLoading] = useState(false);
    const [state, dispatch] = useImmerReducer(ElementsReducer, ELEMENT.INITIAL_STATE);
    const { element = {}, elements = [] } = state;
    const { addBarToApplication, application = {} } = useApplications();
    const { screen = {} } = useScreens();
    const { logout, viewer = {} } = useViewer();

    const addElements = (elements) => {
        dispatch({
            type: ELEMENT.LIST,
            payload: { elements },
        });
    };

    const checkElements = (elements = [], { checked = true } = {}) => {
        elements = Array.isArray(elements) ? elements : [elements];
        dispatch({
            type: ELEMENT.CHECK,
            payload: { elements, checked },
        });
    };

    const createElement = useCallback(
        (attributes = {}) => {
            attributes = {
                ...defaultAttributes,
                IDGuia: application.id,
                ...attributes,
            };
            setLoading(true);
            return fetch(`${URL.API}/elements`, {
                method: 'PATCH',
                body: JSON.stringify([attributes]),
                headers: getHeaders({ auth: true }),
            })
                .then(handleResponse)
                .then((elements) => {
                    setLoading(false);
                    const updates = reorderElements(attributes);
                    elements = elements.map((element) => ({
                        ...(updates.find(({ id }) => id === element.id) || {}),
                        ...element,
                    }));
                    dispatch({
                        type: ELEMENT.CREATE,
                        payload: { elements },
                    });
                    const newElement = elements.find(({ IDElement }) => IDElement);
                    getElement(newElement);
                    if (newElement.IDElementType === ELEMENT_TYPE.BAR) {
                        addBarToApplication(newElement);
                    }
                    return newElement;
                })
                .catch((err) => {
                    setLoading(false);
                    console.error(err);
                });
        },
        [application.id, elements]
    );

    const deleteElements = useCallback(
        (elementsToDelete) => {
            elementsToDelete = Array.isArray(elementsToDelete)
                ? elementsToDelete
                : [elementsToDelete];
            elementsToDelete = elementsToDelete
                .map((elementToDelete) => {
                    const { id, lastUpdated } =
                        elements.find(({ id }) => id === elementToDelete.id) || {};
                    return { delete: true, id, lastUpdated };
                })
                .filter(({ id }) => id);
            setLoading(true);
            return fetch(`${URL.API}/elements`, {
                method: 'PATCH',
                body: JSON.stringify(elementsToDelete),
                headers: getHeaders({ auth: true }),
            })
                .then(handleResponse)
                .then((elements) => {
                    elements = elements.map((element) => ({
                        ...element,
                        Sequence:
                            (state.elements.find(({ id }) => id === element.id) || { Sequence: 0 })
                                .Sequence - 1,
                    }));
                    setLoading(false);
                    dispatch({
                        type: ELEMENT.DELETE,
                        payload: { elements: elementsToDelete },
                    });
                });
        },
        [elements]
    );

    const duplicateElements = (elements) => {};

    const getElement = useCallback(
        (element = {}, { select = true } = {}) => {
            const { id } = element;
            if (!id || isNaN(id)) {
                return Promise.reject();
            }
            element = elements.length
                ? elements.find(({ id }) => id === element.id) || element
                : element;

            setLoading(true);
            return fetch(`${URL.API}/elements/${id}`, {
                headers: getHeaders({ auth: true }),
            })
                .then(async (response) => {
                    const { data = {} } = await response.json();
                    return response.ok ? data : Promise.reject(data.type);
                })
                .then((element) => {
                    setLoading(false);
                    dispatch({
                        type: ELEMENT.GET,
                        payload: { element, select: select || !!element.IDParentElement },
                    });
                    return element;
                })
                .catch((err) => {
                    setLoading(false);
                    console.error(err);
                });
        },
        [elements]
    );

    const getElements = useCallback(
        ({ idMainElement, reset = false } = {}) => {
            if (!idMainElement && (screen.IDTipoSeccion !== SCREEN_TYPE.INFO || !screen.concrete)) {
                return Promise.resolve([]);
            }
            let { concrete = {} } = screen;
            concrete = concrete || {};
            const { IDMainElement } = concrete;
            idMainElement = idMainElement || IDMainElement;
            const children = elements.filter(
                ({ IDParentElement }) => IDParentElement === idMainElement
            );
            if (children.length) {
                return Promise.resolve(elements);
            }
            setLoading(true);
            return fetch(`${URL.API}/elements/${idMainElement}/descendants`, {
                headers: getHeaders({ auth: true }),
            })
                .then(async (response) => {
                    const { data = {} } = await response.json();
                    return response.ok ? data : Promise.reject(data.type);
                })
                .then((elements) => {
                    setLoading(false);
                    dispatch({
                        type: ELEMENT.LIST,
                        payload: { elements, reset },
                    });
                    return elements;
                })
                .catch((error) => {
                    setLoading(false);
                    console.error(error);
                    if (error === 'unauthorized') {
                        logout();
                    }
                });
        },
        [screen.id, screen.concrete]
    );

    const getRootElement = (element) => {
        const { IDParentElement } = element;
        const parentElement = elements.find(({ id }) => id === IDParentElement);
        return parentElement ? getRootElement(parentElement) : element;
    };

    const refreshElements = useCallback(() => {
        if (screen.IDTipoSeccion !== SCREEN_TYPE.INFO || !screen.concrete) {
            return Promise.resolve([]);
        }
        let { concrete = {} } = screen;
        concrete = concrete || {};
        const { IDMainElement } = concrete;
        setLoading(true);
        return fetch(`${URL.API}/elements/${IDMainElement}/descendants`, {
            headers: getHeaders({ auth: true }),
        })
            .then(async (response) => {
                const { data = {} } = await response.json();
                return response.ok ? data : Promise.reject(data.type);
            })
            .then((elements) => {
                setLoading(false);
                dispatch({
                    type: ELEMENT.LIST,
                    payload: { elements, reset: true },
                });
                return elements;
            })
            .catch((error) => {
                setLoading(false);
                console.error(error);
                if (error === 'unauthorized') {
                    logout();
                }
            });
    }, [screen.id, screen.concrete]);

    const reorderElements = useCallback(
        (attributes) => {
            let updates = [attributes];
            let {
                id: elementId,
                IDParentElement: newParentId,
                Sequence: newSequence = elements.length,
            } = attributes;
            let oldSequence = elements.length + 1;
            const siblings = elements.filter(
                ({ IDParentElement }) => IDParentElement === newParentId
            );

            if (!isNaN(elementId)) {
                const oldAttributes = elements.find(({ id }) => id === elementId);
                const { IDParentElement: oldParentId, Sequence } = oldAttributes;
                oldSequence = Sequence;
                if (newParentId && oldParentId !== newParentId) {
                    updates = [
                        ...updates,
                        ...elements
                            .filter(({ IDParentElement: parentId }) => parentId === oldParentId)
                            .map(({ id, lastUpdated, Sequence }) => {
                                return Sequence > oldSequence
                                    ? { id, lastUpdated, Sequence: Sequence - 1 }
                                    : undefined;
                            })
                            .filter((el) => el),
                    ];
                }
            }

            if (oldSequence < newSequence) {
                updates = [
                    ...updates,
                    ...siblings
                        .map(({ id, lastUpdated, Sequence }) =>
                            id !== elementId && Sequence <= newSequence && Sequence > oldSequence
                                ? { id, lastUpdated, Sequence: Sequence - 1 }
                                : undefined
                        )
                        .filter((el) => el),
                ];
            } else if (oldSequence > newSequence) {
                updates = [
                    ...updates,
                    ...siblings
                        .map(({ id, lastUpdated, Sequence }) =>
                            id !== elementId && Sequence >= newSequence && Sequence < oldSequence
                                ? { id, lastUpdated, Sequence: Sequence + 1 }
                                : undefined
                        )
                        .filter((el) => el),
                ];
            }
            return updates;
        },
        [elements]
    );

    const selectElement = useCallback(
        (element) => {
            if (!element) {
                return;
            }
            dispatch({
                type: ELEMENT.SELECT,
                payload: { element },
            });
        },
        [viewer.id, application.id, screen.id]
    );

    const uncheckElements = (elements) => {
        checkElements(elements, { checked: false });
    };

    const unselectElement = () => {
        dispatch({ type: ELEMENT.UNSELECT });
    };

    const updateElement = useCallback(
        (attributes = {}, element = state.element) => {
            const _element = elements.find(({ id }) => id === (element || {}).id);
            if (!_element) {
                return Promise.resolve();
            }
            const { id, IDParentElement, lastUpdated } = _element;
            attributes = {
                ...attributes,
                id,
                lastUpdated,
            };

            setLoading(true);
            return fetch(`${URL.API}/elements`, {
                method: 'PATCH',
                body: JSON.stringify([attributes]),
                headers: getHeaders({ auth: true }),
            })
                .then(
                    async (response) => {
                        const data = (await response.json()) || {};
                        return response.ok ? data : Promise.reject(data.type);
                    },
                    (error) => Promise.reject(error)
                )
                .then((elements) => {
                    setLoading(false);
                    const updates = attributes.Sequence
                        ? reorderElements({ ...attributes, id, IDParentElement })
                        : [attributes];
                    elements = elements.map((element) => ({
                        ...(updates.find(({ id }) => id === element.id) || {}),
                        ...element,
                    }));
                    dispatch({
                        type: ELEMENT.UPDATE,
                        payload: { elements },
                    });
                    const elementUpdated = { ...element, ...attributes };
                    if (elementUpdated.IDElementType === ELEMENT_TYPE.BAR) {
                        addBarToApplication(elementUpdated);
                    }
                    return elementUpdated;
                })
                .catch((error) => {
                    setLoading(false);
                    console.error(error);
                    if (error === 'unauthorized') {
                        logout();
                    }
                });
        },
        [element.id, elements]
    );

    useEffect(() => {
        getElements();
    }, [screen.id, screen.concrete]);

    useEffect(() => {
        refreshElements();
    }, [screen.refreshed]);

    useEffect(() => {
        addElements(application.bars);
    }, [application.bars]);

    if (process.env.NODE_ENV === 'development') {
        console.log('ELEMENTS >>>', state);
    }

    return (
        <ElementsContext.Provider
            value={{
                ...state,
                checkElements,
                createElement,
                deleteElements,
                duplicateElements,
                getElement,
                getElements,
                getRootElement,
                loading,
                refreshElements,
                selectElement,
                uncheckElements,
                unselectElement,
                updateElement,
            }}
        >
            {children}
        </ElementsContext.Provider>
    );
};

export const ElementsConsumer = ElementsContext.Consumer;
export default ElementsContext;
