import React, { useEffect, useRef } from 'react';
import { Card, CardBody } from 'reactstrap';
import { useApplications, useModals } from '../hooks';
import defaultIcon from '../assets/svg/logos/icon.svg';

export const Application = (application) => {
    let { icon, Nombre } = application;
    const bgRef = useRef();
    const iconRef = useRef();
    const { getApplicationIcon, selectApplication } = useApplications();
    const { closeModal } = useModals();

    useEffect(() => {
        let mounted = true;
        (async () => {
            if (!icon && mounted) {
                icon = await getApplicationIcon({ application });
                iconRef.current && (iconRef.current.src = icon);
                bgRef.current && (bgRef.current.style.backgroundImage = `url(${icon})`);
            }
        })();
        return () => {
            mounted = false;
        };
    }, [iconRef, bgRef]);

    const handleClick = () => {
        selectApplication(application);
        closeModal();
    };

    return (
        <Card className="application shadow-sm" onClick={handleClick}>
            <div className="card-img-top icon">
                <img
                    key={icon}
                    src={icon}
                    alt={Nombre}
                    ref={iconRef}
                    onLoad={({ currentTarget }) => {
                        currentTarget.classList.add('loaded');
                    }}
                />
                <img src={defaultIcon} alt={Nombre} />
                <div className="bg" style={{ backgroundImage: `url(${icon})` }} ref={bgRef} />
                <div className="bg" style={{ backgroundImage: `url(${defaultIcon})` }} />
            </div>
            <CardBody>{Nombre}</CardBody>
        </Card>
    );
};
