import React from 'react';
import { useHistory } from 'react-router-dom';
import { Card, CardBody } from 'reactstrap';
import ls from 'local-storage';
import { ProgressIndeterminate, SocialLogin } from '../../components';
import { useUser } from '../../hooks';
import { LoginForm } from '.';
import { logoWhite } from '../../assets/svg/logos';

const LoginPage = (props) => {
    const { loading } = useUser();
    const history = useHistory();
    const { auth = {} } = ls('user') || {};
    const { token } = auth;

    if (token) {
        history.push('/');
    }

    return (
        <main className="login gradient-half-secondary min-height-100vh--lg">
            <header className="position-absolute-top-left-0--lg z-index-2">
                <nav className="d-flex justify-content-between align-items-center">
                    <div className="col-lg-5 col-xl-4 text-lg-center px-0">
                        <a className="d-inline-block p-3 p-sm-5" href="/" aria-label="Mobincube">
                            <img src={logoWhite} height="50px" alt="Logo" />
                        </a>
                    </div>
                </nav>
            </header>
            <div className="min-height-100vh--lg">
                <Card className="border-0 shadow">
                    <ProgressIndeterminate visible={loading} />
                    <CardBody className="p-4">
                        <SocialLogin />
                        <LoginForm {...props} />
                    </CardBody>
                </Card>
            </div>
        </main>
    );
};

export { LoginPage };
