import { getUnique } from '../../_helpers';

export const ELEMENT = {
    INITIAL_STATE: { element: undefined, elements: [] },
    CHECK: 'ELEMENT_CHECK',
    CREATE: 'ELEMENT_CREATE',
    DELETE: 'ELEMENT_DELETE',
    GET: 'ELEMENT_GET',
    GET_CONCRETE: 'ELEMENT_GET_CONCRETE',
    LIST: 'ELEMENT_LIST',
    SELECT: 'ELEMENT_SELECT',
    UNSELECT: 'ELEMENT_UNSELECT',
    UPDATE: 'ELEMENT_UPDATE',
};

export const ElementsReducer = (draft, action) => {
    const { payload, type } = action;

    switch (type) {
        case ELEMENT.CHECK:
            draft.elements = draft.elements.map((element) =>
                payload.elements.some(({ id }) => id === element.id)
                    ? {
                          ...element,
                          checked: payload.checked,
                      }
                    : element
            );
            break;

        case ELEMENT.CREATE:
            draft.elements = getUnique([
                ...draft.elements.map((element) => {
                    const payloadElement = payload.elements.find(({ id }) => id === element.id);
                    return payloadElement
                        ? {
                              ...element,
                              ...payloadElement,
                              concrete: {
                                  ...(element.concrete || {}),
                                  ...(payloadElement.concrete || {}),
                              },
                          }
                        : element;
                }),
                payload.elements.find(({ IDElement }) => IDElement),
            ]);
            draft.element = draft.elements.find(({ current }) => current);
            break;

        case ELEMENT.DELETE:
            draft.elements = draft.elements.filter(
                (element) => !payload.elements.some(({ id }) => id === element.id)
            );
            draft.element = draft.elements.find(({ current }) => current);
            break;

        case ELEMENT.GET:
            draft.elements = getUnique([
                ...draft.elements.map((element) =>
                    element.id === payload.element.id
                        ? {
                              ...payload.element,
                              ...element,
                              current: payload.select || element.current,
                          }
                        : element
                ),
                { ...payload.element, current: payload.select },
            ]);
            draft.element = draft.elements.find(({ current }) => current);
            break;

        case ELEMENT.LIST:
            draft.elements = getUnique(
                [...(payload.reset ? [] : draft.elements), ...(payload.elements || [])].map(
                    (element) => {
                        const _element = draft.elements.find(({ id }) => id === element.id) || {};
                        return {
                            ..._element,
                            ...element,
                        };
                    }
                )
            );
            draft.element = draft.elements.find(({ current }) => current);
            break;

        case ELEMENT.SELECT:
            draft.elements = draft.elements.map((element) =>
                element.id === payload.element.id
                    ? { ...element, checked: true, current: true }
                    : { ...element, checked: false, current: false }
            );
            draft.element = draft.elements.find(({ current }) => current);
            break;

        case ELEMENT.UNSELECT:
            draft.elements = draft.elements.map((element) => ({ ...element, current: false }));
            draft.element = undefined;
            break;

        case ELEMENT.UPDATE:
            draft.elements = draft.elements.map((element) => {
                const payloadElement = payload.elements.find(({ id }) => id === element.id);
                return payloadElement
                    ? {
                          ...element,
                          ...payloadElement,
                          concrete: {
                              ...(element.concrete || {}),
                              ...(payloadElement.concrete || {}),
                          },
                      }
                    : element;
            });
            draft.element = draft.elements.find(({ current }) => current);
            break;

        default:
            return draft;
    }
};
