import React, { useCallback, useEffect, useRef } from 'react';
import { Tabs } from 'antd';
import { itOrSomeParentHasClass } from '../../_helpers';
import { Icon, Screen, Sortable } from '../../components';
import { ELEMENT_TYPE } from '../../context';
import { useElements, useResources, useScreens } from '../../hooks';

const { TabPane } = Tabs;

export const MainContent = ({ data }) => {
    const isSomethingFocus = useRef(false);
    const {
        closeScreen,
        detachBarFromScreen,
        screen = {},
        screens = [],
        selectScreen,
    } = useScreens();
    const {
        deleteElements,
        element = {},
        elements = [],
        selectElement,
        unselectElement = () => {},
    } = useElements();
    const { unselectResource = () => {} } = useResources();
    const { concrete = {} } = screen;
    const { IDMainElement } = concrete || {};

    const handleChangeScreen = (key) => {
        const id = parseInt(key.replace('TabScreen', ''), 10);
        selectScreen({ id });
        unselectElement();
    };

    const handleClose = (e, screen) => {
        e.stopPropagation();
        closeScreen(screen);
    };

    const handleAdd = useCallback(
        (event) => {
            const { item } = event;
            const { dataset } = item;
            const { elementId, elementType } = dataset;
            item.remove();

            if (!elementId) {
                return;
            }

            unselectElement();
            if (parseInt(elementType, 10) === ELEMENT_TYPE.BAR) {
                const id = `${screen.id},${elementId}`;
                detachBarFromScreen({ id, screen });
                return;
            }

            deleteElements([{ id: parseInt(elementId, 10) }]);
        },
        [elements]
    );

    const handleClick = useCallback(
        (event) => {
            const { target } = event;
            const isHeaderMenuOpened = document.querySelector('.dropdown-menu.show');
            const isSomeInputFocused = document.querySelector(
                'input:focus, [contenteditable]:focus'
            );
            let somethingFocus = document.querySelector(
                '.dropdown-menu.show, input:focus, [contenteditable]:focus'
            );
            if (
                !element.id ||
                isHeaderMenuOpened ||
                isSomeInputFocused ||
                isSomethingFocus.current ||
                itOrSomeParentHasClass(target, [
                    'ant-select-dropdown',
                    'ant-select-selector',
                    'ant-slider-handle',
                    'ant-tabs-tab',
                    'btn',
                    'color-picker',
                    'color-picker-bg',
                    'dropdown',
                    'dropdown-item',
                    'dropdown-menu',
                    'element-wrapper',
                    'form-control',
                    'list-group-item',
                    'modal',
                    'panel',
                ])
            ) {
                if (isSomethingFocus.current) {
                    isSomethingFocus.current = false;
                }
                if (somethingFocus) {
                    isSomethingFocus.current = true;
                }
                return;
            }
            if (somethingFocus) {
                isSomethingFocus.current = true;
            }
            if (!element.IDParentElement || element.IDParentElement === IDMainElement) {
                unselectElement();
                unselectResource();
                return;
            }
            selectElement({ id: element.IDParentElement });
        },
        [element.id, isSomethingFocus]
    );

    useEffect(() => {
        document.addEventListener('click', handleClick, false);
        return () => {
            document.removeEventListener('click', handleClick, false);
        };
    }, [element.id]);

    return (
        <Sortable
            className="main-content flex-grow-1"
            group={{ name: 'Cancel', pull: true, put: ['ContentTree', 'DragItems', 'Elements'] }}
            handle=".none"
            onAdd={handleAdd}
        >
            <Tabs activeKey={`TabScreen${screen.id}`} onChange={handleChangeScreen} type="card">
                {screens
                    .filter(({ opened }) => opened)
                    .sort((screen1, screen2) =>
                        `${screen1.Nombre}${screen1.id}` < `${screen2.Nombre}${screen2.id}` ? -1 : 1
                    )
                    .map((_screen) => (
                        <TabPane
                            tab={
                                <div className={_screen.id === screen.id ? 'active' : ''}>
                                    <strong>{_screen.Nombre}</strong>
                                    <Icon type="close" onClick={(e) => handleClose(e, _screen)} />
                                </div>
                            }
                            key={`TabScreen${_screen.id}`}
                        >
                            <div
                                className="visor"
                                data-landscape={_screen.orientation === 'landscape'}
                            >
                                <Screen {..._screen} />
                            </div>
                        </TabPane>
                    ))}
            </Tabs>
        </Sortable>
    );
};
