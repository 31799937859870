import ls from 'local-storage';
import { getUnique } from '../../_helpers';

const { recentApplications = [] } = ls('user') || {};

export const VIEWER = {
    INITIAL_STATE: {
        recentApplications,
        viewer: undefined,
    },

    ADD_SOURCE: 'VIEWER_ADD_SOURCE',
    DELETE_SOURCE: 'VIEWER_DELETE_SOURCE',
    ERROR: 'VIEWER_ERROR',
    GET: 'VIEWER_GET',
    RECENT_APPLICATIONS: 'VIEWER_RECENT_APPLICATIONS',
    SET_DEFAULT_SOURCE: 'VIEWER_SET_DEFAULT_SOURCE',
    SOURCES: 'VIEWER_SOURCES',
    SUBSCRIPTION_PREVIEW: 'VIEWER_SUBSCRIPTION_PREVIEW',
    SUBSCRIPTIONS: 'VIEWER_SUBSCRIPTIONS',
    UPDATE: 'VIEWER_UPDATE',
};

export const ViewerReducer = (draft, action) => {
    const { payload, type } = action;
    switch (type) {
        case VIEWER.ADD_SOURCE:
            draft.viewer = {
                ...draft.viewer,
                sources: getUnique([...(draft.viewer.sources || []), payload.source]),
            };
            break;

        case VIEWER.DELETE_SOURCE:
            draft.viewer = {
                ...draft.viewer,
                sources: (draft.viewer.sources || []).filter(({ id }) => id !== payload.source.id),
            };
            break;

        case VIEWER.ERROR:
            draft.error = payload.error;
            break;

        case VIEWER.GET:
            const { recentApplications = [] } = ls('user') || {};
            draft.viewer = { ...draft.viewer, ...payload.viewer };
            draft.recentApplications = recentApplications;
            delete draft.error;
            break;

        case VIEWER.RECENT_APPLICATIONS:
            draft.recentApplications = payload.recentApplications;
            break;

        case VIEWER.SET_DEFAULT_SOURCE:
            draft.viewer = {
                ...draft.viewer,
                sources: (draft.viewer.sources || []).map((source) => ({
                    ...source,
                    default: source.id === payload.source.id,
                })),
            };
            break;

        case VIEWER.SOURCES:
            draft.viewer = { ...draft.viewer, sources: payload.sources };
            break;

        case VIEWER.SUBSCRIPTION_PREVIEW:
            draft.viewer = { ...draft.viewer, preview: payload.preview };
            break;

        case VIEWER.SUBSCRIPTIONS:
            draft.viewer = { ...draft.viewer, subscriptions: payload.subscriptions };
            break;

        case VIEWER.UPDATE:
            draft.viewer = { ...draft.viewer, ...payload.viewer };
            break;

        default:
            return draft;
    }
};
