import React from 'react';
import { Slider } from '../../../components';

export const RangeProperty = ({
    decimals,
    max = 100,
    min = 0,
    onChange: handleChange,
    step = 1,
    value = 100,
}) => {
    const handleChangeValue = (value, { preview } = {}) => {
        !preview && handleChange(value);
    };

    return (
        <div>
            <Slider
                value={value}
                format={(value) => {
                    value = value !== null ? value : 0;
                    decimals =
                        typeof decimals === 'undefined'
                            ? `${step}`.length - 1 - `${step}`.indexOf('.')
                            : decimals;
                    return value.toFixed(decimals);
                }}
                max={max}
                min={min}
                onChange={handleChangeValue}
                step={step}
                input={true}
            />
        </div>
    );
};
