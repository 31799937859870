import React, { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Button, Card, CardBody, FormGroup, Input, Label } from 'reactstrap';
import ReCAPTCHA from 'react-google-recaptcha';
import { PAGES, SECRETS } from '../../_config';
import { scrollToInvalid } from '../../_helpers';
import { useUser } from '../../hooks';
import { logoWhite } from '../../assets/svg/logos';

export const ForgotPage = () => {
    const { t } = useTranslation();
    const { createRecovery } = useUser();
    const recaptchaRef = useRef();
    const recoveryForm = useRef();
    const submitted = useRef(false);
    const [state, setState] = useState({ email: '', captcha: '' });

    useEffect(() => {
        if (submitted.current) {
            createRecovery({ variables: { ...state } });
        }
    }, [state]);

    const handleSubmit = (event) => {
        event && event.preventDefault();
        if (submitted.current) {
            return;
        }

        submitted.current = true;
        const form = recoveryForm.current;

        if (form.reportValidity() === false) {
            scrollToInvalid(form);
            return;
        }

        if (!state.captcha) {
            recaptchaRef.current.execute();
        }
    };

    return (
        <main className="forgot gradient-half-secondary min-height-100vh--lg">
            <header className="position-absolute-top-left-0--lg z-index-2">
                <nav className="d-flex justify-content-between align-items-center">
                    <div className="col-lg-5 col-xl-4 text-lg-center px-0">
                        <a className="d-inline-block p-3 p-sm-5" href="/" aria-label="Mobincube">
                            <img src={logoWhite} height="50px" alt="Logo" />
                        </a>
                    </div>
                </nav>
            </header>
            <div className="min-height-100vh--lg">
                <Card className="border-0 shadow">
                    <CardBody className="p-4">
                        <form className="js-validate" onSubmit={handleSubmit} ref={recoveryForm}>
                            <div className="mb-5">
                                <h2 className="h3 text-primary font-weight-normal mb-0">
                                    {t('forgot.title')}
                                </h2>
                                <p>{t('forgot.subtitle')}</p>
                            </div>
                            <FormGroup>
                                <Label
                                    for="InputIdentifier"
                                    className="h6 small d-block text-uppercase"
                                >
                                    {t('common.email')}
                                </Label>
                                <Input
                                    id="InputIdentifier"
                                    type="email"
                                    name="email"
                                    className="p-2"
                                    data-cy="InputIdentifier"
                                    aria-describedby="emailHelp"
                                    placeholder={t('common.email_placeholder')}
                                    onChange={(event) => {
                                        const { value: email } = event.currentTarget;
                                        setState((state) => ({ ...state, email }));
                                    }}
                                    required
                                    autoFocus
                                />
                            </FormGroup>

                            <FormGroup className="mb-5">
                                <ReCAPTCHA
                                    ref={recaptchaRef}
                                    sitekey={SECRETS.GRE_CAPTCHA_SITE_KEY}
                                    size="invisible"
                                    onChange={(captcha) =>
                                        setState((state) => ({ ...state, captcha }))
                                    }
                                />
                            </FormGroup>

                            <div className="d-lg-flex justify-content-between">
                                <div>
                                    <span className="text-sm-right text-muted">
                                        {t('forgot.remember_password')}{' '}
                                        <Link to={{ pathname: PAGES.SIGN_IN }} data-cy="signin">
                                            {t('common.sign_in')}
                                        </Link>
                                    </span>
                                </div>
                                <div className="text-right pl-lg-5">
                                    <Button
                                        color="primary"
                                        data-cy="submit"
                                        className="w-100 position-relative text-uppercase font-weight-bold mt-4 mt-lg-0"
                                        disabled={submitted.current}
                                    >
                                        {t('forgot.request_reset_link')}
                                    </Button>
                                </div>
                            </div>
                        </form>
                    </CardBody>
                </Card>
            </div>
        </main>
    );
};

export default ForgotPage;
