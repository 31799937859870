import { getUnique } from '../../_helpers';

export const ADVERTISEMENT = {
    INITIAL_STATE: {
        codes: [],
        configuration: {},
        formats: [],
        providers: [],
    },

    CODES: 'ADVERTISEMENT_CODE',
    CONFIGURATION: 'ADVERTISEMENT_CONFIGURATION',
    FORMATS: 'ADVERTISEMENT_FORMATS',
    PROVIDER: 'ADVERTISEMENT_PROVIDER',
};

export const AdvertisementReducer = (draft, action) => {
    const { payload, type } = action;
    switch (type) {
        case ADVERTISEMENT.CODES:
            draft.codes = getUnique([...payload.codes, ...draft.codes]);
            break;

        case ADVERTISEMENT.CONFIGURATION:
            draft.configuration = { ...draft.configuration, ...payload.configuration };
            break;

        case ADVERTISEMENT.FORMATS:
            draft.formats = getUnique([
                ...payload.formats.map(({ format, provider, ...rest }) => ({
                    id: `${provider}-${format}`,
                    format,
                    provider,
                    ...rest,
                })),
                ...draft.formats,
            ]).sort((f1, f2) => (f1.format < f2.format ? -1 : 1));
            break;

        case ADVERTISEMENT.PROVIDER:
            break;

        case ADVERTISEMENT.RESET:
            return ADVERTISEMENT.INITIAL_STATE;

        default:
            return draft;
    }
};
