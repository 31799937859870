import { getUnique } from '../../_helpers';

export const SCREEN = {
    INITIAL_STATE: { screen: undefined, screens: [] },
    ADD_SCREEN_BAR: 'SCREEN_ADD_SCREEN_BAR',
    CHECK: 'SCREEN_CHECK',
    CLOSE: 'SCREEN_CLOSE',
    CREATE_BACKGROUND_IMAGE: 'SCREEN_CREATE_BACKGROUND_IMAGE',
    DELETE: 'SCREEN_DELETE',
    DELETE_BACKGROUND_IMAGE: 'SCREEN_DELETE_BACKGROUND_IMAGE',
    DETACH_SCREEN_BAR: 'SCREEN_DETACH_SCREEN_BAR',
    GET: 'SCREEN_GET',
    GET_CONCRETE: 'SCREEN_GET_CONCRETE',
    LIST: 'SCREEN_LIST',
    SELECT: 'SCREEN_SELECT',
    UNSELECT: 'SCREEN_UNSELECT',
    UPDATE: 'SCREEN_UPDATE',
    UPDATE_CONCRETE: 'SCREEN_UPDATE_CONCRETE',
    UPDATE_BACKGROUND_IMAGE: 'SCREEN_UPDATE_BACKGROUND_IMAGE',
};

export const ScreensReducer = (draft, action) => {
    const { payload, type } = action;

    switch (type) {
        case SCREEN.ADD_SCREEN_BAR:
            draft.screens = draft.screens.map((screen) =>
                screen.id === payload.screenBar.IDSection
                    ? {
                          ...screen,
                          section_bars: getUnique([...screen.section_bars, payload.screenBar]),
                      }
                    : screen
            );
            draft.screen = draft.screens.find(({ current }) => current);
            break;

        case SCREEN.CHECK:
            draft.screens = draft.screens.map((screen) =>
                payload.screens.some(({ id }) => id === screen.id)
                    ? {
                          ...screen,
                          checked: payload.checked,
                      }
                    : screen
            );
            draft.screen = draft.screens.find(({ current }) => current);
            break;

        case SCREEN.CLOSE:
            draft.screens = draft.screens.map((screen) =>
                screen.id === payload.screen.id
                    ? { ...screen, checked: false, current: false, opened: false }
                    : screen
            );
            const firstOpened = draft.screens.find(({ opened }) => opened);
            if (draft.screen && draft.screen.id === payload.screen.id) {
                if (firstOpened) {
                    firstOpened.checked = true;
                    firstOpened.current = true;
                    draft.screen = firstOpened;
                } else {
                    draft.screen = undefined;
                }
            }
            break;

        case SCREEN.CREATE_BACKGROUND_IMAGE:
            draft.screens = draft.screens.map((screen) =>
                screen.id === payload.screen.id
                    ? {
                          ...screen,
                          section_image: payload.backgroundImage,
                      }
                    : screen
            );
            draft.screen = draft.screens.find(({ current }) => current);
            break;

        case SCREEN.DELETE:
            draft.screens = draft.screens.filter(
                (screen) => !payload.screens.some(({ id }) => id === screen.id)
            );
            if (!draft.screens.find(({ current }) => current)) {
                draft.screens = getUnique([
                    ...draft.screens
                        .filter(({ opened }) => opened)
                        .map((screen, i) =>
                            i === 0 ? { ...screen, checked: true, current: true } : screen
                        ),
                    ...draft.screens,
                ]);
            }
            draft.screen = draft.screens.find(({ current }) => current);
            break;

        case SCREEN.DELETE_BACKGROUND_IMAGE:
            draft.screens = draft.screens.map((screen) =>
                screen.id === payload.screen.id
                    ? {
                          ...screen,
                          section_image: null,
                      }
                    : screen
            );
            draft.screen = draft.screens.find(({ current }) => current);
            break;

        case SCREEN.DETACH_SCREEN_BAR:
            draft.screens = draft.screens.map((screen) =>
                screen.id === payload.screen.id
                    ? {
                          ...screen,
                          section_bars: screen.section_bars.filter(({ id }) => id !== payload.id),
                      }
                    : screen
            );
            draft.screen = draft.screens.find(({ current }) => current);
            break;

        case SCREEN.GET:
            draft.screens = getUnique([
                ...draft.screens.map((screen) =>
                    screen.id === payload.screen.id
                        ? {
                              ...screen,
                              ...payload.screen,
                              checked: payload.select || screen.checked,
                              current: payload.select || screen.current,
                              opened: payload.select || screen.opened,
                          }
                        : {
                              ...screen,
                              checked: payload.select ? false : screen.checked,
                              current: payload.select ? false : screen.current,
                              opened: screen.opened,
                          }
                ),
                {
                    ...payload.screen,
                    checked: payload.select,
                    current: payload.select,
                    opened: payload.select,
                },
            ]);
            draft.screen = draft.screens.find(({ current }) => current);
            break;

        case SCREEN.GET_CONCRETE:
            draft.screens = draft.screens.map((screen) =>
                screen.id === payload.screen.id ? { ...screen, ...payload.screen } : screen
            );
            draft.screen = draft.screens.find(({ current }) => current);
            break;

        case SCREEN.LIST:
            draft.screens = getUnique(
                [...(payload.reset ? [] : draft.screens), ...(payload.screens || [])].map(
                    (screen) => {
                        const _screen = draft.screens.find(({ id }) => id === screen.id) || {};
                        return {
                            ..._screen,
                            ...screen,
                        };
                    }
                )
            );
            draft.screen = draft.screens.find(({ current }) => current);
            break;

        case SCREEN.SELECT:
            draft.screens = draft.screens.map((screen) =>
                screen.id === payload.screen.id
                    ? { ...screen, checked: true, current: true, opened: true }
                    : { ...screen, checked: false, current: false }
            );
            draft.screen = draft.screens.find(({ current }) => current);
            break;

        case SCREEN.UNSELECT:
            draft.screens = draft.screens.map((screen) => ({
                ...screen,
                current: false,
            }));
            draft.screen = undefined;
            break;

        case SCREEN.UPDATE:
            draft.screens = draft.screens.map((screen) =>
                screen.id === payload.screen.id ? { ...screen, ...payload.screen } : screen
            );
            draft.screen = draft.screens.find(({ current }) => current);
            break;

        case SCREEN.UPDATE_CONCRETE:
            draft.screens = draft.screens.map((screen) =>
                screen.id === payload.screen.id
                    ? { ...screen, concrete: { ...screen.concrete, ...payload.attributes } }
                    : screen
            );
            draft.screen = draft.screens.find(({ current }) => current);
            break;

        case SCREEN.UPDATE_BACKGROUND_IMAGE:
            draft.screens = draft.screens.map((screen) =>
                screen.id === payload.screen.id
                    ? {
                          ...screen,
                          section_image: { ...screen.section_image, ...payload.attributes },
                      }
                    : screen
            );
            draft.screen = draft.screens.find(({ current }) => current);
            break;

        default:
            return draft;
    }
};
