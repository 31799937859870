import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { TextEditor } from '../../../components';
import { RESOURCE_TYPE } from '../../../context';
import { useApplications, useElements, useResources } from '../../../hooks';
import { ElementWrapper } from '.';

export const Text = ({ element }) => {
    const textRef = useRef();
    const { application = {} } = useApplications();
    const { updateElement } = useElements();
    const { createResource, getTextContent, resources = [], updateResource } = useResources();
    const { idCurrentLang } = application;
    const { concrete = {}, hAlignChild: textAlign } = element;
    const { FontColor, fontSize, IDText } = concrete;
    const color = `#${FontColor || '000000'}`;
    const textRes = resources.find(({ id }) => id === IDText) || {};
    const { online } = textRes;
    const data = {};
    const style = { color, textAlign };
    const initialText = (textRes.content && textRes.content[`lang${idCurrentLang}`]) || null;
    const [text, setText] = useState(initialText);

    if (isNaN(fontSize)) {
        data['data-font-size'] = fontSize;
    } else {
        style.fontSize = `${fontSize}pt`;
    }

    useEffect(() => {
        if (!textRes.id) {
            createResource({
                type: RESOURCE_TYPE.TEXT,
                online: 0,
                content: { [`lang${idCurrentLang}`]: '' },
            }).then((resource) => {
                updateElement({ concrete: { IDText: resource.id } }, element);
                setText('');
                textRef.current && textRef.current.focus();
            });
        }
    }, [textRes.id]);

    useMemo(() => {
        let mounted = true;
        if (!textRes.id) {
            return;
        }
        getTextContent(textRes).then(async (content) => {
            if (content) {
                let text = typeof content === 'string' ? content : content[`lang${idCurrentLang}`];
                if (online) {
                    try {
                        text = await fetch(text);
                    } catch (error) {
                        console.error(error);
                    }
                }
                mounted && setText(text);
            }
        });

        return () => {
            mounted = false;
        };
    }, [idCurrentLang, textRes.id, application.refreshed]);

    const handleBlur = useCallback(
        (text) => {
            const { content = {} } = textRes;
            setText(text);
            updateResource({ content: { ...content, [`lang${idCurrentLang}`]: text } }, textRes);
        },
        [idCurrentLang, textRes.id]
    );

    return (
        <ElementWrapper data={data} element={element} style={style}>
            <div className="element-content">
                {text !== null &&
                    ((online && text) || (
                        <TextEditor onBlur={handleBlur} value={text} extRef={textRef} />
                    ))}
            </div>
        </ElementWrapper>
    );
};
