import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import debounce from 'just-debounce-it';
import parse from 'html-react-parser';
import { Empty, Menu, Popconfirm, Tooltip } from 'antd';
import {
    Badge,
    Button,
    Card,
    CardBody,
    CardDeck,
    CardTitle,
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    FormGroup,
    Input,
    Label,
    ModalBody,
    ModalFooter,
    UncontrolledDropdown,
} from 'reactstrap';
import { MODALS } from '../_config';
import { getMimeType, getUniqueName } from '../_helpers';
import { Icon, Modal, Resource, Search } from '../components';
import { ELEMENT_TYPE, RESOURCE_TYPE } from '../context';
import {
    useApplications,
    useElements,
    useModals,
    useNearScreen,
    useResources,
    useScreens,
    useUnsplash,
} from '../hooks';

const CloudResources = () => {
    return null;
};
const LocalResources = ({ folder = null, query = '' } = {}) => {
    const { t } = useTranslation();
    const externalRef = useRef();
    const { filter = () => true } = useModals();
    const { loading, resources = [], selectResource, setPage = () => {} } = useResources();
    const filteredResources = resources
        .filter(({ type }) => type !== RESOURCE_TYPE.TEXT)
        .filter(({ idParentFolder, idFolder = null }) =>
            typeof idParentFolder !== 'undefined' ? idParentFolder === folder : idFolder === folder
        )
        .filter(({ createdFromModule }) => !createdFromModule)
        .filter(filter)
        .filter(({ name = '' }) => name.toLowerCase().includes(query.toLowerCase()));
    const { isNearScreen } = useNearScreen({
        externalRef: loading ? null : externalRef,
        once: false,
    });

    const debounceHandleNextPage = useCallback(
        debounce(() => setPage((prevPage) => prevPage + 1), 200),
        [setPage]
    );

    useEffect(() => {
        folder && selectResource({ id: folder });
    }, [folder]);

    useEffect(
        function() {
            if (isNearScreen) debounceHandleNextPage();
        },
        [debounceHandleNextPage, isNearScreen]
    );

    return (
        <CardDeck className="flex-wrap" data-count={filteredResources.length}>
            {!filteredResources.length && <Empty description={t('resources.no_results_found')} />}
            {filteredResources
                .sort((r1, r2) => (r1.name < r2.name ? -1 : 1))
                .map((res) => (
                    <Resource
                        key={`${res.id}`}
                        className={`${res.current ? 'resource--current' : ''}${
                            res.checked ? ' resource--checked' : ''
                        }`}
                        {...res}
                    />
                ))}
            <div className="w-100" ref={externalRef} />
        </CardDeck>
    );
};

const Photo = ({ className, query, ...photo }) => {
    const { t } = useTranslation();
    const { updateElement } = useElements();
    const { onClick: handleClick, target } = useModals();
    const { createScreenBackgroundImage, updateScreenBackgroundImage } = useScreens();
    const { checkPhoto } = useUnsplash();
    const { createResource, resources = [], selectResource, setLoading } = useResources();
    const filesName = resources.map(({ name }) => name);
    const { alt_description: altDescription, urls, user } = photo;
    const { thumb, regular } = urls;
    const {
        links: { html: link },
        name,
    } = user;

    const handlePhotoClick = useCallback(async () => {
        if (!handleClick && !target) {
            checkPhoto(photo, { checked: !photo.checked });
            return;
        }

        setLoading(true);
        fetch(regular)
            .then((response) => response.blob())
            .then((blob) => {
                const reader = new FileReader();
                reader.onload = async (e) => {
                    const fileContent = e.target.result.replace(/[^;]*;base64,/, '');
                    createResource({
                        content: fileContent,
                        name: getUniqueName(`${altDescription}`, { names: filesName }),
                        online: 0,
                        type: RESOURCE_TYPE.IMAGE,
                    }).then((resource) => {
                        if (handleClick) {
                            handleClick(resource);
                            return;
                        }
                        selectResource(resource);
                        const { id } = resource;
                        if (target.id === target.IDSection) {
                            (target.section_image &&
                                updateScreenBackgroundImage({ IDImage: id }, target)) ||
                                createScreenBackgroundImage({ IDImage: id }, target);
                        } else {
                            updateElement({ concrete: { IDImage: id } });
                        }
                    });
                };
                reader.readAsDataURL(blob);
            });

        document.querySelector('.modal .close').click();
    }, [photo, query]);

    return (
        <Card
            className={`resource shadow-sm${className ? ` ${className}` : ''}`}
            onClick={handlePhotoClick}
        >
            <Tooltip mouseEnterDelay={1} placement="top" title={altDescription}>
                <div
                    className="card-img-top icon"
                    style={{ backgroundImage: `url(${thumb})` }}
                ></div>
            </Tooltip>
            <CardBody>
                <CardTitle>{parse(t('resources.unsplash_footer', { link, name }))}</CardTitle>
            </CardBody>
        </Card>
    );
};

const UnsplashResources = ({ query }) => {
    const { t } = useTranslation();
    const { application = {} } = useApplications();
    const externalRef = useRef();
    const { loading, photos = [], search, setPage } = useUnsplash();
    const { isNearScreen } = useNearScreen({
        externalRef: loading ? null : externalRef,
        once: false,
    });

    const debounceHandleNextPage = useCallback(
        debounce(() => setPage((prevPage) => prevPage + 1), 200),
        [setPage]
    );

    useEffect(
        function() {
            if (isNearScreen) debounceHandleNextPage();
        },
        [debounceHandleNextPage, isNearScreen]
    );

    useEffect(() => {
        setPage(1);
        search({ value: query || application.Nombre });
    }, [query]);

    return (
        <CardDeck className="unsplash flex-wrap" data-count={photos.length}>
            {!photos.length && <Empty description={t('resources.no_results_found')} />}
            {photos.map((photo) => (
                <Photo
                    key={`UnsplashPhoto-${photo.id}`}
                    query={query}
                    className={`${photo.checked ? ' resource--checked' : ''}`}
                    {...photo}
                />
            ))}
            <div className="w-100" ref={externalRef} />
        </CardDeck>
    );
};

const ResourcesContent = ({ content, query }) => {
    switch (content) {
        case 'local':
            return <LocalResources query={query} />;
        case 'cloud':
            return <CloudResources />;
        case 'unsplash':
            return <UnsplashResources query={query} />;
        default:
            content = parseInt(content.replace('folder', ''), 10);
            return <LocalResources folder={content} />;
    }
};

export const Resources = ({ isOpen: isOpenProp }) => {
    const { t } = useTranslation();
    const [isOpen, setIsOpen] = useState(isOpenProp);
    const [isFolderNameModalOpened, openFolderNameModal] = useState(false);
    const [isFileUrlModalOpened, openFileUrlModal] = useState(false);
    const inputFile = useRef();
    const editInputFile = useRef();
    const { application = {} } = useApplications();
    const [query, setQuery] = useState('');
    const { key = 'local', modal, setModal, target = {} } = useModals();
    const {
        createResource,
        deleteResources,
        resource = {},
        resources = [],
        setLoading,
        uncheckAllResources,
        unselectResource,
        updateResource,
    } = useResources();
    const { photos = [] } = useUnsplash();
    const [updated, updateMenu] = useState(0);
    const [content, setContent] = useState(key);
    const checkedResources = resources.filter(({ checked }) => checked);
    const checkedPhotos = photos.filter(({ checked }) => checked);
    const filesName = resources.map(({ name }) => name);
    const folders = resources.filter(({ type }) => type === RESOURCE_TYPE.FOLDER);
    const folder = resource.type === RESOURCE_TYPE.FOLDER ? resource : {};

    const getMenuFolder = ({ parent = {} } = {}) => {
        const { idFolder: parentId = null } = parent;
        const folders = resources
            .filter(({ type }) => type === RESOURCE_TYPE.FOLDER)
            .filter(({ idParentFolder }) => idParentFolder === parentId);

        if (!folders.length) {
            return null;
        }

        return folders.map((folder) => {
            const { idFolder, name } = folder;
            return (
                <Menu.SubMenu key={`folder${idFolder}`} title={name}>
                    <Menu.Item key={`folder${idFolder}`}>{t('resources.title')}</Menu.Item>
                    {getMenuFolder({ parent: folder })}
                </Menu.SubMenu>
            );
        });
    };

    const getFolderName = () =>
        new Promise((resolve, reject) => {
            openFolderNameModal(true);
            setTimeout(() => {
                const input = document.getElementById('FolderName');
                document.getElementById('NameAccept').onclick = () => {
                    if (!input.reportValidity()) {
                        return;
                    }
                    openFolderNameModal(false);
                    const { value: name } = input;
                    resolve(name);
                };
                document.querySelector('.modal .name-modal .close').onclick = () => {
                    reject();
                };
            }, 500);
        });

    const getFileUrl = () =>
        new Promise((resolve, reject) => {
            openFileUrlModal(true);
            setTimeout(() => {
                const input = document.getElementById('FileUrl');
                document.getElementById('UrlAccept').onclick = () => {
                    if (!input.reportValidity()) {
                        return;
                    }
                    openFileUrlModal(false);
                    const { value: url } = input;
                    resolve(url);
                };
                document.querySelector('.modal .url-modal .close').onclick = () => {
                    reject();
                };
            }, 500);
        });

    const handleCreateFolderClick = async () => {
        let idParentFolder = null;
        if (resource.type === RESOURCE_TYPE.FOLDER) {
            idParentFolder = resource.id;
        }
        let name = '';
        try {
            name = await getFolderName();
        } catch (e) {
            return;
        }
        createResource({ idParentFolder, name, type: RESOURCE_TYPE.FOLDER });
    };

    const handleSearch = (event) => {
        setQuery(event.currentTarget.value);
    };

    const handleUploadFromComputerClick = useCallback(
        (event) => {
            inputFile.current.click();
        },
        [inputFile]
    );

    const handleUploadFromComputerChange = (event) => {
        const { currentTarget } = event;
        const { files } = currentTarget;
        const filesToUpload = Object.values(files).map((file) => {
            let { name } = file;
            return { file, name };
        });
        filesToUpload.forEach(({ file, name }) => {
            let { type } = file;
            type = type.split('/')[0];
            type = Object.values(RESOURCE_TYPE).includes(type) ? type : RESOURCE_TYPE.BASE;
            const reader = new FileReader();
            reader.onload = async (e) => {
                const fileContent = e.target.result.replace(/[^;]*;base64,/, '');
                createResource({
                    content: fileContent,
                    idFolder: folder.id,
                    name: getUniqueName(name, { names: filesName }),
                    online: 0,
                    type,
                });
            };
            reader.readAsDataURL(file);
        });
    };

    const handleUploadFromUrlClick = async () => {
        let url = '';
        try {
            url = await getFileUrl();
        } catch (e) {
            return;
        }
        const name = url.split('/').pop();
        const ext = name.split('.').pop();
        let type = getMimeType(ext);
        type = type.split('/')[0];
        type = Object.values(RESOURCE_TYPE).includes(type) ? type : RESOURCE_TYPE.BASE;

        createResource({
            content: url,
            idFolder: folder.id,
            Filename: url,
            name,
            online: 1,
            type,
        });
    };

    const handleAddClick = useCallback(async () => {
        setLoading(true);
        await Promise.all(
            checkedPhotos.map((photo) => {
                const { alt_description: altDescription, urls } = photo;
                const { regular } = urls;
                return new Promise((resolve) =>
                    fetch(regular)
                        .then((response) => response.blob())
                        .then((blob) => {
                            const reader = new FileReader();
                            reader.onload = async (e) => {
                                const fileContent = e.target.result.replace(/[^;]*;base64,/, '');
                                createResource({
                                    content: fileContent,
                                    name: getUniqueName(
                                        `${altDescription || query || application.Nombre}`,
                                        {
                                            names: filesName,
                                        }
                                    ),
                                    online: 0,
                                    type: RESOURCE_TYPE.IMAGE,
                                }).then(() => {
                                    setLoading(true);
                                    resolve();
                                });
                            };
                            reader.readAsDataURL(blob);
                        })
                );
            })
        );
        setLoading(false);
        setQuery('');
        setContent('local');
        updateMenu(Date.now());
    }, [checkedPhotos, content]);

    const handleEditFromComputerClick = useCallback(
        (event) => {
            editInputFile.current.click();
        },
        [editInputFile]
    );

    const handleEditFromComputerChange = useCallback(
        (event) => {
            const { currentTarget } = event;
            const { files } = currentTarget;
            const filesToUpload = Object.values(files).map((file) => {
                let { name } = file;
                return { file, name };
            });
            filesToUpload.forEach(({ file, name }) => {
                const reader = new FileReader();
                reader.onload = async (e) => {
                    const fileContent = e.target.result.replace(/[^;]*;base64,/, '');
                    updateResource(
                        {
                            blobUrl: undefined,
                            content: fileContent,
                            name,
                            online: 0,
                        },
                        checkedResources[0]
                    );
                };
                reader.readAsDataURL(file);
            });
        },
        [checkedResources]
    );

    const handleEditFromUrlClick = useCallback(async () => {
        let url = '';
        try {
            url = await getFileUrl();
        } catch (e) {
            return;
        }
        const name = url.split('/').pop();

        updateResource(
            {
                blobUrl: undefined,
                content: url,
                Filename: url,
                name,
                online: 1,
            },
            checkedResources[0]
        );
    }, [checkedResources]);

    const handleMenuClick = ({ key }) => {
        uncheckAllResources();
        setContent(key);
    };

    useEffect(() => {
        setIsOpen(modal === MODALS.RESOURCES);
    }, [modal]);

    return (
        <>
            <Modal
                className="resources modal-menu max-80"
                isOpen={isOpen}
                title={
                    <>
                        <span>{t('resources.title')}</span>
                        {!!checkedResources.length && (
                            <Badge className="ml-2">{checkedResources.length}</Badge>
                        )}
                    </>
                }
                actions={
                    <>
                        <Search icon={false} onBlur={handleSearch} visible={true} />
                        {(content !== 'unsplash' && (
                            <UncontrolledDropdown>
                                <DropdownToggle disabled={!application.id}>
                                    <Tooltip
                                        key="AddResource"
                                        placement="bottom"
                                        title={t('resources.add_resource')}
                                    >
                                        <Icon type="plus" />
                                    </Tooltip>
                                    <span>{t('resources.add_resource')}</span>
                                </DropdownToggle>
                                <DropdownMenu
                                    right
                                    className={`shadow${content === 'unsplash' ? ' d-none' : ''}`}
                                >
                                    <DropdownItem onClick={handleCreateFolderClick}>
                                        <span>{t('resources.create_folder')}</span>
                                    </DropdownItem>
                                    <DropdownItem onClick={handleUploadFromComputerClick}>
                                        <span>{t('resources.upload_from_computer')}</span>
                                    </DropdownItem>
                                    <DropdownItem onClick={handleUploadFromUrlClick}>
                                        <span>{t('resources.upload_from_url')}</span>
                                    </DropdownItem>
                                </DropdownMenu>
                            </UncontrolledDropdown>
                        )) || (
                            <DropdownItem disabled={!checkedPhotos.length} onClick={handleAddClick}>
                                <Icon type="plus" />
                                <span>{t('resources.add_resource')}</span>
                            </DropdownItem>
                        )}
                        <UncontrolledDropdown>
                            <DropdownToggle disabled={checkedResources.length !== 1}>
                                <Tooltip
                                    key="EditResource"
                                    placement="bottom"
                                    title={t('resources.edit_resource')}
                                >
                                    <Icon type="pencil" />
                                </Tooltip>
                                <span>{t('resources.edit_resource')}</span>
                            </DropdownToggle>
                            <DropdownMenu
                                right
                                className={`shadow${content === 'unsplash' ? ' d-none' : ''}`}
                            >
                                <DropdownItem onClick={handleEditFromComputerClick}>
                                    <span>{t('resources.upload_from_computer')}</span>
                                </DropdownItem>
                                <DropdownItem onClick={handleEditFromUrlClick}>
                                    <span>{t('resources.upload_from_url')}</span>
                                </DropdownItem>
                            </DropdownMenu>
                        </UncontrolledDropdown>
                        <Popconfirm
                            title={t('resources.delete_confirm', {
                                count: checkedResources.length,
                                name: (checkedResources[0] || {}).name,
                            })}
                            onConfirm={() => deleteResources(checkedResources)}
                            onCancel={() => {}}
                            okText={t('common.yes')}
                            cancelText={t('common.no')}
                        >
                            <Button disabled={!checkedResources.length}>
                                <Icon type="delete" />
                                <span>{t('resources.delete_resource')}</span>
                            </Button>
                        </Popconfirm>
                    </>
                }
                onClosed={() => {
                    !target.id && unselectResource();
                    setModal(null);
                }}
            >
                <ModalBody>
                    <Menu
                        key={`ResourcesMenu${updated}`}
                        className="w-auto"
                        mode="inline"
                        onClick={handleMenuClick}
                        defaultSelectedKeys={[content]}
                    >
                        {folders.length ? (
                            <Menu.SubMenu title={t('header.local_resources')}>
                                <Menu.Item key="local">{t('resources.title')}</Menu.Item>
                                {getMenuFolder()}
                            </Menu.SubMenu>
                        ) : (
                            <Menu.Item key="local">{t('header.local_resources')}</Menu.Item>
                        )}
                        {(!target.id ||
                            target.id === target.IDSection ||
                            target.IDElementType === ELEMENT_TYPE.IMAGE) && (
                            <Menu.Item key="unsplash">{t('header.unsplash')}</Menu.Item>
                        )}
                    </Menu>
                    <ModalBody data-key={content}>
                        <ResourcesContent content={content} query={query} />
                    </ModalBody>
                </ModalBody>
            </Modal>
            <Modal
                className="name-modal"
                isOpen={!!isFolderNameModalOpened}
                onClosed={() => openFolderNameModal(false)}
                title={t('resources.create_folder')}
            >
                <ModalBody>
                    <FormGroup>
                        <Label className="h6 small d-block text-uppercase">
                            {t('common.name')}
                        </Label>
                        <Input id="FolderName" autoFocus required />
                    </FormGroup>
                </ModalBody>
                <ModalFooter>
                    <Button id="NameAccept">{t('common.accept')}</Button>
                </ModalFooter>
            </Modal>
            <input
                ref={inputFile}
                className="d-none"
                type="file"
                multiple
                onChange={handleUploadFromComputerChange}
            />
            <input
                ref={editInputFile}
                className="d-none"
                type="file"
                multiple
                onChange={handleEditFromComputerChange}
            />
            <Modal
                className="url-modal"
                isOpen={!!isFileUrlModalOpened}
                onClosed={() => openFileUrlModal(false)}
                title={t('resources.upload_from_url')}
            >
                <ModalBody>
                    <FormGroup>
                        <Label className="h6 small d-block text-uppercase">{t('common.url')}</Label>
                        <Input id="FileUrl" autoFocus required />
                    </FormGroup>
                </ModalBody>
                <ModalFooter>
                    <Button id="UrlAccept">{t('common.accept')}</Button>
                </ModalFooter>
            </Modal>
        </>
    );
};
