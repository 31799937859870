import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Popconfirm } from 'antd';
import { Sortable } from '../../../components';
import { DRAG_ITEM_TYPE, getHeaders, SCREEN_TYPE, SCREEN_TYPE_NAME } from '../../../context';
import { useDragItems, useElements, useResources, useScreens } from '../../../hooks';
import { BODY_TYPE, Element } from '..';
import {
    ActionImage,
    Calendar,
    Dataview,
    Feed,
    Info,
    Map,
    OptionList,
    Splash,
    Undefined,
    Web,
} from '.';

const ScreenImage = (props) => {
    const screenImage = useRef();
    const { resources = [] } = useResources();
    const { hAlign, IDImage, vAlign, width } = props;
    const imageRes = resources.find(({ id }) => id === IDImage);

    useEffect(() => {
        let mounted = true;
        if (!imageRes) {
            screenImage.current.style.backgroundImage = '';
            return;
        }
        if (imageRes.online) {
            screenImage.current.style.backgroundImage = `url(${imageRes.url})`;
            return;
        }

        fetch(imageRes.url, {
            headers: getHeaders({ auth: true }),
        })
            .then(async (response) => {
                const image = await response.blob();
                return response.ok ? image : Promise.reject();
            })
            .then((blob) => {
                if (mounted) {
                    const url = window.URL.createObjectURL(blob);
                    screenImage.current.style.backgroundImage = `url(${url})`;
                }
            })
            .catch((err) => {});
        return () => {
            mounted = false;
        };
    }, [screenImage.current, imageRes]);

    return (
        <div
            className="screen-image"
            data-halign={hAlign}
            data-valign={vAlign}
            ref={screenImage}
            style={{ backgroundSize: `${width}%` }}
        ></div>
    );
};

const ScreenBars = ({ position }) => {
    const { screen = {} } = useScreens();
    const { elements = [] } = useElements();
    const { section_bars: screenBars = [] } = screen;
    const sortableData = {
        'data-bars': true,
        'data-position': position,
        'data-not-accept': `${DRAG_ITEM_TYPE.SCREEN},${BODY_TYPE.VERTICAL}`,
    };
    const filteredScreenBars = screenBars
        .filter(({ position: p }) => p === position)
        .sort((bar1, bar2) => (bar1.sequence < bar2.sequence ? -1 : 1));
    const filteredBars = elements.filter((element) =>
        filteredScreenBars.some(({ IDElement }) => IDElement === element.id)
    );
    return (
        <Sortable
            key={`BarsContent-${position}-${filteredBars
                .map(({ lastUpdated }) => lastUpdated)
                .join('-')}}`}
            className="bar-wrapper"
            group={{ name: 'Elements', pull: true, put: ['DragItems', 'Elements'] }}
            data={sortableData}
        >
            {filteredScreenBars.map(({ id, IDElement, IDSection }) => {
                const element = elements.find(({ id }) => id === IDElement) || {};
                return <Element key={`Bar-${id}`} element={element} screenId={IDSection} />;
            })}
        </Sortable>
    );
};

export const ScreenWrapper = ({ children, ...screen }) => {
    const { t } = useTranslation();
    const { getDragItem } = useDragItems();
    const { createElement, selectElement } = useElements();
    const { createResource } = useResources();
    const { createConcreteScreen, updateConcreteScreen } = useScreens();
    const [itemToAdd, setItemToAdd] = useState({});
    const { title } = itemToAdd;
    const {
        Background: backgroundColor,
        id,
        IDTipoSeccion: type,
        section_image: screenImage = {},
    } = screen;

    const style = {
        backgroundColor: `#${backgroundColor}`,
    };

    const handleVisibleChange = (visible) => {
        !visible && !!itemToAdd.id && setItemToAdd({});
    };

    const handleAdd = (event) => {
        const { item } = event;
        let { dataset = {} } = item;
        let { id } = dataset;
        item.remove();
        if (!id) {
            return;
        }
        id = parseInt(id, 10);
        const dragItem = getDragItem(id);

        if (type === SCREEN_TYPE.UNDEFINED) {
            handleAddConfirm(dragItem);
            return;
        }

        setItemToAdd(dragItem);
    };

    const handleAddConfirm = useCallback(
        async (dragItem = itemToAdd) => {
            const { attributes, type } = dragItem;
            if (type === DRAG_ITEM_TYPE.SCREEN) {
                const { concrete = {} } = attributes;
                const { content, idModule, name } = concrete;
                const screen = await createConcreteScreen(attributes);
                if (idModule) {
                    const resource = await createResource({
                        content,
                        createdFromModule: true,
                        idModule,
                        name,
                        online: 0,
                        type: 'zip',
                    });
                    resource && updateConcreteScreen({ idZip: resource.id }, screen);
                }
                return;
            }
            createConcreteScreen({ typeName: SCREEN_TYPE_NAME.INFO })
                .then(({ concrete: { IDMainElement } = {} } = {}) => {
                    if (!IDMainElement) {
                        return;
                    }
                    return createElement({
                        ...attributes,
                        IDParentElement: IDMainElement,
                        Sequence: 1,
                        id: `c${Date.now()}`,
                    });
                })
                .then((element) => selectElement(element));
        },
        [itemToAdd]
    );

    return (
        <div className="screen-wrapper" data-screen-id={id} data-screen-type={type} style={style}>
            <ScreenBars position="top" />
            <ScreenBars position="bottom" />
            <Popconfirm
                title={t('screens.confirm_lose_current_content', { title })}
                visible={!!itemToAdd.id}
                onConfirm={() => handleAddConfirm(itemToAdd)}
                onCancel={() => setItemToAdd({})}
                onVisibleChange={handleVisibleChange}
                okText={t('common.yes')}
                cancelText={t('common.no')}
            >
                <div className="screen-main-content">
                    <Sortable
                        className="drop-zone"
                        group={{ name: 'Viewport', pull: false, put: ['DragItems'] }}
                        handle=".none"
                        onAdd={handleAdd}
                        data={{
                            'data-accept': `${DRAG_ITEM_TYPE.ELEMENT},${DRAG_ITEM_TYPE.SCREEN}`,
                        }}
                    ></Sortable>
                    {children}
                </div>
            </Popconfirm>
            {screenImage && <ScreenImage {...screenImage} />}
        </div>
    );
};

export const Screen = (props) => {
    const { IDTipoSeccion: type } = props;

    switch (type) {
        case SCREEN_TYPE.UNDEFINED:
            return <Undefined {...props} />;
        case SCREEN_TYPE.OPTIONLIST:
            return <OptionList {...props} />;
        case SCREEN_TYPE.ACTIONIMAGE:
            return <ActionImage {...props} />;
        case SCREEN_TYPE.INFO:
            return <Info {...props} />;
        case SCREEN_TYPE.CALENDAR:
            return <Calendar {...props} />;
        case SCREEN_TYPE.FEED:
            return <Feed {...props} />;
        case SCREEN_TYPE.MAP:
            return <Map {...props} />;
        case SCREEN_TYPE.DATAVIEW:
            return <Dataview {...props} />;
        case SCREEN_TYPE.WEB:
        case SCREEN_TYPE.COUPON:
        case SCREEN_TYPE.RICHWEB:
            return <Web {...props} />;
        case SCREEN_TYPE.SPLASH:
            return <Splash {...props} />;
        default:
            return <Undefined {...props} />;
    }
};
