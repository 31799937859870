import React, { useCallback, useEffect, useRef, useState } from 'react';
import parse from 'html-react-parser';
import { useTranslation } from 'react-i18next';
import { Empty, Menu } from 'antd';
import {
    Button,
    Card,
    CardBody,
    CardDeck,
    CardFooter,
    CardHeader,
    CardTitle,
    FormGroup,
    Input,
    InputGroup,
    InputGroupAddon,
    Label,
    ModalBody,
} from 'reactstrap';
import { MODALS } from '../_config';
import { Icon, Modal } from '../components';
import { SERVICE_TYPE } from '../context';
import { useApplications, useModals, useViewer } from '../hooks';

const AppId = () => {
    const { t } = useTranslation();
    const {
        application = {},
        getApplicationSettings,
        updateApplicationSettings,
    } = useApplications();
    const { openModal } = useModals();
    const { isAllowed } = useViewer();
    const [isChangeVendorAllowed, setChangeVendorAllowed] = useState(false);
    const { settings = {} } = application;
    const { appstore = {}, playstore = {} } = settings;
    let { bundleId = '' } = appstore;
    bundleId = bundleId || '';
    let { packageName = '' } = playstore;
    packageName = packageName || '';

    const handleBlur = useCallback(
        (event) => {
            const { currentTarget } = event;
            let { dataset, name, value } = currentTarget;
            const { setting } = dataset;
            if (!isChangeVendorAllowed) {
                value = `${t('app_settings.com_mobincube')}.${value}`;
            }
            updateApplicationSettings({ setting, value: { [name]: value } }, application);
        },
        [application, isChangeVendorAllowed]
    );

    useEffect(() => {
        if (!bundleId || !packageName) {
            getApplicationSettings({ application, settings: ['appstore', 'playstore'] });
        }
        (async () => {
            const isChangeVendorAllowed = await isAllowed(SERVICE_TYPE.PRIVATE_BRAND);
            setChangeVendorAllowed(isChangeVendorAllowed);
        })();
    }, []);

    return (
        <>
            <div className="mb-3">{t('app_settings.app_id_description')}</div>
            <div className="mb-5">{parse(t('app_settings.package_name_rules'))}</div>
            <FormGroup>
                <Label className="h6 small d-block text-muted text-uppercase">
                    {t('app_settings.package_name_android')}
                </Label>
                {(isChangeVendorAllowed && (
                    <Input
                        type="text"
                        name="packageName"
                        data-setting="playstore"
                        defaultValue={packageName}
                        onBlur={handleBlur}
                        placeholder="xxx.xxxx.123"
                    />
                )) || (
                    <InputGroup>
                        <InputGroupAddon
                            addonType="prepend"
                            onClick={() => openModal(MODALS.UPGRADE, { back: MODALS.APP_INFO })}
                        >
                            {`${t('app_settings.com_mobincube')}.`}
                        </InputGroupAddon>
                        <Input
                            type="text"
                            name="packageName"
                            data-setting="playstore"
                            defaultValue={packageName.replace(
                                `${t('app_settings.com_mobincube')}.`,
                                ''
                            )}
                            onBlur={handleBlur}
                            placeholder="xxxx.123"
                        />
                    </InputGroup>
                )}
            </FormGroup>
            <FormGroup>
                <Label className="h6 small d-block text-muted text-uppercase">
                    {t('app_settings.bundle_id_ios')}
                </Label>
                {(isChangeVendorAllowed && (
                    <Input
                        type="text"
                        name="bundleId"
                        data-setting="appstore"
                        defaultValue={bundleId}
                        onBlur={handleBlur}
                        placeholder="xxx.xxxx.123"
                    />
                )) || (
                    <InputGroup>
                        <InputGroupAddon
                            addonType="prepend"
                            onClick={() => openModal(MODALS.UPGRADE, { back: MODALS.APP_INFO })}
                        >
                            {`${t('app_settings.com_mobincube')}.`}
                        </InputGroupAddon>
                        <Input
                            type="text"
                            name="bundleId"
                            data-setting="appstore"
                            defaultValue={bundleId.replace(
                                `${t('app_settings.com_mobincube')}.`,
                                ''
                            )}
                            onBlur={handleBlur}
                            placeholder="xxxx.123"
                        />
                    </InputGroup>
                )}
            </FormGroup>
        </>
    );
};

export const Certificates = () => {
    const { t } = useTranslation();
    const {
        application = {},
        getApplicationSettings,
        updateApplicationSettings,
    } = useApplications();
    const { settings = {} } = application;
    const { appstore } = settings;
    const { p12, signature, url } = appstore || {};

    const handleClick = (event) => {
        const { currentTarget } = event;
        const { nextElementSibling } = currentTarget;
        nextElementSibling.click();
    };

    const handleChange = (event) => {
        const { currentTarget } = event;
        const { dataset, files = [], name } = currentTarget;

        if (!files.length) {
            return;
        }

        const { setting } = dataset;
        updateApplicationSettings({ setting, value: { [name]: files[0] } }, application);
    };

    const handleBlur = (event) => {
        const { currentTarget } = event;
        const { dataset, name, value } = currentTarget;
        const { setting } = dataset;
        updateApplicationSettings({ setting, value: { [name]: value } }, application);
    };

    useEffect(() => {
        if (!appstore) {
            getApplicationSettings({ application, settings: ['appstore'] });
        }
    }, []);

    return (
        <>
            <div className="mb-3">{t('app_settings.certificates_description')}</div>
            <a
                className="d-inline-block mb-3"
                href="https://support.mobincube.com/hc/en-us/articles/200511933#provisioning"
                target="_blank"
                rel="noopener noreferrer"
            >
                {t('app_settings.how_to_get_the_p12_file')}
            </a>
            <CardDeck className="mt-3">
                <Card>
                    <CardHeader>
                        <CardTitle>p12</CardTitle>
                        <small className="text-muted">certificate.p12</small>
                    </CardHeader>
                    <CardBody>
                        {p12 ? (
                            <Empty
                                image={<Icon className="icon-success" type="file-check" />}
                                description={t('tools.file_uploaded')}
                            />
                        ) : (
                            <Empty description={t('tools.please_upload_file')} />
                        )}
                    </CardBody>
                    <CardFooter>
                        <Button color="link" onClick={handleClick}>
                            {t('tools.upload')}
                        </Button>
                        <input
                            className="d-none"
                            type="file"
                            name="p12"
                            data-setting="appstore"
                            onChange={handleChange}
                            accept=".p12"
                        />
                    </CardFooter>
                </Card>
                <Card>
                    <CardHeader>
                        <CardTitle>Provisioning profile</CardTitle>
                        <small className="text-muted">provisioning.mobileprovision</small>
                    </CardHeader>
                    <CardBody>
                        {signature ? (
                            <Empty
                                image={<Icon className="icon-success" type="file-check" />}
                                description={t('tools.file_uploaded')}
                            />
                        ) : (
                            <Empty description={t('tools.please_upload_file')} />
                        )}
                    </CardBody>
                    <CardFooter>
                        <Button color="link" onClick={handleClick}>
                            {t('tools.upload')}
                        </Button>
                        <input
                            className="d-none"
                            type="file"
                            name="signature"
                            data-setting="appstore"
                            onChange={handleChange}
                            accept=".mobileprovision"
                        />
                    </CardFooter>
                </Card>
            </CardDeck>
            <div className="mb-3">{t('app_settings.redirects_description')}</div>
            <div>
                <FormGroup>
                    <Label className="h6 small d-block text-muted text-uppercase">
                        {t('common.url')}
                    </Label>
                    <Input
                        type="text"
                        name="url"
                        data-setting="appstore"
                        defaultValue={url}
                        onBlur={handleBlur}
                        placeholder="https://itunes.apple.com/XX/app/XXX/XXXX"
                    />
                </FormGroup>
            </div>
        </>
    );
};

const UploadKey = () => {
    const { t } = useTranslation();
    const {
        application = {},
        getApplicationSettings,
        updateApplicationSettings,
    } = useApplications();
    const passwordInput = useRef();
    const [value, setValue] = useState({});
    const { keystore, keystorePassword } = value;
    const { settings = {} } = application;
    const { playstore } = settings;
    const { hasCertificate } = playstore || {};

    const handleClick = (event) => {
        const { currentTarget } = event;
        const { nextElementSibling } = currentTarget;
        nextElementSibling.click();
    };

    const handleChangeFile = (event) => {
        const { currentTarget } = event;
        const { files = [], name } = currentTarget;
        if (!files[0]) {
            return;
        }
        setValue((v) => ({ ...v, [name]: files[0] }));
    };

    const handleKeydownPassword = (event) => {
        const { currentTarget, key } = event;
        if (key === 'Enter') {
            currentTarget.blur();
            handleChangePassword();
        }
    };

    const handleChangePassword = () => {
        const { name, value } = passwordInput.current;
        setValue((v) => ({ ...v, [name]: value }));
    };

    useEffect(() => {
        if (!playstore) {
            getApplicationSettings({ application, settings: ['playstore'] });
        }
    }, []);

    useEffect(() => {
        if (!keystore || !keystorePassword) {
            return;
        }
        updateApplicationSettings({ setting: 'playstore', value }, application);
    }, [value]);

    return (
        <>
            <div className="mb-3">{t('app_settings.keystore_description')}</div>
            <CardDeck className="mt-3">
                {hasCertificate ? (
                    <Card>
                        <CardHeader>
                            <CardTitle>{t('header.uploadkey')}</CardTitle>
                            <small className="text-muted">.pfx, .keystore</small>
                        </CardHeader>
                        <CardBody>
                            <Empty
                                image={<Icon className="icon-success" type="file-check" />}
                                description={t('tools.file_uploaded')}
                            />
                        </CardBody>
                    </Card>
                ) : (
                    <>
                        <Card>
                            <CardHeader>
                                <CardTitle>{t('header.uploadkey')}</CardTitle>
                                <small className="text-muted">.pfx, .keystore</small>
                            </CardHeader>
                            <CardBody>
                                {keystore ? (
                                    <Empty
                                        image={<Icon className="icon-success" type="file-check" />}
                                        description={t('tools.file_uploaded')}
                                    />
                                ) : (
                                    <Empty description={t('tools.please_upload_file')} />
                                )}
                            </CardBody>
                            <CardFooter>
                                <Button color="link" onClick={handleClick}>
                                    {t('tools.upload')}
                                </Button>
                                <input
                                    className="d-none"
                                    type="file"
                                    name="keystore"
                                    data-setting="playstore"
                                    onChange={handleChangeFile}
                                    accept=".pfx, .keystore"
                                />
                            </CardFooter>
                        </Card>
                        <Card>
                            <CardHeader>
                                <CardTitle>{t('common.password')}</CardTitle>
                            </CardHeader>
                            <CardBody>
                                <FormGroup className="m-3">
                                    <Label className="h6 small d-block text-muted text-uppercase">
                                        {t('common.password')}
                                    </Label>
                                    <Input
                                        type="text"
                                        name="keystorePassword"
                                        data-setting="playstore"
                                        placeholder=""
                                        innerRef={passwordInput}
                                        onKeyDown={handleKeydownPassword}
                                    />
                                </FormGroup>
                            </CardBody>
                            <CardFooter>
                                <Button color="link" onClick={handleChangePassword}>
                                    {t('common.check')}
                                </Button>
                            </CardFooter>
                        </Card>
                    </>
                )}
            </CardDeck>
        </>
    );
};

const AppInfoContent = ({ content }) => {
    switch (content) {
        case 'appId':
            return <AppId />;
        case 'certificates':
            return <Certificates />;
        case 'keystore':
            return <UploadKey />;
        default:
            return null;
    }
};

export const AppInfo = ({ isOpen: isOpenProp }) => {
    const { t } = useTranslation();
    const [isOpen, setIsOpen] = useState(isOpenProp);
    const { modal, setModal, key = 'appId' } = useModals();
    const [content, setContent] = useState(key);

    useEffect(() => {
        setIsOpen(modal === MODALS.APP_INFO);
    }, [modal]);

    const handleMenuClick = ({ key }) => {
        setContent(key);
    };

    return (
        <Modal
            className="appinfo modal-menu max-80"
            isOpen={isOpen}
            title={t('app_settings.title')}
            onClosed={() => setModal(null)}
        >
            <ModalBody>
                <Menu
                    className="w-auto"
                    mode="inline"
                    onClick={handleMenuClick}
                    defaultSelectedKeys={[content]}
                >
                    <Menu.Item key="appId">{t('header.app_id')}</Menu.Item>
                    <Menu.Item key="certificates">
                        {t('header.certificate_and_provisioning_file')}
                    </Menu.Item>
                    <Menu.Item key="keystore">{t('header.uploadkey')}</Menu.Item>
                </Menu>
                <ModalBody className="px-5" data-key={content}>
                    <AppInfoContent content={content} />
                </ModalBody>
            </ModalBody>
        </Modal>
    );
};
