import { i18n } from '../i18n';
import 'cookieconsent';
export function initializeCookieConsent() {
    window.cookieconsent.initialise({
        palette: {
            popup: {
                background: '#221f45',
            },
        },
        theme: 'classic',
        position: 'bottom-right',
        content: i18n.t('cookieconsent', { returnObjects: true }),
    });
}
