import React, { createContext, useCallback, useEffect, useState } from 'react';
import { useImmerReducer } from 'use-immer';
import { useTranslation } from 'react-i18next';
import { message } from 'antd';
import ls from 'local-storage';
import { ApplicationsReducer, APPLICATION } from './reducers';
import { URL } from '../_config';
import { getUnique, getUniqueName } from '../_helpers';
import { useViewer } from '../hooks';
import {
    AdvertisementProvider,
    ErrorsProvider,
    getHeaders,
    handleResponse,
    ResourcesProvider,
    ScreensProvider,
    UnsplashProvider,
} from '.';

export const DEMO_PLATFORM = {
    ANDROID: 1,
    APPETIZE: 21,
};

export const DEMO_STATE = {
    DOWNLOADED: 'downloaded',
    FAILED: 'failed',
    PROCESSED: 'processed',
    PROCESSING: 'processing',
};

export const PUBLISH_STATE = {
    FAILED: 'failed',
    PROCESSED: 'processed',
    PROCESSING: 'processing',
};

export const SERVICE_TYPE = {
    PUSH_NOTIFICATION: 12,
    FIREBASE: 28,
    ASO: 26,
    MONETIZE: 13,
    REMOVE_INITIAL_SPLASH: 19,
    REMOVE_ADS_ANDROID: 6,
    REMOVE_ADS_IOS: 1,
    REMOVE_ADS_HTML: 3,
    CLOUD: 20,
    CLOUD_PLUS: 25,
    PRIVATE_BRAND: 8,
    AD_SUPPORTED: 29,
};

const defaultAttributes = {};

const defaultSettings = {
    appstore: {
        bundleId: null,
        url: undefined,
        p12: null,
        signature: null,
    },
    playstore: {
        packageName: '',
        url: '',
    },
};

const relations = [
    'bars',
    'bars.concrete',
    'demos',
    'languages',
    'resources',
    'resourcesAudio',
    'resourcesFolder',
    'resourcesText',
    'resourcesImage',
    'resourcesVideo',
    'resourcesZip',
    'serviceUses',
    'shadings',
    'styles',
    'services',
];

export const ApplicationsContext = createContext({});

export const ApplicationsProvider = ({ children }) => {
    const { t } = useTranslation();
    const [orderBy, setOrderBy] = useState('lastAccess_on');
    const [orderType, setOrderType] = useState('desc');
    const [loading, setLoading] = useState(false);
    const [page, setPage] = useState(1);
    const [state, dispatch] = useImmerReducer(ApplicationsReducer, APPLICATION.INITIAL_STATE);
    let { application = {}, applications = [] } = state;
    const {
        addToRecentApplications,
        logout,
        removeFromRecentApplications,
        viewer = {},
    } = useViewer();

    applications = applications.sort((app1, app2) => {
        if (orderType === 'asc') {
            return app1[orderBy] < app2[orderBy] ? -1 : 1;
        }
        return app1[orderBy] < app2[orderBy] ? 1 : -1;
    });

    const activateService = useCallback(
        async (service) => {
            const { auth = {} } = ls('user') || {};
            const { token } = auth;
            const { error } = await (
                await fetch(
                    `${URL.MOBINCUBE_EDITOR}/activateService.php?&service_id=${service}&app_id=${application.id}&token=${token}`
                )
            ).json();

            if (error) {
                const name = t(`services.service_${service}`);
                message.error(t('applications.error.activate_service', { name }));
                setLoading(false);
                return false;
            }

            await getApplication(application);
            return true;
        },
        [application]
    );

    const addApplications = useCallback(
        (applications) => {
            dispatch({
                type: APPLICATION.ADD,
                payload: { applications },
            });
        },
        [applications]
    );

    const addBarToApplication = useCallback(
        (bar) => {
            dispatch({
                type: APPLICATION.ADD_BAR,
                payload: { bar },
            });
        },
        [applications]
    );

    const addServices = useCallback(
        (serviceIds) => {
            setLoading(true);
            return Promise.all(
                serviceIds.map((serviceId) =>
                    fetch(`${URL.API}/applications/${application.id}/services/${serviceId}`, {
                        method: 'POST',
                        headers: getHeaders({ auth: true }),
                    })
                )
            )
                .then(() => {
                    setLoading(false);
                    dispatch({
                        type: APPLICATION.ADD_SERVICES,
                        payload: { services: serviceIds.map((id) => ({ id, idService: id })) },
                    });
                })
                .catch((error) => {
                    setLoading(false);
                    console.error(error);
                });
        },
        [application]
    );

    const createApplication = (attributes = {}) => {
        attributes = {
            ...defaultAttributes,
            Nombre: `${t('defaults.new_application_name')} ${Date.now()}`,
            ...attributes,
        };

        setLoading(true);
        fetch(`${URL.API}/applications`, {
            method: 'POST',
            body: JSON.stringify(attributes),
            headers: getHeaders({ auth: true }),
        })
            .then(handleResponse)
            .then((application) => {
                setLoading(false);
                dispatch({
                    type: APPLICATION.CREATE,
                    payload: { applications: [application] },
                });
                selectApplication(application);
            })
            .catch((err) => {
                setLoading(false);
                const { errors = {}, type } = err;
                const { Nombre } = errors;
                if (type === 'unprocessable_entity') {
                    if (Nombre) {
                        let name = attributes.Nombre;
                        const num = parseInt((name.match(/(\d+)$/) || [null, 0])[1], 10);
                        name = name.replace(`${num}`, `${num + 1}`);
                        createApplication({ ...attributes, Nombre: name });
                    }
                }
                console.error(err);
            });
    };

    const deleteApplication = useCallback(
        (application = state.application) => {
            const { id } = application;
            return fetch(`${URL.API}/applications/${id}`, {
                method: 'DELETE',
                headers: getHeaders({ auth: true }),
            }).then(() => {
                removeFromRecentApplications(application);
                removeFromDemosAndPublications(application);
                dispatch({
                    type: APPLICATION.DELETE,
                    payload: { id },
                });
            });
        },
        [applications]
    );

    const downloadApk = useCallback(
        (application = state.application) => {
            const { auth = {} } = ls('user') || {};
            const { token } = auth;
            setLoading(true);
            return fetch(
                `${URL.MOBINCUBE_EDITOR}/getDistAndroid.php?token=${token}&app_id=${application.id}`
            )
                .then((response) => response.blob())
                .then((blob) => {
                    setLoading(false);
                    const link = document.createElement('a');
                    link.download = application.Nombre;
                    link.href = window.URL.createObjectURL(blob);
                    link.click();
                })
                .catch((error) => {
                    setLoading(false);
                    console.error(error);
                });
        },
        [application]
    );

    const downloadGooglePlayBundle = useCallback(
        (application = state.application) => {
            const { auth = {} } = ls('user') || {};
            const { token } = auth;
            setLoading(true);
            return fetch(
                `${URL.MOBINCUBE_EDITOR}/getDistGooglePlay.php?token=${token}&app_id=${application.id}`
            )
                .then((response) => response.blob())
                .then((blob) => {
                    setLoading(false);
                    const link = document.createElement('a');
                    link.download = application.Nombre;
                    link.href = window.URL.createObjectURL(blob);
                    link.click();
                })
                .catch((error) => {
                    setLoading(false);
                    console.error(error);
                });
        },
        [application]
    );

    const downloadPepk = useCallback(
        (application = state.application) => {
            const { auth = {} } = ls('user') || {};
            const { token } = auth;
            setLoading(true);
            return fetch(
                `${URL.MOBINCUBE_EDITOR}/getPepk.php?token=${token}&app_id=${application.id}`
            )
                .then((response) => response.blob())
                .then((blob) => {
                    setLoading(false);
                    const link = document.createElement('a');
                    const { idCurrentLang, languages, Nombre: name } = application;
                    const { downloadCode } =
                        languages.find(({ IDLang }) => IDLang === idCurrentLang) || {};
                    link.download = `${downloadCode || name}_pepk.zip`;
                    link.href = window.URL.createObjectURL(blob);
                    link.click();
                })
                .catch((error) => {
                    setLoading(false);
                    console.error(error);
                });
        },
        [application]
    );

    const duplicateApplication = useCallback(
        (application = state.application) => {
            const { auth = {} } = ls('user') || {};
            const { token } = auth;
            setLoading(true);
            return fetch(
                `${URL.MOBINCUBE_EDITOR}/cloneApp.php?token=${token}&app_id=${application.id}`
            )
                .then((response) => response.json())
                .then(({ new_app: newApp }) => {
                    setLoading(false);
                    if (!newApp) {
                        message.error(
                            t('applications.error.duplicate', { name: application.Nombre })
                        );
                        return;
                    }
                    const { id } = newApp;
                    getApplication({ id });
                })
                .catch((error) => {
                    setLoading(false);
                    console.error(error);
                });
        },
        [application]
    );

    const generateGooglePlayBundle = useCallback(() => {
        const { id, Nombre: name } = application;
        const { auth = {} } = ls('user') || {};
        const { token } = auth;
        setLoading(true);
        return fetch(
            `${URL.MOBINCUBE_EDITOR}/requestGooglePlayBundle.php?token=${token}&app_id=${id}`
        )
            .then((response) => response.json())
            .then(({ error, status: state }) => {
                setLoading(false);
                if (error) {
                    message.error(t('applications.error.generate_google_play_bundle', { name }));
                    return;
                }
                const user = ls('user');
                const { publications = [] } = user;
                if (state === PUBLISH_STATE.PROCESSING) {
                    ls('user', {
                        ...user,
                        publications: getUnique([...publications, { id }]),
                    });
                    getApplication({ id });
                } else {
                    ls('user', {
                        ...user,
                        publications: publications.filter(({ id }) => id !== application.id),
                    });
                }
            })
            .catch((error) => {
                setLoading(false);
                console.error(error);
            });
    }, [application]);

    const generateIosIpa = useCallback(() => {
        const { id, Nombre: name } = application;
        const { auth = {} } = ls('user') || {};
        const { token } = auth;
        setLoading(true);
        return fetch(`${URL.MOBINCUBE_EDITOR}/requestIosIpa.php?token=${token}&app_id=${id}`)
            .then((response) => response.json())
            .then(({ error, status: state }) => {
                setLoading(false);
                if (error) {
                    message.error(t('applications.error.generate_ios_ipa', { name }));
                    return;
                }
                const user = ls('user');
                const { publications = [] } = user;
                if (state === PUBLISH_STATE.PROCESSING) {
                    ls('user', {
                        ...user,
                        publications: getUnique([...publications, { id }]),
                    });
                    getApplication({ id });
                } else {
                    ls('user', {
                        ...user,
                        publications: publications.filter(({ id }) => id !== application.id),
                    });
                }
            })
            .catch((error) => {
                setLoading(false);
                console.error(error);
            });
    }, [application]);

    const getApplication = (application = {}, { select = true } = {}) => {
        const { id } = application;
        if (!id || isNaN(id)) {
            return Promise.resolve({});
        }

        select && setLoading(true);
        return fetch(`${URL.API}/applications/${id}?relations=${JSON.stringify(relations)}`, {
            headers: getHeaders({ auth: true }),
        })
            .then(handleResponse)
            .then((application) => {
                if (application.isParked) {
                    unpark(application);
                    return;
                }
                addToRecentApplications(application);
                setLoading(false);
                dispatch({
                    type: APPLICATION.GET,
                    payload: { application, select },
                });
                return application;
            })
            .then((application) => {
                if (!application) {
                    return;
                }
                const {
                    inProcess,
                    processState_googleplay: googlePlayState,
                    processState_ios: iosState,
                    state,
                } = application;
                const user = ls('user');
                const { demos = [], publications = [] } = user;
                ls('user', {
                    ...user,
                    demos: getUnique([
                        ...(demos || []),
                        ...(application.demos.filter(
                            ({ state }) => state === DEMO_STATE.PROCESSING
                        ) || []),
                    ]),
                });

                if (
                    (inProcess && state === PUBLISH_STATE.PROCESSING) ||
                    googlePlayState === PUBLISH_STATE.PROCESSING ||
                    iosState === PUBLISH_STATE.PROCESSING
                ) {
                    ls('user', {
                        ...user,
                        publications: getUnique([...publications, { id }]),
                    });
                    setTimeout(() => getApplication({ id }, { select: false }), 10000);
                } else {
                    ls('user', {
                        ...user,
                        publications: publications.filter(({ id }) => id !== application.id),
                    });
                    updateApplication({ lastAccess_on: parseInt(Date.now() / 1000, 10) }, { id });
                }

                return application;
            })
            .catch((error) => {
                setLoading(false);
                console.error(error);
            });
    };

    const getApplications = useCallback(
        ({
            limit = 20,
            name = '',
            orderBy: _orderBy = orderBy,
            orderType: _orderType = orderType,
        } = {}) => {
            setLoading(true);
            fetch(
                `${
                    URL.API
                }/applications?name=${name}&orderBy=${_orderBy}&orderType=${_orderType}&page=${page}&per_page=${limit}&offset=${(page -
                    1) *
                    limit}&relations=['serviceUses']`,
                {
                    headers: getHeaders({ auth: true }),
                }
            )
                .then(handleResponse)
                .then((applications) => {
                    setLoading(false);
                    dispatch({
                        type: APPLICATION.LIST,
                        payload: { applications },
                    });
                })
                .catch((error) => {
                    setLoading(false);
                    console.error(error);
                });
        },
        [page]
    );

    const getApplicationIcon = useCallback(
        async ({ application = state.application, force = false, width = 250 } = {}) => {
            let { icon, iconUrl } = applications.find(({ id }) => id === application.id) || {};
            if (applications.id && !iconUrl) {
                application = await getApplication(application, { select: false });
                iconUrl = application.iconUrl;
            }
            if (icon && !force) {
                return Promise.resolve(icon);
            }

            setLoading(true);
            return fetch(
                `${URL.API}/applications/${application.id}/icon?width=${width}&height=250`,
                {
                    headers: getHeaders({ auth: true }),
                }
            )
                .then(async (response) => {
                    if (response.ok) {
                        return response.blob();
                    }
                    const data = (await response.json()) || {};
                    return Promise.reject(data.type);
                })
                .then((blob) => {
                    setLoading(false);
                    const icon = window.URL.createObjectURL(blob);
                    dispatch({
                        type: APPLICATION.UPDATE,
                        payload: { application: { ...application, icon } },
                    });
                    return icon;
                })
                .catch((error) => {
                    setLoading(false);
                    console.error(error);
                    if (error === 'unauthorized') {
                        logout();
                    }
                });
        },
        [applications]
    );

    const getApplicationName = useCallback(
        (name, { count = 0 } = {}) => {
            const applicationNames = applications.map(({ Nombre }) => Nombre);
            return getUniqueName(name, { names: applicationNames });
        },
        [applications]
    );

    const getApplicationSettings = useCallback(
        ({ application = state.application, settings = [] }) => {
            setLoading(true);
            return Promise.all(
                settings.map((setting) =>
                    fetch(`${URL.API}/applications/${application.id}/settings/${setting}`, {
                        headers: getHeaders({ auth: true }),
                    }).then(async (response) => {
                        return response.ok ? await response.json() : undefined;
                    })
                )
            )
                .then((response) => {
                    settings = settings
                        .map((setting, i) => ({
                            [setting]: response[i],
                        }))
                        .reduce((settings, setting) => ({ ...settings, ...setting }), {});
                    setLoading(false);
                    dispatch({
                        type: APPLICATION.GET_SETTINGS,
                        payload: { application, settings },
                    });
                    return response;
                })
                .catch((error) => {
                    setLoading(false);
                    console.error(error);
                });
        },
        [application]
    );

    const getDemo = ({ id }) => {
        return fetch(`${URL.API}/demo/${id}`, {
            headers: getHeaders({ auth: true }),
        })
            .then(async (response) => {
                const { data = {} } = await response.json();
                return response.ok ? data : Promise.reject(data.type);
            })
            .then((demo) => {
                const { state } = demo;
                if (state === DEMO_STATE.PROCESSING) {
                    setTimeout(() => getDemo({ id }), 10000);
                }
                dispatch({
                    type: APPLICATION.GET_DEMO,
                    payload: { demo },
                });
                return demo;
            })
            .catch((error) => {
                console.error(error);
            });
    };

    const getFirebase = ({ application = state.application }) => {
        setLoading(true);
        return fetch(`${URL.API}/applications/${application.id}/settings/firebase`, {
            headers: getHeaders({ auth: true }),
        })
            .then(async (response) => {
                const data = (await response.json()) || {};
                return response.ok ? data : Promise.reject(data.type);
            })
            .then((firebase) => {
                setLoading(false);
                dispatch({
                    type: APPLICATION.GET_FIREBASE,
                    payload: { application, firebase },
                });
                return firebase;
            })
            .catch((error) => {
                setLoading(false);
                console.error(error);
            });
    };

    const getShading = (
        { finalColor = '000000', id, mainColor = 'ffffff', shadingType = 'transparent' },
        { readonly = false } = {}
    ) => {
        if (!id && shadingType === 'transparent') {
            return Promise.resolve(null);
        }
        const shading = application.shadings.find((shading) => {
            return id
                ? shading.id === id
                : shading.shadingType === shadingType &&
                      shading.finalColor === finalColor &&
                      shading.mainColor === mainColor;
        });
        if (readonly || shading) {
            return Promise.resolve(shading);
        }

        return fetch(`${URL.API}/shadings`, {
            method: 'POST',
            body: JSON.stringify({ IDGuia: application.id, finalColor, mainColor, shadingType }),
            headers: getHeaders({ auth: true }),
        })
            .then(async (response) => {
                const { data = {} } = await response.json();
                return response.ok ? data : Promise.reject(data.type);
            })
            .then((shading) => {
                dispatch({
                    type: APPLICATION.GET_SHADING,
                    payload: { application, shading },
                });
                return shading;
            })
            .catch((error) => {
                console.error(error);
                if (error === 'unauthorized') {
                    logout();
                }
            });
    };

    const isServiceActive = useCallback(
        (serviceIds) => {
            serviceIds = Array.isArray(serviceIds) ? serviceIds : [serviceIds];
            const { services = [] } = application;
            return (
                !!services.length &&
                serviceIds.reduce(
                    (isServiceActive, serviceId) =>
                        isServiceActive && services.some(({ id }) => id === serviceId),
                    true
                )
            );
        },
        [application.services]
    );

    const publishVersion = useCallback(
        ({ application = state.application }) => {
            const { auth = {} } = ls('user') || {};
            const { token } = auth;

            setLoading(true);
            return fetch(
                `${URL.MOBINCUBE_EDITOR}/requestPublication.php?token=${token}&app_id=${application.id}`
            ).then((version) => {
                setLoading(false);
                getApplication(application, { select: false }).then((application) => {
                    if (!application) {
                        return;
                    }
                    let preferences = ls('preferences') || {};
                    const { lastVersionInfo } = application;
                    const { versions = [] } = preferences;
                    preferences = {
                        ...preferences,
                        versions: [...versions, lastVersionInfo],
                    };
                    ls('preferences', preferences);
                });
            });
        },
        [application]
    );

    const publishIos = useCallback(
        ({ application = state.application }) => {
            const { auth = {} } = ls('user') || {};
            const { token } = auth;

            setLoading(true);
            return fetch(
                `${URL.MOBINCUBE_EDITOR}/requestPublication.php?token=${token}&app_id=${application.id}`
            ).then((version) => {
                setLoading(false);
                getApplication(application, { select: false }).then((application) => {
                    if (!application) {
                        return;
                    }
                    let preferences = ls('preferences') || {};
                    const { lastVersionInfo } = application;
                    const { versions = [] } = preferences;
                    preferences = {
                        ...preferences,
                        versions: [...versions, lastVersionInfo],
                    };
                    ls('preferences', preferences);
                });
            });
        },
        [application]
    );

    const refreshApplication = useCallback(() => {
        const { id } = application;
        setLoading(true);
        return fetch(`${URL.API}/applications/${id}?relations=${JSON.stringify(relations)}`, {
            headers: getHeaders({ auth: true }),
        })
            .then(handleResponse)
            .then((_application) => {
                setLoading(false);
                let refreshedApplication = {
                    ...application,
                    ..._application,
                    refreshed: Date.now(),
                };
                dispatch({
                    type: APPLICATION.GET,
                    payload: { application: refreshedApplication },
                });
                return refreshedApplication;
            })
            .catch((error) => {
                setLoading(false);
                console.error(error);
            });
    }, [application]);

    const removeFromDemosAndPublications = ({ id }) => {
        const user = ls('user') || {};
        const { demos = [], publications = [] } = user;
        ls('user', {
            ...user,
            demos: demos.filter(({ IDGuia }) => IDGuia !== id),
            publications: publications.filter(({ id: IDGuia }) => IDGuia !== id),
        });
    };

    const removeServices = useCallback(
        (serviceIds) => {
            setLoading(true);
            return Promise.all(
                serviceIds.map((serviceId) =>
                    fetch(`${URL.API}/applications/${application.id}/services/${serviceId}`, {
                        method: 'DELETE',
                        headers: getHeaders({ auth: true }),
                    })
                )
            )
                .then(() => {
                    setLoading(false);
                    dispatch({
                        type: APPLICATION.REMOVE_SERVICES,
                        payload: { application, services: serviceIds },
                    });
                })
                .catch((error) => {
                    setLoading(false);
                    console.error(error);
                });
        },
        [application]
    );

    const requestDemo = useCallback(
        ({ application = state.application, platform = 'android' }) => {
            const { auth = {} } = ls('user') || {};
            const { token } = auth;
            setLoading(true);
            return fetch(
                `${URL.MOBINCUBE_EDITOR}/requestDemo.php?token=${token}&app_id=${application.id}&platform=${platform}`
            )
                .then((response) => response.json())
                .then(async ({ idDemo }) => {
                    if (!idDemo) {
                        message.error(t('applications.error.demo'));
                        return;
                    }
                    const demo = await getDemo({ id: idDemo });
                    let preferences = ls('preferences') || {};
                    const { demos = [] } = preferences;
                    preferences = {
                        ...preferences,
                        demos: [...demos, demo],
                    };
                    ls('preferences', preferences);
                    setLoading(false);
                })
                .catch((error) => {
                    setLoading(false);
                    console.error(error);
                });
        },
        [application]
    );

    const selectApplication = useCallback(
        ({ id }) => {
            getApplication({ id }).then((application) => {
                if (!application) {
                    return;
                }
                dispatch({
                    type: APPLICATION.SELECT,
                    payload: { application: { id } },
                });
            });
        },
        [applications, viewer.id]
    );

    const setApplicationIcon = useCallback(
        (base64) => {
            setLoading(true);
            const { iconUrl } = application;
            fetch(iconUrl, {
                headers: getHeaders({ auth: true }),
                method: 'POST',
                body: JSON.stringify({ json_icon: base64 }),
            }).then(() => {
                getApplicationIcon({ force: true });
            });
        },
        [application]
    );

    const skipVersionTo = useCallback(
        ({ application = state.application, version }) => {
            if (application.lastVersionInfo.version >= version) {
                return Promise.reject();
            }
            setLoading(true);
            return fetch(`${URL.API}/applications/${application.id}/version`, {
                method: 'PATCH',
                body: JSON.stringify({ version }),
                headers: getHeaders({ auth: true }),
            })
                .then(async (response) => {
                    const data = (await response.json()) || {};
                    return response.ok ? data : Promise.reject(data.type);
                })
                .then(async () => {
                    setLoading(false);
                    getApplication(application);
                })
                .catch((error) => {
                    setLoading(false);
                    console.error(error);
                });
        },
        [application]
    );

    const toggleServices = useCallback(
        (services) => {
            if (services.some((service) => isServiceActive(service))) {
                removeServices(services);
            } else {
                addServices(services);
            }
        },
        [application]
    );

    const unpark = useCallback(
        (application = state.application) => {
            setLoading(true);
            const hideUnparkingMessage = message.loading(t('applications.unparking'), 0);
            return fetch(`${URL.API}/applications/${application.id}/unpark`, {
                method: 'POST',
                headers: getHeaders({ auth: true }),
            })
                .then(() => {
                    setLoading(false);
                    hideUnparkingMessage();
                    selectApplication(application);
                })
                .catch((error) => {
                    setLoading(false);
                    console.error(error);
                });
        },
        [application]
    );

    const unselectApplication = () => {
        dispatch({ type: APPLICATION.UNSELECT });
    };

    const updateApplication = useCallback(
        (attributes = {}, application = state.application) => {
            setLoading(true);
            fetch(`${URL.API}/applications/${application.id}`, {
                method: 'PATCH',
                body: JSON.stringify(attributes),
                headers: getHeaders({ auth: true }),
            })
                .then(async (response) => {
                    const data = (await response.json()) || {};
                    return response.ok ? data : Promise.reject(data.type);
                })
                .then((application) => {
                    setLoading(false);
                    dispatch({
                        type: APPLICATION.UPDATE,
                        payload: { application },
                    });
                })
                .catch((error) => {
                    setLoading(false);
                    console.error(error);
                    if (error === 'unauthorized') {
                        logout();
                    }
                });
        },
        [application.id]
    );

    const updateApplicationSettings = useCallback(
        ({ setting, value = {} }, application = state.application) => {
            const { settings = {} } = application;
            const { p12 = {}, signature = {}, keystore = {} } = value;
            p12.setting = 'p12';
            signature.setting = 'signature';
            keystore.setting = 'keystore';
            setLoading(true);

            const update = (content) => {
                if (!settings[setting]) {
                    content = {
                        ...defaultSettings[setting],
                        ...content,
                    };
                }
                if (setting === 'appstore') {
                    content.name = `appStore${application.id}`;
                }
                fetch(`${URL.API}/applications/${application.id}/settings/${setting}`, {
                    method: settings[setting] ? 'PATCH' : 'POST',
                    body: JSON.stringify(content),
                    headers: getHeaders({ auth: true }),
                })
                    .then(async (response) => {
                        const data = (await response.json()) || {};
                        return response.ok ? data : Promise.reject(data);
                    })
                    .then(() => {
                        setLoading(false);
                        getApplicationSettings({ application, settings: [setting] });
                    })
                    .catch((error) => {
                        const { errors = {} } = error;
                        setLoading(false);
                        message.error(t(`applications.error.${Object.keys(errors)[0]}`));
                    });
            };

            if (p12.name || signature.name || keystore.name) {
                Promise.all(
                    [p12, signature, keystore]
                        .filter(({ name }) => name)
                        .map(
                            (file) =>
                                new Promise((resolve) => {
                                    const reader = new FileReader();
                                    reader.onload = (e) => {
                                        const fileContent = e.target.result.replace(
                                            /[^;]*;base64,/,
                                            ''
                                        );
                                        resolve({ [file.setting]: fileContent });
                                    };
                                    reader.readAsDataURL(file);
                                })
                        )
                ).then((files) => {
                    update({
                        ...value,
                        ...files.reduce((json, jsonFile) => ({ ...json, ...jsonFile }), {}),
                    });
                });
                return;
            }
            update(value);
        },
        [application]
    );

    const updateFirebase = ({ application, file, firebase, name, value }) => {
        const update = (content) =>
            fetch(
                `${URL.API}/applications/${firebase.IDGuia || application.id}/settings/firebase`,
                {
                    method: firebase.id ? 'PATCH' : 'POST',
                    body: JSON.stringify({ [name]: content }),
                    headers: getHeaders({ auth: true }),
                }
            )
                .then(async (response) => {
                    const data = (await response.json()) || {};
                    return response.ok ? data : Promise.reject(data.type);
                })
                .then((firebase) => {
                    setLoading(false);
                    dispatch({
                        type: APPLICATION.GET_FIREBASE,
                        payload: { firebase },
                    });
                    if (!firebase.id) {
                        getFirebase({ application });
                    }
                    return firebase;
                });

        if (file) {
            const reader = new FileReader();
            reader.onload = (e) => {
                const fileContent = e.target.result.replace(/[^;]*;base64,/, '');
                update(fileContent);
            };
            reader.readAsDataURL(file);
            return;
        }
        update(value);
    };

    useEffect(() => {
        const { demos = [] } = application;
        const { id } = demos.find(({ state }) => state === DEMO_STATE.PROCESSING) || {};
        if (id) {
            getDemo({ id });
        }
    }, [application.id]);

    if (process.env.NODE_ENV === 'development') {
        console.log('APPLICATIONS >>>', state);
    }

    return (
        <ApplicationsContext.Provider
            value={{
                ...state,
                activateService,
                addApplications,
                addBarToApplication,
                addServices,
                applications,
                createApplication,
                deleteApplication,
                downloadApk,
                downloadGooglePlayBundle,
                downloadPepk,
                duplicateApplication,
                generateGooglePlayBundle,
                generateIosIpa,
                getApplication,
                getApplications,
                getApplicationIcon,
                getApplicationName,
                getApplicationSettings,
                getDemo,
                getFirebase,
                getShading,
                isServiceActive,
                loading,
                page,
                publishIos,
                publishVersion,
                refreshApplication,
                removeServices,
                requestDemo,
                selectApplication,
                setApplicationIcon,
                setOrderBy,
                setOrderType,
                setPage,
                skipVersionTo,
                toggleServices,
                unselectApplication,
                updateApplication,
                updateApplicationSettings,
                updateFirebase,
            }}
        >
            {(application.id && (
                <AdvertisementProvider>
                    <UnsplashProvider>
                        <ResourcesProvider>
                            <ScreensProvider>
                                <ErrorsProvider>{children}</ErrorsProvider>
                            </ScreensProvider>
                        </ResourcesProvider>
                    </UnsplashProvider>
                </AdvertisementProvider>
            )) ||
                children}
        </ApplicationsContext.Provider>
    );
};

export const ApplicationsConsumer = ApplicationsContext.Consumer;
export default ApplicationsContext;
