import React from 'react';
import { useTranslation } from 'react-i18next';
import { Select } from 'antd';
import { CardBody, CardTitle, Input } from 'reactstrap';
import { ACTION_TYPE } from '../../../context';
import { useScreens } from '../../../hooks';
import { ScreenWrapper } from '.';

const { Option } = Select;

export const Splash = (props) => {
    const { t } = useTranslation();
    const { screens = [], updateScreen } = useScreens();
    let { Parameter: parameter, Timeout: timeout } = props;
    timeout = timeout / 1000;

    const handleInputBlur = (event) => {
        const { currentTarget } = event;
        const { value } = currentTarget;
        if (value === timeout) {
            return;
        }
        updateScreen({ Timeout: value * 1000 });
    };

    const handleChangeScreen = (screen) => {
        updateScreen({ Parameter: screen });
    };

    return (
        <ScreenWrapper {...props}>
            <CardBody>
                <CardTitle>
                    <strong>{t('properties.seconds')}</strong>
                </CardTitle>
                <Input defaultValue={timeout} onBlur={handleInputBlur} />
            </CardBody>
            <CardBody>
                <CardTitle>
                    <strong>{t(`actions.${ACTION_TYPE.SCREEN}`)}</strong>
                </CardTitle>
                <Select defaultValue={parameter} onChange={handleChangeScreen}>
                    {screens.map(({ id, Nombre: name }) => (
                        <Option key={`ScreenOption${id}`} value={`${id}`}>
                            {name}
                        </Option>
                    ))}
                </Select>
            </CardBody>
        </ScreenWrapper>
    );
};
