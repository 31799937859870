import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ls from 'local-storage';
import { URL } from '../_config';
import { useApplications } from '../hooks';
import { CustomModal, ProgressIndeterminate } from '.';

export const ClassicEditor = ({ children, screen }) => {
    const { t } = useTranslation();
    const { application = {}, getApplication } = useApplications();
    const [iframe, setIframe] = useState();
    const [isOpen, setIsOpen] = useState();
    const [loading, setLoading] = useState(true);
    const { auth = {} } = ls('user') || {};
    const { token } = auth;
    const { Nombre: name } = screen;
    let src = `${URL.MOBINCUBE_EDITOR_REDIRECT}?app_id=${application.id}&token=${token}`;
    if (screen.id) {
        src = `${src}&callback_url=${encodeURIComponent(
            `/edicionIframe.php#editor/section/${screen.id}`
        )}`;
    }

    const toggle = () => setIsOpen((isOpen) => !isOpen);

    useEffect(() => {
        if (!iframe) {
            return;
        }
        let mounted = true;

        iframe.onload = () => {
            mounted && setLoading(false);
        };

        return () => {
            mounted = false;
        };
    }, [iframe]);

    return (
        <CustomModal
            isOpen={isOpen}
            button={children}
            className="classic-editor"
            title={`${t('shortcuts.classic_editor')}: ${name}`}
            toggle={toggle}
            onOpened={() => setLoading(true)}
            onClosed={() => getApplication(application)}
        >
            <ProgressIndeterminate visible={loading} />
            <iframe src={src} title={name} ref={setIframe} />
        </CustomModal>
    );
};
