// @flow
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ReactQuill from 'react-quill';

const modules = {
    toolbar: [[{ color: [] }], [{ header: [1, 2, 3, false] }], ['bold', 'italic', 'underline']],
    clipboard: {
        matchers: [
            [
                'SPAN',
                (node, delta) => {
                    delta.map((op) => {
                        if (node.attributes.length) {
                            op.attributes = op.attributes || {};
                            if (node.attributes.color) {
                                op.attributes.color = node.attributes.color.nodeValue;
                            }
                        }
                        return op;
                    });
                    return delta;
                },
            ],
            [
                'B',
                (node, delta) => {
                    delta.map((op) => {
                        op.attributes = op.attributes || {};
                        op.attributes.bold = true;
                        return op;
                    });
                    return delta;
                },
            ],
        ],
        matchVisual: false,
    },
};

const getHtmlCode = {
    header: (text, header) => `<h${header}>${text.replace(/(^<br>|<br>$)/g, '')}</h${header}>`,
    italic: (text) => `<i>${text}</i>`,
    bold: (text) => `<b>${text}</b>`,
    color: (text, color) => `<span color="${color}">${text}</span>`,
    underline: (text) => `<u>${text}</u>`,
};

export const TextEditor = ({
    extRef,
    onBlur: handleExtBlur = () => {},
    style = {},
    value = '',
}) => {
    const { t } = useTranslation();
    const [hasChange, setHasChange] = useState(false);
    const ref = useRef();
    const quill = extRef || ref;

    const parseLine = (str) => {
        const match = {
            '&': '&amp;',
            '<': '&lt;',
            '>': '&gt;',
            '"': '&quot;',
            '\n': '<br>',
        };
        return str.replace(/[&<>"\n]/g, (s) => match[s]);
    };

    const getContent = (line) => {
        const { attributes = {}, insert } = line;
        let content = parseLine(insert);
        Object.keys(attributes).forEach((key) => {
            const value = attributes[key];
            if (key === 'header') {
                return;
            }
            content =
                typeof getHtmlCode[key] === 'function' ? getHtmlCode[key](content, value) : content;
        });
        return content;
    };

    const handleBlur = useCallback(() => {
        const html = convert();
        if (hasChange) {
            handleExtBlur(html);
            setHasChange(false);
        }
    }, [hasChange, value]);

    const handleChange = (content, delta, source, editor) => {
        setHasChange(true);
        setTimeout(() => refreshTooltip(), 1);
    };

    const convert = () => {
        let html = '';
        let block = '';
        const { ops: contents } = quill.current.editor.getContents();
        contents.forEach((line, i) => {
            const contentLine = getContent(line);
            if (line.attributes && line.attributes.header) {
                html += getHtmlCode.header(block, line.attributes.header);
                block = '';
            } else if (line.insert.length > 1 && line.insert[0] === '\n') {
                html += `${block}<br>`;
                block = contentLine.replace(/^<br>/, '');
            } else {
                block += contentLine;
            }
            if (i === contents.length - 1) {
                html += block.replace(/<br>$/, '');
            }
        });
        return html;
    };

    const refreshTooltip = (range) => {
        if (!quill.current) {
            return;
        }
        range = range || quill.current.editor.getSelection();
        const tooltip = quill.current.editor.theme.tooltip;
        if (range != null && range.length > 0) {
            tooltip.root.style.width = '';
            tooltip.root.style.width = tooltip.root.offsetWidth + 'px';
            const bounds = tooltip.quill.getBounds(range);
            const el = quill.current.editingArea.getBoundingClientRect();
            tooltip.root.style.top = el.top + bounds.top + bounds.height + 'px';
            tooltip.root.style.left =
                el.left - tooltip.root.offsetWidth / 2 + bounds.left + bounds.width / 2 + 'px';
            tooltip.show();
        } else {
            tooltip.hide();
        }
    };

    const handleChangeSelection = (range) => {
        refreshTooltip(range);
    };

    useEffect(() => {
        quill.current.editor.root.onblur = handleBlur;
        return () => {
            if (quill.current) {
                quill.current.editor.root.onblur = null;
            }
        };
    }, [hasChange, quill.current]);

    useEffect(() => {
        quill.current.setEditorContents(quill.current.editor, value);
    }, [value]);

    return (
        <ReactQuill
            defaultValue={value}
            modules={modules}
            onBlur={handleBlur}
            onChange={handleChange}
            onChangeSelection={handleChangeSelection}
            placeholder={t('defaults.text')}
            ref={quill}
            style={style}
            theme="bubble"
        />
    );
};

export default TextEditor;
