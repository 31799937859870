import { URL } from '../_config';

const demo = {
    title: 'Demo',
    type: 'object',
    properties: {
        Descargado: { type: 'number', enum: [0, 1] },
        FechaDemo: { type: 'string' },
        FechaDescarga: { type: ['null', 'string'] },
        IDGuia: { type: 'number' },
        IDLang: { type: 'number' },
        UserAgent: { type: ['null', 'string'] },
        adSupported: { type: 'number' },
        downloadCode: { type: 'string' },
        errorMessage: { type: ['null', 'string'] },
        id: { type: 'number' },
        idPlatform: { type: 'number' },
        inProcess: { type: 'string', enum: ['0', '1'] },
        isPieceable: { type: 'string', enum: ['0', '1'] },
        state: { type: 'string', enum: ['downloaded', 'failed', 'processed', 'processing'] },
        urlPieceable: { type: ['null', 'number'] },
    },
};

const version = {
    title: 'Version',
    type: 'object',
    properties: {
        IDGuia: { type: 'number' },
        added_on: { type: 'string' },
        closed: { type: 'string', enum: ['0', '1'] },
        id: { type: 'number' },
        notes: { type: 'string' },
        version: { type: 'number' },
    },
};

export const application = {
    title: 'Application',
    type: 'object',
    properties: {
        FechaCreacion: { type: 'string' },
        IDMainSection: { type: 'number' },
        Nombre: { type: 'string' },
        adServerApp: { type: ['null', 'number'] },
        deleted_at: { type: ['null', 'string'] },
        demos: {
            type: 'array',
            items: {
                ...demo,
            },
        },
        disabledReason: { type: ['null', 'string'] },
        googleAnalyticsId: { type: ['null', 'string'] },
        hidden: { type: 'string', enum: ['0', '1'] },
        iconUrl: { type: 'string', pattern: `${URL.API}/applications/\\d+/icon` },
        id: { type: 'number' },
        idCurrentLang: { type: 'number' },
        inProcess: { type: 'boolean' },
        isDisabled: { type: 'boolean' },
        isParked: { type: 'boolean' },
        lastAccess_on: { type: 'string' },
        lastVersionInfo: {
            ...version,
        },
        orientation: { type: 'string', enum: ['landscape', 'portrait'] },
        preloadOnlineResources: { type: 'boolean' },
        processState_ios: {
            type: 'string',
            enum: ['failed', 'processed', 'processing', 'ready', 'unready'],
        },
        processState_wp: {
            type: 'string',
            enum: ['failed', 'processed', 'processing', 'ready', 'unready'],
        },
        state: { type: 'string', enum: ['failed', 'processed', 'processing'] },
        versions: {
            type: 'array',
            items: {
                ...version,
            },
        },
    },
};

export default application;
