import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Select } from 'antd';
import { Button, CardBody, CardTitle, Input } from 'reactstrap';
import { MODALS } from '../../../_config';
import { RESOURCE_TYPE } from '../../../context';
import { useElements, useModals, useResources } from '../../../hooks';
import { ElementWrapper } from '.';

const { Option } = Select;

export const Image = ({ element }) => {
    const { t } = useTranslation();
    const { updateElement } = useElements();
    const { openModal } = useModals();
    const { createResource, resources = [], selectResource, unselectResource } = useResources();
    const { concrete = {} } = element;
    const { IDImage } = concrete;
    const imageRes = resources.find(({ id }) => id === IDImage) || {};
    const { online: _online = 0, url } = imageRes;
    const [online, setOnline] = useState(_online);

    useEffect(() => {
        let mounted = true;
        if (mounted && imageRes.id) {
            selectResource(imageRes);
            setOnline(imageRes.online);
        }
        return () => {
            mounted = false;
        };
    }, [IDImage]);

    const handleChangeOnline = (online) => {
        setOnline(online);
    };

    const handleChangeUrl = (event) => {
        const { currentTarget } = event;
        const { value } = currentTarget;
        if (!currentTarget.reportValidity()) {
            return;
        }

        const resource = resources.find(({ url }) => url === value);
        if (resource) {
            updateElement({ concrete: { IDImage: resource.id } });
        } else {
            createResource({
                type: RESOURCE_TYPE.IMAGE,
                online: 1,
                content: value,
                Filename: value,
            }).then(
                (resource) => resource && updateElement({ concrete: { IDImage: resource.id } })
            );
        }
    };

    const handleSelectImageClick = () => {
        if (imageRes.id) {
            selectResource(imageRes);
        } else {
            unselectResource();
        }
        const filter = ({ type }) => type === RESOURCE_TYPE.IMAGE;
        openModal(MODALS.RESOURCES, { filter, target: element });
    };

    return (
        <ElementWrapper element={element}>
            <CardBody>
                <CardTitle>
                    <strong>{t('properties.source')}</strong>
                </CardTitle>
                <Select value={online} onChange={handleChangeOnline}>
                    <Option value={0}>{t('properties.resource')}</Option>
                    <Option value={1}>{t('common.url')}</Option>
                    <Option value={2}>{t('header.cloud')}</Option>
                </Select>
                <div className="mb-3" />
                {(online === 0 && (
                    <Button color="primary" onClick={handleSelectImageClick}>
                        {t('properties.select_image')}
                    </Button>
                )) ||
                    (online === 1 && (
                        <Input
                            type="url"
                            placeholder="https://"
                            defaultValue={_online !== 1 ? '' : url}
                            onBlur={handleChangeUrl}
                            required
                        />
                    )) || (
                        <Input
                            type="text"
                            placeholder="XXXXXXXXXX"
                            defaultValue={_online !== 2 ? '' : url}
                            onBlur={handleChangeUrl}
                            required
                        />
                    )}
            </CardBody>
        </ElementWrapper>
    );
};
