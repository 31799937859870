import React from 'react';
import { useTranslation } from 'react-i18next';
import { ClassicEditor, Icon } from '../../../components';
import { SCREEN_ICON } from '../../../context';
import { ScreenWrapper } from '.';

export const Feed = (props) => {
    const { t } = useTranslation();

    return (
        <ScreenWrapper {...props}>
            <div className="skeleton">
                <Icon type={SCREEN_ICON.FEED} />
                <span>{t('elements.feed')}</span>
                <hr />
                <ClassicEditor screen={props}>{t('viewport.edit_screen')}</ClassicEditor>
            </div>
        </ScreenWrapper>
    );
};
