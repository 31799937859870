import React from 'react';
import { useTranslation } from 'react-i18next';
import { ElementWrapper } from '.';

export const TextField = ({ element }) => {
    const { t } = useTranslation();
    const { concrete = {} } = element;
    const { fontColor, format } = concrete;
    const color = `#${fontColor}`;

    return (
        <ElementWrapper element={element}>
            <div className="element-content">
                <input
                    type={format}
                    defaultValue={format === 'text' ? t('elements.sample_text') : 0}
                    style={{ color }}
                />
            </div>
        </ElementWrapper>
    );
};
