import React from 'react';
import { getHex, getRgba } from '../../../_helpers';
import { ColorPicker } from '../../../components';

export const ColorProperty = ({ format, onChange: handleChange, value: _value }) => {
    const value = getHex(_value);

    const handleChangingColor = () => {};
    const handleChangeColor = (color) => {
        color = getRgba(color);
        if (color === value) {
            return;
        }
        handleChange(color);
    };

    return (
        <ColorPicker
            value={value}
            onChange={handleChangingColor}
            onBlur={handleChangeColor}
            input={true}
        />
    );
};
