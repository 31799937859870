import { i18n } from '../i18n';
import { URL } from '../_config';
import { BODY_ICON, BODY_TYPE } from '../components';
import { ACTION_TYPE, ELEMENT_ICON, ELEMENT_TYPE, SCREEN_ICON, SCREEN_TYPE_NAME } from '../context';

export const DRAG_ITEM_TYPE = {
    ELEMENT: 'DRAG_ITEM_ELEMENT',
    SCREEN: 'DRAG_ITEM_SCREEN',
};

export const dragItemsData = [
    {
        title: i18n.t('elements.layout'),
        limit: 9,
        items: [
            {
                type: DRAG_ITEM_TYPE.ELEMENT,
                icon: ELEMENT_ICON.IMAGE,
                title: i18n.t('elements.image'),
                id: 1,
                installed: true,
                attributes: {
                    IDElementType: ELEMENT_TYPE.IMAGE,
                },
            },
            {
                type: DRAG_ITEM_TYPE.ELEMENT,
                icon: ELEMENT_ICON.TEXT,
                title: i18n.t('elements.text'),
                id: 2,
                installed: true,
                attributes: {
                    IDElementType: ELEMENT_TYPE.TEXT,
                },
            },
            {
                type: DRAG_ITEM_TYPE.ELEMENT,
                icon: ELEMENT_ICON.SEPARATOR,
                title: i18n.t('elements.separator'),
                id: 3,
                installed: true,
                attributes: {
                    IDElementType: ELEMENT_TYPE.SEPARATOR,
                },
            },
            {
                type: DRAG_ITEM_TYPE.ELEMENT,
                icon: ELEMENT_ICON.TEXTFIELD,
                title: i18n.t('elements.text_field'),
                id: 4,
                installed: true,
                attributes: {
                    IDElementType: ELEMENT_TYPE.TEXTFIELD,
                },
            },
            {
                type: DRAG_ITEM_TYPE.ELEMENT,
                icon: ELEMENT_ICON.VIDEO,
                title: i18n.t('elements.video'),
                id: 5,
                installed: true,
                attributes: {
                    IDElementType: ELEMENT_TYPE.VIDEO,
                },
            },
            {
                type: DRAG_ITEM_TYPE.ELEMENT,
                icon: BODY_ICON.HORIZONTAL,
                title: i18n.t('elements.horizontal_body'),
                id: 48,
                installed: true,
                attributes: {
                    IDElementType: ELEMENT_TYPE.BODY,
                    concrete: {
                        bodyType: BODY_TYPE.HORIZONTAL,
                    },
                },
            },
            {
                type: DRAG_ITEM_TYPE.ELEMENT,
                icon: BODY_ICON.VERTICAL,
                title: i18n.t('elements.vertical_body'),
                id: 49,
                installed: true,
                attributes: {
                    IDElementType: ELEMENT_TYPE.BODY,
                    width: 50,
                    concrete: {
                        bodyType: BODY_TYPE.VERTICAL,
                    },
                },
            },
            {
                type: DRAG_ITEM_TYPE.ELEMENT,
                icon: ELEMENT_ICON.BAR,
                title: i18n.t('elements.bar'),
                id: 51,
                installed: true,
                attributes: {
                    IDElementType: ELEMENT_TYPE.BAR,
                },
            },
            {
                type: DRAG_ITEM_TYPE.SCREEN,
                icon: 'view-sequential',
                title: i18n.t('elements.db_list'),
                id: 42,
                installed: true,
                attributes: {
                    typeName: SCREEN_TYPE_NAME.DATAVIEW,
                    concrete: {},
                },
            },
            {
                type: DRAG_ITEM_TYPE.SCREEN,
                icon: 'cards-variant',
                title: i18n.t('elements.db_entry'),
                id: 43,
                installed: true,
                attributes: {
                    typeName: SCREEN_TYPE_NAME.DATAVIEW,
                    concrete: {},
                },
            },
        ],
    },
    {
        title: i18n.t('elements.forms'),
        limit: 3,
        items: [
            {
                type: DRAG_ITEM_TYPE.SCREEN,
                icon: 'tune',
                title: i18n.t('elements.preferences'),
                id: 6,
                attributes: {
                    typeName: SCREEN_TYPE_NAME.WEB,
                    concrete: {
                        url: '',
                        idModule: 21,
                        name: 'preferences.zip',
                        content: `${URL.KUSTODIO_SHARING}/2WkbCHg9bNJVcfuhUgwcum.zip`,
                    },
                },
            },
            {
                type: DRAG_ITEM_TYPE.SCREEN,
                icon: 'comment-question',
                title: i18n.t('elements.quiz'),
                id: 7,
                attributes: {
                    typeName: SCREEN_TYPE_NAME.WEB,
                    concrete: {
                        url: '',
                        idModule: 22,
                        name: 'quiz.zip',
                        content: `${URL.KUSTODIO_SHARING}/5Owd8BfmgZzyBgXL2o2ud0.zip`,
                    },
                },
            },
        ],
    },
    {
        title: i18n.t('elements.web'),
        limit: 6,
        items: [
            {
                type: DRAG_ITEM_TYPE.SCREEN,
                icon: 'link-off',
                title: i18n.t('elements.offline'),
                id: 8,
                installed: true,
                attributes: {
                    typeName: SCREEN_TYPE_NAME.WEB,
                    concrete: {
                        idZip: 0,
                        url: null,
                    },
                },
            },
            {
                type: DRAG_ITEM_TYPE.SCREEN,
                icon: 'link',
                title: i18n.t('elements.online'),
                id: 9,
                installed: true,
                attributes: {
                    typeName: SCREEN_TYPE_NAME.WEB,
                    concrete: {
                        idZip: null,
                        url: 'https://mobincube.com',
                    },
                },
            },
            {
                type: DRAG_ITEM_TYPE.SCREEN,
                icon: SCREEN_ICON.FEED,
                title: i18n.t('elements.feed'),
                id: 39,
                installed: true,
                attributes: {
                    typeName: SCREEN_TYPE_NAME.FEED,
                    concrete: {},
                },
            },
            {
                type: DRAG_ITEM_TYPE.SCREEN,
                icon: 'web',
                title: i18n.t('elements.db_web'),
                id: 47,
                installed: true,
                attributes: {
                    typeName: SCREEN_TYPE_NAME.DATAVIEW,
                    concrete: {},
                },
            },
        ],
    },
    {
        title: i18n.t('elements.tools'),
        limit: 3,
        items: [
            {
                type: DRAG_ITEM_TYPE.SCREEN,
                icon: 'timer',
                title: i18n.t('elements.loader'),
                id: 10,
                attributes: {
                    typeName: SCREEN_TYPE_NAME.WEB,
                    concrete: {
                        url: '',
                        idModule: 11,
                        name: 'loader.zip',
                        content: `${URL.KUSTODIO_SHARING}/1PBLHyxePkpAXzWPRcT2ke.zip`,
                    },
                },
            },
            {
                type: DRAG_ITEM_TYPE.SCREEN,
                icon: 'login',
                title: i18n.t('elements.login'),
                id: 11,
                attributes: {
                    typeName: SCREEN_TYPE_NAME.WEB,
                    concrete: {
                        url: '',
                        idModule: 2,
                        name: 'login.zip',
                        content: `${URL.KUSTODIO_SHARING}/528AEUovgQ7h60Toiu5HV.zip`,
                    },
                },
            },
            {
                type: DRAG_ITEM_TYPE.SCREEN,
                icon: 'logout',
                title: i18n.t('elements.logout'),
                id: 12,
                attributes: {
                    typeName: SCREEN_TYPE_NAME.WEB,
                    concrete: {
                        url: '',
                        idModule: 12,
                        name: 'logout.zip',
                        content: `${URL.KUSTODIO_SHARING}/5YsE9yRw85Rx0j2PEzl3sV.zip`,
                    },
                },
            },
            {
                type: DRAG_ITEM_TYPE.SCREEN,
                icon: 'view-carousel',
                title: i18n.t('elements.on_boarding'),
                id: 13,
                attributes: {
                    typeName: SCREEN_TYPE_NAME.WEB,
                    concrete: {
                        url: '',
                        idModule: 13,
                        name: 'onboarding.zip',
                        content: `${URL.KUSTODIO_SHARING}/5AbUWP5YTZNCMgWsLibi00.zip`,
                    },
                },
            },
            {
                type: DRAG_ITEM_TYPE.SCREEN,
                icon: 'star',
                title: i18n.t('elements.app_review'),
                id: 14,
                attributes: {
                    typeName: SCREEN_TYPE_NAME.WEB,
                    concrete: {
                        url: '',
                        idModule: 19,
                        name: 'app_review.zip',
                        content: `${URL.KUSTODIO_SHARING}/2AaHpR198U8YlBTX93LDcD.zip`,
                    },
                },
            },
            {
                type: DRAG_ITEM_TYPE.SCREEN,
                icon: 'clipboard-check',
                title: i18n.t('elements.task_list'),
                id: 15,
                attributes: {
                    typeName: SCREEN_TYPE_NAME.WEB,
                    concrete: {
                        url: '',
                        idModule: 20,
                        name: 'task_list.zip',
                        content: `${URL.KUSTODIO_SHARING}/1cT56C81fSXi6bpfkY9Vr8.zip`,
                    },
                },
            },
            {
                type: DRAG_ITEM_TYPE.SCREEN,
                icon: 'file-pdf',
                title: i18n.t('elements.offline_pdf'),
                id: 16,
                attributes: {
                    typeName: SCREEN_TYPE_NAME.WEB,
                    concrete: {
                        url: '',
                        idModule: 25,
                        name: 'offline_pdf.zip',
                        content: `${URL.KUSTODIO_SHARING}/31Tqh7szGSjoWupaeaNlFP.zip`,
                    },
                },
            },
            {
                type: DRAG_ITEM_TYPE.SCREEN,
                icon: 'file-pdf',
                title: i18n.t('elements.online_pdf'),
                id: 17,
                attributes: {
                    typeName: SCREEN_TYPE_NAME.WEB,
                    concrete: {
                        url: '',
                        idModule: 26,
                        name: 'online_pdf.zip',
                        content: `${URL.KUSTODIO_SHARING}/MDYYXosrsQbiOHui0qutk.zip`,
                    },
                },
            },
            {
                type: DRAG_ITEM_TYPE.SCREEN,
                icon: SCREEN_ICON.SPLASH,
                title: i18n.t('elements.splash'),
                id: 41,
                installed: true,
                attributes: {
                    typeName: SCREEN_TYPE_NAME.SPLASH,
                    IDAction: ACTION_TYPE.SCREEN,
                    concrete: {},
                },
            },
            {
                type: DRAG_ITEM_TYPE.SCREEN,
                icon: 'alert-octagon',
                title: i18n.t('elements.purchase_blocker'),
                id: 50,
                attributes: {
                    typeName: SCREEN_TYPE_NAME.WEB,
                    concrete: {
                        url: '',
                        idModule: 29,
                        name: 'purchase_blocker.zip',
                        content: `${URL.KUSTODIO_SHARING}/607Zcjge6BLSnrkcTeJt4s.zip`,
                    },
                },
            },
        ],
    },
    {
        title: i18n.t('elements.menu'),
        limit: 3,
        items: [
            {
                type: DRAG_ITEM_TYPE.SCREEN,
                icon: 'grid',
                title: i18n.t('elements.grid'),
                id: 18,
                attributes: {
                    typeName: SCREEN_TYPE_NAME.WEB,
                    concrete: {
                        url: '',
                        idModule: 8,
                        name: 'menu_grid.zip',
                        content: `${URL.KUSTODIO_SHARING}/14vHq1IzLHM8qRnyeE0Iv4.zip`,
                    },
                },
            },
            {
                type: DRAG_ITEM_TYPE.SCREEN,
                icon: 'view-stream',
                title: i18n.t('elements.rainbow'),
                id: 19,
                attributes: {
                    typeName: SCREEN_TYPE_NAME.WEB,
                    concrete: {
                        url: '',
                        idModule: 14,
                        name: 'menu_layout_rainbow.zip',
                        content: `${URL.KUSTODIO_SHARING}/6XnPtaW6u76Af00snlsjoK.zip`,
                    },
                },
            },
            {
                type: DRAG_ITEM_TYPE.SCREEN,
                icon: 'view-quilt',
                title: i18n.t('elements.elegance'),
                id: 20,
                attributes: {
                    typeName: SCREEN_TYPE_NAME.WEB,
                    concrete: {
                        url: '',
                        idModule: 15,
                        name: 'menu_layout_elegance.zip',
                        content: `${URL.KUSTODIO_SHARING}/oPexr0PzGYevjj5imdzMw.zip`,
                    },
                },
            },
            {
                type: DRAG_ITEM_TYPE.SCREEN,
                icon: SCREEN_ICON.OPTIONLIST,
                title: i18n.t('elements.basic'),
                id: 36,
                installed: true,
                attributes: {
                    typeName: SCREEN_TYPE_NAME.OPTIONLIST,
                    concrete: {},
                },
            },
        ],
    },
    {
        title: i18n.t('elements.social'),
        limit: 3,
        items: [
            {
                type: DRAG_ITEM_TYPE.SCREEN,
                icon: 'message-text',
                title: i18n.t('elements.chat'),
                id: 21,
                attributes: {
                    typeName: SCREEN_TYPE_NAME.WEB,
                    concrete: {
                        url: '',
                        idModule: 1,
                        name: 'chat.zip',
                        content: `${URL.KUSTODIO_SHARING}/2p8CXDMuAWNpABRjFUn4jp.zip`,
                    },
                },
            },
            {
                type: DRAG_ITEM_TYPE.SCREEN,
                icon: 'message-image',
                title: i18n.t('elements.instawall'),
                id: 22,
                attributes: {
                    typeName: SCREEN_TYPE_NAME.WEB,
                    concrete: {
                        url: '',
                        idModule: 18,
                        name: 'instawall.zip',
                        content: `${URL.KUSTODIO_SHARING}/6BYuVpIfrAkXaHdOztCl0L.zip`,
                    },
                },
            },
        ],
    },
    {
        title: i18n.t('elements.augmented_virtual_reality'),
        limit: 3,
        items: [
            {
                type: DRAG_ITEM_TYPE.SCREEN,
                icon: 'panorama-horizontal',
                title: i18n.t('elements.gallery'),
                id: 23,
                attributes: {
                    typeName: SCREEN_TYPE_NAME.WEB,
                    concrete: {
                        url: '',
                        idModule: 3,
                        name: 'vr_gallery.zip',
                        content: `${URL.KUSTODIO_SHARING}/30nPrAEdeHTL2zNzqnO5re.zip`,
                    },
                },
            },
            {
                type: DRAG_ITEM_TYPE.SCREEN,
                icon: 'nature-people',
                title: i18n.t('elements.tour'),
                id: 24,
                attributes: {
                    typeName: SCREEN_TYPE_NAME.WEB,
                    concrete: {
                        url: '',
                        idModule: 9,
                        name: 'vr_tour.zip',
                        content: `${URL.KUSTODIO_SHARING}/5K8jWz1cTERHfFqXmK1KSa.zip`,
                    },
                },
            },
            {
                type: DRAG_ITEM_TYPE.SCREEN,
                icon: 'navigation',
                title: i18n.t('elements.db_ar'),
                id: 47,
                installed: true,
                attributes: {
                    typeName: SCREEN_TYPE_NAME.DATAVIEW,
                    concrete: {},
                },
            },
        ],
    },
    {
        title: i18n.t('elements.gallery'),
        limit: 3,
        items: [
            {
                type: DRAG_ITEM_TYPE.SCREEN,
                icon: 'panorama',
                title: i18n.t('elements.zoomable_image'),
                id: 25,
                attributes: {
                    typeName: SCREEN_TYPE_NAME.WEB,
                    concrete: {
                        url: '',
                        idModule: 16,
                        name: 'zoomable_image.zip',
                        content: `${URL.KUSTODIO_SHARING}/3TkZXizXeQoqTd0y60fyBb.zip`,
                    },
                },
            },
            {
                type: DRAG_ITEM_TYPE.SCREEN,
                icon: 'image-multiple',
                title: i18n.t('elements.pinterest_gallery'),
                id: 26,
                attributes: {
                    typeName: SCREEN_TYPE_NAME.WEB,
                    concrete: {
                        url: '',
                        idModule: 17,
                        name: 'pinterest_style.zip',
                        content: `${URL.KUSTODIO_SHARING}/75mJdwn3t7knMA0IrvvRQ8.zip`,
                    },
                },
            },
            {
                type: DRAG_ITEM_TYPE.SCREEN,
                icon: 'camera-burst',
                title: i18n.t('elements.db_gallery'),
                id: 45,
                installed: true,
                attributes: {
                    typeName: SCREEN_TYPE_NAME.DATAVIEW,
                    concrete: {},
                },
            },
        ],
    },
    {
        title: i18n.t('elements.screen_redirection'),
        limit: 3,
        items: [
            {
                type: DRAG_ITEM_TYPE.SCREEN,
                icon: 'city',
                title: i18n.t('elements.by_city'),
                id: 27,
                attributes: {
                    typeName: SCREEN_TYPE_NAME.WEB,
                    concrete: {
                        url: '',
                        idModule: 4,
                        name: 'bifurcation_by_city.zip',
                        content: `${URL.KUSTODIO_SHARING}/5nIRzrt0wkrDvOAxFzuJxT.zip`,
                    },
                },
            },
            {
                type: DRAG_ITEM_TYPE.SCREEN,
                icon: 'earth',
                title: i18n.t('elements.by_country'),
                id: 28,
                attributes: {
                    typeName: SCREEN_TYPE_NAME.WEB,
                    concrete: {
                        url: '',
                        idModule: 6,
                        name: 'bifurcation_by_country.zip',
                        content: `${URL.KUSTODIO_SHARING}/19fmKJuf9i9bKgg0ZL8bXY.zip`,
                    },
                },
            },
            {
                type: DRAG_ITEM_TYPE.SCREEN,
                icon: 'translate',
                title: i18n.t('elements.by_language'),
                id: 29,
                attributes: {
                    typeName: SCREEN_TYPE_NAME.WEB,
                    concrete: {
                        url: '',
                        idModule: 5,
                        name: 'bifurcation_by_language.zip',
                        content: `${URL.KUSTODIO_SHARING}/3jzMYIdv46bozS95ROaPSO.zip`,
                    },
                },
            },
            {
                type: DRAG_ITEM_TYPE.SCREEN,
                icon: 'memory',
                title: i18n.t('elements.by_os'),
                id: 30,
                attributes: {
                    typeName: SCREEN_TYPE_NAME.WEB,
                    concrete: {
                        url: '',
                        idModule: 7,
                        name: 'bifurcation_by_os.zip',
                        content: `${URL.KUSTODIO_SHARING}/gxhEa8KYF1DOrug0MByPq.zip`,
                    },
                },
            },
        ],
    },
    {
        title: i18n.t('elements.games'),
        limit: 3,
        items: [
            {
                type: DRAG_ITEM_TYPE.SCREEN,
                icon: 'apps',
                title: i18n.t('elements.find_pairs'),
                id: 31,
                attributes: {
                    typeName: SCREEN_TYPE_NAME.WEB,
                    concrete: {
                        url: '',
                        idModule: 23,
                        name: 'find_pairs.zip',
                        content: `${URL.KUSTODIO_SHARING}/44VzjmtH3W9JW3gK5xNLoP.zip`,
                    },
                },
            },
            {
                type: DRAG_ITEM_TYPE.SCREEN,
                icon: 'format-list-numbered',
                title: i18n.t('elements.endless_quiz'),
                id: 32,
                attributes: {
                    typeName: SCREEN_TYPE_NAME.WEB,
                    concrete: {
                        url: '',
                        idModule: 24,
                        name: 'endless_quiz.zip',
                        content: `${URL.KUSTODIO_SHARING}/13gfgmT6NcH21n7wEOtWmx.zip`,
                    },
                },
            },
        ],
    },
    {
        title: i18n.t('elements.design'),
        limit: 3,
        items: [
            {
                type: DRAG_ITEM_TYPE.SCREEN,
                icon: 'format-align-left',
                title: i18n.t('elements.rich_paragraph'),
                id: 33,
                attributes: {
                    typeName: SCREEN_TYPE_NAME.WEB,
                    concrete: {
                        url: '',
                        idModule: 10,
                        name: 'rich_paragraph.zip',
                        content: `${URL.KUSTODIO_SHARING}/0r3Uzd6BcLI3sudDQe3qK.zip`,
                    },
                },
            },
            {
                type: DRAG_ITEM_TYPE.SCREEN,
                icon: 'book-open',
                title: i18n.t('elements.magazine'),
                id: 34,
                attributes: {
                    typeName: SCREEN_TYPE_NAME.WEB,
                    concrete: {
                        url: '',
                        idModule: 27,
                        name: 'magazine.zip',
                        content: `${URL.KUSTODIO_SHARING}/6K3NTfTfNpR3vyuJ15q6uY.zip`,
                    },
                },
            },
            {
                type: DRAG_ITEM_TYPE.SCREEN,
                icon: 'format-align-left',
                title: i18n.t('elements.zoomable_text'),
                id: 35,
                attributes: {
                    typeName: SCREEN_TYPE_NAME.WEB,
                    concrete: {
                        url: '',
                        idModule: 28,
                        name: 'zoomable_text.zip',
                        content: `${URL.KUSTODIO_SHARING}/2E7OmW2KnZGkhKxScKpDNW.zip`,
                    },
                },
            },
            {
                type: DRAG_ITEM_TYPE.SCREEN,
                icon: SCREEN_ICON.ACTIONIMAGE,
                title: i18n.t('elements.interactive_image'),
                id: 37,
                installed: true,
                attributes: {
                    typeName: SCREEN_TYPE_NAME.ACTIONIMAGE,
                    concrete: {},
                },
            },
        ],
    },
    {
        title: i18n.t('elements.maps'),
        limit: 3,
        items: [
            {
                type: DRAG_ITEM_TYPE.SCREEN,
                icon: SCREEN_ICON.MAP,
                title: i18n.t('elements.map'),
                id: 40,
                installed: true,
                attributes: {
                    typeName: SCREEN_TYPE_NAME.MAP,
                    concrete: {},
                },
            },
            {
                type: DRAG_ITEM_TYPE.SCREEN,
                icon: 'map-marker-multiple',
                title: i18n.t('elements.db_map'),
                id: 46,
                installed: true,
                attributes: {
                    typeName: SCREEN_TYPE_NAME.DATAVIEW,
                    concrete: {},
                },
            },
        ],
    },
    {
        title: i18n.t('elements.date'),
        limit: 3,
        items: [
            {
                type: DRAG_ITEM_TYPE.SCREEN,
                icon: SCREEN_ICON.CALENDAR,
                title: i18n.t('elements.calendar'),
                id: 38,
                installed: true,
                attributes: {
                    typeName: SCREEN_TYPE_NAME.CALENDAR,
                    concrete: {},
                },
            },
            {
                type: DRAG_ITEM_TYPE.SCREEN,
                icon: 'calendar-range',
                title: i18n.t('elements.db_calendar'),
                id: 44,
                installed: true,
                attributes: {
                    typeName: SCREEN_TYPE_NAME.DATAVIEW,
                    concrete: {},
                },
            },
        ],
    },
];
