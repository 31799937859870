import React from 'react';
import { useTranslation } from 'react-i18next';
import { CardBody, CardTitle, Input } from 'reactstrap';
import { ElementWrapper } from '.';
import { useElements } from '../../../hooks';

export const Bar = ({ element }) => {
    const { t } = useTranslation();
    const { updateElement } = useElements();
    const { concrete } = element;
    const { title } = concrete;

    const handleBlur = (event) => {
        const { currentTarget } = event;
        const { value } = currentTarget;
        if (value === title) {
            return;
        }
        updateElement({ concrete: { title: value } }, element);
    };

    return (
        <ElementWrapper element={element}>
            <CardBody>
                <CardTitle>
                    <strong>{t('elements.bar_name')}</strong>
                </CardTitle>
                <Input onBlur={handleBlur} defaultValue={title} />
            </CardBody>
        </ElementWrapper>
    );
};
