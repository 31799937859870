import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Panel } from '../components';
import { useElements, useScreens, useViewer } from '../hooks';
import { Element, Screen } from '.';

export const PropertiesPanel = () => {
    const { t } = useTranslation();
    const { element = {} } = useElements();
    const { screen = {} } = useScreens();
    const { getPanelWidth, isVisiblePropertiesPanel, setIsVisiblePropertiesPanel } = useViewer();

    useEffect(() => {
        (element.id || screen.id) && setIsVisiblePropertiesPanel(true);
    }, [element.id, screen.id]);

    return (
        <Panel
            className={`properties-panel${!isVisiblePropertiesPanel ? ' d-none' : ''}`}
            disabled={!screen.id && !element.id}
            panel="properties"
            title={t('properties.title')}
            width={getPanelWidth('properties')}
        >
            {(element.id && <Element element={element} />) || (screen.id && <Screen {...screen} />)}
        </Panel>
    );
};
