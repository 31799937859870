import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from 'reactstrap';
import { MODALS } from '../../../_config';
import { getLanguage } from '../../../_helpers';
import { FilePreview, Icon } from '../../../components';
import { useModals, useResources, useScreens } from '../../../hooks';

export const FileProperty = ({
    accept = {},
    onChange: handelChange,
    buttonLabel: _buttonLabel,
    value,
}) => {
    const { t } = useTranslation();
    const { openModal, setModal } = useModals();
    const { getBlobUrl, resources = [] } = useResources();
    const { screen = {} } = useScreens();
    let { concrete = {} } = screen;
    concrete = concrete || {};
    const { idZip } = concrete;
    const zipRes = resources.find(({ id }) => id === idZip) || {};
    const { entries = [] } = zipRes;
    const lang = getLanguage();
    const buttonLabel =
        typeof _buttonLabel === 'string'
            ? _buttonLabel
            : (_buttonLabel && _buttonLabel[lang]) || t('properties.select');
    const resource =
        resources.find(
            ({ Filename, filename }) => `../${Filename}` === value || `../${filename}` === value
        ) || {};
    const entry = entries.find(({ name }) => name === value) || {};
    const [, update] = useState();

    useEffect(() => {
        let mounted = true;
        if (!resource.id) {
            return;
        }
        (async () => {
            await getBlobUrl(resource);
            if (mounted) {
                update(Date.now());
            }
        })();

        return () => {
            mounted = false;
        };
    }, [resource]);

    const filter = (res) => {
        let { fileType: filTypes, type: types } =
            typeof accept === 'string'
                ? { type: accept }
                : { fileType: (accept.name || '').replace('.', ''), ...accept };
        let show = false;
        if (filTypes) {
            filTypes = Array.isArray(filTypes) ? filTypes : [filTypes];
            let { filename, Filename, name } = res;
            show =
                show ||
                filTypes.some((fileType) =>
                    `${filename}${Filename}${name}`.includes(`.${fileType}`)
                );
        }
        if (types) {
            types = Array.isArray(types) ? types : [types];
            show = show || types.some((type) => type === 'file/*' || type === res.type);
        }
        return show;
    };

    const handleDelete = () => {
        handelChange('');
    };

    const handleResourceClick = (resource) => {
        const { Filename, filename } = resource;
        handelChange(`../${Filename || filename}`);
        setModal(null);
    };

    const handleClick = () => {
        openModal(MODALS.RESOURCES, { filter, onClick: handleResourceClick });
    };

    return (
        <>
            {value && (
                <FilePreview
                    className="property-file-preview mb-2"
                    {...resource}
                    {...entry}
                    name={resource.Filename || resource.name || entry.name}
                    style={{ height: '140px', width: '200px' }}
                />
            )}
            <div className="d-flex">
                <Button className="font-size-16 text-uppercase" onClick={handleClick}>
                    <small>{buttonLabel}</small>
                </Button>
                {value && (
                    <Button className="ml-2 border-0" color="danger" onClick={handleDelete} outline>
                        <Icon type="delete" />
                    </Button>
                )}
            </div>
        </>
    );
};
