import React, { useEffect, useState } from 'react';
import parse from 'html-react-parser';
import { useTranslation } from 'react-i18next';
import ls from 'local-storage';
import { Empty, Menu, message } from 'antd';
import {
    Button,
    Card,
    CardBody,
    CardDeck,
    CardFooter,
    CardHeader,
    CardTitle,
    FormGroup,
    Input,
    Label,
    ModalBody,
} from 'reactstrap';
import { MODALS, URL } from '../_config';
import { Icon, Modal } from '../components';
import { useApplications, useModals } from '../hooks';
import {
    appAnnie,
    doyoStories,
    firebase as firebaseLogo,
    googleTrends,
    iPage,
    kustodio,
    launchKit,
    sensorTower,
    wideo,
} from '../assets/img/tools';

export const TOOL_TYPE = {
    APP_ANNIE: 'appAnnnie',
    DOYO_STORIES: 'doyoStories',
    FIREBASE: 'firebase',
    GOOGLE_TRENDS: 'googleTrends',
    IPAGE: 'iPage',
    KUSTODIO: 'kustodio',
    LAUNCH_KIT: 'launchKit',
    SENSOR_TOWER: 'sensorTower',
    WIDEO: 'wideo',
};

const AppAnnie = () => {
    const { t } = useTranslation();
    return (
        <>
            <img src={appAnnie} alt="App Annie logo" />
            <p>{parse(t('tools.appannie_description'))}</p>
            <a href={t('tools.appannie_link')} target="_blank" rel="noopener noreferrer">
                {t('tools.appannie_link')}
            </a>
        </>
    );
};

const DoyoStories = () => {
    const { t } = useTranslation();
    const { doyoAuth = {} } = ls('user') || {};
    const { token } = doyoAuth;
    return (
        <>
            <img src={doyoStories} alt="DoyoStories logo" />
            <p>{parse(t('tools.doyo_stories_description'))}</p>
            <a href={`${URL.DOYO_STORIES}/t/${token}`} target="_blank" rel="noopener noreferrer">
                {t('tools.doyo_stories_link')}
            </a>
        </>
    );
};

const Firebase = () => {
    const { t } = useTranslation();
    const { application = {}, getFirebase, updateFirebase } = useApplications();
    const { firebase = {} } = application;
    const { androidFile, androidServerKey, iosFile } = firebase;

    const handleClick = (event) => {
        const { currentTarget } = event;
        const { nextElementSibling } = currentTarget;
        nextElementSibling.click();
    };

    const handleChange = (event) => {
        const { currentTarget } = event;
        const { files, name } = currentTarget;
        const file = files[0];
        if (!['google-services.json', 'google-services.json'].includes(file.name)) {
            currentTarget.value = '';
            message.error(t('tools.these_files_dont_have_a_valid_format'));
            return;
        }
        updateFirebase({ application, file, firebase, name });
    };

    const handleBlur = (event) => {
        const { currentTarget } = event;
        const { name, value } = currentTarget;
        updateFirebase({ application, firebase, name, value });
    };

    useEffect(() => {
        getFirebase({ application });
    }, []);

    return (
        <>
            <img src={firebaseLogo} alt="Firebase logo" />
            <div>{parse(t('tools.firebase_description'))}</div>
            <a href={t('tools.firebase_link')} target="_blank" rel="noopener noreferrer">
                {t('tools.firebase_link')}
            </a>
            <CardDeck className="mt-3">
                <Card>
                    <CardHeader>
                        <CardTitle>Android</CardTitle>
                        <small className="text-muted">google-services.json</small>
                    </CardHeader>
                    <CardBody>
                        {androidFile ? (
                            <Empty
                                image={<Icon className="icon-success" type="file-check" />}
                                description={t('tools.file_uploaded')}
                            />
                        ) : (
                            <Empty description={t('tools.please_upload_file')} />
                        )}
                    </CardBody>
                    <CardFooter>
                        <Button color="link" onClick={handleClick}>
                            {t('tools.upload')}
                        </Button>
                        <input
                            className="d-none"
                            type="file"
                            name="androidFile"
                            onChange={handleChange}
                            accept=".json"
                        />
                    </CardFooter>
                </Card>
                <Card>
                    <CardHeader>
                        <CardTitle>iOS</CardTitle>
                        <small className="text-muted">firebase.plist</small>
                    </CardHeader>
                    <CardBody>
                        {iosFile ? (
                            <Empty
                                image={<Icon className="icon-success" type="file-check" />}
                                description={t('tools.file_uploaded')}
                            />
                        ) : (
                            <Empty description={t('tools.please_upload_file')} />
                        )}
                    </CardBody>
                    <CardFooter>
                        <Button color="link" onClick={handleClick}>
                            {t('tools.upload')}
                        </Button>
                        <input
                            className="d-none"
                            type="file"
                            name="iosFile"
                            onChange={handleChange}
                            accept=".plist"
                        />
                    </CardFooter>
                </Card>
            </CardDeck>
            <FormGroup className="mt-3">
                <Label className="h6 small d-block text-muted text-uppercase">
                    {t('tools.android_server_key')}
                </Label>
                <Input
                    type="text"
                    name="androidServerKey"
                    defaultValue={androidServerKey}
                    onBlur={handleBlur}
                />
            </FormGroup>
        </>
    );
};

const GoogleTrends = () => {
    const { t } = useTranslation();
    return (
        <>
            <img src={googleTrends} alt="Google Trends logo" />
            <p>{parse(t('tools.google_trends_description'))}</p>
            <a href={t('tools.google_trends_link')} target="_blank" rel="noopener noreferrer">
                {t('tools.google_trends_link')}
            </a>
        </>
    );
};

const IPage = () => {
    const { t } = useTranslation();
    return (
        <>
            <img src={iPage} alt="iPage logo" />
            <p>{parse(t('tools.ipage_description'))}</p>
            <a href={t('tools.ipage_link')} target="_blank" rel="noopener noreferrer">
                {t('tools.ipage_link')}
            </a>
        </>
    );
};

const LaunchKit = () => {
    const { t } = useTranslation();
    return (
        <>
            <img src={launchKit} alt="LaunchKit logo" />
            <p>{parse(t('tools.launchkit_description'))}</p>
            <a href={t('tools.launchkit_link')} target="_blank" rel="noopener noreferrer">
                {t('tools.launchkit_link')}
            </a>
        </>
    );
};

const Kustodio = () => {
    const { t } = useTranslation();
    const { doyoAuth = {} } = ls('user') || {};
    const { token } = doyoAuth;
    return (
        <>
            <img src={kustodio} alt="Kustodio logo" />
            <p>{parse(t('tools.kustodio_description'))}</p>
            <a href={`${URL.KUSTODIO}/t/${token}`} target="_blank" rel="noopener noreferrer">
                {t('tools.kustodio_link')}
            </a>
        </>
    );
};

const SensorTower = () => {
    const { t } = useTranslation();
    return (
        <>
            <img src={sensorTower} alt="Sensor Tower logo" />
            <p>{parse(t('tools.sensor_tower_description'))}</p>
            <a href={t('tools.sensor_tower_link')} target="_blank" rel="noopener noreferrer">
                {t('tools.sensor_tower_link')}
            </a>
        </>
    );
};

const Wideo = () => {
    const { t } = useTranslation();
    return (
        <>
            <img src={wideo} alt="Wideo logo" />
            <p>{parse(t('tools.wideo_description'))}</p>
            <a href={t('tools.wideo_link')} target="_blank" rel="noopener noreferrer">
                {t('tools.wideo_link')}
            </a>
        </>
    );
};

const ToolContent = ({ content }) => {
    switch (content) {
        case TOOL_TYPE.APP_ANNIE:
            return <AppAnnie />;
        case TOOL_TYPE.DOYO_STORIES:
            return <DoyoStories />;
        case TOOL_TYPE.FIREBASE:
            return <Firebase />;
        case TOOL_TYPE.GOOGLE_TRENDS:
            return <GoogleTrends />;
        case TOOL_TYPE.IPAGE:
            return <IPage />;
        case TOOL_TYPE.KUSTODIO:
            return <Kustodio />;
        case TOOL_TYPE.LAUNCH_KIT:
            return <LaunchKit />;
        case TOOL_TYPE.SENSOR_TOWER:
            return <SensorTower />;
        case TOOL_TYPE.WIDEO:
            return <Wideo />;
        default:
            return null;
    }
};

export const Tools = ({ isOpen: isOpenProp }) => {
    const { t } = useTranslation();
    const [isOpen, setIsOpen] = useState(isOpenProp);
    const { modal, setModal, key = 'firebase' } = useModals();
    const [content, setContent] = useState(key);

    useEffect(() => {
        setIsOpen(modal === MODALS.TOOLS);
    }, [modal]);

    const handleMenuClick = ({ key }) => {
        setContent(key);
    };

    return (
        <Modal
            className="tools modal-menu max-80"
            isOpen={isOpen}
            title={t('tools.title')}
            onClosed={() => setModal(null)}
        >
            <ModalBody>
                <Menu
                    className="w-auto"
                    mode="inline"
                    onClick={handleMenuClick}
                    defaultSelectedKeys={[content]}
                >
                    <Menu.Item key={TOOL_TYPE.APP_ANNIE}>{t('header.app_annie')}</Menu.Item>
                    <Menu.Item key={TOOL_TYPE.FIREBASE}>{t('header.firebase')}</Menu.Item>
                    <Menu.Item key={TOOL_TYPE.GOOGLE_TRENDS}>{t('header.google_trends')}</Menu.Item>
                    <Menu.Item key={TOOL_TYPE.KUSTODIO}>{t('header.kustodio')}</Menu.Item>
                    <Menu.Item key={TOOL_TYPE.LAUNCH_KIT}>{t('header.launch_kit')}</Menu.Item>
                    <Menu.Item key={TOOL_TYPE.SENSOR_TOWER}>{t('header.sensor_tower')}</Menu.Item>
                    <Menu.Item key={TOOL_TYPE.WIDEO}>{t('header.wideo')}</Menu.Item>
                </Menu>
                <ModalBody className="px-5" data-key={content}>
                    <ToolContent content={content} />
                </ModalBody>
            </ModalBody>
        </Modal>
    );
};
