import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Select, Tooltip } from 'antd';
import {
    Button,
    CardBody,
    CardTitle,
    Input,
    InputGroup,
    InputGroupAddon,
    Label,
    ListGroupItem,
} from 'reactstrap';
import { MODALS } from '../_config';
import { ACTION_LABEL, ACTION_TYPE, RESOURCE_TYPE } from '../context';
import { useActions, useModals, useResources, useScreens } from '../hooks';
import { Icon } from '.';

const { Option } = Select;

const ACTION_RESOURCE_TYPE = {
    [ACTION_TYPE.PLAY]: RESOURCE_TYPE.AUDIO,
    [ACTION_TYPE.STOP]: RESOURCE_TYPE.AUDIO,
};

export const ResourceSelector = ({
    action,
    isProperty = false,
    onChange: handleChange,
    value: valueProp,
}) => {
    const { t } = useTranslation();
    const { unselectResource } = useResources();
    const { openModal, setModal } = useModals();
    const [value, setValue] = useState(valueProp);

    useEffect(() => {
        setValue(valueProp);
    }, [valueProp]);

    const handleClick = () => {
        unselectResource();
        const filter = ({ type }) => type === ACTION_RESOURCE_TYPE[action];
        const onClick = (resource) => {
            const { id = '', type = '' } = resource;
            resource && handleChange(`@${type.toLowerCase()}${id}`);
            setModal(null);
        };
        openModal(MODALS.RESOURCES, { filter, onClick });
    };

    return (
        <ul className="m-0 p-0">
            {value && (
                <ListGroupItem action className="bg-transparent border-0 d-flex p-0 mb-3">
                    <div className="list-group-item-title flex-grow-1">{value}</div>
                    <Tooltip title={t('resources.delete_resource')}>
                        <Button
                            className="border-0 rounded-circle"
                            color="danger"
                            outline
                            onClick={() => handleChange('')}
                        >
                            <Icon type="delete" />
                        </Button>
                    </Tooltip>
                </ListGroupItem>
            )}
            <Button onClick={handleClick}>{t('properties.select')}</Button>
        </ul>
    );
};

const getParameterToShow = ({ action, parameter, screens }) => {
    parameter = parameter.replace(/(^"|"$)/g, '');
    switch (action) {
        case ACTION_TYPE.PLAY:
        case ACTION_TYPE.STOP:
            var resource = parameter;
            try {
                var json = JSON.parse(parameter);
                resource = json.resource;
            } catch (e) {}
            return resource;

        case ACTION_TYPE.SCREEN:
            const screen = screens.find(({ Nombre: name }) => name === parameter) || {};
            const { id } = screen;
            return `${id}`;

        default:
            return parameter;
    }
};

const mountParameter = ({ action, parameter, screens }) => {
    switch (action) {
        case ACTION_TYPE.PLAY:
        case ACTION_TYPE.STOP:
            return JSON.stringify({ resource: parameter, loop: false });

        case ACTION_TYPE.SCREEN:
            const screen = screens.find(({ id }) => id === parseInt(parameter, 10)) || {};
            const { Nombre: name = '' } = screen;
            return name;

        default:
            return parameter;
    }
};

export const Functions = ({ onChange: handleChange, value: valueProp }) => {
    const { t } = useTranslation();
    const { getIdFromLabel, getLabelFromId } = useActions();
    const { screens = [] } = useScreens();
    const functions = valueProp.split(';').map((func) => {
        let [, label, parameter] = func.match(/([^(]*)\(([^)]*)\)/) || [
            null,
            func || ACTION_LABEL[ACTION_TYPE.UNDEFINED],
            '',
        ];
        const action = getIdFromLabel(label);
        parameter = getParameterToShow({ action, parameter, screens });
        return { action, parameter };
    });
    const [value, setValue] = useState(functions);

    useEffect(() => {
        const functions = value.map(({ action: _action, parameter: _parameter }) => {
            const action = getLabelFromId(_action);
            let parameter = _parameter;
            parameter = mountParameter({ action, parameter, screens });
            return action ? `${action}("${parameter}")` : '';
        });
        const parameter = functions.join(';');
        handleChange(parameter);
    }, [value]);

    const handleAddAction = () => setValue((value) => [...value, { action: 0, parameter: '' }]);

    const handleRemoveAction = (index) =>
        setValue((value) => value.filter((func, i) => i !== index));

    const handleChangeAction = useCallback(
        ({ action, parameter }, index) => {
            setValue((value) =>
                value.map((func, i) => (i !== index ? func : { action, parameter }))
            );
        },
        [value]
    );

    return (
        <ul className="m-0 p-0">
            {value.map(({ action, parameter }, index) => (
                <ListGroupItem key={`Function-${index}`} className="bg-transparent p-0">
                    <Action
                        action={action}
                        parameter={parameter}
                        onChange={(params) => handleChangeAction(params, index)}
                        blackListActions={[ACTION_TYPE.FUNCTIONS]}
                    />
                    <Tooltip title={t('common.delete')}>
                        <Button
                            className="border-0 rounded-circle mt-2 position-absolute-top-right-0"
                            color="danger"
                            outline
                            onClick={() => handleRemoveAction(index)}
                        >
                            <Icon type="delete" />
                        </Button>
                    </Tooltip>
                </ListGroupItem>
            ))}
            <Button className="mt-3" onClick={handleAddAction}>
                {t('common.add')}
            </Button>
        </ul>
    );
};

export const ScreenSelector = ({
    isProperty = false,
    onChange: handleChange,
    value: valueProp,
}) => {
    const { t } = useTranslation();
    const { createScreen, screens = [] } = useScreens();
    const [value, setValue] = useState(valueProp);
    const [name, setName] = useState('');

    useEffect(() => {
        setValue(valueProp);
    }, [valueProp]);

    const onNameChange = (event) => {
        const { currentTarget } = event;
        const { value } = currentTarget;
        setName(value);
    };

    const addScreen = () => {
        createScreen({ Nombre: name }, { select: false }).then(
            ({ id } = {}) => id && handleChange(id)
        );
    };

    return (
        <Select
            className="w-100"
            placeholder={t('properties.select_screen')}
            value={value}
            onChange={handleChange}
            showSearch
            filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
            dropdownRender={(menu) => (
                <div>
                    {menu}
                    <hr />
                    <div className="px-3 pb-2">
                        <Label className="h6 small d-block text-uppercase">{t('header.new')}</Label>
                        <InputGroup>
                            <Input value={name} onChange={onNameChange} />
                            <InputGroupAddon addonType="append">
                                <Button onClick={addScreen}>
                                    <Icon type="plus" />
                                </Button>
                            </InputGroupAddon>
                        </InputGroup>
                    </div>
                </div>
            )}
        >
            <Option disabled value="">
                {t('properties.select_screen')}
            </Option>
            {screens
                .sort((s1, s2) => (s1.Nombre < s2.Nombre ? -1 : 1))
                .map(({ id, Nombre: name }) => (
                    <Option
                        key={`ScreenParameter${id}`}
                        value={isProperty ? encodeURI(name) : `${id}`}
                    >
                        {name}
                    </Option>
                ))}
        </Select>
    );
};

const Parameter = ({ action, parameter, onChange: handleParameterChange }) => {
    const { t } = useTranslation();
    const {
        hasFunctionsParameter,
        hasInputParameter,
        hasResourceParameter,
        hasScreenParameter,
    } = useActions();

    const handleInputChange = (event) => {
        const { currentTarget } = event;
        const { value } = currentTarget;
        handleParameterChange(value);
    };

    const handleFunctionsChange = (parameter) => {
        handleParameterChange(parameter);
    };

    return (
        <>
            <CardTitle>
                <strong>{t(`actions.parameters.${action}`)}</strong>
            </CardTitle>
            {hasResourceParameter(action) && (
                <ResourceSelector
                    action={action}
                    value={parameter}
                    onChange={handleParameterChange}
                />
            )}
            {hasScreenParameter(action) && (
                <ScreenSelector value={parameter} onChange={handleParameterChange} />
            )}
            {hasInputParameter(action) && (
                <Input defaultValue={parameter} onBlur={handleInputChange} />
            )}
            {hasFunctionsParameter(action) && (
                <Functions value={parameter} onChange={handleFunctionsChange} />
            )}
        </>
    );
};

export const Action = ({
    action: actionProp,
    parameter: parameterProp,
    onChange: handleChange = () => {},
    blackListActions = [],
}) => {
    const { t } = useTranslation();
    const [action, setAction] = useState(actionProp);
    const [parameter, setParameter] = useState(parameterProp);
    const { getActions, hasParameter } = useActions();
    const { screen = {} } = useScreens();

    useEffect(() => {
        setAction(actionProp);
    }, [actionProp]);

    useEffect(() => {
        setParameter(parameterProp);
    }, [parameterProp]);

    const handleActionChange = (action) => {
        setAction(action);
        handleChange({ action, parameter: '' });
    };

    const handleParameterChange = (parameter) => {
        setParameter(parameter);
        handleChange({ action, parameter });
    };

    return (
        <>
            <CardBody className="action">
                <CardTitle>
                    <strong>{t('properties.action')}</strong>
                </CardTitle>
                <Select
                    className="w-100"
                    value={t(`actions.${action}`)}
                    onChange={handleActionChange}
                    showSearch
                    filterOption={(input, option) =>
                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                >
                    {getActions(screen)
                        .filter((action) => !blackListActions.includes(action))
                        .sort((a1, a2) => (t(`actions.${a1}`) < t(`actions.${a2}`) ? -1 : 1))
                        .map((action) => (
                            <Option key={`Action${action}`} value={action}>
                                {t(`actions.${action}`)}
                            </Option>
                        ))}
                </Select>
            </CardBody>
            {hasParameter(action) && (
                <CardBody className="parameter">
                    <Parameter
                        action={action}
                        parameter={parameter}
                        onChange={handleParameterChange}
                    />
                </CardBody>
            )}
        </>
    );
};
