import React from 'react';
import { useTranslation } from 'react-i18next';
import { CardBody, CardTitle } from 'reactstrap';
import { Select } from 'antd';
import { useElements } from '../../../hooks';
import { ElementWrapper } from '.';

const { Option } = Select;

export const Separator = ({ element }) => {
    const { t } = useTranslation();
    const { updateElement } = useElements();
    const { concrete = {} } = element;
    const { lineStyle = 'solid' } = concrete;

    const handleChangeStyle = (lineStyle) => {
        updateElement({ concrete: { lineStyle } }, element);
    };

    return (
        <ElementWrapper element={element}>
            <CardBody>
                <CardTitle>
                    <strong>{t('properties.style')}</strong>
                </CardTitle>
                <Select defaultValue={lineStyle} onChange={handleChangeStyle}>
                    <Option value="none">{t('properties.none_line')}</Option>
                    <Option value="solid">{t('properties.solid_line')}</Option>
                    <Option value="dashed">{t('properties.dashed_line')}</Option>
                    <Option value="dotted">{t('properties.dotted_line')}</Option>
                    <Option value="shaded">{t('properties.shaded_line')}</Option>
                </Select>
            </CardBody>
        </ElementWrapper>
    );
};
