import React, { useEffect, useRef } from 'react';
import { useElements, useResources } from '../../../hooks';
import { ElementWrapper } from '.';
import defaultImage from '../../../assets/img/no-image.png';

export const Image = React.memo(({ element }) => {
    const image = useRef();
    const { updateElement } = useElements();
    const { getBlobUrl, resources = [] } = useResources();
    const { concrete = {} } = element;
    const { IDImage } = concrete;
    const imageRes = resources.find(({ id }) => id === IDImage) || {};
    const { name } = imageRes;

    useEffect(() => {
        let mounted = true;
        if (!imageRes.id) {
            if (IDImage) {
                image.current.src = defaultImage;
                updateElement({ concrete: { IDImage: 0 } }, element);
            }
            return;
        }
        if (imageRes.online) {
            image.current.src = imageRes.url;
            return;
        }

        (async () => {
            const url = await getBlobUrl(imageRes);
            if (mounted) {
                image.current.src = url;
            }
        })();

        return () => {
            mounted = false;
        };
    }, [image.current, IDImage, imageRes.version]);

    return (
        <ElementWrapper element={element}>
            <div className="element-content">
                <img alt={name} ref={image} src={defaultImage} />
            </div>
        </ElementWrapper>
    );
});
