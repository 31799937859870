export const PLANS = {
    INITIAL_STATE: { plans: [] },
    LIST: 'PLANS_LIST',
};

export const PlansReducer = (draft, action) => {
    const { payload, type } = action;

    switch (type) {
        case PLANS.LIST:
            draft.plans = payload.plans;
            break;

        default:
            return draft;
    }
};
