import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import parse from 'html-react-parser';
import { Menu } from 'antd';
import {
    Badge,
    Button,
    Card,
    CardBody,
    CardDeck,
    CardFooter,
    CardImg,
    CardImgOverlay,
    CardTitle,
    FormGroup,
    Input,
    Label,
    ModalBody,
} from 'reactstrap';
import { MODALS } from '../_config';
import { Icon, Modal } from '../components';
import {
    FORMAT_ID,
    FORMAT_IMAGE,
    FORMAT_TYPE,
    PLATFORM_ID,
    PLATFORM_TYPE,
    PROVIDER_DATA,
    PROVIDER_ID,
    PROVIDER_TYPE,
    SERVICE_TYPE,
} from '../context';
import { useAdvertisement, useApplications, useModals } from '../hooks';
import { interstitial, native, videopreroll } from '../assets/img/adFormats';
import {
    adcolony,
    admobnative,
    amazon,
    facebook,
    startapp,
    tappx,
    unityads,
} from '../assets/img/adProviders';

const Formats = ({ pattern, provider, showCodes = true, labelCode, onBlur }) => {
    const { t } = useTranslation();
    const { codes = [], formats = [], updateCodes, setFormatDisabled } = useAdvertisement();

    const handleInputBlur = (event) => {
        const { currentTarget } = event;
        const { value: code, dataset } = currentTarget;
        let { disabled, format, platform } = dataset;
        format = parseInt(format, 10);
        platform = parseInt(platform, 10);

        updateCodes({
            provider,
            codes: [{ code, disabled, format, platform }],
        });
    };

    const handleToggleDisabled = ({ disabled, format }) => {
        setFormatDisabled({ disabled, format, provider });
    };

    return (
        <CardDeck className="flex-wrap">
            {formats
                .filter(
                    ({ format, provider: formatProvider }) =>
                        format !== FORMAT_ID.APPID && provider === formatProvider
                )
                .map(({ id, disabled, format, provider: formatProvider }) => {
                    const formats = PROVIDER_DATA[provider];
                    const { platforms = [] } = formats.find(({ type }) => type === format) || {};

                    return (
                        <Card key={`Format-${id}`}>
                            <div className="position-relative">
                                {disabled ? null : (
                                    <Badge color="success" pill>
                                        <Icon type="check" />
                                    </Badge>
                                )}
                                <CardImg
                                    top
                                    width="100%"
                                    src={FORMAT_IMAGE[format]}
                                    alt={t(`monetize.${FORMAT_TYPE[format]}`)}
                                />
                                <CardImgOverlay>
                                    <CardTitle>{t(`monetize.${FORMAT_TYPE[format]}`)}</CardTitle>
                                </CardImgOverlay>
                            </div>
                            {showCodes && (
                                <CardBody>
                                    {platforms.map((platform) => {
                                        let { adCode = '' } =
                                            codes.find(
                                                ({ format: f, platform: pl, provider: pr }) =>
                                                    f === format &&
                                                    pr === provider &&
                                                    pl === platform
                                            ) || {};
                                        adCode = !pattern
                                            ? adCode
                                            : (adCode.match(pattern) || [])[1] || '';
                                        return (
                                            <FormGroup
                                                key={`Code-${provider}-${format}-${platform}`}
                                            >
                                                {labelCode && <Label>{labelCode}</Label>}
                                                {platforms.length > 1 && (
                                                    <Label>
                                                        {' '}
                                                        {t(`monetize.${PLATFORM_TYPE[platform]}`)}
                                                    </Label>
                                                )}
                                                <Input
                                                    defaultValue={adCode}
                                                    data-disabled={disabled}
                                                    data-format={format}
                                                    data-platform={platform}
                                                    onBlur={onBlur || handleInputBlur}
                                                />
                                            </FormGroup>
                                        );
                                    })}
                                </CardBody>
                            )}
                            <CardFooter>
                                <Button
                                    color="link"
                                    disabled={
                                        !codes.some(
                                            ({ adCode, format: f, provider: p }) =>
                                                f === format && p === provider && adCode
                                        )
                                    }
                                    onClick={() =>
                                        handleToggleDisabled({ disabled: !disabled, format })
                                    }
                                >
                                    {disabled ? t('monetize.enable') : t('monetize.disable')}
                                </Button>
                            </CardFooter>
                        </Card>
                    );
                })}
        </CardDeck>
    );
};

const AdColony = () => {
    const { t } = useTranslation();
    const { codes = [], getFormats, formats = [], updateCodes } = useAdvertisement();
    const { disabled: isInterstitialDisabled } =
        formats.find(
            ({ format, provider }) =>
                provider === PROVIDER_ID.ADCOLONY && format === FORMAT_ID.INTERSTITIAL
        ) || {};
    const { disabled: isVideoDisabled } =
        formats.find(
            ({ format, provider }) =>
                provider === PROVIDER_ID.ADCOLONY && format === FORMAT_ID.VIDEO
        ) || {};
    const { disabled: isVideoPrerollDisabled } =
        formats.find(
            ({ format, provider }) =>
                provider === PROVIDER_ID.ADCOLONY && format === FORMAT_ID.VIDEOPREROLL
        ) || {};
    const { adCode: adCodeAndroid = '' } =
        codes.find(
            ({ platform, provider }) =>
                platform === PLATFORM_ID.ANDROID && provider === PROVIDER_ID.ADCOLONY
        ) || {};
    const appIdAndroid = adCodeAndroid.split(',')[0];
    const { adCode: adCodeIos = '' } =
        codes.find(
            ({ platform, provider }) =>
                platform === PLATFORM_ID.IOS && provider === PROVIDER_ID.ADCOLONY
        ) || {};
    const appIdIos = adCodeIos.split(',')[0];

    const appId = {
        [PLATFORM_ID.ANDROID]: appIdAndroid,
        [PLATFORM_ID.IOS]: appIdIos,
    };

    useEffect(() => {
        getFormats({ provider: PROVIDER_ID.ADCOLONY });
    }, []);

    const handleAppIdInputBlur = useCallback(
        (event) => {
            const { currentTarget } = event;
            const { value: code, name } = currentTarget;
            const platform = parseInt(name, 10);
            const { adCode: interstitialAdCode = '' } =
                codes.find(
                    ({ format: f, platform: pl, provider: pr }) =>
                        f === FORMAT_ID.INTERSTITIAL &&
                        pl === platform &&
                        pr === PROVIDER_ID.ADCOLONY
                ) || {};
            const { adCode: videoAdCode = '' } =
                codes.find(
                    ({ format: f, platform: pl, provider: pr }) =>
                        f === FORMAT_ID.VIDEO && pl === platform && pr === PROVIDER_ID.ADCOLONY
                ) || {};
            const { adCode: prerollAdCode = '' } =
                codes.find(
                    ({ format: f, platform: pl, provider: pr }) =>
                        f === FORMAT_ID.VIDEOPREROLL &&
                        pl === platform &&
                        pr === PROVIDER_ID.ADCOLONY
                ) || {};

            updateCodes({
                provider: PROVIDER_ID.ADCOLONY,
                codes: [
                    {
                        code: `${code},${interstitialAdCode.split(',')[1]}`,
                        disabled: isInterstitialDisabled,
                        format: FORMAT_ID.INTERSTITIAL,
                        platform,
                    },
                    {
                        code: `${code},${videoAdCode.split(',')[1]}`,
                        disabled: isVideoDisabled,
                        format: FORMAT_ID.VIDEO,
                        platform,
                    },
                    {
                        code: `${code},${prerollAdCode.split(',')[1]}`,
                        disabled: isVideoPrerollDisabled,
                        format: FORMAT_ID.VIDEOPREROLL,
                        platform,
                    },
                ],
            });
        },
        [codes]
    );

    const handleInputBlur = (event) => {
        const { currentTarget } = event;
        const { value: code, dataset } = currentTarget;
        let { disabled, format, platform } = dataset;
        format = parseInt(format, 10);
        platform = parseInt(platform, 10);

        updateCodes({
            provider: PROVIDER_ID.ADCOLONY,
            codes: [{ code: `${appId[platform]},${code}`, disabled, format, platform }],
        });
    };

    return (
        <>
            <img className="logo mb-3" src={adcolony} alt="AdColony logo" />
            <p>{parse(t('monetize.adcolony_description'))}</p>
            <a href="https://www.adcolony.com/" target="_blank" rel="noopener noreferrer">
                https://www.adcolony.com/
            </a>
            <hr />
            <h6 className="mt-4">{t('monetize.configuration')}</h6>
            <CardDeck>
                <Card>
                    <CardBody>
                        <FormGroup>
                            <Label>
                                {t('monetize.appid')} {t('monetize.android')}
                            </Label>
                            <Input
                                defaultValue={appIdAndroid}
                                name={PLATFORM_ID.ANDROID}
                                onBlur={handleAppIdInputBlur}
                            />
                        </FormGroup>
                    </CardBody>
                </Card>
                <Card>
                    <CardBody>
                        <FormGroup>
                            <Label>
                                {t('monetize.appid')} {t('monetize.ios')}
                            </Label>
                            <Input
                                defaultValue={appIdIos}
                                name={PLATFORM_ID.IOS}
                                onBlur={handleAppIdInputBlur}
                            />
                        </FormGroup>
                    </CardBody>
                </Card>
            </CardDeck>
            <h6 className="mt-4">{t('monetize.formats')}</h6>
            <Formats
                provider={PROVIDER_ID.ADCOLONY}
                labelCode={t('monetize.zone_id')}
                pattern={`,(.*)$`}
                onBlur={handleInputBlur}
            />
        </>
    );
};

const AdMobNative = () => {
    const { t } = useTranslation();
    const { codes = [], getFormats, updateCodes } = useAdvertisement();
    const { adCode: appIdAndroid = '' } =
        codes.find(
            ({ format, platform, provider }) =>
                format === FORMAT_ID.APPID &&
                platform === PLATFORM_ID.ANDROID &&
                provider === PROVIDER_ID.ADMOBNATIVE
        ) || {};
    const { adCode: appIdIos = '' } =
        codes.find(
            ({ format, platform, provider }) =>
                format === FORMAT_ID.APPID &&
                platform === PLATFORM_ID.IOS &&
                provider === PROVIDER_ID.ADMOBNATIVE
        ) || {};

    useEffect(() => {
        getFormats({ provider: PROVIDER_ID.ADMOBNATIVE });
    }, []);

    const handleAppIdInputBlur = (event) => {
        const { currentTarget } = event;
        const { value: code, name } = currentTarget;
        const platform = parseInt(name, 10);

        updateCodes({
            provider: PROVIDER_ID.ADMOBNATIVE,
            codes: [
                {
                    code,
                    disabled: false,
                    format: FORMAT_ID.APPID,
                    platform,
                },
            ],
        });
    };

    return (
        <>
            <img className="logo mb-3" src={admobnative} alt="AdColony logo" />
            <p>{parse(t('monetize.admob_description'))}</p>
            <a href="https://www.google.com/admob/" target="_blank" rel="noopener noreferrer">
                https://www.google.com/admob/
            </a>
            <hr />
            <h6 className="mt-4">{t('monetize.configuration')}</h6>
            <CardDeck>
                <Card>
                    <CardBody>
                        <FormGroup>
                            <Label>
                                {t('monetize.appid')} ({t('monetize.android')})
                            </Label>
                            <Input
                                defaultValue={appIdAndroid}
                                name={PLATFORM_ID.ANDROID}
                                onBlur={handleAppIdInputBlur}
                            />
                        </FormGroup>
                    </CardBody>
                </Card>
                <Card>
                    <CardBody>
                        <FormGroup>
                            <Label>
                                {t('monetize.appid')} ({t('monetize.ios')})
                            </Label>
                            <Input
                                defaultValue={appIdIos}
                                name={PLATFORM_ID.IOS}
                                onBlur={handleAppIdInputBlur}
                            />
                        </FormGroup>
                    </CardBody>
                </Card>
            </CardDeck>
            <h6 className="mt-4">{t('monetize.formats')}</h6>
            <Formats provider={PROVIDER_ID.ADMOBNATIVE} labelCode={t('monetize.ad_unit_id')} />
        </>
    );
};

const Amazon = () => {
    const { t } = useTranslation();
    const { getFormats } = useAdvertisement();

    useEffect(() => {
        getFormats({ provider: PROVIDER_ID.AMAZON });
    }, []);

    return (
        <>
            <img className="logo mb-3" src={amazon} alt="AdColony logo" />
            <p>{parse(t('monetize.amazon_description'))}</p>
            <a
                href="https://developer.amazon.com/apps-and-games/mobile-ads"
                target="_blank"
                rel="noopener noreferrer"
            >
                https://developer.amazon.com/apps-and-games/mobile-ads
            </a>
            <hr />
            <h6 className="mt-4">{t('monetize.formats')}</h6>
            <Formats provider={PROVIDER_ID.AMAZON} />
        </>
    );
};

const Facebook = () => {
    const { t } = useTranslation();
    const { getFormats } = useAdvertisement();

    useEffect(() => {
        getFormats({ provider: PROVIDER_ID.FACEBOOK });
    }, []);

    return (
        <>
            <img className="logo mb-3" src={facebook} alt="AdColony logo" />
            <p>{parse(t('monetize.facebook_description'))}</p>
            <a
                href="https://developers.facebook.com/products/audience-network"
                target="_blank"
                rel="noopener noreferrer"
            >
                https://developers.facebook.com/products/audience-network
            </a>
            <hr />
            <h6 className="mt-4">{t('monetize.formats')}</h6>
            <Formats provider={PROVIDER_ID.FACEBOOK} />
        </>
    );
};

const StartApp = () => {
    const { t } = useTranslation();
    const { codes = [], getFormats, formats = [], updateCodes } = useAdvertisement();
    const { disabled: isBannerDisabled } =
        formats.find(
            ({ format, provider }) =>
                provider === PROVIDER_ID.STARTAPP && format === FORMAT_ID.BANNER
        ) || {};
    const { disabled: isInterstitialDisabled } =
        formats.find(
            ({ format, provider }) =>
                provider === PROVIDER_ID.TAPPX && format === FORMAT_ID.INTERSTITIAL
        ) || {};
    const { disabled: isOnExitDisabled } =
        formats.find(
            ({ format, provider }) =>
                provider === PROVIDER_ID.STARTAPP && format === FORMAT_ID.ONEXIT
        ) || {};
    const { disabled: isOnReturnDisabled } =
        formats.find(
            ({ format, provider }) =>
                provider === PROVIDER_ID.TAPPX && format === FORMAT_ID.ONRETURN
        ) || {};
    const { disabled: isNativeDisabled } =
        formats.find(
            ({ format, provider }) =>
                provider === PROVIDER_ID.STARTAPP && format === FORMAT_ID.NATIVE
        ) || {};
    const { disabled: isVideoPrerollDisabled } =
        formats.find(
            ({ format, provider }) =>
                provider === PROVIDER_ID.TAPPX && format === FORMAT_ID.VIDEOPREROLL
        ) || {};
    const { disabled: isSplashDisabled } =
        formats.find(
            ({ format, provider }) =>
                provider === PROVIDER_ID.STARTAPP && format === FORMAT_ID.SPLASH
        ) || {};
    const { disabled: isVideoDisabled } =
        formats.find(
            ({ format, provider }) => provider === PROVIDER_ID.TAPPX && format === FORMAT_ID.VIDEO
        ) || {};
    const { adCode: adCodeAndroid = '' } =
        codes.find(
            ({ platform, provider }) =>
                platform === PLATFORM_ID.ANDROID && provider === PROVIDER_ID.STARTAPP
        ) || {};
    const appIdAndroid = adCodeAndroid.split(',')[1];
    const { adCode: adCodeIos = '' } =
        codes.find(
            ({ platform, provider }) =>
                platform === PLATFORM_ID.IOS && provider === PROVIDER_ID.STARTAPP
        ) || {};
    const appIdIos = adCodeIos.split(',')[1];
    const accountId = adCodeAndroid.split(',')[0];

    useEffect(() => {
        getFormats({ provider: PROVIDER_ID.STARTAPP });
    }, []);

    const handleAccountIdInputBlur = (event) => {
        const { currentTarget } = event;
        const { value: code } = currentTarget;
        const androidCode = `${code},${appIdAndroid}`;
        const iosCode = `${code},${appIdIos}`;

        updateCodes({
            provider: PROVIDER_ID.STARTAPP,
            codes: [
                {
                    code: androidCode,
                    disabled: isBannerDisabled,
                    format: FORMAT_ID.BANNER,
                    platform: PLATFORM_ID.ANDROID,
                },
                {
                    code: iosCode,
                    disabled: isBannerDisabled,
                    format: FORMAT_ID.BANNER,
                    platform: PLATFORM_ID.IOS,
                },
                {
                    code: androidCode,
                    disabled: isInterstitialDisabled,
                    format: FORMAT_ID.INTERSTITIAL,
                    platform: PLATFORM_ID.ANDROID,
                },
                {
                    code: iosCode,
                    disabled: isInterstitialDisabled,
                    format: FORMAT_ID.INTERSTITIAL,
                    platform: PLATFORM_ID.IOS,
                },
                {
                    code: androidCode,
                    disabled: isOnExitDisabled,
                    format: FORMAT_ID.ONEXIT,
                    platform: PLATFORM_ID.ANDROID,
                },
                {
                    code: iosCode,
                    disabled: isOnExitDisabled,
                    format: FORMAT_ID.ONEXIT,
                    platform: PLATFORM_ID.IOS,
                },
                {
                    code: androidCode,
                    disabled: isOnReturnDisabled,
                    format: FORMAT_ID.ONRETURN,
                    platform: PLATFORM_ID.ANDROID,
                },
                {
                    code: iosCode,
                    disabled: isOnReturnDisabled,
                    format: FORMAT_ID.ONRETURN,
                    platform: PLATFORM_ID.IOS,
                },
                {
                    code: androidCode,
                    disabled: isNativeDisabled,
                    format: FORMAT_ID.NATIVE,
                    platform: PLATFORM_ID.ANDROID,
                },
                {
                    code: iosCode,
                    disabled: isNativeDisabled,
                    format: FORMAT_ID.NATIVE,
                    platform: PLATFORM_ID.IOS,
                },
                {
                    code: androidCode,
                    disabled: isVideoPrerollDisabled,
                    format: FORMAT_ID.VIDEOPREROLL,
                    platform: PLATFORM_ID.ANDROID,
                },
                {
                    code: iosCode,
                    disabled: isVideoPrerollDisabled,
                    format: FORMAT_ID.VIDEOPREROLL,
                    platform: PLATFORM_ID.IOS,
                },
                {
                    code: androidCode,
                    disabled: isSplashDisabled,
                    format: FORMAT_ID.SPLASH,
                    platform: PLATFORM_ID.ANDROID,
                },
                {
                    code: iosCode,
                    disabled: isSplashDisabled,
                    format: FORMAT_ID.SPLASH,
                    platform: PLATFORM_ID.IOS,
                },
                {
                    code: androidCode,
                    disabled: isVideoDisabled,
                    format: FORMAT_ID.VIDEO,
                    platform: PLATFORM_ID.ANDROID,
                },
                {
                    code: iosCode,
                    disabled: isVideoDisabled,
                    format: FORMAT_ID.VIDEO,
                    platform: PLATFORM_ID.IOS,
                },
            ],
        });
    };

    const handleAppIdInputBlur = (event) => {
        const { currentTarget } = event;
        let { value: code, name } = currentTarget;
        const platform = parseInt(name, 10);
        code = `${accountId},${code}`;

        updateCodes({
            provider: PROVIDER_ID.STARTAPP,
            codes: [
                {
                    code,
                    disabled: isBannerDisabled,
                    format: FORMAT_ID.BANNER,
                    platform,
                },
                {
                    code,
                    disabled: isInterstitialDisabled,
                    format: FORMAT_ID.INTERSTITIAL,
                    platform,
                },
                {
                    code,
                    disabled: isOnExitDisabled,
                    format: FORMAT_ID.ONEXIT,
                    platform,
                },
                {
                    code,
                    disabled: isOnReturnDisabled,
                    format: FORMAT_ID.ONRETURN,
                    platform,
                },
                {
                    code,
                    disabled: isNativeDisabled,
                    format: FORMAT_ID.NATIVE,
                    platform,
                },
                {
                    code,
                    disabled: isVideoPrerollDisabled,
                    format: FORMAT_ID.VIDEOPREROLL,
                    platform,
                },
                {
                    code,
                    disabled: isSplashDisabled,
                    format: FORMAT_ID.SPLASH,
                    platform,
                },
                {
                    code,
                    disabled: isVideoDisabled,
                    format: FORMAT_ID.VIDEO,
                    platform,
                },
            ],
        });
    };

    return (
        <>
            <img className="logo mb-3" src={startapp} alt="AdColony logo" />
            <p>{parse(t('monetize.startapp_description'))}</p>
            <a href="https://www.startapp.com/" target="_blank" rel="noopener noreferrer">
                https://www.startapp.com/
            </a>
            <hr />
            <h6 className="mt-4">{t('monetize.configuration')}</h6>
            <CardDeck>
                <Card>
                    <CardBody>
                        <FormGroup>
                            <Label>{t('monetize.account_id')}</Label>
                            <Input defaultValue={accountId} onBlur={handleAccountIdInputBlur} />
                        </FormGroup>
                    </CardBody>
                </Card>
                <Card>
                    <CardBody>
                        <FormGroup>
                            <Label>
                                {t('monetize.app_id')} ({t('monetize.android')})
                            </Label>
                            <Input
                                defaultValue={appIdAndroid}
                                name={PLATFORM_ID.ANDROID}
                                onBlur={handleAppIdInputBlur}
                            />
                        </FormGroup>
                    </CardBody>
                </Card>
                <Card>
                    <CardBody>
                        <FormGroup>
                            <Label>
                                {t('monetize.app_id')} ({t('monetize.ios')})
                            </Label>
                            <Input
                                defaultValue={appIdIos}
                                name={PLATFORM_ID.IOS}
                                onBlur={handleAppIdInputBlur}
                            />
                        </FormGroup>
                    </CardBody>
                </Card>
            </CardDeck>
            <h6 className="mt-4">{t('monetize.formats')}</h6>
            <Formats provider={PROVIDER_ID.STARTAPP} showCodes={false} />
        </>
    );
};

const Tappx = () => {
    const { t } = useTranslation();
    const { codes = [], getFormats, formats = [], updateCodes } = useAdvertisement();
    const { disabled: isBannerDisabled } =
        formats.find(
            ({ format, provider }) => provider === PROVIDER_ID.TAPPX && format === FORMAT_ID.BANNER
        ) || {};
    const { disabled: isInterstitialDisabled } =
        formats.find(
            ({ format, provider }) =>
                provider === PROVIDER_ID.TAPPX && format === FORMAT_ID.INTERSTITIAL
        ) || {};
    const { adCode: adCodeAndroid = '' } =
        codes.find(
            ({ platform, provider }) =>
                platform === PLATFORM_ID.ANDROID && provider === PROVIDER_ID.TAPPX
        ) || {};
    const keyAndroid = adCodeAndroid.split(',')[0];
    const { adCode: adCodeIos = '' } =
        codes.find(
            ({ platform, provider }) =>
                platform === PLATFORM_ID.IOS && provider === PROVIDER_ID.TAPPX
        ) || {};
    const keyIos = adCodeIos.split(',')[0];

    useEffect(() => {
        getFormats({ provider: PROVIDER_ID.TAPPX });
    }, []);

    const handleKeyInputBlur = (event) => {
        const { currentTarget } = event;
        const { value: code, name } = currentTarget;
        const platform = parseInt(name, 10);

        updateCodes({
            provider: PROVIDER_ID.TAPPX,
            codes: [
                {
                    code,
                    disabled: isBannerDisabled,
                    format: FORMAT_ID.BANNER,
                    platform,
                },
                {
                    code,
                    disabled: isInterstitialDisabled,
                    format: FORMAT_ID.INTERSTITIAL,
                    platform,
                },
            ],
        });
    };

    return (
        <>
            <img className="logo mb-3" src={tappx} alt="AdColony logo" />
            <p>{parse(t('monetize.tappx_description'))}</p>
            <a href="https://www.tappx.com/" target="_blank" rel="noopener noreferrer">
                https://www.tappx.com/
            </a>
            <hr />
            <h6 className="mt-4">{t('monetize.configuration')}</h6>
            <CardDeck>
                <Card>
                    <CardBody>
                        <FormGroup>
                            <Label>
                                {t('monetize.tappx_key')} ({t('monetize.android')})
                            </Label>
                            <Input
                                defaultValue={keyAndroid}
                                name={PLATFORM_ID.ANDROID}
                                onBlur={handleKeyInputBlur}
                            />
                        </FormGroup>
                    </CardBody>
                </Card>
                <Card>
                    <CardBody>
                        <FormGroup>
                            <Label>
                                {t('monetize.tappx_key')} ({t('monetize.ios')})
                            </Label>
                            <Input
                                defaultValue={keyIos}
                                name={PLATFORM_ID.IOS}
                                onBlur={handleKeyInputBlur}
                            />
                        </FormGroup>
                    </CardBody>
                </Card>
            </CardDeck>
            <h6 className="mt-4">{t('monetize.formats')}</h6>
            <Formats provider={PROVIDER_ID.TAPPX} showCodes={false} />
        </>
    );
};

const UnityAds = () => {
    const { t } = useTranslation();
    const { codes = [], getFormats, formats = [], updateCodes } = useAdvertisement();
    const { disabled: isInterstitialDisabled } =
        formats.find(
            ({ format, provider }) =>
                provider === PROVIDER_ID.UNITYADS && format === FORMAT_ID.INTERSTITIAL
        ) || {};
    const { disabled: isVideoDisabled } =
        formats.find(
            ({ format, provider }) =>
                provider === PROVIDER_ID.UNITYADS && format === FORMAT_ID.VIDEO
        ) || {};
    const { disabled: isVideoPrerollDisabled } =
        formats.find(
            ({ format, provider }) =>
                provider === PROVIDER_ID.UNITYADS && format === FORMAT_ID.VIDEOPREROLL
        ) || {};
    const { adCode: gameId = '' } =
        codes.find(
            ({ platform, provider }) =>
                platform === PLATFORM_ID.ANDROID && provider === PROVIDER_ID.UNITYADS
        ) || {};

    useEffect(() => {
        getFormats({ provider: PROVIDER_ID.UNITYADS });
    }, []);

    const handleGameIdInputBlur = (event) => {
        const { currentTarget } = event;
        const { value: code } = currentTarget;
        updateCodes({
            provider: PROVIDER_ID.UNITYADS,
            codes: [
                {
                    code,
                    disabled: isInterstitialDisabled,
                    format: FORMAT_ID.INTERSTITIAL,
                    platform: PLATFORM_ID.ANDROID,
                },
                {
                    code,
                    disabled: isVideoDisabled,
                    format: FORMAT_ID.VIDEO,
                    platform: PLATFORM_ID.ANDROID,
                },
                {
                    code,
                    disabled: isVideoPrerollDisabled,
                    format: FORMAT_ID.VIDEOPREROLL,
                    platform: PLATFORM_ID.ANDROID,
                },
            ],
        });
    };

    return (
        <>
            <img className="logo mb-3" src={unityads} alt="AdColony logo" />
            <p>{parse(t('monetize.unityads_description'))}</p>
            <a
                href="https://unity3d.com/unity/features/ads"
                target="_blank"
                rel="noopener noreferrer"
            >
                https://unity3d.com/unity/features/ads
            </a>
            <hr />
            <h6 className="mt-4">{t('monetize.configuration')}</h6>
            <CardDeck>
                <Card>
                    <CardBody>
                        <FormGroup>
                            <Label>{t('monetize.game_id')}</Label>
                            <Input defaultValue={gameId} onBlur={handleGameIdInputBlur} />
                        </FormGroup>
                    </CardBody>
                </Card>
            </CardDeck>
            <h6 className="mt-4">{t('monetize.formats')}</h6>
            <Formats provider={PROVIDER_ID.UNITYADS} showCodes={false} disabled={!gameId} />
        </>
    );
};

const Configuration = () => {
    const { t } = useTranslation();
    const { configuration = {}, getConfiguration, updateConfiguration } = useAdvertisement();
    const { interval = '', videoInterval = '', itemsBetweenNativeAds = '' } = configuration;

    useEffect(() => {
        configuration._equals({}) && getConfiguration();
    }, []);

    const handleInputBlur = (event) => {
        const { currentTarget } = event;
        const { value, name } = currentTarget;

        updateConfiguration({ [name]: value });
    };

    return (
        <CardDeck className="flex-wrap">
            <Card>
                <div className="position-relative">
                    <CardImg
                        top
                        width="100%"
                        src={interstitial}
                        alt={t('monetize.interstitial_frequency_title')}
                    />
                    <CardImgOverlay>
                        <CardTitle>{t('monetize.interstitial_frequency_title')}</CardTitle>
                    </CardImgOverlay>
                </div>
                <CardBody>
                    <FormGroup>
                        <Label>{t('monetize.seconds')}</Label>
                        <Input name="interval" defaultValue={interval} onBlur={handleInputBlur} />
                    </FormGroup>
                    <p>
                        <small>{t('monetize.interstitial_frequency_description')}</small>
                    </p>
                </CardBody>
            </Card>
            <Card>
                <div className="position-relative">
                    <CardImg
                        top
                        width="100%"
                        src={native}
                        alt={t('monetize.native_frequency_title')}
                    />
                    <CardImgOverlay>
                        <CardTitle>{t('monetize.native_frequency_title')}</CardTitle>
                    </CardImgOverlay>
                </div>
                <CardBody>
                    <FormGroup>
                        <Label>{t('monetize.seconds')}</Label>
                        <Input
                            name="videoInterval"
                            defaultValue={videoInterval}
                            onBlur={handleInputBlur}
                        />
                    </FormGroup>
                    <p>
                        <small>{t('monetize.native_frequency_description')}</small>
                    </p>
                </CardBody>
            </Card>
            <Card>
                <div className="position-relative">
                    <CardImg
                        top
                        width="100%"
                        src={videopreroll}
                        alt={t('monetize.video_frequency_title')}
                    />
                    <CardImgOverlay>
                        <CardTitle>{t('monetize.video_frequency_title')}</CardTitle>
                    </CardImgOverlay>
                </div>
                <CardBody>
                    <FormGroup>
                        <Label>{t('monetize.items')}</Label>
                        <Input
                            name="itemsBetweenNativeAds"
                            defaultValue={itemsBetweenNativeAds}
                            onBlur={handleInputBlur}
                        />
                    </FormGroup>
                    <p>
                        <small>{t('monetize.video_frequency_description')}</small>
                    </p>
                </CardBody>
            </Card>
        </CardDeck>
    );
};

const AdvertisementContent = ({ provider }) => {
    switch (provider) {
        case PROVIDER_TYPE.ADCOLONY:
            return <AdColony />;
        case PROVIDER_TYPE.ADMOBNATIVE:
            return <AdMobNative />;
        case PROVIDER_TYPE.AMAZON:
            return <Amazon />;
        case PROVIDER_TYPE.FACEBOOK:
            return <Facebook />;
        case PROVIDER_TYPE.STARTAPP:
            return <StartApp />;
        case PROVIDER_TYPE.TAPPX:
            return <Tappx />;
        case PROVIDER_TYPE.UNITYADS:
            return <UnityAds />;
        case 'configuration':
            return <Configuration />;
        default:
            return null;
    }
};

export const Advertisement = ({ isOpen: isOpenProp }) => {
    const { t } = useTranslation();
    const [isOpen, setIsOpen] = useState(isOpenProp);
    const { activateService, application = {} } = useApplications();
    const { closeModal, modal, setModal, key = 'configuration' } = useModals();
    const [provider, setProvider] = useState(key);

    useEffect(() => {
        (async () => {
            if (modal === MODALS.ADVERTISEMENT) {
                if (!application.adServerApp && !(await activateService(SERVICE_TYPE.MONETIZE))) {
                    closeModal();
                }
            }
            setIsOpen(modal === MODALS.ADVERTISEMENT);
        })();
    }, [modal]);

    const handleMenuClick = ({ key }) => {
        setProvider(key);
    };

    return (
        <Modal
            className="advertisement modal-menu max-80"
            isOpen={isOpen}
            title={t('header.advertisement')}
            onClosed={() => setModal(null)}
        >
            <ModalBody>
                <Menu
                    className="w-auto"
                    mode="inline"
                    onClick={handleMenuClick}
                    defaultSelectedKeys={[provider]}
                >
                    <Menu.Item key={PROVIDER_TYPE.ADCOLONY}>{t('header.adcolony')}</Menu.Item>
                    <Menu.Item key={PROVIDER_TYPE.ADMOBNATIVE}>{t('header.admob')}</Menu.Item>
                    <Menu.Item key={PROVIDER_TYPE.AMAZON}>{t('header.amazon')}</Menu.Item>
                    <Menu.Item key={PROVIDER_TYPE.FACEBOOK}>{t('header.facebook')}</Menu.Item>
                    <Menu.Item key={PROVIDER_TYPE.STARTAPP}>{t('header.startapp')}</Menu.Item>
                    <Menu.Item key={PROVIDER_TYPE.TAPPX}>{t('header.tappx')}</Menu.Item>
                    <Menu.Item key={PROVIDER_TYPE.UNITYADS}>{t('header.unityads')}</Menu.Item>
                    <Menu.Item key="configuration">{t('header.configuration')}</Menu.Item>
                </Menu>
                <ModalBody className="px-5" data-key={provider}>
                    <AdvertisementContent provider={provider} />
                </ModalBody>
            </ModalBody>
        </Modal>
    );
};
