import React, { createContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import ls from 'local-storage';
import { message } from 'antd';
import { PAGES, URL } from '../_config';
import { getLanguage } from '../_helpers';
import { logout, ViewerProvider } from './Viewer';
import { getHeaders, handleResponse } from '.';

export const UserContext = createContext();

export const UserProvider = ({ children }) => {
    const { t } = useTranslation();
    const history = useHistory();
    const [loading, setLoading] = useState(false);
    const { auth = {} } = ls('user') || {};
    const { token } = auth;

    const applyRecovery = ({ variables }) => {
        setLoading(true);
        const { email, password, password_confirmation, token } = variables;

        return fetch(`${URL.API}/password/reset`, {
            method: 'POST',
            body: JSON.stringify({ email, password, password_confirmation, token }),
            headers: getHeaders(),
        })
            .then(async (response) => {
                const data = (await response.json()) || {};
                return response.ok ? data : Promise.reject(data);
            })
            .then(({ data: auth }) => {
                setLoading(false);
                ls.remove('recovery_email');
                let user = ls('user') || {};
                user = { ...user, auth };
                ls('user', user);
                history.push('/');
            });
    };

    const createAuthenticationOnDoyo = (variables) =>
        new Promise((resolve, reject) => {
            const options = {
                operationName: 'CreateAuthentication',
                query:
                    'mutation CreateAuthentication($identifier: String!, $password: String!) {\n  createAuthentication(input: {identifier: $identifier, password: $password}) {\n    id\n    token\n    __typename\n  }\n}\n',
                variables,
            };
            return fetch(URL.PASSPORT, {
                method: 'POST',
                body: JSON.stringify(options),
                headers: getHeaders(),
            })
                .then(async (response) => {
                    const data = (await response.json()) || {};
                    return response.ok ? data : Promise.reject(data);
                })
                .then(({ data }) => {
                    const { createAuthentication = {} } = data;
                    let user = ls('user') || {};
                    user = { ...user, doyoAuth: createAuthentication };
                    ls('user', user);
                    resolve();
                })
                .catch(() => {
                    const user = ls('user') || {};
                    delete user.doyoAuth;
                    ls('user', user);
                    reject();
                });
        });

    const createAuthentication = async ({ variables }) => {
        setLoading(true);
        try {
            await createAuthenticationOnDoyo(variables);
        } catch (e) {}
        const { identifier, password } = variables;
        return fetch(`${URL.API}/login`, {
            method: 'POST',
            body: JSON.stringify({ identifier, password }),
            headers: getHeaders(),
        })
            .then(handleResponse)
            .then((auth) => {
                setLoading(false);
                let user = ls('user') || {};
                user = { ...user, auth };
                ls('user', user);
            })
            .catch((error) => {
                const { errors, type } = error;
                setLoading(false);
                ls.remove('user');
                console.error(error);
                message.error(t(`login.${type || errors}`));
            });
    };

    const createRecovery = ({ variables: { email } }) => {
        setLoading(true);
        return fetch(`${URL.API}/password/email`, {
            method: 'POST',
            body: JSON.stringify({ email }),
            headers: getHeaders(),
        }).then(() => {
            setLoading(false);
            message.success(t('forgot.request_sent'));
            ls('recovery_email', email);
        });
    };

    const createUserOnDoyo = (variables) => {
        const options = {
            operationName: 'CreateUser',
            query:
                'mutation CreateUser($email: String!, $password: String!, $announcements: Boolean, $captcha: String!) {\n  createUser(input: {email: $email, password: $password, announcements: $announcements, captcha: $captcha}) {\n    id\n    __typename\n  }\n}\n',
            variables,
        };
        return fetch(URL.PASSPORT, {
            method: 'POST',
            body: JSON.stringify(options),
            headers: getHeaders(),
        });
    };

    const createUser = async ({ variables }) => {
        setLoading(true);
        const { announcements, captcha, referral, email, password } = variables;
        const { ip: userIP, country_code: countryCode, country_code: companyCountryCode } =
            (await getClientInfo()) || {};

        variables = {
            Email: email,
            referral,
            companyCountryCode,
            countryCode,
            lang: getLanguage(),
            password,
            unsubscribed: !announcements,
            userIP,
            username: email,
        };

        createUserOnDoyo({ announcements, captcha, email, password });

        return fetch(`${URL.API}/users`, {
            method: 'POST',
            body: JSON.stringify(variables),
            headers: getHeaders({ auth: true }),
        })
            .then(async (response) => {
                const data = (await response.json()) || {};
                return response.ok ? data : Promise.reject(data);
            })
            .then(() => createAuthentication({ variables: { identifier: email, password } }))
            .then(() => {
                const { auth = {} } = ls('user') || {};
                const { token } = auth;

                if (token) {
                    if (window.location.pathname.includes('/v1')) {
                        const { doyoAuth = {} } = ls('user') || {};
                        const { token: doyoToken } = doyoAuth;
                        const redirect = `${URL.MOBINCUBE_EDITOR_REDIRECT}?token=${token}&doyotoken=${doyoToken}`;
                        window.location = redirect;
                        return;
                    }
                    history.push('/');
                }
            })
            .catch((error) => {
                setLoading(false);
                console.error(error);
                message.error(t('register.unprocessable_entity'));
            });
    };

    const createUserBySocialLogin = async (json) => {
        setLoading(true);
        const {
            announcements,
            referral,
            email: Email,
            first_name: firstName,
            id: identifier,
            last_name: contactSurname,
            name,
            provider,
        } = json;
        const { ip: userIP, country_code: countryCode, country_code: companyCountryCode } =
            (await getClientInfo()) || {};

        return fetch(`${URL.API}/socialLogin`, {
            method: 'POST',
            body: JSON.stringify({
                announcements,
                referral,
                userIP,
                countryCode,
                companyCountryCode,
                lang: getLanguage(),
                contactName: firstName || name,
                contactSurname,
                Email,
                provider,
                identifier,
            }),
            headers: getHeaders({ auth: true }),
        })
            .then(async (response) => {
                const data = (await response.json()) || {};
                return response.ok ? data : Promise.reject(data);
            })
            .then((auth) => {
                const { token } = auth;
                ls('user', { auth });
                if (token) {
                    if (window.location.pathname.includes('/v1')) {
                        const redirect = `${URL.MOBINCUBE_EDITOR_REDIRECT}?token=${token}`;
                        window.location = redirect;
                        return;
                    }
                    history.push('/');
                }
            })
            .catch((error) => {
                setLoading(false);
                console.error(error);
                message.error(t('register.unprocessable_entity'));
            });
    };

    const getClientInfo = () => {
        setLoading(true);
        return fetch(URL.CLIENT_INFO)
            .then(async (response) => {
                const data = (await response.json()) || {};
                return response.ok ? data : Promise.reject(data.type);
            })
            .then((data) => {
                setLoading(false);
                return data;
            })
            .catch((error) => {
                setLoading(false);
                console.error(error);
            });
    };

    useEffect(() => {
        fetch(`${URL.API}/health`).then((response) => {
            if (response.status === 503 && window.location.pathname !== PAGES.MAINTENANCE) {
                logout();
                window.location.href = PAGES.MAINTENANCE;
            }
            if (response.status !== 503 && window.location.pathname === PAGES.MAINTENANCE) {
                window.location.href = '/';
            }
        });
    }, []);

    return (
        <UserContext.Provider
            value={{
                applyRecovery,
                createAuthentication,
                createRecovery,
                createUser,
                createUserBySocialLogin,
                loading,
            }}
        >
            {(token && <ViewerProvider>{children}</ViewerProvider>) || children}
        </UserContext.Provider>
    );
};

export const UserConsumer = UserContext.Consumer;
export default UserContext;
