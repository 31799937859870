import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { message } from 'antd';
import { useResources } from '../hooks';

const FilePreview = ({
    blobUrl: blobUrlProp = '',
    className,
    id = '',
    name = '',
    url = '',
    blob = {},
    onChange,
    style = {},
}) => {
    const { t } = useTranslation();
    const { getBlobUrl } = useResources();
    const preview = useRef();
    const [value, setValue] = useState({});
    const [blobUrl, setBlobUrl] = useState(blobUrlProp);
    const ext = name.split('.').pop();

    useEffect(() => {
        let mounted = true;
        if (!id || blobUrl) {
            return;
        }

        (async () => {
            const url = await getBlobUrl({ id });
            if (mounted) {
                setBlobUrl(url);
            }
        })();

        return () => {
            mounted = false;
        };
    }, []);

    useEffect(() => {
        let mounted = true;
        if (mounted) {
            setBlobUrl(blobUrlProp);
        }
        return () => {
            mounted = false;
        };
    }, [blobUrlProp]);

    const onLoad = () => {
        let parent = preview.current;
        const { firstElementChild: child } = parent;
        child.style.transform = '';
        if (child.offsetWidth > parent.offsetWidth) {
            child.style.transform = `scale(${parent.offsetWidth / child.offsetWidth - 0.05})`;
        }
        if (child.offsetHeight > parent.offsetHeight) {
            child.style.transform = `scale(${parent.offsetHeight / child.offsetHeight - 0.05})`;
        }
    };

    const Preview = useCallback(
        ({ ext, id, name, url, value }) => {
            switch (ext) {
                case 'mp3':
                    return <audio controls src={url} />;

                case 'pdf':
                case 'xml':
                case 'txt':
                    return <iframe title={name} src={url} />;

                case 'png':
                case 'gif':
                case 'jpg':
                case 'jpeg':
                case 'svg':
                    return <img className="img" src={url} alt={name} />;

                case 'ttf':
                    const fontFamily = name.replace('-', '').split('.')[0];
                    return (
                        <div>
                            <style>{`
                                @font-face {
                                    font-family: ${fontFamily};
                                    src: url(${url});
                                }
                            `}</style>
                            <div style={{ fontFamily, fontSize: '5rem' }}>Ag</div>
                        </div>
                    );

                case 'mp4':
                    return <video controls src={url} />;

                default:
                    return (
                        <div className="no-preview">
                            <h6 className="m-0">{t('files.no_preview')}</h6>
                        </div>
                    );
            }
        },
        [url, value]
    );

    useEffect(() => {
        let mounted = true;

        if (ext === 'json') {
            fetch(url)
                .then((resp) => resp.json())
                .then((json) => {
                    mounted && setValue(json);
                })
                .catch((e) => {
                    message.error(t('files.error_loading'));
                });
        } else if (url) {
            preview.current.firstElementChild &&
                preview.current.firstElementChild.addEventListener('load', onLoad, false);
        }
        return () => {
            mounted = false;
            preview.current.firstElementChild &&
                preview.current.firstElementChild.removeEventListener('load', onLoad, false);
        };
    }, [blobUrl, preview.current, url]);

    return (
        <div className={`preview${className ? ` ${className}` : ''}`} ref={preview} style={style}>
            <Preview ext={ext} id={id} name={name} url={blobUrl || url} value={value} />
        </div>
    );
};

export default FilePreview;
export { FilePreview };
