export const DRAG_ITEMS = {
    INITIAL_STATE: {
        dragItems: [],
    },

    LIST: 'DRAG_ITEMS_LIST',
};

export const DragItemsReducer = (draft, action) => {
    const { payload, type } = action;
    switch (type) {
        case DRAG_ITEMS.LIST:
            draft.dragItems = payload.dragItemsData;
            break;

        default:
            return DRAG_ITEMS.INITIAL_STATE;
    }
};
