import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import parse from 'html-react-parser';
import { Popover } from 'antd';
import { Button, CardBody, CardTitle, ListGroupItem } from 'reactstrap';
import { getLanguage } from '../../../_helpers';
import { Icon, Sortable } from '../../../components';
import {
    CheckboxProperty,
    ColorProperty,
    DateProperty,
    FileProperty,
    LabelProperty,
    LocationProperty,
    RangeProperty,
    ScreenProperty,
    SelectProperty,
    TextProperty,
} from '.';

export const PROPERTY_TYPE = {
    CHECKBOX: 'checkbox',
    COLOR: 'color',
    DATE: 'date',
    FILE: 'file',
    LABEL: 'label',
    LIST: 'list',
    LOCATION: 'location',
    RANGE: 'range',
    SCREEN: 'screen',
    SELECT: 'select',
    TEXT: 'text',
};

const DEFAULT_VALUE = {
    [PROPERTY_TYPE.CHECKBOX]: true,
    [PROPERTY_TYPE.COLOR]: '#ffffff',
    [PROPERTY_TYPE.DATE]: '',
    [PROPERTY_TYPE.FILE]: '',
    [PROPERTY_TYPE.LABEL]: '',
    [PROPERTY_TYPE.LIST]: [],
    [PROPERTY_TYPE.LOCATION]: '',
    [PROPERTY_TYPE.RANGE]: 0,
    [PROPERTY_TYPE.SCREEN]: '',
    [PROPERTY_TYPE.SELECT]: '',
    [PROPERTY_TYPE.TEXT]: '',
};

export const ListProperty = ({
    buttonLabel: _buttonLabel,
    _key: key,
    onChange: handleChange,
    schema,
    value = [],
}) => {
    const { t } = useTranslation();
    const lang = getLanguage();
    const buttonLabel =
        typeof _buttonLabel === 'string'
            ? _buttonLabel
            : (_buttonLabel && _buttonLabel[lang]) || t('common.add');

    const getItemName = () => {
        if (!value.length) {
            return `${t('properties.item')} 1`;
        }
        const lastItemLabel = value.map(({ label = '' }) => label).pop();
        if (!lastItemLabel) {
            return '';
        }
        let [, space, num] = lastItemLabel.match(/(\s*)(\d+)$/) || [null, ' ', 0];
        num = parseInt(num, 10);
        return `${lastItemLabel.replace(/(\s*)(\d+)$/, '')}${space}${!isNaN(num) ? num + 1 : ''}`;
    };

    const handleItemChange = useCallback(
        (item) => {
            const { index, key, value: _value } = item;
            value[index][key] = _value;

            handleChange(value);
        },
        [value]
    );

    const handleLabelChange = useCallback(
        (label, i) => {
            value[i].label = label;
            handleChange(value);
        },
        [value]
    );

    const handleClick = useCallback(() => {
        handleChange([
            ...value,
            {
                ...schema.reduce(
                    (resp, { key, type, value }) => ({
                        ...resp,
                        [key]: value || DEFAULT_VALUE[type],
                    }),
                    {}
                ),
                label: getItemName(),
            },
        ]);
    }, [value]);

    const handleDelete = useCallback(
        (index) => {
            handleChange(value.filter((v, i) => i !== index));
        },
        [value]
    );
    const handleSort = useCallback(
        (event) => {
            const { newIndex, oldIndex } = event;
            const _value = value._clone();
            _value._move(oldIndex, newIndex);

            handleChange(_value);
        },
        [value]
    );

    return (
        <>
            <Button className="font-size-16 mb-3 text-uppercase" onClick={handleClick}>
                <small>{buttonLabel}</small>
            </Button>
            <Sortable
                key={value.map(({ label }) => label).join('-')}
                className="list-group"
                group="ListProperty"
                handle=".sort-handle"
                onAdd={() => {}}
                onEnd={() => {}}
                onSort={handleSort}
                onStart={() => {}}
                tag="ul"
            >
                {value.map((values, i) => {
                    const { label } = values;
                    return (
                        <Popover
                            key={`${key}-${i}`}
                            content={
                                <>
                                    {!schema.some(({ key }) => key === 'label') && (
                                        <Property
                                            onChange={({ value }) => handleLabelChange(value, i)}
                                            value={label}
                                            type={PROPERTY_TYPE.TEXT}
                                        />
                                    )}
                                    {schema.map((prop) => {
                                        const { key } = prop;
                                        const property = {
                                            ...prop,
                                            value: values[key],
                                        };
                                        return (
                                            <Property
                                                _key={property.key}
                                                index={i}
                                                {...property}
                                                onChange={handleItemChange}
                                            />
                                        );
                                    })}
                                </>
                            }
                            placement="top"
                            trigger="click"
                        >
                            <ListGroupItem action className="property-item d-flex p-0">
                                <Button
                                    className="sort-handle border-0 rounded-circle"
                                    color="link"
                                >
                                    <Icon type="drag-horizontal-variant" />
                                </Button>
                                <div className="list-group-item-title flex-grow-1">{label}</div>
                                <Button
                                    className="border-0 rounded-circle"
                                    color="danger"
                                    outline
                                    onClick={() => handleDelete(i)}
                                >
                                    <Icon type="delete" />
                                </Button>
                            </ListGroupItem>
                        </Popover>
                    );
                })}
            </Sortable>
        </>
    );
};

export const Property = ({ onChange: handleJsonPropertiesChange, _key: key, ...property }) => {
    let { label, type } = property;
    label = label || '';
    const lang = getLanguage();
    const _label = typeof label === 'string' ? label : label[lang];
    let child = null;

    const handleChange = (value) => {
        handleJsonPropertiesChange({ ...property, key, value });
    };

    switch (type) {
        case PROPERTY_TYPE.CHECKBOX:
            child = <CheckboxProperty {...property} onChange={handleChange} />;
            break;
        case PROPERTY_TYPE.COLOR:
            child = <ColorProperty {...property} onChange={handleChange} />;
            break;
        case PROPERTY_TYPE.DATE:
            child = <DateProperty {...property} onChange={handleChange} />;
            break;
        case PROPERTY_TYPE.FILE:
            child = <FileProperty {...property} onChange={handleChange} />;
            break;
        case PROPERTY_TYPE.LIST:
            child = <ListProperty {...property} onChange={handleChange} />;
            break;
        case PROPERTY_TYPE.LABEL:
            child = <LabelProperty {...property} onChange={handleChange} />;
            break;
        case PROPERTY_TYPE.LOCATION:
            child = <LocationProperty {...property} onChange={handleChange} />;
            break;
        case PROPERTY_TYPE.RANGE:
            child = <RangeProperty {...property} onChange={handleChange} />;
            break;
        case PROPERTY_TYPE.SELECT:
            child = <SelectProperty {...property} onChange={handleChange} />;
            break;
        case PROPERTY_TYPE.SCREEN:
            child = <ScreenProperty {...property} onChange={handleChange} />;
            break;
        case PROPERTY_TYPE.TEXT:
            child = <TextProperty {...property} onChange={handleChange} />;
            break;
        default:
            child = null;
    }
    return (
        <CardBody>
            {_label && (
                <CardTitle>
                    <strong>{parse(_label)}</strong>
                </CardTitle>
            )}
            {child}
        </CardBody>
    );
};
