import React from 'react';
import { useTranslation } from 'react-i18next';
import { Select } from 'antd';
import { CardBody, CardTitle, Input } from 'reactstrap';
import { ElementWrapper } from '.';
import { getHex } from '../../../_helpers';
import { ColorPicker } from '../../../components';
import { useElements } from '../../../hooks';

const { Option } = Select;

export const TextField = ({ element }) => {
    const { t } = useTranslation();
    const { updateElement } = useElements();
    const { concrete = {}, id } = element;
    let { fontColor, format, reference } = concrete;
    fontColor = `#${fontColor}`;

    const handleChangeReference = (event) => {
        const { currentTarget } = event;
        const { value: reference } = currentTarget;
        updateElement({ concrete: { reference } }, element);
    };

    const handleChangeFormat = (format) => {
        updateElement({ concrete: { format } }, element);
    };

    const handleChangingFontColor = (picker, color) => {
        const screenEl = document.querySelector(`[data-element-id="${id}"] input`);
        screenEl.style.color = getHex(color);
    };

    const handleChangeFontColor = (color) => {
        if (color === fontColor) {
            return;
        }
        updateElement({ concrete: { fontColor: getHex(color).substr(1) } }, element);
    };

    return (
        <ElementWrapper element={element}>
            <CardBody>
                <CardTitle>
                    <strong>{t('properties.reference')}</strong>
                </CardTitle>
                <Input defaultValue={reference} onBlur={handleChangeReference} required />
            </CardBody>
            <CardBody>
                <CardTitle>
                    <strong>{t('properties.format')}</strong>
                </CardTitle>
                <Select value={format} onChange={handleChangeFormat}>
                    <Option value="text">{t('properties.text')}</Option>
                    <Option value="number">{t('properties.number')}</Option>
                </Select>
            </CardBody>
            <CardBody>
                <CardTitle>
                    <strong>{t('properties.font_color')}</strong>
                </CardTitle>
                <ColorPicker
                    value={fontColor}
                    onChange={handleChangingFontColor}
                    onBlur={handleChangeFontColor}
                    input={true}
                />
            </CardBody>
        </ElementWrapper>
    );
};
