import React, { createContext } from 'react';
import { useElements } from '../hooks';
import { ELEMENT_TYPE, SCREEN_TYPE } from '.';

export const ACTION_TYPE = {
    UNDEFINED: 0,
    SCREEN: 1,
    SELECT: 2,
    CALL: 3,
    BACK: 4,
    END: 5,
    BROWSER: 6,
    SUBMIT: 7,
    NOOP: 8,
    UPDATE: 9,
    SMS: 10,
    MYLOCATION: 11,
    VIEW: 12,
    SET: 13,
    BUY: 14,
    UNBUY: 15,
    CHECKOUT: 16,
    FILTER: 17,
    TTS: 18,
    INTERSTITIAL: 19,
    NEXT: 20,
    PREVIOUS: 21,
    SHARE: 22,
    PLAYER: 23,
    ROUTE: 24,
    REQUEST: 25,
    PLAY: 26,
    STOP: 27,
    VIDEOAD: 28,
    TRACK: 29,
    PRIVACY: 30,
    DOWNLOAD: 31,
    PURCHASE: 32,
    FUNCTIONS: 33,
};
export const ACTION_LABEL = {
    [ACTION_TYPE.UNDEFINED]: '',
    [ACTION_TYPE.SCREEN]: 'section',
    [ACTION_TYPE.SELECT]: 'select',
    [ACTION_TYPE.CALL]: 'call',
    [ACTION_TYPE.BACK]: 'back',
    [ACTION_TYPE.END]: 'end',
    [ACTION_TYPE.BROWSER]: 'browser',
    [ACTION_TYPE.SUBMIT]: 'submit',
    [ACTION_TYPE.NOOP]: 'noop',
    [ACTION_TYPE.UPDATE]: 'update',
    [ACTION_TYPE.SMS]: 'sms',
    [ACTION_TYPE.MYLOCATION]: 'mylocation',
    [ACTION_TYPE.VIEW]: 'view',
    [ACTION_TYPE.SET]: 'set',
    [ACTION_TYPE.BUY]: 'buy',
    [ACTION_TYPE.UNBUY]: 'unbuy',
    [ACTION_TYPE.CHECKOUT]: 'checkout',
    [ACTION_TYPE.FILTER]: 'filter',
    [ACTION_TYPE.TTS]: 'tts',
    [ACTION_TYPE.INTERSTITIAL]: 'interstitial',
    [ACTION_TYPE.NEXT]: 'next',
    [ACTION_TYPE.PREVIOUS]: 'previous',
    [ACTION_TYPE.SHARE]: 'share',
    [ACTION_TYPE.PLAYER]: 'player',
    [ACTION_TYPE.ROUTE]: 'route',
    [ACTION_TYPE.REQUEST]: 'request',
    [ACTION_TYPE.PLAY]: 'play',
    [ACTION_TYPE.STOP]: 'stop',
    [ACTION_TYPE.VIDEOAD]: 'videoad',
    [ACTION_TYPE.TRACK]: 'track',
    [ACTION_TYPE.PRIVACY]: 'privacy',
    [ACTION_TYPE.DOWNLOAD]: 'download',
    [ACTION_TYPE.PURCHASE]: 'purchase',
    [ACTION_TYPE.FUNCTIONS]: 'functions',
};

export const ActionsContext = createContext();

export const ActionsProvider = ({ children }) => {
    const { elements = [] } = useElements();

    const getActions = (screen) => {
        let availableActions = [
            ACTION_TYPE.UNDEFINED,
            ACTION_TYPE.SCREEN,
            ACTION_TYPE.CALL,
            ACTION_TYPE.BROWSER,
            ACTION_TYPE.SHARE,
            ACTION_TYPE.PLAYER,
            ACTION_TYPE.ROUTE,
            ACTION_TYPE.SET,
            ACTION_TYPE.REQUEST,
            ACTION_TYPE.NOOP,
            ACTION_TYPE.BACK,
            ACTION_TYPE.END,
            ACTION_TYPE.PLAY,
            ACTION_TYPE.STOP,
            ACTION_TYPE.INTERSTITIAL,
            ACTION_TYPE.TRACK,
            ACTION_TYPE.PRIVACY,
            ACTION_TYPE.DOWNLOAD,
            ACTION_TYPE.PURCHASE,
            ACTION_TYPE.FUNCTIONS,
        ];

        if (!screen) {
            return availableActions;
        }
        const { id: screenId, IDTipoSeccion: screenType } = screen;

        switch (screenType) {
            case SCREEN_TYPE.INFO: {
                const screenElements = elements.filter(({ IDSection }) => IDSection === screenId);

                if (
                    screenElements.some(
                        ({ IDElementType: type }) => type === ELEMENT_TYPE.TEXTFIELD
                    )
                ) {
                    availableActions.push(ACTION_TYPE.SUBMIT);
                }
                break;
            }

            case SCREEN_TYPE.SPLASH: {
                availableActions = [ACTION_TYPE.SCREEN];
                break;
            }

            default: {
                // noop
            }
        }

        return availableActions;
    };

    const getIdFromLabel = (label) => {
        return (
            parseInt(Object.keys(ACTION_LABEL).find((key) => ACTION_LABEL[key] === label)) ||
            ACTION_TYPE.UNDEFINED
        );
    };

    const getLabelFromId = (id) => {
        return ACTION_LABEL[id] || ACTION_LABEL[ACTION_TYPE.UNDEFINED];
    };

    const hasFunctionsParameter = (action) => {
        return [ACTION_TYPE.FUNCTIONS].includes(action);
    };

    const hasParameter = (action) => {
        return [
            ACTION_TYPE.SCREEN,
            ACTION_TYPE.CALL,
            ACTION_TYPE.BROWSER,
            ACTION_TYPE.SMS,
            ACTION_TYPE.VIEW,
            ACTION_TYPE.SET,
            ACTION_TYPE.FILTER,
            ACTION_TYPE.TTS,
            ACTION_TYPE.SHARE,
            ACTION_TYPE.PLAYER,
            ACTION_TYPE.ROUTE,
            ACTION_TYPE.REQUEST,
            ACTION_TYPE.REQUEST,
            ACTION_TYPE.PLAY,
            ACTION_TYPE.STOP,
            ACTION_TYPE.TRACK,
            ACTION_TYPE.DOWNLOAD,
            ACTION_TYPE.PURCHASE,
            ACTION_TYPE.FUNCTIONS,
        ].includes(action);
    };

    const hasInputParameter = (action) => {
        return [
            ACTION_TYPE.CALL,
            ACTION_TYPE.BROWSER,
            ACTION_TYPE.SMS,
            ACTION_TYPE.VIEW,
            ACTION_TYPE.SET,
            ACTION_TYPE.FILTER,
            ACTION_TYPE.TTS,
            ACTION_TYPE.SHARE,
            ACTION_TYPE.PLAYER,
            ACTION_TYPE.ROUTE,
            ACTION_TYPE.REQUEST,
            ACTION_TYPE.REQUEST,
            ACTION_TYPE.TRACK,
            ACTION_TYPE.DOWNLOAD,
            ACTION_TYPE.PURCHASE,
        ].includes(action);
    };

    const hasResourceParameter = (action) => {
        return [ACTION_TYPE.PLAY, ACTION_TYPE.STOP].includes(action);
    };

    const hasScreenParameter = (action) => {
        return [ACTION_TYPE.SCREEN].includes(action);
    };

    return (
        <ActionsContext.Provider
            value={{
                getActions,
                getIdFromLabel,
                getLabelFromId,
                hasFunctionsParameter,
                hasParameter,
                hasInputParameter,
                hasResourceParameter,
                hasScreenParameter,
            }}
        >
            {children}
        </ActionsContext.Provider>
    );
};

export const ActionsConsumer = ActionsContext.Consumer;
export default ActionsContext;
