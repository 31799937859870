import React from 'react';
import { Body } from '..';
import { ScreenWrapper } from '.';
import { BODY_TYPE } from '../../../components';

export const Info = (screen) => {
    const { concrete = {} } = screen;
    const { IDMainElement } = concrete;
    const mainElement = { concrete: { bodyType: BODY_TYPE.VERTICAL }, id: IDMainElement };

    return (
        <ScreenWrapper {...screen}>
            <Body element={mainElement} />
        </ScreenWrapper>
    );
};
