import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Tabs } from 'antd';
import { Button, ButtonGroup, CardBody, CardHeader, CardTitle } from 'reactstrap';
import { Action, BODY_TYPE, Icon, Shading, Slider } from '../../../components';
import { ELEMENT_HALIGN, ELEMENT_TITLE, ELEMENT_TYPE, ELEMENT_VALIGN } from '../../../context';
import { useElements } from '../../../hooks';
import { Bar, Body, Image, TextField, Separator, Text, Video } from '.';

const { TabPane } = Tabs;

export const ElementWrapper = ({ children, element }) => {
    const { t } = useTranslation();
    const { updateElement } = useElements();
    const {
        concrete = {},
        hAlignParent: hAlign,
        id,
        IDAction = 0,
        IDElementType: type,
        idShading,
        lastUpdated,
        parameter = '',
        vAlignChild: vAlign,
        width,
    } = element;
    const { bodyType } = concrete;
    let title = ELEMENT_TITLE[type];

    if (type === ELEMENT_TYPE.BODY) {
        title = t(`elements.${bodyType}_body`);
    }

    const handleChangeAction = ({ action: IDAction, parameter }) => {
        updateElement({ IDAction, parameter }, element);
    };

    const handleChangeBackground = (shading) => {
        const { id = null } = shading;
        if (idShading === id) {
            return;
        }
        updateElement({ idShading: id ? id : null }, element);
    };

    const handleChangeWidth = useCallback(
        (width, options = {}) => {
            const domElement = document.querySelector(`[data-element-id="${id}"]`);
            if (domElement) {
                domElement.style.maxWidth = `${width}%`;
                domElement.style.minWidth = `${width}%`;
                domElement.style.width = `${width}%`;
            }
            if (options.preview) {
                return;
            }
            updateElement({ width }, { id, lastUpdated });
        },
        [id, lastUpdated]
    );

    const handleChangeHAlign = useCallback(
        (hAlignParent) => {
            updateElement({ hAlignParent }, { id, lastUpdated });
        },
        [id, lastUpdated]
    );

    const handleChangeVAlign = useCallback(
        (vAlignChild) => {
            updateElement({ vAlignChild }, { id, lastUpdated });
        },
        [id, lastUpdated]
    );

    return (
        <div className="element-properties">
            <Tabs defaultActiveKey={`TabProperties${id}`}>
                <TabPane tab={title} key={`TabProperties${id}`}>
                    {children}
                    {type !== ELEMENT_TYPE.BAR && (
                        <>
                            <CardBody>
                                <CardTitle>
                                    <strong>{t('properties.width')}</strong>
                                </CardTitle>
                                <Slider
                                    min={5}
                                    max={100}
                                    onChange={handleChangeWidth}
                                    format={(value) => `${value}%`}
                                    value={width}
                                    input={true}
                                />
                            </CardBody>
                            {(bodyType === BODY_TYPE.VERTICAL && (
                                <CardBody>
                                    <CardTitle>
                                        <strong>{t('properties.hAlign')}</strong>
                                    </CardTitle>
                                    <ButtonGroup>
                                        <Button
                                            outline
                                            color="primary"
                                            className={
                                                vAlign === ELEMENT_VALIGN.TOP ? 'active' : ''
                                            }
                                            onClick={() => handleChangeVAlign(ELEMENT_VALIGN.TOP)}
                                        >
                                            <Icon type="format-vertical-align-top" />
                                            <span>{t(`properties.${ELEMENT_VALIGN.TOP}`)}</span>
                                        </Button>
                                        <Button
                                            outline
                                            color="primary"
                                            className={
                                                vAlign === ELEMENT_VALIGN.MIDDLE ? 'active' : ''
                                            }
                                            onClick={() =>
                                                handleChangeVAlign(ELEMENT_VALIGN.MIDDLE)
                                            }
                                        >
                                            <Icon type="format-vertical-align-center" />
                                            <span>{t(`properties.${ELEMENT_VALIGN.MIDDLE}`)}</span>
                                        </Button>
                                        <Button
                                            outline
                                            color="primary"
                                            className={
                                                vAlign === ELEMENT_VALIGN.BOTTOM ? 'active' : ''
                                            }
                                            onClick={() =>
                                                handleChangeVAlign(ELEMENT_VALIGN.BOTTOM)
                                            }
                                        >
                                            <Icon type="format-vertical-align-bottom" />
                                            <span>{t(`properties.${ELEMENT_VALIGN.BOTTOM}`)}</span>
                                        </Button>
                                    </ButtonGroup>
                                </CardBody>
                            )) || (
                                <CardBody>
                                    <CardTitle>
                                        <strong>{t('properties.hAlign')}</strong>
                                    </CardTitle>
                                    <ButtonGroup>
                                        <Button
                                            outline
                                            color="primary"
                                            className={
                                                hAlign === ELEMENT_HALIGN.LEFT ? 'active' : ''
                                            }
                                            onClick={() => handleChangeHAlign(ELEMENT_HALIGN.LEFT)}
                                        >
                                            <Icon type="format-horizontal-align-left" />
                                            <span>{t(`properties.${ELEMENT_HALIGN.LEFT}`)}</span>
                                        </Button>
                                        <Button
                                            outline
                                            color="primary"
                                            className={
                                                hAlign === ELEMENT_HALIGN.CENTER ? 'active' : ''
                                            }
                                            onClick={() =>
                                                handleChangeHAlign(ELEMENT_HALIGN.CENTER)
                                            }
                                        >
                                            <Icon type="format-horizontal-align-center" />
                                            <span>{t(`properties.${ELEMENT_HALIGN.CENTER}`)}</span>
                                        </Button>
                                        <Button
                                            outline
                                            color="primary"
                                            className={
                                                hAlign === ELEMENT_HALIGN.RIGHT ? 'active' : ''
                                            }
                                            onClick={() => handleChangeHAlign(ELEMENT_HALIGN.RIGHT)}
                                        >
                                            <Icon type="format-horizontal-align-right" />
                                            <span>{t(`properties.${ELEMENT_HALIGN.RIGHT}`)}</span>
                                        </Button>
                                    </ButtonGroup>
                                </CardBody>
                            )}
                            <CardBody>
                                <CardTitle>
                                    <strong>{t('properties.background')}</strong>
                                </CardTitle>
                                <Shading handleChange={handleChangeBackground} id={idShading} />
                            </CardBody>
                        </>
                    )}
                </TabPane>
                {type !== ELEMENT_TYPE.BAR && (
                    <TabPane tab={t('properties.events')} key={`TabEvents${id}`}>
                        <CardHeader>
                            <strong>{t('properties.ontouch')}</strong>
                        </CardHeader>
                        <Action
                            action={IDAction}
                            parameter={parameter}
                            onChange={handleChangeAction}
                        />
                    </TabPane>
                )}
            </Tabs>
        </div>
    );
};

export const Element = ({ element }) => {
    const { IDElementType: type } = element;

    switch (type) {
        case ELEMENT_TYPE.BAR:
            return <Bar element={element} />;
        case ELEMENT_TYPE.BODY:
            return <Body element={element} />;
        case ELEMENT_TYPE.IMAGE:
            return <Image element={element} />;
        case ELEMENT_TYPE.TEXTFIELD:
            return <TextField element={element} />;
        case ELEMENT_TYPE.SEPARATOR:
            return <Separator element={element} />;
        case ELEMENT_TYPE.TEXT:
            return <Text element={element} />;
        case ELEMENT_TYPE.VIDEO:
            return <Video element={element} />;
        default:
            return <></>;
    }
};
