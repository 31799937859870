import React, { useMemo, useState } from 'react';
import { Icon } from '.';

export const Checkbox = ({ checked: checkedProp = false, onChange = () => {} }) => {
    const [checked, setChecked] = useState(checkedProp);
    const handleChange = (e) => {
        setChecked((checked) => !checked);
        onChange();
    };
    const handleClick = (e) => e.stopPropagation();

    useMemo(() => {
        setChecked(checkedProp);
    }, [checkedProp]);

    return (
        <div className={`checkbox${checked ? ' checked' : ''}`}>
            <input
                type="checkbox"
                checked={checked}
                onChange={handleChange}
                onClick={handleClick}
            />
            <Icon type="check" />
        </div>
    );
};

export default Checkbox;
