import React, { createContext, useCallback, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useImmerReducer } from 'use-immer';
import { useTranslation } from 'react-i18next';
import { message } from 'antd';
import ls from 'local-storage';
import { SCREEN, ScreensReducer } from './reducers';
import { URL } from '../_config';
import { getUniqueName } from '../_helpers';
import { useApplications, useViewer } from '../hooks';
import { ElementsProvider, ELEMENT_HALIGN, ELEMENT_VALIGN, getHeaders } from '.';

export const BACKGROUND_IMAGE = {
    hAlign: ELEMENT_HALIGN.CENTER,
    vAlign: ELEMENT_VALIGN.TOP,
    width: 100,
};

export const SCREEN_TYPE_NAME = {
    UNDEFINED: 'undefined',
    OPTIONLIST: 'menu',
    ACTIONIMAGE: 'map',
    INFO: 'info',
    CALENDAR: 'calendar',
    FEED: 'feed',
    MAP: 'onlinemap',
    DATAVIEW: 'dataview',
    ARCAMERA: 'arcamera',
    WEB: 'embededWeb',
    SPLASH: 'splash',
    SHOP: 'shop',
    COUPON: 'coupon',
    RICHWEB: 'richweb',
};

export const SCREEN_TYPE = {
    UNDEFINED: 0,
    [SCREEN_TYPE_NAME.UNDEFINED]: 0,
    OPTIONLIST: 1,
    [SCREEN_TYPE_NAME.OPTIONLIST]: 1,
    ACTIONIMAGE: 2,
    [SCREEN_TYPE_NAME.ACTIONIMAGE]: 2,
    INFO: 3,
    [SCREEN_TYPE_NAME.INFO]: 3,
    CALENDAR: 4,
    [SCREEN_TYPE_NAME.CALENDAR]: 4,
    FEED: 5,
    [SCREEN_TYPE_NAME.FEED]: 5,
    MAP: 6,
    [SCREEN_TYPE_NAME.MAP]: 6,
    DATAVIEW: 7,
    [SCREEN_TYPE_NAME.DATAVIEW]: 7,
    ARCAMERA: 8,
    [SCREEN_TYPE_NAME.ARCAMERA]: 8,
    WEB: 9,
    [SCREEN_TYPE_NAME.WEB]: 9,
    SPLASH: 10,
    [SCREEN_TYPE_NAME.SPLASH]: 10,
    SHOP: 11,
    [SCREEN_TYPE_NAME.SHOP]: 11,
    COUPON: 12,
    [SCREEN_TYPE_NAME.COUPON]: 12,
    RICHWEB: 13,
    [SCREEN_TYPE_NAME.RICHWEB]: 13,
};

export const SCREEN_ICON = {
    UNDEFINED: 'file',
    OPTIONLIST: 'menu',
    ACTIONIMAGE: 'group',
    INFO: 'collage',
    CALENDAR: 'calendar',
    FEED: 'rss',
    MAP: 'map-marker',
    DATAVIEW: 'view-list',
    ARCAMERA: 'camera-enhance',
    WEB: 'web',
    SPLASH: 'av-timer',
    SHOP: 'cart',
    COUPON: 'ticket',
    RICHWEB: 'web',
    [SCREEN_TYPE.UNDEFINED]: 'file',
    [SCREEN_TYPE.OPTIONLIST]: 'menu',
    [SCREEN_TYPE.ACTIONIMAGE]: 'group',
    [SCREEN_TYPE.INFO]: 'collage',
    [SCREEN_TYPE.CALENDAR]: 'calendar',
    [SCREEN_TYPE.FEED]: 'rss',
    [SCREEN_TYPE.MAP]: 'map-marker',
    [SCREEN_TYPE.DATAVIEW]: 'view-list',
    [SCREEN_TYPE.ARCAMERA]: 'camera-enhance',
    [SCREEN_TYPE.WEB]: 'web',
    [SCREEN_TYPE.SPLASH]: 'av-timer',
    [SCREEN_TYPE.SHOP]: 'cart',
    [SCREEN_TYPE.COUPON]: 'ticket',
    [SCREEN_TYPE.RICHWEB]: 'web',
};

const defaultAttributes = {
    Background: 'FFFFFF',
    IDAction: 0,
    IDHeader: -1,
    IDTipoSeccion: 0,
    Parameter: '',
    Timeout: -1,
    anchor: '1',
};

const relations = ['sectionBars', 'sectionImage'];

export const ScreensContext = createContext(SCREEN.INITIAL_STATE);

export const ScreensProvider = ({ children }) => {
    const location = useLocation();
    const { t } = useTranslation();
    const [loading, setLoading] = useState(false);
    const [_page, setPage] = useState(1);
    const [state, dispatch] = useImmerReducer(ScreensReducer, SCREEN.INITIAL_STATE);
    const { screen = {}, screens = [] } = state;
    const {
        application = {},
        applications = [],
        getApplication,
        updateApplication,
    } = useApplications();
    const { logout } = useViewer();

    const addBarToScreen = useCallback(
        ({ IDElement, screen = state.screen, position, sequence }) => {
            setLoading(true);
            return fetch(`${URL.API}/sectionbars`, {
                method: 'POST',
                body: JSON.stringify({ IDElement, IDSection: screen.id, position, sequence }),
                headers: getHeaders({ auth: true }),
            })
                .then(async (response) => {
                    const { data = {} } = await response.json();
                    return response.ok ? data : Promise.reject(data.type);
                })
                .then((screenBar) => {
                    setLoading(false);
                    dispatch({
                        type: SCREEN.ADD_SCREEN_BAR,
                        payload: { screenBar },
                    });
                    return screenBar;
                })
                .catch((err) => {
                    setLoading(false);
                    console.error(err);
                });
        },
        [application.id, screen.id]
    );

    const checkScreen = (screens, { checked = true } = {}) => {
        screens = Array.isArray(screens) ? screens : [screens];
        dispatch({
            type: SCREEN.CHECK,
            payload: { screens, checked },
        });
    };

    const closeScreen = useCallback(
        ({ id }) => {
            const screen = screens.find((screen) => screen.id === id);
            dispatch({
                type: SCREEN.CLOSE,
                payload: { screen },
            });
        },
        [screens]
    );

    const createConcreteScreen = useCallback(
        ({ typeName, concrete = {} }, screen = state.screen) => {
            setLoading(true);
            const isSameType = screen.typeName === typeName;
            return fetch(
                `${URL.API}/section/${typeName}${isSameType ? `/${screen.concrete.id}` : ''}`,
                {
                    method: isSameType ? 'PUT' : 'POST',
                    body: JSON.stringify({ IDSection: screen.id, ...concrete }),
                    headers: getHeaders({ auth: true }),
                }
            )
                .then(async (response) => {
                    const { data = {} } = await response.json();
                    return response.ok ? data : Promise.reject(data.type);
                })
                .then(async (concrete = {}) => {
                    screen = {
                        ...screen,
                        IDTipoSeccion: SCREEN_TYPE[typeName],
                        typeName,
                        concrete,
                    };
                    concrete = await getConcrete(screen, { force: true });
                    setLoading(false);
                    screen = {
                        ...screen,
                        concrete,
                    };
                    dispatch({
                        type: SCREEN.GET_CONCRETE,
                        payload: { screen },
                    });
                    return screen;
                })
                .catch((err) => {
                    setLoading(false);
                    console.error(err);
                });
        },
        [application.id, screen]
    );

    const createScreen = useCallback(
        (attributes = {}, { select = true } = {}) => {
            attributes = {
                ...defaultAttributes,
                IDGuia: application.id,
                Nombre: getUniqueName(`${t('defaults.new_screen_name')}`, {
                    names: screens.map(({ Nombre: name }) => name),
                }),
                ...attributes,
            };

            setLoading(true);
            return fetch(`${URL.API}/section`, {
                method: 'POST',
                body: JSON.stringify(attributes),
                headers: getHeaders({ auth: true }),
            })
                .then(async (response) => {
                    const { data = {} } = await response.json();
                    return response.ok ? data : Promise.reject(data.type);
                })
                .then((screen) => {
                    setLoading(false);
                    getScreen(screen, { select });
                    return screen;
                })
                .catch((err) => {
                    setLoading(false);
                    console.error(err);
                });
        },
        [application.id, screens]
    );

    const createScreenBackgroundImage = (attributes = {}, screen = state.screen) => {
        if (!screen) {
            return;
        }
        setLoading(true);
        return fetch(`${URL.API}/sections/${screen.id}/images`, {
            method: 'POST',
            body: JSON.stringify({ ...BACKGROUND_IMAGE, ...attributes }),
            headers: getHeaders({ auth: true }),
        })
            .then(async (response) => {
                const { data = {} } = await response.json();
                return response.ok ? data : Promise.reject(data.type);
            })
            .then((backgroundImage) => {
                setLoading(false);
                dispatch({
                    type: SCREEN.CREATE_BACKGROUND_IMAGE,
                    payload: { backgroundImage, screen },
                });
                return backgroundImage;
            })
            .catch((err) => {
                setLoading(false);
                console.error(err);
            });
    };

    const deleteScreens = useCallback(
        (screens) => {
            screens = Array.isArray(screens) ? screens : [screens];
            const application = applications.find(({ id }) => id === screens[0].IDGuia);
            if (screens.some(({ id }) => id === application.IDMainSection)) {
                message.error(t('screens.delete_main_error'));
                return;
            }
            setLoading(true);
            return Promise.all(
                screens.map((screen) =>
                    fetch(`${URL.API}/section/${screen.id}`, {
                        method: 'DELETE',
                        headers: getHeaders({ auth: true }),
                    })
                )
            )
                .then((responses) => {
                    screens = screens.filter((screen, i) => responses[i].ok);
                })
                .then(() => {
                    setLoading(false);
                    dispatch({
                        type: SCREEN.DELETE,
                        payload: { screens },
                    });
                });
        },
        [applications]
    );

    const deleteScreenBackgroundImage = (screen = state.screen) => {
        setLoading(true);
        if (!screen) {
            return;
        }
        return fetch(`${URL.API}/sections/${screen.id}/images`, {
            method: 'DELETE',
            headers: getHeaders({ auth: true }),
        })
            .then(async (response) => {
                const data = (await response.json()) || {};
                return response.ok ? data : Promise.reject(data.type);
            })
            .then(() => {
                setLoading(false);
                dispatch({
                    type: SCREEN.DELETE_BACKGROUND_IMAGE,
                    payload: { screen },
                });
            })
            .catch((err) => {
                setLoading(false);
                console.error(err);
            });
    };

    const detachBarFromScreen = ({ id, screen }) => {
        setLoading(true);
        return fetch(`${URL.API}/sectionbars/${id}`, {
            method: 'DELETE',
            headers: getHeaders({ auth: true }),
        })
            .then(() => {
                setLoading(false);
                dispatch({
                    type: SCREEN.DETACH_SCREEN_BAR,
                    payload: { id, screen },
                });
            })
            .catch((err) => {
                setLoading(false);
                console.error(err);
            });
    };

    const duplicateScreen = useCallback(
        (screen = state.screen, { select = true } = {}) => {
            const { auth = {} } = ls('user') || {};
            const { token } = auth;
            setLoading(true);
            return fetch(
                `${URL.MOBINCUBE_EDITOR}/cloneSection.php?token=${token}&app_id=${application.id}&section_id=${screen.id}`
            )
                .then((response) => response.json())
                .then(async ({ new_section: newScreen }) => {
                    setLoading(false);
                    if (!newScreen) {
                        message.error(t('screens.error.duplicate', { name: screen.Nombre }));
                        return;
                    }
                    const { id } = newScreen;
                    await getApplication(application);
                    newScreen = await getScreen({ id }, { select });
                    return newScreen;
                })
                .catch(() => {
                    setLoading(false);
                    message.error(t('screens.error.duplicate', { name: screen.Nombre }));
                });
        },
        [screen]
    );

    const duplicateScreens = useCallback(
        (screens = []) => {
            Promise.all(screens.map((screen) => duplicateScreen(screen, { select: false }))).then(
                (screens) => {
                    screens[0] && selectScreen(screens[0]);
                }
            );
        },
        [screen]
    );

    const getConcrete = useCallback(
        (screen = {}, { force = false } = {}) => {
            const { id, IDTipoSeccion } = screen;

            if (IDTipoSeccion === SCREEN_TYPE.UNDEFINED || !id || isNaN(id)) {
                return Promise.resolve({});
            }
            screen = screens.find(({ id }) => id === screen.id);
            if (!force && screen.concrete) {
                return Promise.resolve(screen.concrete);
            }

            return fetch(`${URL.API}/section/${id}/concrete`, {
                headers: getHeaders({ auth: true }),
            })
                .then(async (response) => {
                    const { data = {} } = await response.json();
                    return response.ok ? data : Promise.reject(data.type);
                })
                .then((concrete) => {
                    concrete = { ...concrete, now: Date.now() };
                    dispatch({
                        type: SCREEN.GET_CONCRETE,
                        payload: { screen: { ...screen, concrete } },
                    });
                    return concrete;
                })
                .catch((err) => {
                    console.error(err);
                });
        },
        [screens]
    );

    const getScreen = (screen = {}, { select = true } = {}) => {
        const { id } = screen;
        if (!id || isNaN(id)) {
            return Promise.reject();
        }

        screen = screens.length ? screens.find(({ id }) => id === screen.id) || screen : screen;
        if (screen.section_bars) {
            return Promise.resolve(screen);
        }

        setLoading(true);
        return fetch(`${URL.API}/section/${id}?relations=${JSON.stringify(relations)}`, {
            headers: getHeaders({ auth: true }),
        })
            .then(async (response) => {
                const { data = {} } = await response.json();
                return response.ok ? data : Promise.reject(data.type);
            })
            .then((screen) => {
                setLoading(false);
                dispatch({
                    type: SCREEN.GET,
                    payload: { screen, select },
                });
                return screen;
            })
            .catch((err) => {
                setLoading(false);
                console.error(err);
            });
    };

    const getScreens = useCallback(
        ({
            limit = 20,
            name = '',
            orderBy = 'Nombre',
            orderType = 'asc',
            page = _page,
            reset = false,
        } = {}) => {
            setLoading(true);
            return fetch(
                `${URL.API}/applications/${
                    application.id
                }/sections?name=${name}&orderBy=${orderBy}&orderType=${orderType}&page=${page}&per_page=${limit}&offset=${(page -
                    1) *
                    limit}`,
                {
                    headers: getHeaders({ auth: true }),
                }
            )
                .then(async (response) => {
                    const data = (await response.json()) || {};
                    return response.ok ? data : Promise.reject(data.type);
                })
                .then((screens) => {
                    setLoading(false);
                    if (!screens.length) {
                        setPage((page) => page - 1);
                    }
                    dispatch({
                        type: SCREEN.LIST,
                        payload: { screens, reset },
                    });
                    return screens;
                })
                .catch((error) => {
                    setLoading(false);
                    console.error(error);
                    if (error === 'unauthorized') {
                        logout();
                    }
                });
        },
        [application.id, _page]
    );

    const refreshScreen = useCallback(async () => {
        const { id } = screen;
        setLoading(true);
        await getScreens({ page: 1, reset: true });
        return fetch(`${URL.API}/section/${id}?relations=${JSON.stringify(relations)}`, {
            headers: getHeaders({ auth: true }),
        })
            .then(async (response) => {
                const { data = {} } = await response.json();
                return response.ok ? data : Promise.reject(data.type);
            })
            .then((_screen) => {
                setLoading(false);
                let refreshedScreen = { ..._screen, refreshed: Date.now() };
                dispatch({
                    type: SCREEN.GET,
                    payload: { screen: refreshedScreen, select: true },
                });
                return refreshedScreen;
            })
            .catch((err) => {
                setLoading(false);
                console.error(err);
            });
    }, [screen]);

    const selectScreen = useCallback(
        ({ id }) =>
            getScreen({ id }).then((screen) => {
                if (!screen) {
                    return;
                }
                dispatch({
                    type: SCREEN.SELECT,
                    payload: { screen },
                });
            }),
        [screens.length, application.id]
    );

    const unselectScreen = () => {
        dispatch({ type: SCREEN.UNSELECT });
    };

    const updateConcreteScreen = useCallback(
        (attributes = {}, screen = state.screen) => {
            const { concrete } = screen;
            if (!concrete) {
                return;
            }
            setLoading(true);
            fetch(`${URL.API}/section/${screen.typeName}/${concrete.id}`, {
                method: 'PATCH',
                body: JSON.stringify(attributes),
                headers: getHeaders({ auth: true }),
            })
                .then(async (response) => {
                    const data = (await response.json()) || {};
                    return response.ok ? data : Promise.reject(data.type);
                })
                .then(() => {
                    setLoading(false);
                    dispatch({
                        type: SCREEN.UPDATE_CONCRETE,
                        payload: { attributes, screen },
                    });
                })
                .catch((err) => {
                    setLoading(false);
                    console.error(err);
                });
        },
        [screen.id, screen.concrete]
    );

    const updateScreen = useCallback(
        (attributes = {}, screen = state.screen) => {
            if (!screen) {
                return;
            }
            setLoading(true);
            fetch(`${URL.API}/section/${screen.id}`, {
                method: 'PATCH',
                body: JSON.stringify(attributes),
                headers: getHeaders({ auth: true }),
            })
                .then(async (response) => {
                    const data = (await response.json()) || {};
                    return response.ok ? data : Promise.reject(data.type);
                })
                .then((screen) => {
                    setLoading(false);
                    dispatch({
                        type: SCREEN.UPDATE,
                        payload: { screen },
                    });
                })
                .catch((err) => {
                    setLoading(false);
                    console.error(err);
                });
        },
        [screen.id]
    );

    const updateScreenBackgroundImage = useCallback(
        (attributes = {}, screen = state.screen) => {
            if (!screen) {
                return;
            }
            setLoading(true);
            fetch(`${URL.API}/sections/${screen.id}/images`, {
                method: 'PATCH',
                body: JSON.stringify(attributes),
                headers: getHeaders({ auth: true }),
            })
                .then(async (response) => {
                    const data = (await response.json()) || {};
                    return response.ok ? data : Promise.reject(data.type);
                })
                .then(() => {
                    setLoading(false);
                    dispatch({
                        type: SCREEN.UPDATE_BACKGROUND_IMAGE,
                        payload: { attributes, screen },
                    });
                })
                .catch((err) => {
                    setLoading(false);
                    console.error(err);
                });
        },
        [screen.id]
    );

    const updateScreenBar = (attributes, { id }) => {
        setLoading(true);
        return fetch(`${URL.API}/sectionbars/${id}`, {
            method: 'PATCH',
            body: JSON.stringify(attributes),
            headers: getHeaders({ auth: true }),
        })
            .then(async (response) => {
                const { data = {} } = await response.json();
                return response.ok ? data : Promise.reject(data.type);
            })
            .then((screenBar) => {
                setLoading(false);
                dispatch({
                    type: SCREEN.ADD_SCREEN_BAR,
                    payload: { screenBar },
                });
                return screenBar;
            })
            .catch((err) => {
                setLoading(false);
                console.error(err);
            });
    };

    useEffect(() => {
        if (!application.id) {
            return;
        }
        dispatch({
            type: SCREEN.LIST,
            payload: { screens: [], reset: true },
        });
        setPage(1);
        getScreens({ page: 1, reset: true }).then(async (screens) => {
            let { IDMainSection } = application;
            if (!IDMainSection) {
                if (!screens.length) {
                    const newScreen = await createScreen({ Nombre: t('viewport.main_screen') });
                    IDMainSection = newScreen.id;
                } else {
                    IDMainSection = screens[0].id;
                }
                updateApplication({ IDMainSection });
            }

            const [, , , idScreen] = location.pathname.split('/');
            if (!idScreen) {
                selectScreen({ id: IDMainSection });
            }
        });
    }, [application.id]);

    useEffect(() => {
        screens.forEach((screen) => {
            const { IDTipoSeccion: type } = screen;
            if (type === SCREEN_TYPE.WEB) {
                getConcrete(screen);
            }
        });
    }, [screens.length]);

    useEffect(() => {
        getConcrete(screen);
    }, [screen.id]);

    useEffect(() => {
        refreshScreen();
    }, [application.refreshed]);

    useEffect(() => {
        getConcrete(screen, { force: true });
    }, [screen.refreshed]);

    if (process.env.NODE_ENV === 'development') {
        console.log('SCREENS >>>', state);
    }

    return (
        <ScreensContext.Provider
            value={{
                ...state,
                addBarToScreen,
                checkScreen,
                closeScreen,
                createConcreteScreen,
                createScreen,
                createScreenBackgroundImage,
                deleteScreens,
                deleteScreenBackgroundImage,
                detachBarFromScreen,
                duplicateScreen,
                duplicateScreens,
                getScreen,
                getScreens,
                loading,
                page: _page,
                refreshScreen,
                selectScreen,
                unselectScreen,
                updateConcreteScreen,
                updateScreen,
                updateScreenBackgroundImage,
                updateScreenBar,
                setPage,
            }}
        >
            {(screen.id && <ElementsProvider>{children}</ElementsProvider>) || children}
        </ScreensContext.Provider>
    );
};

export const ScreensConsumer = ScreensContext.Consumer;
export default ScreensContext;
