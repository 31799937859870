// @flow
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Input } from 'reactstrap';
import { Icon } from './Icon';

function togglePasswordVisibility(event) {
    const target = event.currentTarget;
    const input = target.previousSibling;
    const type = input.getAttribute('type') === 'password' ? 'text' : 'password';
    target.dataset.type = type;
    input.setAttribute('type', type);
}

function PasswordInput(props) {
    const { t } = useTranslation();
    const { minLength = 8, onChange = () => {} } = props;

    const handleChange = (event) => {
        const { nativeEvent } = event;
        const { target } = nativeEvent;
        target.setCustomValidity('');
        onChange(event);
    };

    return (
        <div className="position-relative">
            <Input
                type="password"
                name="password"
                placeholder={t('common.promise_not_to_tell')}
                aria-describedby="passwordHelp"
                minLength={minLength}
                required
                {...props}
                onChange={handleChange}
            />
            <Button color="link" className="eye d-block" onClick={togglePasswordVisibility}>
                <Icon type="eye d-none" />
                <Icon type="eye-off" />
            </Button>
        </div>
    );
}

export default PasswordInput;
export { PasswordInput };
