import React, { useCallback, useRef } from 'react';
import { Input } from 'reactstrap';

export const TextProperty = ({
    key,
    maxLines,
    multiline = false,
    onChange: handleChange,
    pattern,
    type,
    update,
    value,
}) => {
    const input = useRef();
    const attributes = {};
    const style = {};

    if (maxLines) {
        style.maxHeight = `calc(${maxLines * 15}px + 8px)`;
    }
    multiline && (attributes.type = 'textarea');
    pattern && (attributes.pattern = pattern);

    const handleBlur = useCallback(
        (event) => {
            const { currentTarget } = event;
            const { value } = currentTarget;
            if (!input.current.reportValidity()) {
                input.current.focus();
                return;
            }
            handleChange(value);
        },
        [input]
    );

    const handleKeydown = useCallback(
        (event) => {
            const { key } = event;
            if (key === 'Enter' && !multiline) {
                input.current.blur();
                return false;
            }
            return true;
        },
        [input]
    );

    const handleKeyup = useCallback(
        (event) => {
            if (update !== 'keypress') {
                return;
            }
            const { currentTarget } = event;
            const { value } = currentTarget;
            if (!input.current.reportValidity()) {
                input.current.focus();
                return;
            }
            handleChange(value);
        },
        [input]
    );

    return (
        <Input
            {...attributes}
            defaultValue={value}
            innerRef={input}
            onBlur={handleBlur}
            onKeyDown={handleKeydown}
            onKeyUp={handleKeyup}
            style={style}
        />
    );
};
