import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, ButtonGroup, CardBody, CardTitle, Input } from 'reactstrap';
import { Select } from 'antd';
import { getHex, getRgba } from '../../../_helpers';
import { ColorPicker, Icon, Slider } from '../../../components';
import { ELEMENT_HALIGN, RESOURCE_TYPE } from '../../../context';
import { useApplications, useElements, useResources } from '../../../hooks';
import { ElementWrapper } from '.';

const { Option } = Select;

export const Text = ({ element }) => {
    const { t } = useTranslation();
    const { application = {} } = useApplications();
    const { updateElement } = useElements();
    const { createResource, resources = [], updateResource } = useResources();
    const { idCurrentLang } = application;
    const { concrete = {}, hAlignChild: textAlign, id, lastUpdated } = element;
    const { IDText, FontColor: _fontColor, fontSize: _fontSize } = concrete;
    const textRes = resources.find(({ id }) => id === IDText) || {};
    const { online: _online = 0, url } = textRes;
    const [fontColor, setFontColor] = useState(_fontColor || '000000');
    const [fontSize, setFontSize] = useState(_fontSize);
    const [online, setOnline] = useState(_online);

    const handleChangeOnline = (online) => {
        setOnline(online);
    };

    const handleChangeTextAlign = useCallback(
        (hAlignChild) => {
            updateElement({ hAlignChild }, { id, lastUpdated });
        },
        [id, lastUpdated]
    );

    const handleChangeUrl = (event) => {
        const { currentTarget } = event;
        const { value } = currentTarget;
        if (!currentTarget.reportValidity()) {
            return;
        }

        const resource = resources.find(({ url }) => url === value);
        if (resource) {
            updateElement({ concrete: { IDText: resource.id } });
        } else if (textRes.id) {
            updateResource({ content: { [`lang${idCurrentLang}`]: value }, online: 1 }, textRes);
        } else {
            createResource({
                type: RESOURCE_TYPE.TEXT,
                online: 1,
                content: value,
            }).then((resource) => updateElement({ concrete: { IDText: resource.id } }));
        }
    };

    const handleChangeFontColor = (fontColor, options = {}) => {
        const domElement = document.querySelector(`[data-element-id="${id}"] [contenteditable]`);
        if (domElement) {
            domElement.style.color = getRgba(fontColor);
        }
        if (options.preview) {
            return;
        }
        if (fontColor.length === 9) {
            fontColor = `#${fontColor[3]}${fontColor[4]}${fontColor[5]}${fontColor[6]}${fontColor[7]}${fontColor[8]}`;
        }
        fontColor = getHex(fontColor).replace('#', '');
        setFontColor(fontColor);
        updateElement({ concrete: { FontColor: `${fontColor}` } });
    };

    const handleChangeFontSize = (fontSize, options = {}) => {
        const domElement = document.querySelector(`[data-element-id="${id}"]`);
        setFontSize(fontSize);
        if (domElement) {
            if (isNaN(fontSize)) {
                domElement.dataset.fontSize = fontSize;
                domElement.style.fontSize = '';
            } else {
                delete domElement.dataset.fontSize;
                domElement.style.fontSize = `${fontSize}pt`;
            }
        }
        if (options.preview) {
            return;
        }
        updateElement({ concrete: { fontSize: `${fontSize}` } });
    };

    return (
        <ElementWrapper element={element}>
            <CardBody>
                <CardTitle>
                    <strong>{t('properties.source')}</strong>
                </CardTitle>
                <Select value={online} onChange={handleChangeOnline}>
                    <Option value={0}>{t('properties.resource')}</Option>
                    <Option value={1}>{t('common.url')}</Option>
                    <Option value={2}>{t('header.cloud')}</Option>
                </Select>
                <div className="mb-3" />
                {(online === 0 && <></>) ||
                    (online === 1 && (
                        <Input
                            type="url"
                            placeholder="https://"
                            defaultValue={_online !== 1 ? '' : url}
                            onBlur={handleChangeUrl}
                            required
                        />
                    )) || (
                        <Input
                            type="text"
                            placeholder="XXXXXXXXXX"
                            defaultValue={_online !== 2 ? '' : url}
                            onBlur={handleChangeUrl}
                            required
                        />
                    )}
            </CardBody>
            <CardBody>
                <CardTitle>
                    <strong>{t('properties.font_size')}</strong>
                </CardTitle>
                <Select
                    defaultValue={_fontSize === null || isNaN(_fontSize) ? _fontSize : 12}
                    onChange={handleChangeFontSize}
                >
                    <Option value={null}>{t('properties.default_font')}</Option>
                    <Option value="tiny">{t('properties.tiny_font')}</Option>
                    <Option value="small">{t('properties.small_font')}</Option>
                    <Option value="medium">{t('properties.medium_font')}</Option>
                    <Option value="large">{t('properties.large_font')}</Option>
                    <Option value="huge">{t('properties.huge_font')}</Option>
                    <Option value={12}>{t('properties.custom_font_size')}</Option>
                </Select>
                <div className="mb-3" />
                {fontSize && !isNaN(fontSize) && (
                    <Slider
                        min={6}
                        max={200}
                        onChange={handleChangeFontSize}
                        value={parseInt(fontSize, 10)}
                        input={true}
                    />
                )}
            </CardBody>
            <CardBody>
                <CardTitle>
                    <strong>{t('properties.text_align')}</strong>
                </CardTitle>
                <ButtonGroup>
                    <Button
                        outline
                        color="primary"
                        className={
                            textAlign === null || textAlign === ELEMENT_HALIGN.LEFT ? 'active' : ''
                        }
                        onClick={() => handleChangeTextAlign(ELEMENT_HALIGN.LEFT)}
                    >
                        <Icon type="format-align-left" />
                        <span>{t(`properties.${ELEMENT_HALIGN.LEFT}`)}</span>
                    </Button>
                    <Button
                        outline
                        color="primary"
                        className={textAlign === ELEMENT_HALIGN.CENTER ? 'active' : ''}
                        onClick={() => handleChangeTextAlign(ELEMENT_HALIGN.CENTER)}
                    >
                        <Icon type="format-align-center" />
                        <span>{t(`properties.${ELEMENT_HALIGN.CENTER}`)}</span>
                    </Button>
                    <Button
                        outline
                        color="primary"
                        className={textAlign === ELEMENT_HALIGN.RIGHT ? 'active' : ''}
                        onClick={() => handleChangeTextAlign(ELEMENT_HALIGN.RIGHT)}
                    >
                        <Icon type="format-align-right" />
                        <span>{t(`properties.${ELEMENT_HALIGN.RIGHT}`)}</span>
                    </Button>
                </ButtonGroup>
            </CardBody>
            <CardBody>
                <CardTitle>
                    <strong>{t('properties.font_color')}</strong>
                </CardTitle>
                <ColorPicker
                    value={`#${fontColor}`}
                    disableAlpha={true}
                    onChange={(picker, color) => handleChangeFontColor(color, { preview: true })}
                    onBlur={handleChangeFontColor}
                    input={true}
                />
            </CardBody>
        </ElementWrapper>
    );
};
