import banner from './banner.png';
import interstitial from './interstitial.png';
import native from './native.png';
import onexit from './onexit.png';
import onreturn from './onreturn.png';
import splash from './splash.png';
import video from './video.png';
import videopreroll from './videopreroll.png';

export { banner, interstitial, native, onexit, onreturn, splash, video, videopreroll };
