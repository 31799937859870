import React, { useState } from 'react';
import { Resizable } from 'react-resizable';
import { Card, CardBody, CardHeader } from 'reactstrap';
import { useViewer } from '../hooks';
import { MenuActions } from '.';

export const Panel = ({
    actions,
    children,
    className,
    disabled,
    panel,
    title,
    width: widthProp,
}) => {
    const { setPanelWidth } = useViewer();
    const [width, setWidth] = useState(widthProp);
    let [resizing, setResizing] = useState(false);
    const attributes = {};
    if (disabled) {
        attributes.disabled = true;
    }

    const handleResize = (event, { handle, node, size }) => {
        const { parentNode: panel } = node;
        const { width } = size;
        panel.style.width = `${width}px`;
        setWidth(width);
        setResizing(true);
    };

    const handleResizeStop = (event, { handle, node, size }) => {
        const { width } = size;
        handleResize(event, { handle, node, size });
        setPanelWidth(panel, width);
        setResizing(false);
    };

    return (
        <Resizable
            className="box absolutely-positioned"
            axis="x"
            width={width}
            height={0}
            onResize={handleResize}
            onResizeStop={handleResizeStop}
            resizeHandles={['e', 'w']}
        >
            <Card
                {...attributes}
                className={`panel${className ? ` ${className}` : ''}`}
                style={{ width }}
            >
                <CardHeader>
                    <strong>{title}</strong>
                    <MenuActions key={`MenuActions-${panel}-${resizing}`}>{actions}</MenuActions>
                </CardHeader>
                <CardBody>{children}</CardBody>
            </Card>
        </Resizable>
    );
};

export default Panel;
