import React from 'react';
import { useTranslation } from 'react-i18next';
import { Card, CardBody } from 'reactstrap';
import { ProgressIndeterminate, SocialLogin } from '../../components';
import { useUser } from '../../hooks';
import { RegisterForm } from '.';
import { logoWhite } from '../../assets/svg/logos';

export const RegisterPage = (props) => {
    const { t } = useTranslation();
    const { loading } = useUser();

    return (
        <main className="register gradient-half-secondary min-height-100vh--lg">
            <header className="position-absolute-top-left-0--lg z-index-2">
                <nav className="d-flex justify-content-between align-items-center">
                    <div className="col-lg-5 col-xl-4 text-lg-center px-0">
                        <a className="d-inline-block p-3 p-sm-5" href="/" aria-label="Mobincube">
                            <img src={logoWhite} height="50px" alt="Logo" />
                        </a>
                    </div>
                </nav>
            </header>
            <div className="min-height-100vh--lg">
                <Card className="border-0 shadow" style={{ maxWidth: '420px' }}>
                    <ProgressIndeterminate visible={loading} />
                    <CardBody className="p-4">
                        <h2 className="h3 text-primary font-weight-normal">
                            {t('register.title')}
                        </h2>
                        <p className="mb-5">{t('register.subtitle')}</p>
                        <SocialLogin />
                        <RegisterForm {...props} />
                    </CardBody>
                </Card>
            </div>
        </main>
    );
};

export default RegisterPage;
