import React from 'react';
import parse from 'html-react-parser';
import { getLanguage } from '../../../_helpers';

export const LabelProperty = ({ value }) => {
    if (!value) {
        return null;
    }
    const lang = getLanguage();
    const text = typeof value === 'string' ? value : value[lang];
    return <div>{parse(text)}</div>;
};
