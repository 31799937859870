import React, { useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import debounce from 'just-debounce-it';
import QRCode from 'qrcode.react';
import ls from 'local-storage';
import { notification } from 'antd';
import { Button, ModalFooter } from 'reactstrap';
import { MODALS, URL } from '../../_config';
import { mobileCheck } from '../../_helpers';
import {
    useAdvertisement,
    useApplications,
    useElements,
    useModals,
    useResources,
    useScreens,
    useViewer,
} from '../../hooks';
import { ProgressIndeterminate } from '../../components';
import { DEMO_STATE, PUBLISH_STATE } from '../../context';
import { Modals } from '../../modals';
import {
    ContentTreePanel,
    ElementsPanel,
    ErrorsPanel,
    PropertiesPanel,
    ScreensPanel,
} from '../../panels';
import { MainContent, MainHeader } from '.';

const isMobile = mobileCheck();
document.body.dataset.mobile = isMobile;

const Initiator = () => {
    const history = useHistory();
    const location = useLocation();
    const {
        application = {},
        getApplication = () => {},
        loading: loadingApplications,
    } = useApplications();
    const { getElement = () => {} } = useElements();
    const { modal, openModal } = useModals();
    const { getScreen = () => {} } = useScreens();
    const { loading: loadingViewer, showViewer, viewer = {} } = useViewer();
    const loading = loadingApplications || loadingViewer;
    const [, idUser, idApplication, idScreen, , idElement] = location.pathname.split('/');

    useEffect(() => {
        if (idUser || idApplication || idScreen || idElement) {
            (async () => {
                const user = await showViewer({ id: parseInt(idUser, 10) });
                const application = await getApplication({ id: parseInt(idApplication, 10) });
                const screen = await getScreen({ id: parseInt(idScreen, 10) });
                const element = await getElement({ id: parseInt(idElement, 10) });
                if (
                    !idUser ||
                    (user && !idApplication) ||
                    (application && !idScreen) ||
                    (screen && !idElement) ||
                    element
                ) {
                    history.push('/');
                }
            })();
        }
    }, []);

    useEffect(() => {
        if (!viewer.id) {
            showViewer({ id: idUser });
        }
        if (!loading && !modal && !application.id) {
            openModal(MODALS.WELCOME);
            return;
        }
    }, [application.id, modal]);

    return null;
};

const LoadingBar = () => {
    const { loading: loadingAdvertisement } = useAdvertisement();
    const { loading: loadingApplications } = useApplications();
    const { loading: loadingElements } = useElements();
    const { loading: loadingResources } = useResources();
    const { loading: loadingScreens } = useScreens();
    const { loading: loadingViewer } = useViewer();
    const loading =
        loadingAdvertisement ||
        loadingApplications ||
        loadingElements ||
        loadingResources ||
        loadingScreens ||
        loadingViewer;

    return <ProgressIndeterminate visible={loading && !document.querySelector('.modal')} />;
};

const Demos = () => {
    const { t } = useTranslation();
    const { applications = [], getApplication, getApplicationIcon, getDemo } = useApplications();
    const { openModal } = useModals();

    const removeDemoOnLocalStorage = (demoToRemove) => {
        let preferences = ls('preferences') || {};
        const { demos = [] } = preferences;
        preferences = {
            ...preferences,
            demos: demos.filter(({ id }) => id !== demoToRemove.id),
        };
        ls('preferences', preferences);
    };

    useEffect(
        debounce(() => {
            const { demos: lsDemos = [] } = ls('user') || {};
            if (lsDemos.length) {
                lsDemos.forEach(({ id, IDGuia }) => {
                    if (!applications.some(({ id }) => id === IDGuia)) {
                        getApplication({ id: IDGuia }, { select: false }).then(() =>
                            getDemo({ id })
                        );
                    }
                });
            }
        }, 500),
        []
    );

    useEffect(() => {
        const demos = applications.reduce((lastDemosRequested = [], { demos = [] }) => {
            const lastDemosRequestedFromApp = demos.reduce((lastDemos, demo) => {
                let lastPlatformDemo = lastDemos.find(
                    ({ idPlatform }) => idPlatform === demo.idPlatform
                );
                if (lastPlatformDemo && lastPlatformDemo.FechaDemo < demo.FechaDemo) {
                    lastDemos = lastDemos.filter(({ id }) => id !== lastPlatformDemo.id);
                    lastPlatformDemo = undefined;
                }
                if (!lastPlatformDemo) {
                    lastDemos = [...lastDemos, demo];
                }
                return lastDemos;
            }, []);
            return [...lastDemosRequested, ...lastDemosRequestedFromApp];
        }, []);

        const generatedDemo = demos.find(
            ({ Descargado: isDownloaded, state }) => state === DEMO_STATE.PROCESSED && !isDownloaded
        );

        (async () => {
            if (generatedDemo) {
                const { downloadCode, id, IDGuia, idPlatform } = generatedDemo;
                let preferences = ls('preferences') || {};
                const { demos = [] } = preferences;
                if (!demos.some((demo) => demo && demo.id === id)) {
                    return;
                }
                const application = applications.find(({ id }) => id === IDGuia);
                const { Nombre: name } = application;
                const url = `${URL.STORE}/${downloadCode}`;
                const icon = await getApplicationIcon({ application });
                notification.success({
                    key: `Demo${id}`,
                    message: (
                        <>
                            <img src={icon} alt={`${name} QR`} className="mr-2" width="32px" />
                            <span>{name}</span>
                        </>
                    ),
                    description: (
                        <>
                            <p>{t('publish.demo_has_been_generated')}</p>
                            <QRCode value={url} includeMargin={false} />
                        </>
                    ),
                    duration: 0,
                    onClick: () => {
                        removeDemoOnLocalStorage({ id });
                        openModal(MODALS.PUBLISH, {
                            key: idPlatform === 1 ? 'androidDemo' : 'iosDemo',
                        });
                        notification.close(`Demo${id}`);
                    },
                    onClose: () => removeDemoOnLocalStorage({ id }),
                });
            }
        })();
    }, [applications]);

    return null;
};

const Publications = () => {
    const { t } = useTranslation();
    const { applications = [], getApplication, getApplicationIcon } = useApplications();
    const { openModal } = useModals();

    const removeVersionOnLocalStorage = (versionToSave) => {
        let preferences = ls('preferences') || {};
        const { versions = [] } = preferences;
        preferences = {
            ...preferences,
            versions: versions.filter(({ id }) => id !== versionToSave.id),
        };
        ls('preferences', preferences);
    };

    useEffect(
        debounce(() => {
            const { publications: lsPublications = [] } = ls('user') || {};
            if (lsPublications.length) {
                lsPublications.forEach(({ id: IDGuia }) => {
                    if (!applications.some(({ id }) => id === IDGuia)) {
                        getApplication({ id: IDGuia }, { select: false });
                    }
                });
            }
        }, 500),
        []
    );

    useEffect(() => {
        const application = applications.find(
            ({ inProcess, state }) => state === PUBLISH_STATE.PROCESSED && !inProcess
        );

        if (!application) {
            return;
        }
        const { idCurrentLang, languages = [], lastVersionInfo = {}, Nombre: name } = application;
        const { downloadCode } = languages.find(({ IDLang }) => IDLang === idCurrentLang) || {};
        const url = `${URL.STORE}/${downloadCode}`;
        const { id } = lastVersionInfo;

        (async () => {
            let preferences = ls('preferences') || {};
            const { versions = [] } = preferences;
            if (!versions.some((version) => version.id === id)) {
                return;
            }
            const icon = await getApplicationIcon({ application });
            notification.success({
                key: `Publication${id}`,
                message: (
                    <>
                        <img src={icon} alt={`${name} QR`} className="mr-2" width="32px" />
                        <span>{name}</span>
                    </>
                ),
                description: (
                    <>
                        <p>{t('applications.publication_has_been_generated')}</p>
                        <QRCode value={url} includeMargin={false} />
                        <ModalFooter>
                            <Button color="link">{t('publish.go_to_publications')}</Button>
                        </ModalFooter>
                    </>
                ),
                duration: 0,
                onClick: async () => {
                    removeVersionOnLocalStorage({ id });
                    await getApplication(application);
                    openModal(MODALS.PUBLISH, {
                        key: 'version',
                    });
                    notification.close(`Publication${id}`);
                },
                onClose: () => removeVersionOnLocalStorage({ id }),
            });
        })();
    }, [applications]);

    useEffect(() => {
        const application = applications.find(
            ({ inProcess, processState_googleplay: state }) =>
                state === PUBLISH_STATE.PROCESSED && !inProcess
        );

        if (!application) {
            return;
        }
        const { lastVersionInfo = {}, Nombre: name } = application;
        const { id } = lastVersionInfo;

        (async () => {
            let preferences = ls('preferences') || {};
            const { versions = [] } = preferences;
            if (!versions.some((version) => version.id === id)) {
                return;
            }
            const icon = await getApplicationIcon({ application });
            notification.success({
                key: `GooglePlay${id}`,
                message: (
                    <>
                        <img src={icon} alt={`${name} QR`} className="mr-2" width="32px" />
                        <span>{name}</span>
                    </>
                ),
                description: (
                    <>
                        <p>{t('applications.publication_has_been_generated')}</p>
                        <ModalFooter>
                            <Button color="link">{t('publish.go_to_publications')}</Button>
                        </ModalFooter>
                    </>
                ),
                duration: 0,
                onClick: () => {
                    removeVersionOnLocalStorage({ id });
                    openModal(MODALS.PUBLISH, {
                        key: 'googlePlay',
                    });
                    notification.close(`GooglePlay${id}`);
                },
                onClose: () => removeVersionOnLocalStorage({ id }),
            });
        })();
    }, [applications]);

    useEffect(() => {
        const application = applications.find(
            ({ inProcess, processState_ios: state }) =>
                state === PUBLISH_STATE.PROCESSED && !inProcess
        );

        if (!application) {
            return;
        }
        const { lastVersionInfo = {}, Nombre: name } = application;
        const { id } = lastVersionInfo;

        (async () => {
            let preferences = ls('preferences') || {};
            const { versions = [] } = preferences;
            if (!versions.some((version) => version.id === id)) {
                return;
            }
            const icon = await getApplicationIcon({ application });
            notification.success({
                key: `AppStore${id}`,
                message: (
                    <>
                        <img src={icon} alt={`${name} QR`} className="mr-2" width="32px" />
                        <span>{name}</span>
                    </>
                ),
                description: (
                    <>
                        <p>{t('applications.publication_has_been_generated')}</p>
                        <ModalFooter>
                            <Button color="link">{t('publish.go_to_publications')}</Button>
                        </ModalFooter>
                    </>
                ),
                duration: 0,
                onClick: () => {
                    removeVersionOnLocalStorage({ id });
                    openModal(MODALS.PUBLISH, {
                        key: 'appStore',
                    });
                    notification.close(`AppStore${id}`);
                },
                onClose: () => removeVersionOnLocalStorage({ id }),
            });
        })();
    }, [applications]);

    return null;
};

const MainPage = () => {
    const { refreshApplication = () => {} } = useApplications();

    const onWindowFocus = () => {
        refreshApplication();
    };

    useEffect(() => {
        window.addEventListener('focus', onWindowFocus);
        return () => {
            window.removeEventListener('focus', onWindowFocus);
        };
    }, []);

    return (
        <>
            <Initiator />
            <Demos />
            <Publications />
            <main>
                <MainHeader />
                <LoadingBar />
                <div className="container-wrapper">
                    <ScreensPanel />
                    <ElementsPanel />
                    <MainContent />
                    <ErrorsPanel />
                    <ContentTreePanel />
                    <PropertiesPanel />
                </div>
            </main>
            <Modals />
        </>
    );
};

export default MainPage;
export { MainPage };
