import React, { createContext, useCallback, useEffect, useState } from 'react';
import { useImmerReducer } from 'use-immer';
import JSZip from 'jszip';
import { RESOURCE, ResourcesReducer } from './reducers';
import { URL } from '../_config';
import { getMimeType } from '../_helpers';
import mimeTypes from '../_helpers/mimeTypes.json';
import { useApplications, useViewer } from '../hooks';
import { i18n } from '../i18n';
import { getHeaders } from '.';

export const ONLINE_TITLE = {
    0: i18n.t('properties.resource'),
    1: i18n.t('common.url'),
};

export const RESOURCE_TYPE = {
    AUDIO: 'audio',
    FOLDER: 'folders',
    IMAGE: 'image',
    TEXT: 'text',
    BASE: 'base',
    VIDEO: 'video',
    ZIP: 'zip',
};

export const RESOURCE_ICON = {
    [RESOURCE_TYPE.AUDIO]: 'music',
    [RESOURCE_TYPE.FOLDER]: 'folder',
    [RESOURCE_TYPE.IMAGE]: 'image',
    [RESOURCE_TYPE.TEXT]: 'text',
    [RESOURCE_TYPE.BASE]: 'file',
    [RESOURCE_TYPE.VIDEO]: 'video',
    [RESOURCE_TYPE.ZIP]: 'zip-box',
};

const defaultAttributes = {
    idFolder: null,
};

export const ResourcesContext = createContext(RESOURCE.INITIAL_STATE);

export const ResourcesProvider = ({ children }) => {
    const [loading, setLoading] = useState(false);
    const [state, dispatch] = useImmerReducer(ResourcesReducer, RESOURCE.INITIAL_STATE);
    const { resource = {}, resources = [] } = state;
    const { application = {} } = useApplications();
    const { viewer = {} } = useViewer();

    const checkResources = (resources, { checked = true } = {}) => {
        resources = Array.isArray(resources) ? resources : [resources];
        dispatch({
            type: RESOURCE.CHECK,
            payload: { resources, checked },
        });
    };

    const createResource = useCallback(
        ({ type, ...attributes } = {}) => {
            attributes = {
                ...defaultAttributes,
                IDGuia: application.id,
                ...attributes,
            };

            setLoading(true);
            return fetch(
                `${URL.API}/resource${!type || type === RESOURCE_TYPE.BASE ? '' : `/${type}`}`,
                {
                    method: 'POST',
                    body: JSON.stringify(attributes),
                    headers: getHeaders({ auth: true }),
                }
            )
                .then(async (response) => {
                    let data = await response.json();
                    data = data.data || data || {};
                    return response.ok ? data : Promise.reject(data.type);
                })
                .then((resource) => {
                    setLoading(false);
                    dispatch({
                        type: RESOURCE.GET,
                        payload: {
                            resource: {
                                id: resource.idFolder,
                                idFolder: null,
                                ...resource,
                                type,
                            },
                        },
                    });
                    return resource;
                })
                .catch((err) => {
                    setLoading(false);
                    console.error(err);
                });
        },
        [application.id]
    );

    const deleteResources = (resources) => {
        setLoading(true);
        return Promise.all(
            resources.map((resource) =>
                fetch(
                    `${URL.API}/resource${
                        resource.type !== RESOURCE_TYPE.BASE ? `/${resource.type}` : ''
                    }/${resource.id}`,
                    {
                        method: 'DELETE',
                        headers: getHeaders({ auth: true }),
                    }
                )
            )
        )
            .then((responses) => {
                resources = resources.filter((resource, i) => responses[i].ok);
            })
            .then(() => {
                setLoading(false);
                dispatch({
                    type: RESOURCE.DELETE,
                    payload: { resources },
                });
            });
    };

    const duplicateResources = (resources) => {};

    const getBlob = useCallback(
        (res) => {
            const resource = resources.find(({ id }) => id === res.id);
            if (resource.blob) {
                return Promise.resolve(resource.blob);
            }
            return fetch(`${resource.url}${resource.version ? `?v=${resource.version}` : ''}`, {
                headers: getHeaders({ auth: true }),
            })
                .then(async (response) => {
                    const blob = await response.blob();
                    return response.ok ? blob : Promise.reject();
                })
                .then((blob) => {
                    dispatch({ type: RESOURCE.GET_BLOB, payload: { resource, blob } });
                    return blob;
                })
                .catch((err) => {});
        },
        [resources]
    );

    const getBlobUrl = useCallback(
        (res) => {
            const resource = resources.find(({ id }) => id === res.id);
            if (resource.blobUrl) {
                return Promise.resolve(resource.blobUrl);
            }
            if (resource.blob) {
                const url = window.URL.createObjectURL(resource.blob);
                dispatch({ type: RESOURCE.GET_BLOB_URL, payload: { resource, url } });
                return Promise.resolve(url);
            }
            return fetch(`${resource.url}${resource.version ? `?v=${resource.version}` : ''}`, {
                headers: getHeaders({ auth: true }),
            })
                .then(async (response) => {
                    const blob = await response.blob();
                    return response.ok ? blob : Promise.reject();
                })
                .then((blob) => {
                    const url = window.URL.createObjectURL(blob);
                    dispatch({ type: RESOURCE.GET_BLOB_URL, payload: { resource, url } });
                    return url;
                })
                .catch((err) => {});
        },
        [resources]
    );

    const getContent = (entry, resource = state.resource) => {
        return new Promise((resolve) => {
            if (!entry) {
                resolve(null);
                return;
            }
            if (entry.content) {
                resolve(entry.content);
                return;
            }
            setLoading(true);
            const xhr = new XMLHttpRequest();
            xhr.open('GET', entry.url, true);
            xhr.onreadystatechange = () => {
                if (xhr.readyState === 4 && xhr.status === 200) {
                    const content = xhr.response;
                    dispatch({
                        type: RESOURCE.GET_CONTENT,
                        payload: { content, entry, resource },
                    });
                    setLoading(false);
                    resolve(xhr.response);
                }
            };
            xhr.send();
        });
    };

    const getContentReplaced = useCallback(
        (entry, resource = state.resource) => {
            resource = resources.find(({ id }) => id === resource.id);
            const { entries = [] } = resource;
            return new Promise((resolve) => {
                if (!entry) {
                    resolve();
                    return;
                }
                const { contentReplaced } = entry;
                if (contentReplaced) {
                    resolve(contentReplaced);
                    return;
                }
                getContent(entry, resource).then((content) => {
                    if (!content) {
                        return;
                    }
                    setLoading(true);
                    const replaceStr = {};
                    entries.forEach((entry) => {
                        replaceStr[entry.name.replace('dist/', '')] = entry.url;
                    });
                    const mimetypes = Object.keys(mimeTypes)
                        .reverse()
                        .join('|');
                    const regex = new RegExp(
                        `['"]*[./]*([\\w\\-/]+\\.(${mimetypes}))[?]*[#\\w]*(['"]*)`,
                        'ig'
                    );
                    const contentReplaced = content.replace(regex, (s, key, type, quote) => {
                        const resource = resources.find(
                            ({ Filename, filename }) =>
                                Filename === key.capitalize() || filename === key.capitalize()
                        );
                        if (replaceStr[key]) {
                            return `${quote}${replaceStr[key]}${quote}`;
                        } else if (resource) {
                            const { blobUrl } = resource;
                            return `"${blobUrl}"`;
                        }
                        return s;
                    });

                    dispatch({
                        type: RESOURCE.GET_CONTENT_REPLACED,
                        payload: { contentReplaced, entry, resource },
                    });
                    setLoading(false);
                    resolve(contentReplaced);
                });
            });
        },
        [resource, resources]
    );

    const getResources = useCallback(() => {
        const {
            resources = [],
            resources_audio = [],
            resources_folder = [],
            resources_image = [],
            resources_text = [],
            resources_video = [],
            resources_zip = [],
        } = application;
        dispatch({
            type: RESOURCE.LIST,
            payload: {
                resources: [
                    ...resources.map((res) => ({ ...res, type: RESOURCE_TYPE.BASE })),
                    ...resources_audio.map((res) => ({
                        ...res,
                        type: RESOURCE_TYPE.AUDIO,
                    })),
                    ...resources_folder.map((res) => ({
                        id: res.idFolder,
                        ...res,
                        type: RESOURCE_TYPE.FOLDER,
                    })),
                    ...resources_image.map((res) => ({
                        ...res,
                        type: RESOURCE_TYPE.IMAGE,
                    })),
                    ...resources_text.map((res) => ({
                        ...res,
                        type: RESOURCE_TYPE.TEXT,
                    })),
                    ...resources_video.map((res) => ({
                        ...res,
                        type: RESOURCE_TYPE.VIDEO,
                    })),
                    ...resources_zip.map((res) => ({ ...res, type: RESOURCE_TYPE.ZIP })),
                ].filter(({ name }) => !/^mobincube_/.test(name)),
                reset: true,
            },
        });
    }, [application.resources]);

    const getTextContent = useCallback(
        (res) => {
            const resource = resources.find(({ id }) => id === res.id);
            if (resource.content) {
                //return Promise.resolve(resource.content);
            }
            return fetch(`${res.url}${res.version ? `?v=${res.version}` : ''}`, {
                headers: getHeaders({ auth: true }),
            })
                .then(async (response) => {
                    const text = await response.json();
                    return response.ok ? text : Promise.reject();
                })
                .then((content) => {
                    dispatch({
                        type: RESOURCE.GET_TEXT_CONTENT,
                        payload: { resource, content },
                    });
                    return content;
                })
                .catch((err) => {});
        },
        [resources]
    );

    const selectResource = useCallback(
        (resource) => {
            dispatch({
                type: RESOURCE.SELECT,
                payload: { resource },
            });
        },
        [viewer.id, application.id]
    );

    const uncheckAllResources = useCallback(() => {
        checkResources(resources, { checked: false });
    }, [resources]);

    const uncheckResources = (resources) => {
        checkResources(resources, { checked: false });
    };

    const unselectResource = () => {
        dispatch({ type: RESOURCE.UNSELECT });
    };

    const unzip = async (resource) => {
        return new Promise(async (resolve, reject) => {
            try {
                setLoading(true);
                const zip = await getBlob(resource);
                const uncompressedZip = await JSZip.loadAsync(zip);
                let { jsonProperties } = resource;
                let jsonPropertiesToUpdate = null;
                let { files = {} } = uncompressedZip;
                const entries = (
                    await Promise.all(
                        Object.values(files).map(async (file) => {
                            const { name } = file;
                            if (name === 'properties.json') {
                                if (!jsonProperties) {
                                    await file.async('string').then((str) => {
                                        try {
                                            jsonPropertiesToUpdate = JSON.parse(str);
                                        } catch (error) {
                                            console.error(error);
                                        }
                                    });
                                }
                                const blob = new Blob([JSON.stringify(jsonProperties)], {
                                    type: 'application/json',
                                });
                                const url = window.URL.createObjectURL(blob);
                                return {
                                    name,
                                    blob,
                                    url,
                                };
                            }
                            return file.async('blob').then(async (blob) => {
                                if (!blob.size) {
                                    return;
                                }
                                let ext = name.split('.').pop();
                                blob = blob.slice(0, blob.size, getMimeType(ext));
                                const url = window.URL.createObjectURL(blob);
                                return {
                                    name,
                                    blob,
                                    url,
                                };
                            });
                        })
                    )
                ).filter((entry) => entry);
                resolve(entries);

                dispatch({
                    type: RESOURCE.UNZIP,
                    payload: { entries, jsonProperties, resource },
                });

                if (jsonPropertiesToUpdate) {
                    updateResource({ jsonProperties: jsonPropertiesToUpdate }, resource);
                }

                setLoading(false);
            } catch (e) {
                setLoading(false);
                console.error('>>>', e);
            }
        });
    };

    const updateResource = useCallback(
        (attributes = {}, resource = state.resource) => {
            if (!resource) {
                return;
            }
            setLoading(true);
            return fetch(
                `${URL.API}/resource${
                    resource.type === RESOURCE_TYPE.BASE ? '' : `/${resource.type}`
                }${`/${resource.id}`}`,
                {
                    method: 'PATCH',
                    body: JSON.stringify(attributes),
                    headers: getHeaders({ auth: true }),
                }
            )
                .then(async (response) => {
                    const data = (await response.json()) || {};
                    return response.ok ? data : Promise.reject(data.type);
                })
                .then(() => {
                    setLoading(false);
                    const { jsonProperties } = attributes;
                    if (jsonProperties) {
                        const { entries = [] } = resource;
                        if (entries.length) {
                            attributes.entries = entries.map((entry) => {
                                const { name } = entry;
                                if (name === 'properties.json') {
                                    const blob = new Blob([JSON.stringify(jsonProperties)], {
                                        type: 'application/json',
                                    });
                                    const url = window.URL.createObjectURL(blob);
                                    return { name, blob, url };
                                }
                                return entry;
                            });
                        }
                        attributes.updated = Date.now();
                    } else {
                        getBlobUrl(resource);
                    }
                    if (attributes.content) {
                        attributes.version = (resource.version || 0) + 1;
                    }
                    dispatch({
                        type: RESOURCE.UPDATE,
                        payload: { resource: { ...resource, ...attributes } },
                    });
                })
                .catch((err) => {
                    setLoading(false);
                    console.error(err);
                });
        },
        [resource, resources]
    );

    useEffect(() => {
        getResources();
    }, [application.resources]);

    if (process.env.NODE_ENV === 'development') {
        console.log('RESOURCES >>>', state);
    }

    return (
        <ResourcesContext.Provider
            value={{
                ...state,
                checkResources,
                createResource,
                deleteResources,
                duplicateResources,
                getBlob,
                getBlobUrl,
                getContent,
                getContentReplaced,
                getResources,
                getTextContent,
                loading,
                selectResource,
                setLoading,
                uncheckAllResources,
                uncheckResources,
                unselectResource,
                unzip,
                updateResource,
            }}
        >
            {children}
        </ResourcesContext.Provider>
    );
};

export const ResourcesConsumer = ResourcesContext.Consumer;
export default ResourcesContext;
