import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, CardHeader, ListGroup, ListGroupItem } from 'reactstrap';
import {
    DRAG_ITEM_TYPE,
    ELEMENT_ICON,
    ELEMENT_TITLE,
    ELEMENT_TYPE,
    SCREEN_ICON,
    SCREEN_TYPE,
    SCREEN_TYPE_NAME,
} from '../context';
import { BODY_ICON, BODY_TYPE, Icon, Panel, Sortable } from '../components';
import {
    useApplications,
    useDragItems,
    useElements,
    useResources,
    useScreens,
    useViewer,
} from '../hooks';

const ScreenItem = (screen) => {
    const { getDragItem } = useDragItems();
    const { resources = [] } = useResources();
    const { IDTipoSeccion: type, typeName } = screen;
    const { t } = useTranslation();
    let icon = SCREEN_ICON[type];
    let title = t(`screens.${typeName}`);
    if (type === SCREEN_TYPE.WEB) {
        let { concrete = {} } = screen;
        concrete = concrete || {};
        const { idZip } = concrete;
        const zipRes = resources.find(({ id }) => id === idZip) || {};
        const { idModule } = zipRes;
        const { icon: dragItemIcon, title: dragItemTitle } =
            getDragItem(
                ({ attributes: { concrete: { idModule: m } = {} } = {} }) =>
                    idModule && m === idModule
            ) || {};
        icon = dragItemIcon || icon;
        title = dragItemTitle || title;
    }

    return (
        <ListGroupItem action className="list-group-screen-item">
            <div className="tree-item-title">
                <Icon type={icon} className="mr-2" />
                <span>{title}</span>
            </div>
        </ListGroupItem>
    );
};

const Item = ({
    children = [],
    expanded: expandedProp,
    sortableData = {},
    visible = true,
    ...props
}) => {
    const { t } = useTranslation();
    const [expanded, setExpanded] = useState(expandedProp);
    const { element = {}, elements = [], selectElement } = useElements();
    const { screen = {} } = useScreens();
    const { id, IDElementType: type, concrete = {} } = props;
    const { bodyType, name: barName, title: barTitle } = concrete;
    let icon = ELEMENT_ICON[type];
    let title = ELEMENT_TITLE[type];
    const data = {
        'data-active': id && id === element.id,
        'data-element-id': id,
        'data-element-type': type,
        'data-type': DRAG_ITEM_TYPE.ELEMENT,
    };
    if (barName) {
        data['data-screen-bar-id'] = `${screen.id},${id}`;
    }
    if (bodyType) {
        data['data-body-type'] = bodyType;
    }

    sortableData = {
        ...sortableData,
        'data-accept': `${
            bodyType === BODY_TYPE.HORIZONTAL ? BODY_TYPE.VERTICAL : DRAG_ITEM_TYPE.ELEMENT
        }`,
        'data-not-accept': `${id ? ELEMENT_TYPE.BAR : ''}`,
    };
    if (type === ELEMENT_TYPE.BAR && barName !== barTitle) {
        title = barTitle;
    }
    if (type === ELEMENT_TYPE.BODY) {
        icon = BODY_ICON[bodyType];
        title = t(`elements.${bodyType}_body`);
    }
    if (bodyType !== BODY_TYPE.HORIZONTAL) {
        sortableData['data-not-accept'] = `${
            sortableData['data-not-accept'] ? `${sortableData['data-not-accept']},` : ''
        }${BODY_TYPE.VERTICAL}`;
    }
    children = children.length
        ? children
        : elements.filter(({ IDParentElement }) => IDParentElement === id);

    const handleExpand = (event) => {
        event.stopPropagation();
        setExpanded((expanded) => !expanded);
    };

    const handleSelectElement = (event) => {
        event.preventDefault();
        event.stopPropagation();
        if (id === element.id) {
            return;
        }
        selectElement({ id });
    };

    const handleMouseEnter = (event) => {
        const { currentTarget } = event;
        const { parentElement } = currentTarget;
        const { dataset } = parentElement;
        const { elementId } = dataset;
        document
            .querySelectorAll(`[data-element-id]`)
            .forEach((el) => el.classList.remove('hover'));
        const elementWrapper = document.querySelector(`[data-element-id="${elementId}"]`);
        if (elementWrapper) {
            elementWrapper.classList.add('hover');
            elementWrapper.scrollIntoView({
                behavior: 'smooth',
                block: 'center',
                inline: 'center',
            });
        }
    };

    const handleMouseLeave = (event) => {
        const { currentTarget } = event;
        const { parentElement } = currentTarget;
        const { dataset } = parentElement;
        const { elementId } = dataset;
        document.querySelector(`[data-element-id="${elementId}"]`).classList.remove('hover');
    };

    return (
        <ListGroupItem action className={!visible ? 'p-0' : ''} aria-expanded={expanded} {...data}>
            {visible && (
                <div
                    className="tree-item-title"
                    onMouseEnter={handleMouseEnter}
                    onMouseLeave={handleMouseLeave}
                    onClick={handleSelectElement}
                >
                    {([ELEMENT_TYPE.BAR, ELEMENT_TYPE.BODY].includes(type) && (
                        <Button className="tree-expand-button" color="link" onClick={handleExpand}>
                            <Icon type="menu-right" />
                        </Button>
                    )) ||
                        null}
                    <Icon type={icon} className="mr-2" />
                    <span>{title}</span>
                </div>
            )}
            {((!type || type === ELEMENT_TYPE.BAR || type === ELEMENT_TYPE.BODY) && (
                <Sortable
                    key={`ListGroup-${id}-${children
                        .map(({ lastUpdated } = {}) => lastUpdated)
                        .join('-')}`}
                    className="list-group tree-item-children"
                    group={{
                        name: 'ContentTree',
                        pull: true,
                        put: ['ContentTree', 'DragItems', 'Elements'],
                    }}
                    data={sortableData}
                    handle=".tree-item-title"
                    tag="ul"
                >
                    {children
                        .filter((element) => element)
                        .sort((el1, el2) => (el1.Sequence < el2.Sequence ? -1 : 1))
                        .map((element) => (
                            <Item key={`Item${element.id}-${title}`} {...element} />
                        ))}
                </Sortable>
            )) ||
                null}
        </ListGroupItem>
    );
};

export const ContentTreePanel = () => {
    const { t } = useTranslation();
    const { application = {} } = useApplications();
    const { bars = [] } = application;
    const { getDragItemAttributes } = useDragItems();
    const { createElement, selectElement } = useElements();
    const { createConcreteScreen = () => {}, screen = {} } = useScreens();
    const { getPanelWidth, isVisibleContentTreePanel } = useViewer();
    let { concrete, section_bars: screenBars = [] } = screen;
    concrete = concrete || {};
    const { IDMainElement } = concrete;
    const topBars = screenBars
        .filter(({ position }) => position === 'top')
        .sort((bar1, bar2) => (bar1.sequence < bar2.sequence ? -1 : 1))
        .map(({ IDElement }) => bars.find(({ id }) => id === IDElement));
    const bottomBars = screenBars
        .filter(({ position }) => position === 'bottom')
        .sort((bar1, bar2) => (bar1.sequence < bar2.sequence ? -1 : 1))
        .map(({ IDElement }) => bars.find(({ id }) => id === IDElement));

    const handleAdd = (event) => {
        const { item } = event;
        const idItem = parseInt(item.dataset.id, 10);
        if (!idItem) {
            return;
        }
        const attributes = getDragItemAttributes(idItem);
        item.remove();
        if (item.dataset.type === DRAG_ITEM_TYPE.SCREEN) {
            createConcreteScreen(attributes);
            return;
        }
        createConcreteScreen({ typeName: SCREEN_TYPE_NAME.INFO })
            .then(({ IDMainElement }) =>
                createElement({
                    ...attributes,
                    IDParentElement: IDMainElement,
                    Sequence: 1,
                    id: `c${Date.now()}`,
                })
            )
            .then((element) => selectElement(element));
    };

    return (
        <Panel
            className={`content-tree-panel${!isVisibleContentTreePanel ? ' d-none' : ''}`}
            disabled={!screen.id}
            panel="contentTree"
            title={t('content_tree.title')}
            width={getPanelWidth('contentTree')}
        >
            <div key={`TopBars-${topBars.length}`}>
                <CardHeader>
                    <strong>{t('content_tree.top_bars')}</strong>
                </CardHeader>
                <ListGroup className="overflow-auto">
                    <Item
                        children={topBars}
                        expanded={true}
                        sortableData={{ 'data-bars': true, 'data-position': 'top' }}
                        visible={false}
                    />
                </ListGroup>
            </div>
            <CardHeader>
                <strong>{t('content_tree.main_content')}</strong>
            </CardHeader>
            <div className="position-relative">
                <ListGroup className="overflow-auto" data-main-element-id={IDMainElement}>
                    {IDMainElement ? (
                        <Item
                            key={`Item${IDMainElement}`}
                            id={IDMainElement}
                            expanded={true}
                            visible={false}
                        />
                    ) : (
                        <ScreenItem {...screen} />
                    )}
                </ListGroup>
                <Sortable
                    className="drop-zone"
                    group={{ name: 'Viewport', pull: false, put: ['DragItems'] }}
                    handle=".none"
                    onAdd={handleAdd}
                    data={{
                        'data-accept': `${DRAG_ITEM_TYPE.ELEMENT},${DRAG_ITEM_TYPE.SCREEN}`,
                    }}
                />
            </div>
            <div key={`BottomBars-${bottomBars.length}`}>
                <CardHeader>
                    <strong>{t('content_tree.bottom_bars')}</strong>
                </CardHeader>
                <ListGroup className="overflow-auto">
                    <Item
                        children={bottomBars}
                        expanded={true}
                        sortableData={{ 'data-bars': true, 'data-position': 'bottom' }}
                        visible={false}
                    />
                </ListGroup>
            </div>
        </Panel>
    );
};
