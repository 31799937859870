import React from 'react';
import { useTranslation } from 'react-i18next';
import { Icon } from '../../../components';
import { ScreenWrapper } from '.';

export const Undefined = (props) => {
    const { t } = useTranslation();
    return (
        <ScreenWrapper {...props}>
            <div className="skeleton">
                <Icon type="open-in-app" />
                <span>{t('viewport.drop_elements_here')}</span>
            </div>
        </ScreenWrapper>
    );
};
