import React from 'react';
import { Select } from 'antd';
import { getLanguage } from '../../../_helpers';

export const SelectProperty = ({ data = [], multiple, onChange: handleChange, value }) => {
    const lang = getLanguage();

    const handleSelectChange = (value) => {
        handleChange(value);
    };

    return (
        <Select
            className="w-100"
            value={value}
            options={data.map(({ id, label, text, value }) => {
                label = text || label || '';
                label = typeof label === 'string' ? label : label[lang];
                value = value || id || '';
                return { label, value };
            })}
            onChange={handleSelectChange}
        />
    );
};
