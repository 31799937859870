import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Gravatar from 'react-gravatar';
import parse from 'html-react-parser';
import ls from 'local-storage';
import { message, Tag, Tooltip } from 'antd';
import {
    Button,
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    FormGroup,
    Input,
    Label,
    ModalBody,
    ModalFooter,
    Nav,
    NavbarBrand,
    UncontrolledDropdown,
} from 'reactstrap';
import { MODALS, URL } from '../../_config';
import {
    useApplications,
    useElements,
    useModals,
    useResources,
    useScreens,
    useViewer,
} from '../../hooks';
import { Action, Checkbox, CirclePreloader, Icon, Modal } from '../../components';
import {
    DEMO_STATE,
    PLAN_NAME,
    PROVIDER_TYPE,
    PUBLISH_STATE,
    SERVICE_TYPE,
    SUBSCRIPTION_STATE,
} from '../../context';
import { TOOL_TYPE } from '../../modals';
import { logoMinWhite } from '../../assets/svg/logos';
import defaultIcon from '../../assets/svg/logos/icon.svg';

const handleCheck = (event) => {
    const checkbox = event.currentTarget.querySelector('input');
    checkbox && checkbox.click();
};

const Menu = ({ children, className, direction = 'right', disabled, right, toggle }) => {
    const handleMouseEnter = (event) => {
        const { currentTarget } = event;
        const { parentElement } = currentTarget;
        const show = document.querySelector('.main-header .dropdown-menu.show');
        if (show && !parentElement.classList.contains('show')) {
            currentTarget.click();
        }
    };
    const handleMouseEnterOnItem = (event) => {
        const { currentTarget } = event;
        const { parentElement } = currentTarget;
        const menuShown = parentElement.querySelector('.dropdown-menu.show');
        if (menuShown) {
            menuShown.previousSibling.click();
        }
    };

    children = Array.isArray(children) ? children : [children];
    children = children.map((child) => ({
        ...child,
        props: { ...child.props, onMouseEnter: handleMouseEnterOnItem },
    }));

    const menuAttrs = {};
    if (right) {
        menuAttrs.right = true;
    }

    return (
        <UncontrolledDropdown className={className} direction={direction}>
            <DropdownToggle caret disabled={disabled} nav onMouseEnter={handleMouseEnter}>
                {toggle}
            </DropdownToggle>
            <DropdownMenu {...menuAttrs}>{children}</DropdownMenu>
        </UncontrolledDropdown>
    );
};
const ApplicationMenu = () => {
    const { t } = useTranslation();
    const [applicationToDelete, setApplicationToDelete] = useState();
    const {
        application = {},
        createApplication,
        deleteApplication,
        duplicateApplication,
        isServiceActive,
        selectApplication,
        toggleServices,
        unselectApplication,
    } = useApplications();
    const { unselectElement = () => {} } = useElements();
    const { openModal = () => {} } = useModals();
    const { isAllowed, recentApplications = [] } = useViewer();
    const checked = !!application.id && !isServiceActive([SERVICE_TYPE.REMOVE_INITIAL_SPLASH]);

    const handleSetNameClick = () => {
        document.getElementById('ApplicationName').focus();
    };

    const handleSetIconClick = () => {
        document.getElementById('ApplicationIcon').click();
    };

    const handleAppInfoClick = (event) => {
        const { currentTarget } = event;
        const { name: key } = currentTarget;
        openModal(MODALS.APP_INFO, { key });
    };

    const handleChange = async () => {
        if (!(await isAllowed(SERVICE_TYPE.REMOVE_INITIAL_SPLASH))) {
            openModal(MODALS.UPGRADE);
            return;
        }

        toggleServices([SERVICE_TYPE.REMOVE_INITIAL_SPLASH]);
    };

    return (
        <>
            <Menu direction="down" toggle={t('header.app')}>
                <DropdownItem onClick={() => createApplication()}>{t('header.new')}</DropdownItem>
                <DropdownItem onClick={() => openModal(MODALS.APPLICATIONS)}>
                    {t('header.open')}
                </DropdownItem>
                <Menu toggle={t('header.open_recent')} disabled={!recentApplications.length}>
                    {recentApplications.map(({ id, Nombre }) => {
                        return (
                            <DropdownItem
                                key={`RecentApplication${id}`}
                                onClick={() => {
                                    selectApplication({ id });
                                    unselectElement();
                                    document.body.click();
                                }}
                            >
                                {Nombre}
                            </DropdownItem>
                        );
                    })}
                </Menu>
                <DropdownItem
                    disabled={!application.id}
                    onClick={() => duplicateApplication(application)}
                >
                    {t('header.duplicate')}
                </DropdownItem>
                <DropdownItem divider />
                <DropdownItem disabled={!application.id} onClick={() => unselectApplication()}>
                    {t('header.close')}
                </DropdownItem>
                <DropdownItem divider />
                <DropdownItem
                    disabled={!application.id}
                    onClick={() => setApplicationToDelete(application)}
                >
                    {t('header.delete')}
                </DropdownItem>
                <DropdownItem divider />
                <DropdownItem disabled={!application.id} onClick={handleSetNameClick}>
                    {t('header.set_name')}
                </DropdownItem>
                <DropdownItem disabled={!application.id} onClick={handleSetIconClick}>
                    {t('header.set_icon')}
                </DropdownItem>
                <Menu toggle={t('header.app_info')} disabled={!application.id}>
                    <DropdownItem
                        disabled={!application.id}
                        name="appId"
                        onClick={handleAppInfoClick}
                    >
                        {t('header.app_id')}
                    </DropdownItem>
                    <DropdownItem
                        disabled={!application.id}
                        name="certificates"
                        onClick={handleAppInfoClick}
                    >
                        {t('header.certificate_and_provisioning_file')}
                    </DropdownItem>
                    <DropdownItem
                        disabled={!application.id}
                        name="keystore"
                        onClick={handleAppInfoClick}
                    >
                        {t('header.uploadkey')}
                    </DropdownItem>
                </Menu>
                <DropdownItem divider />
                <Menu toggle={t('header.preferences')} disabled={!application.id}>
                    <DropdownItem disabled={!application.id} onClick={handleCheck}>
                        <Checkbox checked={checked} onChange={handleChange} />
                        {t('header.show_initial_splash')}
                    </DropdownItem>
                </Menu>
            </Menu>
            <Modal isOpen={!!applicationToDelete}>
                <ModalBody>
                    {t('applications.delete_confirm', {
                        name: applicationToDelete && applicationToDelete.Nombre,
                    })}
                </ModalBody>
                <ModalFooter>
                    <Button
                        className="mr-2"
                        color="gray"
                        outline
                        onClick={() => {
                            setApplicationToDelete(undefined);
                        }}
                    >
                        {t('common.cancel')}
                    </Button>
                    <Button
                        color="danger"
                        onClick={() => {
                            deleteApplication(applicationToDelete);
                            setApplicationToDelete(undefined);
                        }}
                    >
                        {t('common.delete')}
                    </Button>
                </ModalFooter>
            </Modal>
        </>
    );
};

const ScreenMenu = () => {
    const { t } = useTranslation();
    const [screensToDelete, setScreensToDelete] = useState([]);
    const [isTimerModalOpened, openTimerModal] = useState(false);
    const { application = {}, updateApplication } = useApplications();
    const {
        createScreen,
        deleteScreens,
        duplicateScreen,
        screen = {},
        screens = [],
        selectScreen,
        updateScreen,
    } = useScreens();
    const { openModal } = useModals();
    const { isAllowed } = useViewer();
    const checkedScreens = screens.filter(({ checked }) => checked);
    const { adSupported, anchor, IDAction = 0, orientation, Parameter, Timeout } = screen;

    const handleSetMainScreenClick = () => {
        updateApplication({ IDMainSection: screen.id }, application);
        document.body.click();
    };

    const handleOrientationChange = () => {
        updateScreen(
            { orientation: orientation === 'landscape' ? 'portrait' : 'landscape' },
            screen
        );
    };

    const handleAnchorChange = () => {
        updateScreen({ anchor: !anchor }, screen);
    };

    const handleAdSupportedChange = async () => {
        if (!(await isAllowed(SERVICE_TYPE.AD_SUPPORTED))) {
            openModal(MODALS.UPGRADE);
            return;
        }
        updateScreen({ adSupported: !adSupported }, screen);
    };

    const handleTimerClick = () => {
        openTimerModal(true);
    };

    const handleTimerSecondsChange = (event) => {
        const { currentTarget } = event;
        let { value } = currentTarget;
        value = parseFloat(value.replace(',', '.')) * 1000;

        if (isNaN(value)) {
            value = 0;
        }

        updateScreen({ Timeout: value }, screen);
    };

    const handleTimerActionChange = ({ action: IDAction, parameter: Parameter }) => {
        updateScreen({ IDAction, Parameter }, screen);
    };

    return (
        <>
            <Menu direction="down" toggle={t('header.screens')}>
                <DropdownItem disabled={!application.id} onClick={() => createScreen()}>
                    {t('header.new')}
                </DropdownItem>
                <Menu toggle={t('header.open')} disabled={!application.id}>
                    {screens.map(({ id, Nombre }) => (
                        <DropdownItem
                            key={`Screen${id}`}
                            onClick={() => {
                                selectScreen({ id });
                                document.body.click();
                            }}
                        >
                            {Nombre}
                        </DropdownItem>
                    ))}
                </Menu>
                <DropdownItem disabled={!screen.id} onClick={() => duplicateScreen(screen)}>
                    {t('header.duplicate')}
                </DropdownItem>
                <DropdownItem divider />
                <DropdownItem
                    disabled={!screen.id}
                    onClick={() =>
                        setScreensToDelete(checkedScreens.length ? checkedScreens : [screen])
                    }
                >
                    {t('header.delete')}
                </DropdownItem>
                <DropdownItem divider />
                <Menu toggle={t('header.properties')} disabled={!screen.id}>
                    <DropdownItem disabled={!screen.id} onClick={handleSetMainScreenClick}>
                        {t('header.set_main_screen')}
                    </DropdownItem>
                    <DropdownItem disabled={!screen.id} onClick={handleTimerClick}>
                        {t('header.timer')}
                    </DropdownItem>
                    <DropdownItem disabled={!screen.id} onClick={handleCheck}>
                        <Checkbox
                            checked={orientation === 'landscape'}
                            onChange={handleOrientationChange}
                        />
                        {t('header.landscape')}
                    </DropdownItem>
                    <DropdownItem disabled={!screen.id} onClick={handleCheck}>
                        <Checkbox checked={anchor} onChange={handleAnchorChange} />
                        {t('header.anchor')}
                    </DropdownItem>
                    <DropdownItem disabled={!screen.id} onClick={handleCheck}>
                        <Checkbox checked={adSupported} onChange={handleAdSupportedChange} />
                        {t('header.ad_banner')}
                    </DropdownItem>
                </Menu>
            </Menu>
            <Modal isOpen={!!screensToDelete.length}>
                <ModalBody>
                    {t('screens.delete_confirm', {
                        count: screensToDelete.length,
                        name: screensToDelete[0] && screensToDelete[0].Nombre,
                    })}
                </ModalBody>
                <ModalFooter>
                    <Button
                        className="mr-2"
                        color="gray"
                        outline
                        onClick={() => {
                            setScreensToDelete([]);
                        }}
                    >
                        {t('common.cancel')}
                    </Button>
                    <Button
                        color="danger"
                        onClick={() => {
                            deleteScreens(screensToDelete);
                            setScreensToDelete([]);
                        }}
                    >
                        {t('common.delete')}
                    </Button>
                </ModalFooter>
            </Modal>
            <Modal
                className="timer-modal"
                isOpen={isTimerModalOpened}
                title={t('header.timer')}
                onClosed={() => openTimerModal(false)}
            >
                <ModalBody>
                    <FormGroup>
                        <Label className="h6 small d-block text-uppercase">
                            {t('properties.seconds')}
                        </Label>
                        <Input
                            defaultValue={Timeout === -1 ? 0 : Timeout / 1000}
                            onBlur={handleTimerSecondsChange}
                        />
                    </FormGroup>
                    <Action
                        action={IDAction}
                        parameter={Parameter}
                        onChange={handleTimerActionChange}
                    />
                    <p style={{ width: '270px' }}>
                        <small>{t('screens.timer_description')}</small>
                    </p>
                </ModalBody>
            </Modal>
        </>
    );
};

const ResourcesMenu = () => {
    const { t } = useTranslation();
    const { application = {} } = useApplications();
    const { openModal } = useModals();
    const { uncheckAllResources, unselectResource } = useResources();
    const { doyoAuth = {} } = ls('user') || {};
    const { token } = doyoAuth;

    const handleBarsClick = () => {
        openModal(MODALS.BARS);
    };

    const handleClick = (event) => {
        const { currentTarget } = event;
        const { name: key } = currentTarget;
        uncheckAllResources();
        unselectResource();
        openModal(MODALS.RESOURCES, { key });
    };

    return (
        <Menu direction="down" toggle={t('header.resources')}>
            <DropdownItem disabled={!application.id} name="local" onClick={handleBarsClick}>
                {t('elements.bars')}
            </DropdownItem>
            <DropdownItem divider />
            <DropdownItem disabled={!application.id} name="local" onClick={handleClick}>
                {t('header.local_resources')}
            </DropdownItem>
            <DropdownItem disabled={!application.id} name="unsplash" onClick={handleClick}>
                {t('header.unsplash')}
            </DropdownItem>
            <DropdownItem divider />
            <a className="dropdown-item" href={URL.CLOUD} target="_blank" rel="noopener noreferrer">
                {t('header.open_cloud')}
            </a>
            <a
                className="dropdown-item"
                href={`${URL.KUSTODIO}/t/${token}`}
                target="_blank"
                rel="noopener noreferrer"
            >
                {t('header.open_kustodio')}
            </a>
        </Menu>
    );
};

const PublicationsMenu = () => {
    const { t } = useTranslation();
    const { application = {} } = useApplications();
    const { inProcess, lastVersionInfo = {} } = application;
    const { closed, version = 1 } = lastVersionInfo;
    const { openModal } = useModals();

    const handleClick = (event) => {
        const { currentTarget } = event;
        const { name: key } = currentTarget;
        openModal(MODALS.PUBLISH, { key });
    };

    return (
        <Menu direction="down" toggle={t('header.publicate')}>
            <Menu toggle={t('header.demo')} disabled={!application.id}>
                <DropdownItem disabled={!application.id} name="androidDemo" onClick={handleClick}>
                    Android
                </DropdownItem>
                <DropdownItem disabled={!application.id} name="iosDemo" onClick={handleClick}>
                    iOS
                </DropdownItem>
            </Menu>
            <Menu toggle={t('header.version')} disabled={!application.id}>
                <DropdownItem disabled={!application.id} name="version" onClick={handleClick}>
                    Mobincube
                    {inProcess && <CirclePreloader className="justify-content-end mr-2" />}
                </DropdownItem>
                <DropdownItem
                    disabled={inProcess || (version <= 1 && closed !== '1')}
                    name="googlePlay"
                    onClick={handleClick}
                >
                    {t('header.google_play')}
                    {version <= 1 && closed !== '1' && (
                        <Tag color="warning" className="ml-2 mr-0">
                            {t('publish.publish_version_first')}
                        </Tag>
                    )}
                </DropdownItem>
                <DropdownItem
                    disabled={inProcess || (version <= 1 && closed !== '1')}
                    name="androidMarkets"
                    onClick={handleClick}
                >
                    {t('header.other_android_markets')}
                    {version <= 1 && closed !== '1' && (
                        <Tag color="warning" className="ml-2 mr-0">
                            {t('publish.publish_version_first')}
                        </Tag>
                    )}
                </DropdownItem>
                <DropdownItem
                    disabled={inProcess || (version <= 1 && closed !== '1')}
                    name="appStore"
                    onClick={handleClick}
                >
                    {t('header.app_store')}
                    {version <= 1 && closed !== '1' && (
                        <Tag color="warning" className="ml-2 mr-0">
                            {t('publish.publish_version_first')}
                        </Tag>
                    )}
                </DropdownItem>
            </Menu>
        </Menu>
    );
};

const AdvertisementMenu = () => {
    const { t } = useTranslation();
    const { application = {}, isServiceActive, toggleServices } = useApplications();
    const { openModal } = useModals();
    const { isAllowed } = useViewer();
    const checked =
        !!application.id &&
        !isServiceActive([
            SERVICE_TYPE.REMOVE_ADS_ANDROID,
            SERVICE_TYPE.REMOVE_ADS_IOS,
            SERVICE_TYPE.REMOVE_ADS_HTML,
        ]);

    const handleAdNetworkClick = (event) => {
        const { currentTarget } = event;
        const { name: key } = currentTarget;
        openModal(MODALS.ADVERTISEMENT, { key });
    };

    const handleChange = async () => {
        if (!(await isAllowed(SERVICE_TYPE.REMOVE_INITIAL_SPLASH))) {
            openModal(MODALS.UPGRADE);
            return;
        }

        toggleServices([
            SERVICE_TYPE.REMOVE_ADS_ANDROID,
            SERVICE_TYPE.REMOVE_ADS_IOS,
            SERVICE_TYPE.REMOVE_ADS_HTML,
        ]);
    };

    return (
        <Menu direction="down" toggle={t('header.advertisement')}>
            <DropdownItem disabled={!application.id} onClick={handleCheck}>
                <Checkbox checked={checked} onChange={handleChange} />
                {t('header.show_ads')}
            </DropdownItem>
            <Menu toggle={t('header.ad_networks')} disabled={!application.id}>
                <DropdownItem
                    disabled={!application.id}
                    name={PROVIDER_TYPE.ADCOLONY}
                    onClick={handleAdNetworkClick}
                >
                    {t('header.adcolony')}
                </DropdownItem>
                <DropdownItem
                    disabled={!application.id}
                    name={PROVIDER_TYPE.ADMOBNATIVE}
                    onClick={handleAdNetworkClick}
                >
                    {t('header.admob')}
                </DropdownItem>
                <DropdownItem
                    disabled={!application.id}
                    name={PROVIDER_TYPE.AMAZON}
                    onClick={handleAdNetworkClick}
                >
                    {t('header.amazon')}
                </DropdownItem>
                <DropdownItem
                    disabled={!application.id}
                    name={PROVIDER_TYPE.FACEBOOK}
                    onClick={handleAdNetworkClick}
                >
                    {t('header.facebook')}
                </DropdownItem>
                <DropdownItem
                    disabled={!application.id}
                    name={PROVIDER_TYPE.STARTAPP}
                    onClick={handleAdNetworkClick}
                >
                    {t('header.startapp')}
                </DropdownItem>
                <DropdownItem
                    disabled={!application.id}
                    name={PROVIDER_TYPE.TAPPX}
                    onClick={handleAdNetworkClick}
                >
                    {t('header.tappx')}
                </DropdownItem>
                <DropdownItem
                    disabled={!application.id}
                    name={PROVIDER_TYPE.UNITYADS}
                    onClick={handleAdNetworkClick}
                >
                    {t('header.unityads')}
                </DropdownItem>
            </Menu>
            <DropdownItem
                disabled={!application.id}
                name="configuration"
                onClick={handleAdNetworkClick}
            >
                {t('header.configuration')}
            </DropdownItem>
        </Menu>
    );
};

const WindowsMenu = () => {
    const { t } = useTranslation();
    const { application = {} } = useApplications();
    const {
        isVisibleScreensPanel,
        isVisibleElementsPanel,
        isVisibleErrorsPanel,
        isVisibleContentTreePanel,
        isVisiblePropertiesPanel,
        setIsVisibleScreensPanel,
        setIsVisibleElementsPanel,
        setIsVisibleErrorsPanel,
        setIsVisibleContentTreePanel,
        setIsVisiblePropertiesPanel,
    } = useViewer();

    return (
        <Menu direction="down" toggle={t('header.window')}>
            <DropdownItem disabled={!application.id} onClick={handleCheck}>
                <Checkbox
                    checked={isVisibleScreensPanel}
                    onChange={() => setIsVisibleScreensPanel(!isVisibleScreensPanel)}
                />
                {t('header.screens')}
            </DropdownItem>
            <DropdownItem disabled={!application.id} onClick={handleCheck}>
                <Checkbox
                    checked={isVisibleElementsPanel}
                    onChange={() => setIsVisibleElementsPanel(!isVisibleElementsPanel)}
                />
                {t('header.elements')}
            </DropdownItem>
            <DropdownItem disabled={!application.id} onClick={handleCheck}>
                <Checkbox
                    checked={isVisibleErrorsPanel}
                    onChange={() => setIsVisibleErrorsPanel(!isVisibleErrorsPanel)}
                />
                {t('header.errors')}
            </DropdownItem>
            <DropdownItem disabled={!application.id} onClick={handleCheck}>
                <Checkbox
                    checked={isVisibleContentTreePanel}
                    onChange={() => setIsVisibleContentTreePanel(!isVisibleContentTreePanel)}
                />
                {t('header.content_tree')}
            </DropdownItem>
            <DropdownItem disabled={!application.id} onClick={handleCheck}>
                <Checkbox
                    checked={isVisiblePropertiesPanel}
                    onChange={() => setIsVisiblePropertiesPanel(!isVisiblePropertiesPanel)}
                />
                {t('header.properties')}
            </DropdownItem>
        </Menu>
    );
};

const ToolsMenu = () => {
    const { t } = useTranslation();
    const { openModal } = useModals();

    const handleToolClick = (key) => {
        openModal(MODALS.TOOLS, { key });
    };

    return (
        <Menu direction="down" toggle={t('header.tools')}>
            <Menu toggle={t('header.aso')}>
                <DropdownItem onClick={() => handleToolClick(TOOL_TYPE.APP_ANNIE)}>
                    {t('header.app_annie')}
                </DropdownItem>
                <DropdownItem onClick={() => handleToolClick(TOOL_TYPE.GOOGLE_TRENDS)}>
                    {t('header.google_trends')}
                </DropdownItem>
                <DropdownItem onClick={() => handleToolClick(TOOL_TYPE.IPAGE)}>
                    {t('header.ipage')}
                </DropdownItem>
                <DropdownItem onClick={() => handleToolClick(TOOL_TYPE.SENSOR_TOWER)}>
                    {t('header.sensor_tower')}
                </DropdownItem>
            </Menu>
            <Menu toggle={t('header.file_hosting')}>
                <DropdownItem onClick={() => handleToolClick(TOOL_TYPE.KUSTODIO)}>
                    {t('header.kustodio')}
                </DropdownItem>
                <DropdownItem onClick={() => handleToolClick(TOOL_TYPE.IPAGE)}>
                    {t('header.ipage')}
                </DropdownItem>
            </Menu>
            <Menu toggle={t('header.media')}>
                <DropdownItem onClick={() => handleToolClick(TOOL_TYPE.DOYO_STORIES)}>
                    {t('header.doyo_stories')}
                </DropdownItem>
                <DropdownItem onClick={() => handleToolClick(TOOL_TYPE.LAUNCH_KIT)}>
                    {t('header.launch_kit')}
                </DropdownItem>
                <DropdownItem onClick={() => handleToolClick(TOOL_TYPE.WIDEO)}>
                    {t('header.wideo')}
                </DropdownItem>
            </Menu>
            <Menu toggle={t('header.marketing_and_analytics')}>
                <DropdownItem onClick={() => handleToolClick(TOOL_TYPE.FIREBASE)}>
                    {t('header.firebase')}
                </DropdownItem>
            </Menu>
            <Menu toggle={t('header.push_notifications')}>
                <DropdownItem onClick={() => handleToolClick(TOOL_TYPE.FIREBASE)}>
                    {t('header.firebase')}
                </DropdownItem>
            </Menu>
        </Menu>
    );
};

const HelpMenu = () => {
    const { t } = useTranslation();

    return (
        <Menu direction="down" toggle={t('header.help')}>
            <a
                className="dropdown-item"
                href="https://support.mobincube.com/hc/en-us/requests/new"
                target="_blank"
                rel="noopener noreferrer"
            >
                {t('header.support')}
            </a>
            <a
                className="dropdown-item"
                href="https://support.mobincube.com"
                target="_blank"
                rel="noopener noreferrer"
            >
                {t('header.tutorials')}
            </a>
            <a
                className="dropdown-item"
                href="https://support.mobincube.com/hc/communities/public/questions"
                target="_blank"
                rel="noopener noreferrer"
            >
                {t('header.community')}
            </a>
        </Menu>
    );
};

const Breadcrumbs = () => {
    const { application = {}, updateApplication } = useApplications();
    const { screen = {}, updateScreen } = useScreens();

    const handleFocus = (event) => {
        const { currentTarget } = event;
        const { innerText } = currentTarget;
        event.currentTarget._innerText = innerText;
    };

    const handleKeyDown = (event) => {
        const { currentTarget, key } = event;
        const { dataset, innerText } = currentTarget;
        const { maxlength = 64 } = dataset;
        if (key === 'Enter') {
            currentTarget.blur();
            return;
        }
        if (key.length === 1 && innerText.length === parseInt(maxlength, 10)) {
            event.preventDefault();
            return false;
        }
        return true;
    };

    const handleApplicationNameBlur = (event) => {
        const { currentTarget } = event;
        const { _innerText, innerText } = currentTarget;
        if (_innerText === innerText) {
            return;
        }
        updateApplication({ Nombre: innerText });
    };

    const handleScreenNameBlur = (event) => {
        const { currentTarget } = event;
        const { _innerText, innerText } = currentTarget;
        if (_innerText === innerText) {
            return;
        }
        updateScreen({ Nombre: innerText });
    };

    return (
        <div className="breadcrumbs d-flex align-items-center">
            {!application.id && <span>Mobincube</span>}
            {application.id && (
                <div
                    id="ApplicationName"
                    contentEditable="true"
                    suppressContentEditableWarning={true}
                    onFocus={handleFocus}
                    onKeyDown={handleKeyDown}
                    onBlur={handleApplicationNameBlur}
                >
                    {application.Nombre}
                </div>
            )}
            {screen.id && (
                <>
                    <Icon type="chevron-right" className="mx-2" />
                    <div
                        contentEditable="true"
                        suppressContentEditableWarning={true}
                        onFocus={handleFocus}
                        onKeyDown={handleKeyDown}
                        onBlur={handleScreenNameBlur}
                    >
                        {screen.Nombre}
                    </div>
                </>
            )}
        </div>
    );
};

const MainMenu = () => {
    return (
        <div className="main-menu">
            <Breadcrumbs />
            <Nav>
                <ApplicationMenu />
                <ScreenMenu />
                <ResourcesMenu />
                <PublicationsMenu />
                <AdvertisementMenu />
                <WindowsMenu />
                <ToolsMenu />
                <HelpMenu />
            </Nav>
        </div>
    );
};

const Shortcuts = () => {
    const { t } = useTranslation();
    const { applications = [], getApplication } = useApplications();
    const { openModal } = useModals();
    const { logout, viewer = {} } = useViewer();
    const generatingDemoApplication = applications.find(({ demos = [] }) =>
        demos.some(({ state }) => state === DEMO_STATE.PROCESSING)
    );
    const generatingVersionApplication = applications.find(({ inProcess }) => inProcess);
    const generatingGoogleBundleApplication = applications.find(
        ({ processState_googleplay: gpState }) => gpState === PUBLISH_STATE.PROCESSING
    );
    const generatingIosApplication = applications.find(
        ({ processState_ios: iosState }) => iosState === PUBLISH_STATE.PROCESSING
    );
    const { demos = [], Nombre: demoAppName } = generatingDemoApplication || {};
    const { Nombre: versionAppName } = generatingVersionApplication || {};
    const { Nombre: gBundleAppName } = generatingGoogleBundleApplication || {};
    const { Nombre: iosAppName } = generatingIosApplication || {};
    const generatingDemo = demos.find(({ state }) => state === DEMO_STATE.PROCESSING);
    const { Email: email = '', subscriptions = [] } = viewer;
    const activeSubscription = subscriptions.find(
        ({ idState }) => idState === SUBSCRIPTION_STATE.ACTIVE
    );
    const { idSubscription } = activeSubscription || {};

    return (
        <div className="shortcuts">
            {generatingDemo && (
                <Tooltip title={parse(t('shortcuts.generating_demo', { name: demoAppName }))}>
                    <Button
                        className="position-relative"
                        onClick={() =>
                            openModal(MODALS.PUBLISH, {
                                key: generatingDemo.idPlatform === 1 ? 'androidDemo' : 'iosDemo',
                            })
                        }
                    >
                        <CirclePreloader />
                        <Icon
                            type={
                                generatingDemo.idPlatform === 1
                                    ? 'cellphone-android'
                                    : 'cellphone-iphone'
                            }
                        />
                        <span>DEMO</span>
                    </Button>
                </Tooltip>
            )}
            {!!generatingVersionApplication && (
                <Tooltip title={parse(t('shortcuts.generating_version', { name: versionAppName }))}>
                    <Button
                        className="position-relative"
                        onClick={() => {
                            getApplication(generatingVersionApplication).then(() =>
                                openModal(MODALS.PUBLISH, { key: 'version' })
                            );
                        }}
                    >
                        <CirclePreloader />
                        <Icon type="rocket" />
                        <span>{t('publish.processing')}</span>
                    </Button>
                </Tooltip>
            )}
            {!!generatingGoogleBundleApplication && (
                <Tooltip
                    title={parse(
                        t('shortcuts.generating_google_play_bundle', { name: gBundleAppName })
                    )}
                >
                    <Button
                        className="position-relative"
                        onClick={() => {
                            getApplication(generatingGoogleBundleApplication).then(() =>
                                openModal(MODALS.PUBLISH, { key: 'googlePlay' })
                            );
                        }}
                    >
                        <CirclePreloader />
                        <Icon type="google-play" />
                        <span>{t('publish.processing')}</span>
                    </Button>
                </Tooltip>
            )}
            {!!generatingIosApplication && (
                <Tooltip title={parse(t('shortcuts.generating_ipa', { name: iosAppName }))}>
                    <Button
                        className="position-relative"
                        onClick={() => {
                            getApplication(generatingIosApplication).then(() =>
                                openModal(MODALS.PUBLISH, { key: 'appStore' })
                            );
                        }}
                    >
                        <CirclePreloader />
                        <Icon type="apple" />
                        <span>{t('publish.processing')}</span>
                    </Button>
                </Tooltip>
            )}
            <Tooltip title={t('shortcuts.my_plan')}>
                <Button onClick={() => openModal(MODALS.MY_ACCOUNT, { key: 'subscription' })}>
                    <Icon
                        type={
                            !idSubscription || idSubscription === 1
                                ? 'arrow-up-bold-hexagon-outline'
                                : 'professional-hexagon'
                        }
                    />
                    <span>{PLAN_NAME[idSubscription] || 'FREE'}</span>
                </Button>
            </Tooltip>
            <Menu
                className="account"
                direction="down"
                right
                toggle={
                    <Tooltip title={t('my_account.title')}>
                        <Gravatar
                            email={email || `${Date.now()}`}
                            size={36}
                            default="monsterid"
                            className="avatar"
                        />
                    </Tooltip>
                }
            >
                <DropdownItem header>{t('my_account.title')}</DropdownItem>
                <DropdownItem onClick={() => openModal(MODALS.MY_ACCOUNT, { key: 'summary' })}>
                    {t('my_account.summary')}
                </DropdownItem>
                <DropdownItem onClick={() => openModal(MODALS.MY_ACCOUNT, { key: 'subscription' })}>
                    {t('my_account.subscription')}
                </DropdownItem>
                <DropdownItem onClick={() => openModal(MODALS.MY_ACCOUNT, { key: 'billing' })}>
                    {t('my_account.billing')}
                </DropdownItem>
                <DropdownItem
                    onClick={() => openModal(MODALS.MY_ACCOUNT, { key: 'payment_methods' })}
                >
                    {t('my_account.payment_methods')}
                </DropdownItem>
                <DropdownItem
                    onClick={() => openModal(MODALS.MY_ACCOUNT, { key: 'payment_receipts' })}
                >
                    {t('my_account.payment_receipts')}
                </DropdownItem>
                <DropdownItem divider />
                <Menu direction="left" right toggle={t('shortcuts.language')}>
                    <DropdownItem>{t('shortcuts.english')}</DropdownItem>
                    <DropdownItem>{t('shortcuts.spanish')}</DropdownItem>
                </Menu>
                <DropdownItem divider />
                <DropdownItem onClick={() => logout()}>{t('shortcuts.log_out')}</DropdownItem>
            </Menu>
        </div>
    );
};

export const MainHeader = () => {
    const { t } = useTranslation();
    const { application = {}, getApplicationIcon, setApplicationIcon } = useApplications();

    useEffect(() => {
        if (application.id && !application.icon) {
            getApplicationIcon();
        }
    }, []);

    const handleIconClick = (event) => {
        const { currentTarget } = event;
        const { nextElementSibling } = currentTarget;
        nextElementSibling.click();
    };

    const handleIconChange = (event) => {
        const { currentTarget } = event;
        const { files } = currentTarget;
        const file = files[0];
        if (!file) {
            currentTarget.value = '';
            return;
        }
        const img = new Image();
        img.src = window.URL.createObjectURL(file);
        img.onload = () => {
            const { height, width } = img;
            img.remove();
            let error = '';
            if (height !== width) {
                error = `<li>${t('edit_icon.squared')}</li>`;
            }
            if (width < 512) {
                error += `<li>${t('edit_icon.bigger_512')}</li>`;
            }
            if (error) {
                currentTarget.value = '';
                message.error(parse(`<ul>${error}</ul>`));
                return;
            }

            const reader = new FileReader();
            reader.onload = (readerEv) => {
                let base64Image = readerEv.target.result;
                base64Image = base64Image.replace(/data:image\/[^;]*;base64,/, '');
                setApplicationIcon(base64Image);
            };
            reader.readAsDataURL(file);
        };
    };

    return (
        <header className="main-header gradient-half-secondary shadow-sm">
            <NavbarBrand>
                {(application.id && (
                    <>
                        <img
                            className="icon"
                            alt={`${application.name} icon`}
                            src={application.icon || defaultIcon}
                        />
                        <Button color="transparent" onClick={handleIconClick}>
                            <Icon type="pencil" />
                        </Button>
                        <input
                            id="ApplicationIcon"
                            className="d-none"
                            type="file"
                            onChange={handleIconChange}
                            accept="image/*"
                        />
                    </>
                )) || <img alt="Mobincube icon" src={logoMinWhite} />}
            </NavbarBrand>
            <MainMenu />
            <Shortcuts />
        </header>
    );
};

export default MainHeader;
