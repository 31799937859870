import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Empty, Popconfirm } from 'antd';
import {
    Badge,
    Button,
    Card,
    CardBody,
    CardDeck,
    CardTitle,
    DropdownItem,
    FormGroup,
    Input,
    Label,
    ModalBody,
    ModalFooter,
} from 'reactstrap';
import { MODALS } from '../_config';
import { Element, Icon, Modal, Search } from '../components';
import { ELEMENT_TITLE, ELEMENT_TYPE } from '../context';
import { useElements, useModals } from '../hooks';

export const Bars = ({ isOpen: isOpenProp }) => {
    const { t } = useTranslation();
    const [isOpen, setIsOpen] = useState(isOpenProp);
    const [isBarNameModalOpened, openBarNameModal] = useState(false);
    const {
        checkElements,
        deleteElements,
        elements = [],
        uncheckElements = () => {},
        unselectElement = () => {},
        updateElement,
    } = useElements();
    const [query, setQuery] = useState('');
    const { modal, setModal, target = {} } = useModals();
    const filteredBars = elements
        .filter(({ IDElementType: type }) => type === ELEMENT_TYPE.BAR)
        .filter(({ concrete: { title } }) => title.includes(query));
    const checkedBars = filteredBars.filter(({ checked }) => checked);
    const { concrete = {} } = checkedBars[0] || {};
    const { title = '' } = concrete;

    const handleSearch = (event) => {
        setQuery(event.currentTarget.value);
    };

    const getBarName = () =>
        new Promise((resolve, reject) => {
            openBarNameModal(true);
            setTimeout(() => {
                const input = document.getElementById('BarName');
                document.getElementById('BarNameAccept').onclick = () => {
                    if (!input.reportValidity()) {
                        return;
                    }
                    openBarNameModal(false);
                    const { value: title } = input;
                    resolve(title);
                };
                document.querySelector('.modal .bar-name-modal .close').onclick = () => {
                    reject();
                };
            }, 500);
        });

    const handleSetBarNameClick = async (event) => {
        let title = '';
        try {
            title = await getBarName();
        } catch (e) {
            return;
        }

        updateElement({ concrete: { title } }, checkedBars[0]);
    };

    useEffect(() => {
        setIsOpen(modal === MODALS.BARS);
    }, [modal]);

    return (
        <>
            <Modal
                className="bars"
                isOpen={isOpen}
                title={
                    <>
                        <span>{t('elements.bars')}</span>
                        {!!checkedBars.length && (
                            <Badge className="ml-2">{checkedBars.length}</Badge>
                        )}
                    </>
                }
                actions={
                    <>
                        <Search icon={false} onBlur={handleSearch} visible={true} />
                        <DropdownItem
                            disabled={checkedBars.length !== 1}
                            onClick={handleSetBarNameClick}
                        >
                            <Icon type="pencil" />
                            <span>{t('elements.set_bar_name')}</span>
                        </DropdownItem>
                        <Popconfirm
                            title={t('elements.delete_confirm', {
                                count: checkedBars.length,
                                type: ELEMENT_TITLE[(checkedBars[0] || {}).IDElementType],
                            })}
                            onConfirm={() => deleteElements(checkedBars)}
                            onCancel={() => {}}
                            okText={t('common.yes')}
                            cancelText={t('common.no')}
                        >
                            <Button disabled={!checkedBars.length}>
                                <Icon type="delete" />
                                <span>{t('resources.delete_resource')}</span>
                            </Button>
                        </Popconfirm>
                    </>
                }
                onOpened={() => {
                    uncheckElements();
                }}
                onClosed={() => {
                    !target.id && unselectElement();
                    setModal(null);
                }}
            >
                <ModalBody>
                    {!filteredBars.length && <Empty />}
                    <CardDeck className="flex-wrap">
                        {filteredBars
                            .sort((b1, b2) => (b1.concrete.title < b2.concrete.title ? -1 : 1))
                            .map((bar) => (
                                <Card
                                    key={`BarCard-${bar.id}`}
                                    className={bar.checked ? 'bar--checked' : ''}
                                    onClick={() => checkElements(bar, { checked: !bar.checked })}
                                >
                                    <CardBody>
                                        <Element key={`Bar-${bar.id}`} element={bar} />
                                    </CardBody>
                                    <CardBody>
                                        <CardTitle>{bar.concrete.title}</CardTitle>
                                    </CardBody>
                                </Card>
                            ))}
                    </CardDeck>
                </ModalBody>
            </Modal>
            <Modal
                className="bar-name-modal"
                isOpen={!!isBarNameModalOpened}
                onClosed={() => openBarNameModal(false)}
                title={t('elements.bar_name')}
            >
                <ModalBody>
                    <FormGroup>
                        <Label className="h6 small d-block text-uppercase">
                            {t('common.name')}
                        </Label>
                        <Input id="BarName" defaultValue={title} autoFocus required />
                    </FormGroup>
                </ModalBody>
                <ModalFooter>
                    <Button id="BarNameAccept">{t('common.accept')}</Button>
                </ModalFooter>
            </Modal>
        </>
    );
};
