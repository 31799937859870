import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useApplications, useDragItems, useViewer } from '../hooks';
import { DragItemGroup, Panel, Search } from '../components';

export const ElementsPanel = () => {
    const { t } = useTranslation();
    const [query, setQuery] = useState('');
    const { application = {} } = useApplications();
    const { dragItems = [] } = useDragItems();
    const { getPanelWidth, isVisibleElementsPanel, setIsVisibleElementsPanel } = useViewer();

    useEffect(() => {
        application.id && setIsVisibleElementsPanel(true);
    }, [application.id]);

    const handleSearch = (event) => {
        const { currentTarget } = event;
        const { value } = currentTarget;
        setQuery(value);
    };

    return (
        <Panel
            className={`elements-panel${!isVisibleElementsPanel ? ' d-none' : ''}`}
            disabled={!application.id}
            panel="elements"
            title={t('elements.title')}
            actions={
                <>
                    <Search onChange={handleSearch} />
                </>
            }
            width={getPanelWidth('elements')}
        >
            {dragItems.map((group, i) => (
                <DragItemGroup key={`DragItemGroup${i}`} query={query} {...group} />
            ))}
        </Panel>
    );
};
