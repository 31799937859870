import React, { createContext } from 'react';
import { useImmerReducer } from 'use-immer';
import { URL } from '../_config';
import { PLANS, PlansReducer } from './reducers';
import { getHeaders } from '.';

export const PLAN_TYPE = {
    FREE: 1,
    S: 20,
    M: 16,
    L: 17,
    XL: 21,
    XXL: 19,
    XXL_UNLIMITED: 22,
};

export const PLAN_NAME = {
    1: 'free',
    2: 'home',
    3: 'standard',
    4: 'pro',
    5: 'reseller',
    6: 'custom',
    15: 'advanced',
    16: 'M',
    17: 'L',
    18: 'pro',
    19: 'XXL',
    20: 'S',
    21: 'XL',
    22: 'XXL Unlimited',
};

export const PlansContext = createContext(PLANS.INITIAL_STATE);

export const PlansProvider = ({ children }) => {
    const [state, dispatch] = useImmerReducer(PlansReducer, PLANS.INITIAL_STATE);

    const getPlans = () => {
        return fetch(`${URL.API}/plans?relations=${JSON.stringify(['intervals', 'services'])}`, {
            headers: getHeaders({ auth: true }),
        })
            .then(async (response) => {
                const data = (await response.json()) || {};
                return response.ok ? data : Promise.reject(data.type);
            })
            .then(({ data: plans }) => {
                dispatch({ type: PLANS.LIST, payload: { plans } });
                return plans;
            })
            .catch((error) => {
                console.error(error);
            });
    };

    if (process.env.NODE_ENV === 'development') {
        console.log('PLANS >>>', state);
    }

    return (
        <PlansContext.Provider
            value={{
                ...state,
                getPlans,
            }}
        >
            {children}
        </PlansContext.Provider>
    );
};

export const PlansConsumer = PlansContext.Consumer;
export default PlansContext;
