import React from 'react';
import { useTranslation } from 'react-i18next';
import { Checkbox } from 'antd';
import { getLanguage } from '../../../_helpers';

export const CheckboxProperty = ({
    checkboxLabel: _checkboxLabel,
    onChange: handleChange,
    value = false,
}) => {
    const { t } = useTranslation();
    const lang = getLanguage();
    const checkboxLabel =
        typeof _checkboxLabel === 'string'
            ? _checkboxLabel
            : (_checkboxLabel && _checkboxLabel[lang]) || t('common.enable');

    const handleCheckboxChange = ({ target }) => {
        const { checked: value } = target;
        handleChange(value);
    };

    return (
        <Checkbox defaultChecked={value} onChange={handleCheckboxChange}>
            {checkboxLabel}
        </Checkbox>
    );
};
