import React from 'react';
import { DRAG_ITEM_TYPE, ELEMENT_TYPE } from '../../../context';
import { useElements } from '../../../hooks';
import { Bar, Body, Image, TextField, Separator, Text, Video } from '.';
import { Background } from '../../../components';

export const ElementWrapper = React.memo(({ children, className, data, element, style }) => {
    const { element: currentElement = {}, selectElement } = useElements();
    const {
        id,
        hAlignParent: hAlign,
        IDElementType: type,
        IDParentElement: idParent,
        idShading,
        vAlignChild: vAlign,
        width,
    } = element;

    style = {
        maxWidth: `${width}%`,
        minWidth: `${width}%`,
        width: `${width}%`,
        ...style,
    };

    const handleClick = (event) => {
        event.stopPropagation();
        if ((type === ELEMENT_TYPE.BODY && !idParent) || id === currentElement.id) {
            return;
        }
        selectElement({ id });
    };

    return (
        <div
            className={`element-wrapper${className ? ` ${className}` : ''}${
                id === currentElement.id ? ' element--current' : ''
            }`}
            data-type={DRAG_ITEM_TYPE.ELEMENT}
            data-element-id={id}
            data-element-type={type}
            data-halign={hAlign}
            data-valign={vAlign}
            {...data}
            style={style}
        >
            <div className="element-handler" onClick={handleClick} />
            {children}
            {idShading && <Background id={idShading} />}
        </div>
    );
});

export const Element = React.memo((props) => {
    const { element = {} } = props;
    const { IDElementType: type } = element;

    switch (type) {
        case ELEMENT_TYPE.BAR:
            return <Bar {...props} />;
        case ELEMENT_TYPE.BODY:
            return <Body {...props} />;
        case ELEMENT_TYPE.IMAGE:
            return <Image {...props} />;
        case ELEMENT_TYPE.TEXTFIELD:
            return <TextField {...props} />;
        case ELEMENT_TYPE.SEPARATOR:
            return <Separator {...props} />;
        case ELEMENT_TYPE.TEXT:
            return <Text {...props} />;
        case ELEMENT_TYPE.VIDEO:
            return <Video {...props} />;
        default:
            return <></>;
    }
});
