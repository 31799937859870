import React, { createContext, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useImmerReducer } from 'use-immer';
import * as Sentry from '@sentry/react';
import ls from 'local-storage';
import dayjs from 'dayjs';
import { message } from 'antd';
import { URL } from '../_config';
import { getUnique } from '../_helpers';
import { usePlans } from '../hooks';
import { ViewerReducer, VIEWER } from './reducers';
import { getHeaders, PLAN_NAME, PLAN_TYPE } from '.';

export const SUBSCRIPTION_STATE = {
    PENDING: 1,
    ACTIVE: 2,
    FINISHED: 3,
    UNPAID: 4,
    CANCELED: 5,
    CANCELED_TRIAL: 6,
};

export const ViewerContext = createContext(VIEWER.INITIAL_STATE);

export const logout = () => {
    console.log('*** LOGOUT ***');
    ls.remove('user');
    window.location.href = '/';
};

export const ViewerProvider = ({ children }) => {
    const { t } = useTranslation();
    let { getPlans, plans = [] } = usePlans();
    const preferences = ls('preferences') || {};
    const { panels = {} } = preferences;
    const { contentTree = {}, elements = {}, errors = {}, properties = {}, screens = {} } = panels;
    const [loading, setLoading] = useState(false);
    const [isVisibleScreensPanel, _setIsVisibleScreensPanel] = useState(screens.visible);
    const [isVisibleElementsPanel, _setIsVisibleElementsPanel] = useState(elements.visible);
    const [isVisibleErrorsPanel, _setIsVisibleErrorsPanel] = useState(errors.visible);
    const [isVisibleContentTreePanel, _setIsVisibleContentTreePanel] = useState(
        contentTree.visible
    );
    const [isVisiblePropertiesPanel, _setIsVisiblePropertiesPanel] = useState(properties.visible);
    const [state, dispatch] = useImmerReducer(ViewerReducer, VIEWER.INITIAL_STATE);
    const { recentApplications, viewer = {} } = state;
    const { subscriptions = [] } = viewer;

    const addInstalledItem = (item) => {
        const user = ls('user') || {};
        const { items = [] } = user;
        ls('user', {
            ...user,
            items: getUnique([...items, item]),
        });
    };

    const addToRecentApplications = ({ id, Nombre }) => {
        const applications = getUnique([{ id, Nombre }, ...recentApplications]);
        const user = ls('user') || {};
        ls('user', {
            ...user,
            recentApplications: applications,
        });
        dispatch({
            type: VIEWER.RECENT_APPLICATIONS,
            payload: { recentApplications: applications },
        });
    };

    const createSource = ({ card, ...token } = {}) => {
        if (!card) {
            return Promise.reject();
        }
        setLoading(true);
        const attributes = {
            card_token: token.id,
            user_id: viewer.id,
            stripe_id: card.id,
            brand: card.brand,
            fingerprint: card.fingerprint,
            funding: card.funding,
            exp_month: card.exp_month,
            exp_year: card.exp_year,
            last4: card.last4,
            country: card.country,
            default: !!(viewer.cards || []).length,
        };
        return fetch(`${URL.API}/user/cards`, {
            method: 'POST',
            body: JSON.stringify(attributes),
            headers: getHeaders({ auth: true }),
        })
            .then(async (response) => {
                const data = (await response.json()) || {};
                return response.ok ? data : Promise.reject(data.type);
            })
            .then((source) => {
                setLoading(false);
                dispatch({ type: VIEWER.ADD_SOURCE, payload: { source } });
                return source;
            })
            .catch((error) => {
                setLoading(false);
                message.error(t(`my_account.${error}`));
                if (error === 'unauthorized') {
                    logout();
                }
            });
    };

    const deleteSource = (source) => {
        setLoading(true);
        return fetch(`${URL.API}/cards/${source.id}`, {
            method: 'DELETE',
            headers: getHeaders({ auth: true }),
        })
            .then(async (response) => {
                const data = await (response.ok ? Promise.resolve(response) : response.json());
                return response.ok ? data : Promise.reject(data.type);
            })
            .then(() => {
                setLoading(false);
                dispatch({ type: VIEWER.DELETE_SOURCE, payload: { source } });
                return source;
            })
            .catch((error) => {
                setLoading(false);
                message.error(t(`my_account.${error}`));
                if (error === 'unauthorized') {
                    logout();
                }
            });
    };

    const disableUser = useCallback(() => {
        setLoading(true);
        return fetch(`${URL.API}/users/${viewer.id}`, {
            method: 'DELETE',
            headers: getHeaders({ auth: true }),
        })
            .then(async (response) => {
                const data = (await response.json()) || {};
                return response.ok ? data : Promise.reject(data.type);
            })
            .then(() => {
                setLoading(false);
                return true;
            })
            .catch((error) => {
                setLoading(false);
                console.error(error);
                if (error === 'unauthorized') {
                    logout();
                }
            });
    }, [viewer.id]);

    const getCouponSufix = ({ period = '' } = {}) => {
        const lastSubscription = subscriptions
            .filter(({ finishDate }) => finishDate)
            .sort((s1, s2) => (s1.creationDate > s2.creationDate ? -1 : 1))[0];
        const lastSubscriptionFinishDate = lastSubscription && dayjs(lastSubscription.finishDate);
        const moreThan3MonthsWithNoSubscription =
            lastSubscription && lastSubscriptionFinishDate.add(3, 'month').isBefore(dayjs());
        const isNew = !lastSubscription || moreThan3MonthsWithNoSubscription;

        return `_${isNew ? 'NEW' : 'UPGRD'}_${(period || '')[0].toUpperCase()}`;
    };

    const getPanelWidth = (panel) => {
        const { width = 200 } = panels[panel] || {};
        return width;
    };

    const getSources = useCallback(() => {
        setLoading(true);
        return fetch(`${URL.API}/user/cards`, {
            headers: getHeaders({ auth: true }),
        })
            .then(async (response) => {
                const data = (await response.json()) || {};
                return response.ok ? data : Promise.reject(data.type);
            })
            .then((sources) => {
                setLoading(false);
                dispatch({
                    type: VIEWER.SOURCES,
                    payload: {
                        sources,
                    },
                });
                return sources;
            })
            .catch((error) => {
                setLoading(false);
                console.error(error);
                if (error === 'unauthorized') {
                    logout();
                }
            });
    }, [viewer.id]);

    const getMau = useCallback(() => {
        fetch(`${URL.MAU_SERVER}/v1/users/${viewer.id}`, {
            headers: getHeaders({ auth: true }),
        })
            .then(async (response) => {
                const data = (await response.json()) || {};
                return response.ok ? data : Promise.reject(data.type);
            })
            .then((data) => {
                return data;
            })
            .catch((error) => {
                setLoading(false);
                console.error(error);
                if (error === 'unauthorized') {
                    logout();
                }
            });
    }, [viewer.id]);

    const getSubscriptionPreview = ({ coupon, plan, interval }) => {
        setLoading(true);
        let couponParam = '';
        if (coupon) {
            couponParam = `?coupon=${coupon}${getCouponSufix(interval)}`;
        }
        dispatch({ type: VIEWER.SUBSCRIPTION_PREVIEW, payload: { preview: {} } });
        return fetch(
            `${URL.API}/user/subscriptions${plan ? `/${plan.id}` : ''}/preview${couponParam}`,
            {
                headers: getHeaders({ auth: true }),
            }
        )
            .then(async (response) => {
                const { data = {} } = await response.json();
                return response.ok ? data : Promise.reject(data.type);
            })
            .then((preview) => {
                setLoading(false);
                dispatch({ type: VIEWER.SUBSCRIPTION_PREVIEW, payload: { preview } });
                return preview;
            })
            .catch((error) => {
                setLoading(false);
                console.error(error);
                if (error === 'unauthorized') {
                    logout();
                }
            });
    };

    const getSubscriptions = useCallback(() => {
        setLoading(true);
        return fetch(
            `${URL.API}/user/subscriptions?relations=${JSON.stringify(['interval.plan.services'])}`,
            {
                headers: getHeaders({ auth: true }),
            }
        )
            .then(async (response) => {
                const { data = {} } = await response.json();
                return response.ok ? data : Promise.reject(data.type);
            })
            .then((subscriptions) => {
                setLoading(false);
                dispatch({ type: VIEWER.SUBSCRIPTIONS, payload: { subscriptions } });
                return subscriptions;
            })
            .catch((error) => {
                setLoading(false);
                console.error(error);
                if (error === 'unauthorized') {
                    logout();
                }
            });
    }, [viewer.id]);

    const isAllowed = useCallback(
        (idService) =>
            new Promise(async (resolve) => {
                const { subscriptions } = viewer;
                const { idSubscription: idActivePlan } =
                    subscriptions.find(({ idState }) => idState === SUBSCRIPTION_STATE.ACTIVE) ||
                    {};

                if ([PLAN_TYPE.XXL, PLAN_TYPE.XXL_UNLIMITED].includes(idActivePlan)) {
                    resolve(true);
                    return true;
                }

                if (!plans.length) {
                    plans = await getPlans();
                }

                const { services = [] } = plans.find(({ id }) => id === idActivePlan) || {};
                const _isAllowed = services.some(({ id }) => id === idService);
                resolve(_isAllowed);
                return _isAllowed;
            }),
        [viewer]
    );

    const removeFromRecentApplications = ({ id }) => {
        const applications = recentApplications.filter(({ id: idApp }) => idApp !== id);
        const user = ls('user') || {};
        ls('user', {
            ...user,
            recentApplications: applications,
        });
        dispatch({
            type: VIEWER.RECENT_APPLICATIONS,
            payload: { recentApplications: applications },
        });
    };

    const removeInstalledItem = (item) => {
        const user = ls('user') || {};
        const { items = [] } = user;
        ls('user', {
            ...user,
            items: items.filter(({ id }) => id !== item.id),
        });
    };

    const setIsVisibleScreensPanel = (visible) => {
        _setIsVisibleScreensPanel(visible);
        ls('preferences', {
            ...preferences,
            panels: {
                ...panels,
                screens: {
                    ...panels.screens,
                    visible,
                },
            },
        });
    };

    const setIsVisibleElementsPanel = (visible) => {
        _setIsVisibleElementsPanel(visible);
        ls('preferences', {
            ...preferences,
            panels: {
                ...panels,
                elements: {
                    ...panels.elements,
                    visible,
                },
            },
        });
    };

    const setIsVisibleErrorsPanel = (visible) => {
        _setIsVisibleErrorsPanel(visible);
        ls('preferences', {
            ...preferences,
            panels: {
                ...panels,
                errors: {
                    ...panels.errors,
                    visible,
                },
            },
        });
    };

    const setIsVisibleContentTreePanel = (visible) => {
        _setIsVisibleContentTreePanel(visible);
        ls('preferences', {
            ...preferences,
            panels: {
                ...panels,
                contentTree: {
                    ...panels.contentTree,
                    visible,
                },
            },
        });
    };
    const setIsVisiblePropertiesPanel = (visible) => {
        _setIsVisiblePropertiesPanel(visible);
        ls('preferences', {
            ...preferences,
            panels: {
                ...panels,
                properties: {
                    ...panels.properties,
                    visible,
                },
            },
        });
    };

    const setPanelWidth = (panel, width) => {
        ls('preferences', {
            ...preferences,
            panels: {
                ...panels,
                [panel]: {
                    ...(panels[panel] || {}),
                    width,
                },
            },
        });
    };

    const setPlan = ({ coupon, interval }) => {
        setLoading(true);
        const { idSubscription, idSubscriptionPeriod } = interval;
        coupon = coupon ? `${coupon}${getCouponSufix(interval)}` : '';
        return fetch(`${URL.API}/user/subscriptions/${idSubscription}`, {
            method: 'PATCH',
            body: JSON.stringify({ coupon, idSubscriptionPeriod }),
            headers: getHeaders({ auth: true }),
        })
            .then(async (response) => {
                const data = (await response.json()) || {};
                return response.ok ? data : Promise.reject(data.type);
            })
            .then(() => {
                setLoading(false);
                if (idSubscription === PLAN_TYPE.FREE) {
                    const activeSubscription = subscriptions.find(
                        ({ state }) => state === SUBSCRIPTION_STATE.ACTIVE
                    );
                    message.success(
                        `${t(`subscription.unsubscribe`, {
                            plan_name: PLAN_NAME[activeSubscription.id],
                        })}${activeSubscription.services.map(({ id }) =>
                            t(`services.service_${id}`)
                        )}`
                    );
                } else {
                    message.success(
                        t(`subscription.success`, { plan_name: PLAN_NAME[idSubscription] })
                    );
                }
                getSubscriptions();
                return true;
            })
            .catch((error) => {
                setLoading(false);
                message.error(t(`my_account.${error}`));
                if (error === 'unauthorized') {
                    logout();
                }
                return false;
            });
    };

    const setSourceAsDefault = ({ id }) => {
        setLoading(true);
        return fetch(`${URL.API}/cards/${id}`, {
            method: 'PATCH',
            body: JSON.stringify({ default: true }),
            headers: getHeaders({ auth: true }),
        })
            .then(async (response) => {
                const data = (await response.json()) || {};
                return response.ok ? data : Promise.reject(data.type);
            })
            .then((source) => {
                setLoading(false);
                dispatch({ type: VIEWER.SET_DEFAULT_SOURCE, payload: { source } });
                return source;
            })
            .catch((error) => {
                setLoading(false);
                message.error(t(`my_account.${error}`));
                if (error === 'unauthorized') {
                    logout();
                }
            });
    };

    const showViewer = ({ id }) => {
        setLoading(true);
        return fetch(`${URL.API}/${id && !isNaN(id) ? `users/${id}` : 'user'}`, {
            headers: getHeaders({ auth: true }),
        })
            .then(async (response) => {
                const data = (await response.json()) || {};
                return response.ok ? data : Promise.reject(data.type);
            })
            .then(({ data: viewer }) => {
                setLoading(false);
                Sentry.setUser(viewer);
                dispatch({ type: VIEWER.GET, payload: { viewer } });
                return viewer;
            })
            .catch((error) => {
                setLoading(false);
                console.error(error);
                if (error === 'unauthorized') {
                    logout();
                }
            });
    };

    const updateUser = useCallback(
        (attributes = {}, user = viewer) => {
            setLoading(true);
            return fetch(`${URL.API}/users/${viewer.id}`, {
                method: 'PATCH',
                body: JSON.stringify(attributes),
                headers: getHeaders({ auth: true }),
            })
                .then(async (response) => {
                    const data = (await response.json()) || {};
                    return response.ok ? data : Promise.reject(data.type);
                })
                .then(() => {
                    setLoading(false);
                    dispatch({
                        type: VIEWER.UPDATE,
                        payload: { viewer: user },
                    });
                    return user;
                })
                .catch((error) => {
                    setLoading(false);
                    console.error(error);
                    if (error === 'unauthorized') {
                        logout();
                    }
                });
        },
        [viewer.id]
    );

    const updateUserPassword = () => {};

    useEffect(() => {
        getSubscriptions();
    }, [viewer.id]);

    if (process.env.NODE_ENV === 'development') {
        console.log('VIEWER >>>', state);
    }

    return (
        <ViewerContext.Provider
            value={{
                ...state,
                addInstalledItem,
                addToRecentApplications,
                createSource,
                deleteSource,
                disableUser,
                getSources,
                getMau,
                getPanelWidth,
                getSubscriptionPreview,
                getSubscriptions,
                isAllowed,
                isVisibleScreensPanel,
                isVisibleElementsPanel,
                isVisibleErrorsPanel,
                isVisibleContentTreePanel,
                isVisiblePropertiesPanel,
                loading,
                logout,
                removeFromRecentApplications,
                removeInstalledItem,
                setIsVisibleScreensPanel,
                setIsVisibleElementsPanel,
                setIsVisibleErrorsPanel,
                setIsVisibleContentTreePanel,
                setIsVisiblePropertiesPanel,
                setPanelWidth,
                setPlan,
                setSourceAsDefault,
                showViewer,
                updateUser,
                updateUserPassword,
            }}
        >
            {children}
        </ViewerContext.Provider>
    );
};

export const ViewerConsumer = ViewerContext.Consumer;
export default ViewerContext;
