// @flow
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { message } from 'antd';
import { Button, Input } from 'reactstrap';
import { getUniqueName } from '../_helpers';
import { ELEMENT_TYPE, RESOURCE_TYPE, SCREEN_TYPE, SCREEN_TYPE_NAME } from '../context';
import { useApplications, useElements, useModals, useResources, useScreens } from '../hooks';
import { CustomModal, FilePreview } from '.';

const DropWrapper = ({ className = '', mainFolder = '' }) => {
    const dragging = useRef([]);
    const { t } = useTranslation();
    const { application = {} } = useApplications();
    const {
        createElement,
        element = {},
        elements = [],
        selectElement,
        updateElement,
    } = useElements();
    const { concrete: concreteElement = {}, id: elementId, IDElementType: elementType } = element;
    const { modal } = useModals();
    const { createConcreteScreen, screen = {} } = useScreens();
    const { id: screenId, IDTipoSeccion: screenType } = screen;
    const {
        createResource = () => {},
        resource = {},
        resources = [],
        updateResource,
    } = useResources();
    const [askingToOverwrite, setAskingToOverwrite] = useState(false);
    const dropWrapper = useRef();
    const folder = resource.type === RESOURCE_TYPE.FOLDER ? resource : {};

    const filesName = resources.map(({ name }) => name);

    const handleDragEnter = (event) => {
        const { items = [] } = event.dataTransfer;
        if (!items.length || Object.values(items).some(({ kind }) => kind !== 'file')) {
            return;
        }
        event.preventDefault();
        const { effectAllowed } = event.dataTransfer;
        const isShown = effectAllowed === 'all';
        const draggingFiles = isShown ? Object.values(items) : [];
        dragging.current = draggingFiles;
        dropWrapper.current.classList.add('show');
        message.open({
            content: (
                <>
                    {' '}
                    <span className="mr-1">
                        {t('dropwrapper.directory', {
                            context: !application ? 'unknown' : '',
                            count: dragging.current.length,
                        })}
                    </span>
                    <strong>
                        {`${application.Nombre}${mainFolder ? `/${mainFolder}` : ''}${
                            folder.name ? `/${folder.name}` : ''
                        }`}
                    </strong>
                </>
            ),
            key: 'DropMessage',
        });
    };

    const handleDragLeave = (event) => {
        event.preventDefault();
        dragging.current = [];
        dropWrapper.current.classList.remove('show');
        message.destroy('DropMessage');
    };

    const askToOverwrite = (filesToOverwrite) =>
        new Promise((resolve, reject) => {
            setAskingToOverwrite(filesToOverwrite);
            setTimeout(() => {
                const acceptButton = document.getElementById('OverwriteAccept');
                acceptButton &&
                    (acceptButton.onclick = () => {
                        setAskingToOverwrite(false);
                        resolve();
                    });
                const cancelButton = document.querySelector('.modal .overwrite-modal .close');
                cancelButton &&
                    (cancelButton.onclick = () => {
                        setAskingToOverwrite(false);
                        reject();
                    });
            }, 500);
        });

    const uploadFiles = useCallback(
        async (files) => {
            const filesToUpload = Object.values(files).map((file) => {
                let { name } = file;
                return { file, name };
            });
            const newFilesName = filesToUpload.map(({ name }) => name);
            const filesToOverwrite = {
                from: resources.filter(({ name }) => newFilesName.includes(name)),
                to: filesToUpload.filter(({ name }) => filesName.includes(name)),
            };
            if (filesToOverwrite.from.length) {
                try {
                    await askToOverwrite(filesToOverwrite);
                } catch (e) {
                    return;
                }
            }
            await Promise.all(
                filesToUpload.map(async ({ file, name }, i) => {
                    const { overwrite } = file;
                    let { type } = file;
                    type = type === 'application/zip' ? RESOURCE_TYPE.ZIP : type.split('/')[0];
                    type = Object.values(RESOURCE_TYPE).includes(type) ? type : RESOURCE_TYPE.BASE;

                    const reader = new FileReader();
                    reader.onload = async (e) => {
                        const fileContent = e.target.result.replace(/[^;]*;base64,/, '');
                        if (overwrite === true) {
                            const resource = filesToOverwrite.from.find(
                                ({ name: n }) => n === name
                            );
                            updateResource(
                                {
                                    content: fileContent,
                                },
                                resource
                            );
                        } else {
                            const fileType = name
                                .substring(name.lastIndexOf('.') + 1)
                                .toLowerCase();
                            const resource = await createResource({
                                content: fileContent,
                                fileType,
                                idFolder: folder.id,
                                name: getUniqueName(name, { names: filesName }),
                                online: 0,
                                type,
                            });

                            if (
                                modal ||
                                !screenId ||
                                (screenType !== SCREEN_TYPE.UNDEFINED &&
                                    screenType !== SCREEN_TYPE.INFO)
                            ) {
                                return;
                            }

                            const attributes = {
                                concrete: {},
                            };
                            if (elementId) {
                                if (
                                    elementType === ELEMENT_TYPE.IMAGE &&
                                    !concreteElement.IDImage
                                ) {
                                    attributes.concrete = { IDImage: resource.id };
                                    updateElement(attributes);
                                    return;
                                } else if (
                                    elementType === ELEMENT_TYPE.VIDEO &&
                                    !concreteElement.idVideo
                                ) {
                                    attributes.concrete = { idVideo: resource.id };
                                    updateElement(attributes);
                                    return;
                                }
                            }

                            const { concrete = {} } = screen;
                            let { IDMainElement: IDParentElement } = concrete;

                            if (screenType === SCREEN_TYPE.UNDEFINED) {
                                const {
                                    concrete: { IDMainElement },
                                } = await createConcreteScreen({
                                    typeName: SCREEN_TYPE_NAME.INFO,
                                });
                                IDParentElement = IDMainElement;
                            }

                            if (type !== RESOURCE_TYPE.IMAGE && type !== RESOURCE_TYPE.VIDEO) {
                                return;
                            }

                            if (type === RESOURCE_TYPE.IMAGE) {
                                attributes.concrete = { IDImage: resource.id };
                            } else if (type === RESOURCE_TYPE.VIDEO) {
                                attributes.concrete = { idVideo: resource.id };
                            }
                            const element = await createElement({
                                ...attributes,
                                IDElementType: ELEMENT_TYPE.IMAGE,
                                IDParentElement,
                                IDSection: screenId,
                                Sequence:
                                    elements.filter(
                                        ({ IDParentElement: idParent }) =>
                                            idParent === IDParentElement
                                    ).length + 1,
                                id: `c${Date.now()}`,
                            });

                            selectElement(element);
                            document
                                .querySelector(`[data-element-id="${element.id}"]`)
                                .scrollIntoView({
                                    behavior: 'smooth',
                                    block: 'center',
                                    inline: 'center',
                                });
                        }
                    };
                    reader.readAsDataURL(file);
                })
            );
        },
        [folder, screen, elements]
    );

    const handleChange = (event) => {
        const { currentTarget } = event;
        const { files } = currentTarget;
        uploadFiles(files);
    };

    const handleDrop = (event) => {
        event.preventDefault();
        message.destroy('DropMessage');
        dropWrapper.current.classList.remove('show');
        const { dataTransfer } = event;
        const { files } = dataTransfer;
        uploadFiles(files);
    };

    useEffect(() => {
        document.addEventListener('dragenter', handleDragEnter, false);
        document.addEventListener('dragover', handleDragEnter, false);
        dropWrapper.current.addEventListener('dragleave', handleDragLeave, false);
        dropWrapper.current.addEventListener('drop', handleDrop, false);
        return () => {
            document.removeEventListener('dragenter', handleDragEnter);
            document.removeEventListener('dragover', handleDragEnter);
            dropWrapper.current.removeEventListener('dragleave', handleDragLeave);
            dropWrapper.current.removeEventListener('drop', handleDrop);
        };
    }, [folder, screen, elements]);

    return (
        <>
            <div className={`drop-wrapper${className ? ` ${className}` : ''}`} ref={dropWrapper}>
                <div className="bg" />
                <div className="input">
                    <p className="border-bottom line-height-0 my-3 px-7">
                        <span className="p-1 bg-white">{t('common.or')}</span>
                    </p>
                    <Button
                        className="mt-3"
                        onClick={(event) => event.currentTarget.nextElementSibling.click()}
                    >
                        {t('dropwrapper.select_files')}
                    </Button>
                    <Input
                        multiple
                        className="d-none"
                        onChange={handleChange}
                        tabIndex="-1"
                        type="file"
                    />
                </div>
            </div>
            <CustomModal
                button=""
                buttonParameters={{
                    id: 'AskToOverwriteModal',
                    className: 'd-none',
                }}
                className="overwrite-modal"
                footer={<Button id="OverwriteAccept">{t('common.accept')}</Button>}
                isOpen={!!askingToOverwrite}
                onClosed={() => setAskingToOverwrite(false)}
                title={t('dropwrapper.overwrite_modal_title')}
            >
                {(askingToOverwrite &&
                    askingToOverwrite.from.map((from) => {
                        const { file, name } = askingToOverwrite.to.find(
                            ({ name }) => name === from.name
                        );
                        file.overwrite = true;
                        const to = {
                            name,
                            blob: file,
                            blobUrl: URL.createObjectURL(file),
                        };

                        return (
                            <div
                                className="overwrite-preview"
                                key={`Preview-${from.id}-${Date.now()}`}
                                data-overwrite={true}
                            >
                                <div>
                                    <div className="old-file">
                                        <FilePreview {...from} />
                                        <span>{name}</span>
                                    </div>
                                    <div className="new-file">
                                        <FilePreview {...to} />
                                        <span className="overwrite-name">{to.name}</span>
                                        <span className="keep-both-name">
                                            {getUniqueName(to.name, { names: filesName })}
                                        </span>
                                    </div>
                                </div>
                                <div>
                                    <input
                                        id={`${to.name}KeepBoth`}
                                        type="checkbox"
                                        className="json-node-value-helper"
                                        onChange={(event) => {
                                            const { currentTarget } = event;
                                            const { checked } = currentTarget;
                                            event.currentTarget.parentNode.parentNode.dataset.overwrite = !checked;
                                            file.overwrite = !checked;
                                        }}
                                    />
                                    <label htmlFor={`${to.name}KeepBoth`}>
                                        {t('common.keep_both')}
                                    </label>
                                </div>
                            </div>
                        );
                    })) ||
                    null}
            </CustomModal>
        </>
    );
};

export default DropWrapper;
export { DropWrapper };
