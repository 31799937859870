import React, { useEffect, useState } from 'react';
import { Slider as AntdSlider, InputNumber } from 'antd';

export const Slider = ({
    format = (value) => value,
    input = false,
    max = 100,
    min = 0,
    onChange = () => {},
    step = 1,
    value: propValue = 0,
}) => {
    const [value, setValue] = useState(propValue);

    const handleSlide = (value) => {
        setValue(value);
        onChange(value, { preview: true });
    };

    const handleKeyDown = (event) => {
        const { currentTarget, key } = event;
        if (key === 'Enter') {
            currentTarget.blur();
            return false;
        }
        return true;
    };

    const handleBlur = ({ currentTarget: { innerText: value } }) => {
        const _value = parseInt(value, 10);
        setValue(_value);
        onChange(_value);
    };

    const handleChange = (value) => {
        const _value = parseInt(value, 10);
        setValue(_value);
        onChange(_value);
    };

    useEffect(() => {
        setValue(propValue);
    }, [propValue]);

    return (
        <div className="slider d-flex">
            <AntdSlider
                min={min}
                max={max}
                step={step}
                value={typeof value === 'number' ? value : 0}
                onChange={handleSlide}
                onAfterChange={(value) => onChange(value)}
                tipFormatter={format}
            />
            {input &&
                ((format && (
                    <div
                        contentEditable="true"
                        suppressContentEditableWarning={true}
                        onKeyDown={handleKeyDown}
                        onBlur={handleBlur}
                    >
                        {format(value)}
                    </div>
                )) || (
                    <InputNumber
                        min={min}
                        max={max}
                        value={format(value)}
                        onChange={handleChange}
                    />
                ))}
        </div>
    );
};
