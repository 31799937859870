import React from 'react';
import { ScreenSelector } from '../../../components';

export const ScreenProperty = ({ onChange: handleChange, value }) => {
    const handleScreenChange = (value) => {
        handleChange(value);
    };

    return <ScreenSelector isProperty={true} value={value || ''} onChange={handleScreenChange} />;
};
