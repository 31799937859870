import { getUnique } from '../../_helpers';

export const APPLICATION = {
    INITIAL_STATE: {
        applications: [],
        application: undefined,
    },

    ADD: 'APPLICATION_ADD',
    ADD_BAR: 'APPLICATION_ADD_BAR',
    ADD_SERVICES: 'APPLICATION_ADD_SERVICES',
    CREATE: 'APPLICATION_CREATE',
    DELETE: 'APPLICATION_DELETE',
    ERROR: 'APPLICATION_ERROR',
    GET: 'APPLICATION_GET',
    GET_DEMO: 'APPLICATION_GET_DEMO',
    GET_FIREBASE: 'APPLICATION_GET_FIREBASE',
    GET_SETTINGS: 'APPLICATION_GET_SETTINGS',
    GET_SHADING: 'APPLICATION_GET_SHADING',
    LIST: 'APPLICATION_LIST',
    REMOVE_SERVICES: 'APPLICATION_REMOVE_SERVICES',
    SELECT: 'APPLICATION_SELECT',
    UNSELECT: 'APPLICATION_UNSELECT',
    UPDATE: 'APPLICATION_UPDATE',
};

export const ApplicationsReducer = (draft, action) => {
    const { payload, type } = action;
    switch (type) {
        case APPLICATION.ADD:
        case APPLICATION.CREATE:
            draft.applications = getUnique([...draft.applications, ...payload.applications]);
            break;

        case APPLICATION.ADD_BAR:
            draft.applications = draft.applications.map((application) =>
                application.id === payload.bar.IDGuia
                    ? {
                          ...application,
                          bars: getUnique([payload.bar, ...(application.bars || [])]),
                      }
                    : application
            );
            draft.application = draft.applications.find(({ current }) => current);
            break;

        case APPLICATION.ADD_SERVICES:
            draft.applications = draft.applications.map((application) => ({
                ...application,
                services: getUnique([...(application.services || []), ...payload.services]),
            }));
            draft.application = draft.applications.find(({ current }) => current);
            break;

        case APPLICATION.DELETE:
            draft.applications = draft.applications.filter(({ id }) => id !== payload.id);
            draft.application = draft.applications.find(({ current }) => current);
            break;

        case APPLICATION.ERROR:
            draft.error = payload.error;
            break;

        case APPLICATION.GET:
            draft.applications = getUnique([
                ...draft.applications.map((application) =>
                    application.id === payload.application.id
                        ? {
                              ...application,
                              ...payload.application,
                              current: payload.select || application.current,
                          }
                        : {
                              ...application,
                              current: payload.select ? false : application.current,
                          }
                ),
                { ...payload.application, current: payload.select },
            ]);
            draft.application = draft.applications.find(({ current }) => current);
            break;

        case APPLICATION.GET_DEMO:
            draft.applications = draft.applications.map((application) =>
                application.id === payload.demo.IDGuia
                    ? { ...application, demos: getUnique([payload.demo, ...application.demos]) }
                    : application
            );
            draft.application = draft.applications.find(({ current }) => current);
            break;

        case APPLICATION.GET_FIREBASE:
            draft.applications = draft.applications.map((application) =>
                application.id === payload.firebase.IDGuia
                    ? { ...application, firebase: payload.firebase }
                    : application
            );
            draft.application = draft.applications.find(({ current }) => current);
            break;

        case APPLICATION.GET_SETTINGS:
            draft.applications = draft.applications.map((application) =>
                application.id === payload.application.id
                    ? {
                          ...application,
                          settings: { ...(application.settings || {}), ...payload.settings },
                      }
                    : application
            );
            draft.application = draft.applications.find(({ current }) => current);
            break;

        case APPLICATION.GET_SHADING:
            draft.applications = draft.applications.map((application) =>
                application.id === payload.application.id
                    ? {
                          ...application,
                          shadings: [...(application.shadings || []), payload.shading],
                      }
                    : application
            );
            draft.application = draft.applications.find(({ current }) => current);
            break;

        case APPLICATION.LIST:
            draft.applications = getUnique(
                [...(payload.reset ? [] : draft.applications), ...(payload.applications || [])].map(
                    (application) => {
                        const _application =
                            draft.applications.find(({ id }) => id === application.id) || {};
                        return {
                            ..._application,
                            ...application,
                        };
                    }
                )
            );
            draft.application = draft.applications.find(({ current }) => current);
            break;

        case APPLICATION.REMOVE_SERVICES:
            draft.applications = draft.applications.map((application) =>
                application.id !== payload.application.id
                    ? application
                    : {
                          ...application,
                          services: (application.services || []).filter(
                              ({ id }) => !payload.services.some((serviceId) => serviceId === id)
                          ),
                      }
            );
            draft.application = draft.applications.find(({ current }) => current);
            break;

        case APPLICATION.SELECT:
            draft.applications = draft.applications.map((application) =>
                application.id === payload.application.id
                    ? { ...application, current: true }
                    : { ...application, current: false }
            );
            draft.application = draft.applications.find(({ current }) => current);
            break;

        case APPLICATION.UNSELECT:
            draft.applications = draft.applications.map((application) => ({
                ...application,
                current: false,
            }));
            draft.application = undefined;
            break;

        case APPLICATION.UPDATE:
            draft.applications = draft.applications.map((application) =>
                application.id === payload.application.id
                    ? { ...application, ...payload.application }
                    : application
            );
            draft.application = draft.applications.find(({ current }) => current);
            break;

        default:
            return draft;
    }
};
