import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';
import { AnalyticsProvider } from 'use-analytics';
import IntlProvider from './i18n/IntlProvider';
import registerServiceWorker from './registerServiceWorker';
import { initializeCookieConsent, initializeDefines } from './_helpers';
import { createAnalyticsContext } from './analytics';
import ErrorBoundary from './components/ErrorBoundary';
import App from './App';

const analytics = createAnalyticsContext();

registerServiceWorker();

initializeCookieConsent();
initializeDefines();

ReactDOM.render(
    <AnalyticsProvider instance={analytics}>
        <Router>
            <IntlProvider>
                <ErrorBoundary>
                    <App />
                </ErrorBoundary>
            </IntlProvider>
        </Router>
    </AnalyticsProvider>,
    document.getElementById('app')
);
