import React, { useEffect, useRef } from 'react';
import { getHeaders } from '../../../context';
import { useElements, useResources } from '../../../hooks';
import { ElementWrapper } from '.';
import defaultPoster from '../../../assets/img/no-video.png';

export const Video = ({ element }) => {
    const video = useRef();
    const { updateElement } = useElements();
    const { resources = [] } = useResources();
    const { concrete = {} } = element;
    const { idVideo = null, loops } = concrete;
    const videoRes = resources.find(({ id }) => id === idVideo) || {};

    useEffect(() => {
        let mounted = true;
        if (!videoRes.id) {
            if (idVideo) {
                video.current.src = '';
                updateElement({ concrete: { idVideo: 0 } }, element);
            }
            return;
        }
        fetch(videoRes.url, {
            headers: getHeaders({ auth: true }),
        })
            .then(async (response) => {
                const video = await response.blob();
                return response.ok ? video : Promise.reject();
            })
            .then((blob) => {
                if (mounted) {
                    const src = window.URL.createObjectURL(blob);
                    video.current.removeAttribute('poster');
                    video.current.src = src;
                }
            })
            .catch((err) => {});
        return () => {
            mounted = false;
        };
    }, [video.current, idVideo, videoRes.version]);

    return (
        <ElementWrapper element={element}>
            <div className="element-content">
                <video poster={defaultPoster} loop={loops} ref={video} />
            </div>
        </ElementWrapper>
    );
};
