import { getUnique } from '../../_helpers';

export const RESOURCE = {
    INITIAL_STATE: { resource: undefined, resources: [] },
    CHECK: 'RESOURCE_CHECK',
    CLOSE: 'RESOURCE_CLOSE',
    DELETE: 'RESOURCE_DELETE',
    GET: 'RESOURCE_GET',
    GET_BLOB: 'RESOURCE_GET_BLOB',
    GET_BLOB_URL: 'RESOURCE_GET_BLOB_URL',
    GET_CONCRETE: 'RESOURCE_GET_CONCRETE',
    GET_CONTENT: 'RESOURCE_GET_CONTENT',
    GET_CONTENT_REPLACED: 'RESOURCE_GET_CONTENT_REPLACED',
    GET_TEXT_CONTENT: 'RESOURCE_GET_TEXT_CONTENT',
    LIST: 'RESOURCE_LIST',
    SELECT: 'RESOURCE_SELECT',
    UNSELECT: 'RESOURCE_UNSELECT',
    UNZIP: 'RESOURCE_UNZIP',
    UPDATE: 'RESOURCE_UPDATE',
};

export const ResourcesReducer = (draft, action) => {
    const { payload, type } = action;
    switch (type) {
        case RESOURCE.CHECK:
            draft.resources = draft.resources.map((resource) =>
                payload.resources.some(({ id }) => id === resource.id)
                    ? {
                          ...resource,
                          checked: payload.checked,
                      }
                    : resource
            );
            break;

        case RESOURCE.DELETE:
            draft.resources = draft.resources.filter(
                (resource) => !payload.resources.some(({ id }) => id === resource.id)
            );
            draft.resource = draft.resources.find(({ current }) => current);
            break;

        case RESOURCE.GET:
            draft.resources = getUnique([
                ...draft.resources.map((resource) =>
                    resource.id === payload.resource.id
                        ? {
                              ...payload.resource,
                              ...resource,
                              current: payload.select || resource.current,
                          }
                        : resource
                ),
                { ...payload.resource, current: payload.select },
            ]);
            draft.resource = draft.resources.find(({ current }) => current);
            break;

        case RESOURCE.GET_BLOB:
            draft.resources = draft.resources.map((resource) =>
                resource.id === payload.resource.id ? { ...resource, blob: payload.blob } : resource
            );
            draft.resource = draft.resources.find(({ current }) => current);
            break;

        case RESOURCE.GET_BLOB_URL:
            draft.resources = draft.resources.map((resource) =>
                resource.id === payload.resource.id
                    ? { ...resource, blobUrl: payload.url }
                    : resource
            );
            draft.resource = draft.resources.find(({ current }) => current);
            break;

        case RESOURCE.GET_CONCRETE:
            draft.resources = draft.resources.map((resource) =>
                resource.id === payload.resource.id
                    ? { ...resource, concrete: payload.concrete }
                    : resource
            );
            draft.resource = draft.resources.find(({ current }) => current);
            break;

        case RESOURCE.GET_CONTENT:
            draft.resources = draft.resources.map((resource) => {
                let { entries = [] } = resource;
                entries = entries.map((entry) =>
                    entry.name === payload.entry.name
                        ? { ...entry, content: payload.content }
                        : entry
                );
                return resource.id === payload.resource.id ? { ...resource, entries } : resource;
            });
            draft.resource = draft.resources.find(({ current }) => current);
            break;

        case RESOURCE.GET_CONTENT_REPLACED:
            draft.resources = draft.resources.map((resource) => {
                let { entries = [] } = resource;
                entries = entries.map((entry) =>
                    entry.name === payload.entry.name
                        ? { ...entry, contentReplaced: payload.contentReplaced }
                        : entry
                );
                return resource.id === payload.resource.id ? { ...resource, entries } : resource;
            });
            draft.resource = draft.resources.find(({ current }) => current);
            break;

        case RESOURCE.GET_TEXT_CONTENT:
            draft.resources = draft.resources.map((resource) =>
                resource.id === payload.resource.id
                    ? { ...resource, content: payload.content }
                    : resource
            );
            draft.resource = draft.resources.find(({ current }) => current);
            break;

        case RESOURCE.LIST:
            draft.resources = getUnique(
                [...(payload.reset ? [] : draft.resources), ...(payload.resources || [])].map(
                    (res) => {
                        const _res = draft.resources.find(({ id }) => id === res.id) || {};
                        return {
                            ..._res,
                            ...res,
                        };
                    }
                )
            );
            draft.resource = draft.resources.find(({ current }) => current);
            break;

        case RESOURCE.SELECT:
            draft.resources = draft.resources.map((resource) =>
                resource.id === payload.resource.id
                    ? { ...resource, current: true }
                    : { ...resource, current: false }
            );
            draft.resource = draft.resources.find(({ current }) => current);
            break;

        case RESOURCE.UNSELECT:
            draft.resources = draft.resources.map((resource) => ({ ...resource, current: false }));
            draft.resource = undefined;
            break;

        case RESOURCE.UNZIP:
            draft.resources = draft.resources.map((resource) =>
                resource.id === payload.resource.id
                    ? {
                          ...resource,
                          jsonProperties: payload.jsonProperties,
                          entries: payload.entries,
                      }
                    : resource
            );
            draft.resource = draft.resources.find(({ current }) => current);
            break;

        case RESOURCE.UPDATE:
            draft.resources = draft.resources.map((resource) =>
                resource.id === payload.resource.id
                    ? { ...resource, ...payload.resource }
                    : resource
            );
            draft.resource = draft.resources.find(({ current }) => current);
            break;

        default:
            return draft;
    }
};
