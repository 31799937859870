import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import debounce from 'just-debounce-it';
import { Empty } from 'antd';
import { CardDeck, ModalBody } from 'reactstrap';
import { MODALS } from '../_config';
import { Application, Modal, Search } from '../components';
import { useApplications, useModals, useNearScreen } from '../hooks';

export const Applications = ({ isOpen: isOpenProp }) => {
    const { t } = useTranslation();
    const externalRef = useRef();
    const [isOpen, setIsOpen] = useState(isOpenProp);
    const [query, setQuery] = useState('');
    const { modal, setModal } = useModals();
    const { applications = [], getApplications, loading, page, setPage } = useApplications();
    const filteredApplications = applications.filter(({ Nombre: name }) =>
        name.toLowerCase().includes(query.toLowerCase())
    );

    const { isNearScreen } = useNearScreen({
        externalRef: loading ? null : externalRef,
        once: false,
    });

    const handleSearch = (event) => {
        const { currentTarget } = event;
        const { value } = currentTarget;
        setQuery(value);
    };

    useEffect(() => {
        setIsOpen(modal === MODALS.APPLICATIONS);
    }, [modal]);

    useEffect(() => {
        if (isOpen) {
            getApplications();
        }
    }, [isOpen, page]);

    const debounceHandleNextPage = useCallback(
        debounce(() => setPage((prevPage) => prevPage + 1), 200),
        [setPage]
    );

    useEffect(
        function() {
            if (isNearScreen) debounceHandleNextPage();
        },
        [debounceHandleNextPage, isNearScreen]
    );

    return (
        <Modal
            className="applications"
            isOpen={isOpen}
            title={t('applications.title')}
            actions={
                <>
                    <Search icon={false} onChange={handleSearch} visible={true} />
                </>
            }
            onClosed={() => setModal(null)}
        >
            <ModalBody>
                <CardDeck className="flex-wrap">
                    {filteredApplications.length ? (
                        filteredApplications.map((application) => (
                            <Application key={`${application.id}`} {...application} />
                        ))
                    ) : (
                        <Empty description={t('resources.no_results_found')} />
                    )}
                    <div className="w-100" ref={externalRef} />
                </CardDeck>
            </ModalBody>
        </Modal>
    );
};
