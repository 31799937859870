import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Tabs } from 'antd';
import { Button, CardBody, CardTitle, Input } from 'reactstrap';
import { MODALS } from '../../../_config';
import { getLanguage } from '../../../_helpers';
import { RESOURCE_TYPE } from '../../../context';
import { useModals, useResources, useScreens } from '../../../hooks';
import { ScreenWrapper } from '.';
import { Property } from '..';

const { TabPane } = Tabs;

const ModuleWeb = ({ resource }) => {
    const { updateResource } = useResources();
    const { jsonProperties } = resource;
    const lang = getLanguage();
    const { tabs = [] } = jsonProperties || {};

    const handleJsonPropertiesChange = useCallback(
        (property) => {
            Object.keys(jsonProperties.tabs).forEach((tabIndex) => {
                const tab = jsonProperties.tabs[tabIndex];
                return Object.keys(tab.properties).forEach((propertyIndex) => {
                    const prop = tab.properties[propertyIndex];
                    if (prop.key === property.key) {
                        tab.properties[propertyIndex].value = property.value;
                        return false;
                    }
                    return true;
                });
            });

            updateResource({ jsonProperties }, resource);
        },
        [resource]
    );

    return (
        <Tabs>
            {tabs.map(({ key, label, properties }) => {
                const _label = typeof label === 'string' ? label : label[lang];
                return (
                    <TabPane tab={_label} key={key}>
                        {properties.map((property, i) => (
                            <Property
                                key={property.key}
                                _key={property.key || i}
                                {...property}
                                onChange={handleJsonPropertiesChange}
                            />
                        ))}
                    </TabPane>
                );
            })}
        </Tabs>
    );
};

const OfflineWeb = ({ children, ...screen }) => {
    const { t } = useTranslation();
    const { openModal, setModal } = useModals();
    const { resources = [] } = useResources();
    const { updateConcreteScreen } = useScreens();
    let { concrete = {} } = screen;
    concrete = concrete || {};
    const { idZip } = concrete;
    const zipRes = resources.find(({ id }) => id === idZip) || {};
    const { idModule, jsonProperties } = zipRes;

    const handleSelectZip = () => {
        const filter = ({ type }) => type === RESOURCE_TYPE.ZIP;
        const onClick = (resource) => {
            resource && updateConcreteScreen({ idZip: resource.id }, screen);
            setModal(null);
        };
        openModal(MODALS.RESOURCES, { filter, onClick, target: screen });
    };

    return idModule || jsonProperties ? (
        <ModuleWeb key={`Module-${idModule}-zip-${idZip}`} resource={zipRes} />
    ) : (
        <CardBody>
            <CardTitle>
                <strong>{t('properties.zip')}</strong>
            </CardTitle>
            <Button color="primary" onClick={handleSelectZip}>
                {t('properties.select_zip')}
            </Button>
        </CardBody>
    );
};

const OnlineWeb = ({ children, ...screen }) => {
    const { t } = useTranslation();
    const { updateConcreteScreen } = useScreens();
    const { concrete = {} } = screen;
    const { url = '' } = concrete;

    const handleInputBlur = (event) => {
        const { currentTarget } = event;
        const { value } = currentTarget;
        if (value === url) {
            return;
        }
        updateConcreteScreen({ url: value }, screen);
    };

    return (
        <CardBody>
            <CardTitle>
                <strong>{t('common.url')}</strong>
            </CardTitle>
            <Input defaultValue={url} onBlur={handleInputBlur} />
        </CardBody>
    );
};

export const Web = (props) => {
    let { concrete = {} } = props;
    concrete = concrete || {};
    const { id, idZip, url } = concrete;

    if (!id) {
        return null;
    }

    return (
        <ScreenWrapper {...props}>
            {url === null && <OfflineWeb {...props} />}
            {idZip === null && <OnlineWeb {...props} />}
        </ScreenWrapper>
    );
};
